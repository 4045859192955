import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { Configuration, APIUrls } from '../../../configuration';
import { CovidDeclaration } from '../../models-india/CovidDeclaration';

@Injectable({
    providedIn: 'root'
  })

  export class CoronaService{
    constructor(private _http: LPIndiaCustomHttpClient) { }

    InsertCovidDetails(corona:CovidDeclaration):Observable<Number>
    {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        let body = JSON.stringify(corona);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.InsertCovidDetails,body,{headers:headers}).pipe(map((res) => {return res;}));;
    }
  }