export class CovidDeclaration
{
    public CandidateID:Number;
    public flgCountryVisit:string;
    public CountryName:string;
    public DateofJourney:string;
    public flgFever:string;
    public flgCough:string;
    public flgRespiratoryDistress:string;
    public flgAcknowledge:string;
    public IpAddress:string;
}