import { Component, OnInit } from '@angular/core';
import { ResiAddress } from '../../models-india/resiaddress.model';
import {Router, ActivatedRoute} from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../../app.service';
import { IndiaIdBadgeService } from './idbadgeindia.service';
import { IndiaUserCredentials } from '../../models-india/indiaUserCredentials.model';
import { NgForm } from '@angular/forms';
import { CandidateAdvisorImage } from '../../../lpus/models/candidateAdvisorImage';
import { AlertService } from '../../../utils/alert/alert.service';
import { DashboardIndiaService } from '../../dashboard-india/dashboard-india.service';
import { PostjoiningIndiaService } from 'src/app/lpindia/postjoining/postjoining.serivce';
import { IndiaInputParam } from 'src/app/lpindia/models-india/IndiaInputParam.model';
import { FetchResiAddress } from '../../models-india/fetchresiAddress.model';
import { ResiaddressIndiaService } from '../resiaddress-india/resiaddress-india.service';
import { AuthService } from 'src/app/login/auth.service';
import { NoteConstants } from 'src/app/NotesConstants';

declare function ChgdocsIN() : any;
declare function ChgstatIN() : any;
declare function LoadJsForModalBackDrop(): any;
declare function ChgidIN() : any;
declare function RemoveDefaultBadgeImage():any;
declare function SwipeToLeft(): any;
declare function SwipeToRight(): any;
declare function LoadStartStep1Modal1():any;
declare function StartModalClose1():any;
declare function getElement(id) : any;
declare function LoadOwlCarouselForGallery():any;
declare function LoadJsForDashBoardMobileCarousel() : any;
declare function makeBackActive():any;
declare function makeFrontActive():any;
declare function ShowBottomBar() : any;
declare function LoadStyleForIconsIndia(i: string, e: string, b: string, ba: string, f: string, c: string, edu: string,
  exp: string, p: string, g: string, dis: string, nsr: string, pan: string, aadhar: string, acc: string, med: string, ach: string,  nats: string, statutory: string): any;
@Component({
  selector: 'app-idbadgeindia',
  templateUrl: './idbadgeindia.component.html',
  styleUrls: ['./idbadgeindia.component.css']
})
export class IdbadgeComponentindia implements OnInit {
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
  public imagePath;
  pphone:String="";
  public message: string; 
  imgURL:String;
  showMessage:Boolean;
  fetchresiaddress:FetchResiAddress;
  errorMessage:string;
  emp_no: string;
  emp_firstname: string;
  emp_lastname: string;
  msg:String;
  mobile:Boolean;
  emergencyRC1:string;
  desktop:Boolean;
  uploadImg:Boolean = true;
  userCredentials= new IndiaUserCredentials
  static fileAsBasestring  : string; 
  daysLeft = Number(localStorage.getItem("DaysLeft"));
  empbool:Boolean;
  order:string;
  pendingWeb:Boolean = false;
  addressDetails:ResiAddress[] = [];
  txtTopNote:string;
  txtBottomNote:string;
  txtTitleName:string;
  flgEnableTopNote:boolean;
  flgEnableBottomNote:boolean;
  ibpm:boolean = localStorage.getItem("COMPANY").toString().toUpperCase()=="IBPM INDIA";
  txtTitleNameTemp: string;
  fresher:Boolean= localStorage.getItem("Identification").toUpperCase().trim()=="FRESHER";
  lateral:Boolean= localStorage.getItem("Identification").toUpperCase().trim()!="FRESHER";
  EmailId: string;
  flgCandidateImage:Boolean = localStorage.getItem("flgCandidateImage").toUpperCase() == "Y";
  idbadge:Boolean=false;
emergency:Boolean=false;
ssn:Boolean=false;
address:Boolean=false;
bank:Boolean=false;
basicDetails:Boolean= false;
familyDetails:Boolean= false;
contactDetails:Boolean= false;
educationalDetails:Boolean = false;
experienceDetails:Boolean = false;
passportDetails:Boolean = false;
gapDetails:Boolean = false;
disabilitydetails:Boolean = false;
nsrDetails:Boolean = false;
pandetails:Boolean = false;
aadharDetails:Boolean = false;
accomodation:Boolean = false;
medical:Boolean = false;
achievement:Boolean = false;
placeholder:Boolean = false;
natsDetails:Boolean = false; 
statutory: boolean=false;
message1:Boolean;
params: IndiaInputParam = new IndiaInputParam();
  constructor(
    private idBadgeService:IndiaIdBadgeService,
    private _resiIndiaService:ResiaddressIndiaService,
    private _router:Router,
    private alert:AlertService,
    private breakpointObserver: BreakpointObserver,
    private appService:AppService, 
    private re: ActivatedRoute,
    private dashboard:DashboardIndiaService,
    private _postService: PostjoiningIndiaService,
    private spinner:NgxSpinnerService) { 
    this.userCredentials.CandidateID=Number(localStorage.getItem("intCandidateId"));
    ////need to check this.spinner.show();
    this.re.queryParams.subscribe(params =>{
      this.order = params["order"]
      if(this.order == "W"){
        this.pendingWeb = true;
      }
      else{
        this.pendingWeb = false;
        ChgidIN();
      }
    });
     
  
    }
    SubmitEmergencyContact(form:NgForm){
      // console.log("contact updated");
      let cand = new CandidateAdvisorImage();
      cand.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
      cand.EmergencyContact = form.value.phoneNoRef;
      //console.log(cand)
      this.idBadgeService.InsertEmergencyContact(cand)
      .subscribe(
        res =>
        {
          //console.log(res)
          this.msg = res;
          if(this.order != "N" && this.order != "P" && this.mobile){
            this.CallJsForModalFade1();
            //StartModalClose1();
            
          }
          else{
            this.skiplink();
            }
          //this.alert.success("Done! Emergency contact is updated.",this.mobile);
          // console.log(this.msg);
        }
      );
      
    }
   
  ngOnInit() {
    //console.log(this.daysLeft);
     this.spinner.show();
    this.breakpointObserver
    .observe(['(max-width: 599px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        //this.appService.setIsMobile(true);
        this.mobile = true;
        this.desktop=false;
        window.scroll(0, 0);
        this.re.queryParams.subscribe(params =>{
          this.order = params["order"]
          
        });
        if(this.order==="N"){
          getElement('id');
        }
      } else {
        //this.appService.setIsMobile(false);
        this.mobile = false;
        this.desktop=true;
      }
    });  
    if(this.fresher){
      this.EmailId = "Infosys_LPCampus@infosys.com";
    }
    else{
      if(!this.ibpm){
        this.EmailId="Infosys_Launchpad@infosys.com";
      }
      else if(this.ibpm){
        this.EmailId="IBPM_Launchpad@infosys.com";
      }
    }
    
    this.idBadgeService.fnGetCandPhoto().subscribe(
      (res)=>{
        if(res!=null){
            this.imgURL='data:image/jpg;base64,'+res;
            if(this.imgURL!=null){
              RemoveDefaultBadgeImage();
            }
            else{
              this.imgURL="assets/images/icon_frame_1.png";
            }
          }
        //   else
        //   {
        //     this.imgURL="../../../../assets/images/IDBadgeUpload_default.png";
        // }
        
        //console.log("image came");
         this.spinner.hide()
      }
    )
    this.fetchresiaddress={CandidateId:parseInt(localStorage.getItem("intCandidateId")),AddressType:""}

    this.idBadgeService.GetEmergencyContact()
    .subscribe(
      res=>{
        if(res!=null){
          this.pphone = res;
          // console.log(this.pphone);
        }
        
      }
    );
    this.emp_no = localStorage.getItem("EmpNumber");
    this.emp_firstname = localStorage.getItem("txtCandFirstName");
    this.emp_lastname = localStorage.getItem("txtCandLastName");
    //console.log(this.emp_no)
    if ( this.daysLeft > 0){
      this.empbool = false;
    }
    else if ( this.daysLeft <= 0 && this.emp_no!=null && this.emp_no!=""){
      this.empbool = true;
    }
    else {
      this.empbool=false;
    }  
    
    this.getAddressdetails();

     //Fetch top and bottom notes
     if(AuthService.HeadingList.length>0){
      for (let ele of AuthService.HeadingList){
       if (ele.txtSectionCode!=null && ele.txtSectionCode==NoteConstants.txtIDBadgeIndiaSectionCode){
        const tempTitle=ele.txtTitle.split(',')
        this.txtTopNote=ele.txtTopNote
        this.txtTitleName=tempTitle[0]
        this.txtTitleNameTemp=tempTitle[1]
           
           if(ele.flgEnableBottomNote=="Y"){
             this.flgEnableBottomNote=true
           }
           else{
             this.flgEnableBottomNote=false
           }
           if(ele.flgEnableTopNote=="Y"){
             this.flgEnableTopNote=true
           }
           else{
             this.flgEnableTopNote=false
           }
       }
       
     }
   }
   else{
     this.flgEnableBottomNote=true
     this.flgEnableTopNote=true
     this.txtTitleNameTemp=NoteConstants.txtIDBadgeTopNote
     this.txtTitleName=NoteConstants.txtIDBadgeIndiaTitle
     this.txtTopNote=NoteConstants.txtEmergencyDetailsTopNote
     
   }
  }


  getAddressdetails(){
        this._resiIndiaService.GetAddressDetails(this.fetchresiaddress).subscribe(
          response => {
            this.addressDetails = response ;
             this.spinner.hide();
             //console.log(this.addressDetails)            
            for (var arr of this.addressDetails){
              if(arr.txtAdressType=="Emergency Address"){
                this.emergencyRC1=arr.txtCell;
                this.pphone=this.emergencyRC1;
                
              }
            }
             this.spinner.hide();          
        }
        )   
  }


  skiplink(){
    if(this.order!="N" || !this.mobile){
      if(this.order=="P" && this.mobile){
        LoadOwlCarouselForGallery();
        LoadJsForDashBoardMobileCarousel();
        this.getPendingAction();
        this.appService.setHiddenDashBoard(false);
        this._router.navigateByUrl('lpau/step1-au/dummy', { skipLocationChange: true });
          setTimeout(() => this._router.navigate(['../lpindia']),3000);
          setTimeout(() => ShowBottomBar(),3000);
      }else if(this.order == "W" ){
        StartModalClose1();  
        this._router.navigate(['../lpindia/postjoining/idbadge'],{ queryParams: { order: "W" } })
      }
      else if(this.order == "W" && !this.mobile){
        StartModalClose1();  
        this._router.navigate(['../lpindia/postjoining/idbadge'],{ queryParams: { order: "W" } })
      } 
      else{
      StartModalClose1();  
      ChgdocsIN();
      this._router.navigate(['../placeholder'], { relativeTo: this.re });
      // ChgstatIN();
      // this._router.navigate(['../statutory'], { relativeTo: this.re });
      }
    }
    else{
      this._router.navigate(['notification']);
    }  
  }

  CallJsForModalFade(){
    //alert('I am inside Back drop');
    LoadJsForModalBackDrop();
  }
  swipe(currentIndex: number, action = this.SWIPE_ACTION.RIGHT) {
    // console.log('Inside swipe event');
    if (action === this.SWIPE_ACTION.RIGHT){
      //console.log("right")
      SwipeToRight();
    }
     if (action === this.SWIPE_ACTION.LEFT) {
      //console.log("left")
      SwipeToLeft();
     }
}
CallJsForModalFade1(){
  if(this.order!= "N" && this.order != "P")
  {
  LoadStartStep1Modal1();
  LoadJsForModalBackDrop();
  }
}
getPendingAction(){
  let params = new IndiaInputParam();
    params.CandidateId=parseInt(localStorage.getItem("intCandidateId"));
    params.EmailId=localStorage.getItem("txtEmailId");
    this._postService.getPendingActions(params)
    .subscribe(
      resDashboardIN=>{
        if(resDashboardIN!=null)
        {
        // console.log(res);
        LoadStyleForIconsIndia(resDashboardIN.ImageUpload, resDashboardIN.Emergency,
          resDashboardIN.BANK, resDashboardIN.BasicDetails, resDashboardIN.FamilyDetails,
          resDashboardIN.ContactDetails, resDashboardIN.EducationDetails,
          resDashboardIN.ExperienceDetails, resDashboardIN.PassportDetails, resDashboardIN.GapDetails,
          resDashboardIN.Disabilitydetails, resDashboardIN.NSRDetails,
          resDashboardIN.Pandetails, resDashboardIN.AadharDetails, resDashboardIN.Accommodation,
          resDashboardIN.Medical, resDashboardIN.Achievement, resDashboardIN.NatsDetails, resDashboardIN.Statutory);
        }
    } );
}

MakeFrontActive(){
  makeFrontActive()
}

MakeBackActive(){
  makeBackActive()
}
}
