import { HttpErrorResponse,HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
//import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class LPMainInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = localStorage.getItem("Token") || '';
    const authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+ authToken
      })
    });
    return next.handle(authReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          return throwError("Not Authorized");
        } else {
          return throwError(error);
        }
      })
    ); 
  }
}

export class HttpInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
      // execute an interceptor and pass the reference to the next handler
      return this.interceptor.intercept(req, this.next);
  }
}

@Injectable()
export class CustomMainHttpClient extends HttpClient {
  constructor(handler: HttpBackend, interceptors: HttpInterceptor[]) {
    const chain = interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor), handler);

    super(chain);
  }
}


@Injectable()
export class LPMainCustomHttpClient extends CustomMainHttpClient {
  constructor(handler: HttpBackend) {
    const interceptors: HttpInterceptor[] =[new LPMainInterceptor()];
    super(handler, interceptors);
  }
}
