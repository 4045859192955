import { Injectable } from '@angular/core';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { Configuration, APIUrls } from '../../../configuration';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})
export class OnBoardingVideoService
{

    constructor(private _http:  LPIndiaCustomHttpClient ) { }

    UpdateVideoSeenFlag():  Observable<String>{


                return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateVideoSeenFlag,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))},
            ).pipe(map(
            (res) => {
            return res;
        } 
    ))
    } 

}