import { Component, Input, Output, OnInit, EventEmitter, Inject } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../app.service';
import { Candidate } from '../models-india/Candidate';
import { DashboardIndiaService } from '../dashboard-india/dashboard-india.service';
import { AlertService } from '../../utils/alert/alert.service';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';

import {MatDialog,  MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
//import { DialogData } from '../step1onboarding-india/personal/personal.component';


@Component({
  selector: 'app-bgvcheck',
  templateUrl: './bgvcheck.component.html',
  styleUrls: ['./bgvcheck.component.css']
})
export class BgvcheckComponent implements OnInit {
mobile: boolean;
candidate: Candidate;
  constructor(
    private breakpointObserver:BreakpointObserver,
    private appService: AppService,
    private dashboard: DashboardIndiaService,
    private alert:AlertService,
    public activeModal: NgbActiveModal,
    public modalRef: MatDialogRef<BgvcheckComponent>,
    private spinner:NgxSpinnerService
   // @Inject(MAT_DIALOG_DATA) public data: DialogData,
  ) { }
 // @Input() secName;
 // @Output() updateSectionEvent = new EventEmitter<any>();

  ngOnInit() {


    this.breakpointObserver
    .observe(['(max-width: 599px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        //this.appService.setIsMobile(true);
        this.mobile = true;

      } else {
        //this.appService.setIsMobile(false);
        this.mobile = false;

      }
    });

  }

  // updateSection(value: any) {
  //   this.updateSectionEvent.emit(value);
  // }
  updateBGV(){
    this.appService.updateForm = true;
    this.candidate = { CandidateID: parseInt(localStorage.getItem("intCandidateId")) }
    this.dashboard.UpdateBGVStatus(this.candidate).subscribe(
      (res) => {
        // this.alert.success("Done! BGV Initiated.",this.mobile);
        //   this.alert.success("Done! BGV Initiated.",!this.mobile);
        // if(res != null){
        //   this.bgv = res;
        //   if(this.bgv.flgBGV == 'Y')
        //   {this.flgBGV = true;}
        // }
        // else {}
        this.spinner.hide();
      }
    );

  }
  onNoClick() {
    this.dashboard.modalClose = true;
    this.modalRef.close();
  }
}