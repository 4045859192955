import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { IndiaUserCredentials } from '../models-india/indiaUserCredentials.model';
import { IndiaCandidateAdvisorImage } from '../models-india/indiaCandidateAdvisorImage.model';
import { Candidate } from '../models-india/Candidate';
import { LPIndiaCustomHttpClient } from '../lpindia.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { LPFetchProfileStatus } from '../models-india/LPFetchProfileStatus';
import { IndiaInputParam } from '../models-india/IndiaInputParam.model';
import { LpGetOnboardingAdvisorDetails } from '../models-india/LpGetOnboardingAdvisorDetails';
import { UpdateAttendanceDetails, GetAttendanceDetails, DatatoGetDetails } from '../models-india/Attendance.model';
import { LPFetchLaptopShipDetails } from '../models-india/LPFetchLaptopShipmentDetails';
import { BGV,  BGVPopup , UpdateBGVflg ,SectionShow } from '../models-india/GDC.model';
import { FacialRecognitionStatus } from '../models-india/FacialRecoginition';
import { ReturnStatement } from '@angular/compiler';

@Injectable({
    providedIn: 'root'
})

export class DashboardIndiaService {
  seeAll:boolean;
  infyd:boolean;
  infyLeaders:boolean;
  showProceedScreen:boolean=true;
  pendingAction :string;
  Actions:boolean=false;
  modalClose: boolean=false;
  constructor(private http: LPIndiaCustomHttpClient){
    }

    GetProfileStatus(data:Candidate):Observable<LPFetchProfileStatus>{
      // console.log(Configuration.ResServerBaseURLIndia+APIUrls.GetProfileStatus);
      return this.http.post<LPFetchProfileStatus>(Configuration.ResServerBaseURLIndia + APIUrls.GetProfileStatus,data,
        ).pipe(map(
        (res) => {
          
          return res;
        }
      ))
    }
    GetVaccineDetail():Observable<any>
    {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiIsIng1dCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyIsImtpZCI6Im5PbzNaRHJPRFhFSzFqS1doWHNsSFJfS1hFZyJ9.eyJhdWQiOiJiMTg3NDYyNy1lMzlkLTQ5NzYtYjM3MC1hMGVlN2UzODNhMjQiLCJpc3MiOiJodHRwczovL3N0cy53aW5kb3dzLm5ldC82M2NlN2Q1OS0yZjNlLTQyY2QtYThjYy1iZTc2NGNmZjVlYjYvIiwiaWF0IjoxNjMxMDkwMjIxLCJuYmYiOjE2MzEwOTAyMjEsImV4cCI6MTYzMTA5NDEyMSwiYWNyIjoiMSIsImFpbyI6IkFUUUF5LzhUQUFBQUd5K0VYWHBrblkzZk5hTGJSNWdSbUJ3d2ZPSUIyMmRoQ096c2JnZmI5cCtpZk15ZWE4T0diLzlxWDVJZUxINjMiLCJhbXIiOlsicHdkIiwicnNhIl0sImFwcGlkIjoiYjE4NzQ2MjctZTM5ZC00OTc2LWIzNzAtYTBlZTdlMzgzYTI0IiwiYXBwaWRhY3IiOiIxIiwiZGV2aWNlaWQiOiJlZTZiMWQ0Ni01ODI0LTRkMTYtODIxYy1iMzE2OTNlZDlmZjAiLCJmYW1pbHlfbmFtZSI6IlZlcm1hIiwiZ2l2ZW5fbmFtZSI6IkFrYXNoIiwiaXBhZGRyIjoiMTIyLjE2My4yMTAuMjUiLCJuYW1lIjoiQWthc2ggVmVybWEiLCJvaWQiOiIzNmQ3NzBmYi1jMWY4LTQ0NzktODcyNC0wOTI2Mjc0MTE2MjQiLCJvbnByZW1fc2lkIjoiUy0xLTUtMjEtMjY2NzQ5OTQwLTE2Mzc5NjQ0NDQtOTI5NzAxMDAwLTM5NTg1ODciLCJyaCI6IjAuQVFZQVdYM09ZejR2elVLb3pMNTJUUDlldGlkR2g3R2Q0M1pKczNDZzduNDRPaVFHQUpzLiIsInNjcCI6IlVzZXIuUmVhZCIsInN1YiI6Il9VOGFvYm1EcE16WjYxNnA1d3NrRmp3THV4OTh6ajNvcGJ6cXEzY1F0cU0iLCJ0aWQiOiI2M2NlN2Q1OS0yZjNlLTQyY2QtYThjYy1iZTc2NGNmZjVlYjYiLCJ1bmlxdWVfbmFtZSI6ImFrYXNoLnZlcm1hMDJAYWQuaW5mb3N5cy5jb20iLCJ1cG4iOiJha2FzaC52ZXJtYTAyQGFkLmluZm9zeXMuY29tIiwidXRpIjoiNnRRaVZXc3lYRUdVOVlhQ1BwSEpBQSIsInZlciI6IjEuMCIsIkVtcE5vIjoiMTA3MjI2NSJ9.LCEyXK9_ka80-7nLBKJgUuh0zr6E5YGUJqBB6QYS-ncSSs-kkQz51liFtAPs2jeewcDOFNbedJ_Grz2D1FYJSsbsvgKziqrQrogFUd-bhRMr2GOXHRdYrk4mz1L32YANzWPq7nPFowai5-wlIpslib0HjtYGANOipWZfwYm5dg5Z2B2CAxge7fNk_k14-C8zhlY9JXbdVnM11j3QnpMxamiucWxvWbZa4R-hystx25PSXU4m99OLqIVn1oq6mIQ2-OUxmMAbRypUf8tacLAtH1_eJ94foC3a2QD2pXiQ6Rz-JFd0m5GmvqMftjvwVZzlDJsEq5YnQwKMOLwDnzNpNw');
        headers=headers.append('Content-Type','application/json');
        return this.http.post<any>(Configuration.ResServerBaseURLIndia+APIUrls.GetVaccineDetails,{headers:headers}).pipe(map((res) => {return res;}));;
    }
    
    fnGetCandPhoto(userCredentials:IndiaUserCredentials): Observable<IndiaCandidateAdvisorImage> {
      // console.log(Configuration.ResServerBaseURLIndia+APIUrls.fnGetCandPhotoIN);
      var Headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
      Headers=Headers.append('Content-Type','application/json')
      return this.http.post<IndiaCandidateAdvisorImage>(Configuration.ResServerBaseURLIndia+APIUrls.fnGetCandPhotoIN,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))},{
      headers:Headers
      }).pipe(map(
      (res) => {
      return res;
  } 
))  
}
GetOnboardingAdvisorDetails(input:IndiaInputParam):Observable<LpGetOnboardingAdvisorDetails[]>{
  var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<LpGetOnboardingAdvisorDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetOnboardingAdvisorDetails,input,{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
  }))
}

FetchJoiningAnchorDetails(input:IndiaInputParam):Observable<LpGetOnboardingAdvisorDetails[]>{
  var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<LpGetOnboardingAdvisorDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchJoiningAnchorDetails,input,{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
  }))
}

GetAttendanceDetails(input:DatatoGetDetails):Observable<GetAttendanceDetails[]>{
  var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<GetAttendanceDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetAttendanceDetails,input,{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
  }))
}

InsertAttendanceDetails(input:UpdateAttendanceDetails):Observable<Number>{
  var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.InsertAttendanceDetails,input,{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
  }))
}
GetEnteredAttendanceDetails(input:UpdateAttendanceDetails):Observable<UpdateAttendanceDetails[]>{
  var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<UpdateAttendanceDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetEnteredAttendanceDetails,input,{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
  }))
}

GetLPLaptopShipmentDetailsService(data:Candidate):Observable<LPFetchLaptopShipDetails[]>
{
var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4MzgwOGFhYi01MDFiLTRlMGMtOTVjYi1lODg2YTA1NzRiOTMiLCJleHAiOjE1NTU1Njc2NTgsIm5iZiI6MCwiaWF0IjoxNTU0MjcxNjU4LCJpc3MiOiJodHRwczovL3dpbmdzcGFuLWRldi5pbmZvc3lzYXBwcy5jb20vYXV0aC9yZWFsbXMvSVMxIiwiYXVkIjoicG9ydGFsIiwic3ViIjoiOTNiOWYwZDktODRmNy00NWY1LTkzMDEtNmM0YTAzMDg5M2Y4IiwidHlwIjoiSUQiLCJhenAiOiJwb3J0YWwiLCJub25jZSI6ImY5ZWYzNDUxLWEzYTctNDc1Mi04YmNhLTQxYjg0NTg1YWU0YyIsImF1dGhfdGltZSI6MTU1NDIwMzM5Miwic2Vzc2lvbl9zdGF0ZSI6IjA5NjFhMDNiLTU2NGItNGYwNy1iMjRjLTgzN2FlYmJhNjY0MCIsImFjciI6IjAiLCJuYW1lIjoiQWJoaXNoZWsgTmVnaSIsInByZWZlcnJlZF91c2VybmFtZSI6InRlc3QxQGR1bW15LmNvbSIsImdpdmVuX25hbWUiOiJBYmhpc2hlayIsImZhbWlseV9uYW1lIjoiTmVnaSIsImVtYWlsIjoidGVzdDFAZHVtbXkuY29tIn0.ncBiZlVLJa5QHpe8KwWlOjcnpoF4rnbuQd70_2vSQbrpy0qdP7-AzLDadEp5U03mjzlWWEJR0KegfwVrJuQIdxRHurlJbZ2M7MCHdWroi4RCDCI4HzDSbvyFsDyLrZgg0WEuo5YO7b4Up1JN4sfVro1MfqqCBoimWV63HfDGC0XKJ6hMVt1UC-sX3NfqO4xtC3w5lTfv84wSmdWGhQNKvaTOlHhjqyXTDg8c2UrCaOvbu-jk3L_hOdNOogizMqIufyBhmOMsJusuO-hH5sQuX5L0BA7DFF1plwqyXm9Y5DdjSVApDYiHr5GCgdVCitLAXmCWN1yA3WlnrZGHATr-yw');
headers=headers.append('Content-Type','application/json');

return this.http.post<LPFetchLaptopShipDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetLaptopShipmentDetails,data,
  {
    headers:headers
  }
  ).pipe(map((res)=>{
    return res;
  }))
}
GetBGVStatus(data:Candidate):Observable<BGV>{
  return this.http.post<BGV>(Configuration.ResServerBaseURLIndia+APIUrls.BGVStatus,data).pipe(map(
      (res) => {
        return res;
      }
      )) 
 }
 FetchBGVPopupStatus(data:SectionShow):Observable<BGVPopup>{
  return this.http.post<BGVPopup>(Configuration.ResServerBaseURLIndia+APIUrls.BGVPopupStatus ,data).pipe(map(
      (res) => {
        return res;
      }
      )) 
 }
 UpdateBGVStatus(data:Candidate):Observable<UpdateBGVflg>{
  return this.http.post<UpdateBGVflg>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateBGVStatus ,data).pipe(map(
      (res) => {
        return res;
      }
      )) 
 }
 GetLPOfferLetter(CandidateId:string):Observable<any>{
  return this.http.post<any>(Configuration.ResServerBaseURLIndia+APIUrls.GetOfferLetter,{ "CandidateId": Number(CandidateId) 
  }).pipe(map(
    (res) => {
    return res;

    }
    )) 

}
FetchFacialRecognitionStatus(data:Candidate):Observable<FacialRecognitionStatus>{
  return this.http.post<FacialRecognitionStatus>(Configuration.ResServerBaseURLIndia+APIUrls.FetchFacialRecoginitionRStatus,data).pipe(map(
      (res) => {
        return res;
      }
      )) 
 }

 UpdateFRDate(data:Candidate):Observable<Number>{
  return this.http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateFRData,data).pipe(map(
      (res) => {
        return res;
      }
      )) 
 }
}