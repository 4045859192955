import { HttpErrorResponse,HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
//import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class LPAUInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('Intercepted!', req);
    const authToken = localStorage.getItem("Token") || '';
    const authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+ authToken
      })
    });
    // const authReq = req.clone({
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJjMmJlNTI3Mi0yM2JhLTRjY2UtYTNhYS01MjVjMWU4YmViZTYiLCJleHAiOjE1NjA0OTQ1NDUsIm5iZiI6MCwiaWF0IjoxNTU5MTk4NTQ1LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI1OTQ0Y2U5YS0wZjlmLTRhMjktYTU5Ny05ZDdlNWRhY2VjZWQiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiNjQ4OGFjYTQtMzRhMi00OGM1LWFkMDYtY2NjZGFiYzAwZmI2IiwiYXV0aF90aW1lIjoxNTU5MTk4NTQ1LCJzZXNzaW9uX3N0YXRlIjoiNTIzZTAwMGEtN2Q0Mi00OTQ2LThhMjYtNGY4YWJmZDE5NDVlIiwiYWNyIjoiMSIsIm5hbWUiOiJEaXZ5YW5zaHUgQmhhcmdhdmEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJkaXZ5YW5zaHVAaW5mb3N5cy5jb20iLCJnaXZlbl9uYW1lIjoiRGl2eWFuc2h1IiwiZmFtaWx5X25hbWUiOiJCaGFyZ2F2YSIsImVtYWlsIjoiZGl2eWFuc2h1QGluZm9zeXMuY29tIn0.fjMtvH6qQ8_ifPTIKzzIvuT45XV4yese1cIBD1keiJE2h-xdbID3I8yQMGc_sTx4j_vpWi-ryyLBjeyqEAhGkWCzKwbslEMGvJSQpGuCNg9jMQu6GZvGSf8pwgRSfJSlaY0gj5i3LpTwT2Jqds8JghYwWZOmNwBky1pFzGhVeFP0Ijtti1qib0trTBWkBAVmoQm7543X_sMz4PYRYT9RBBiptnPgMzLFLgSApLcLJ7j8TOqRsBXkDYPHhmuPQk6EiOVk_9Un45inhuRG5hbfq9Tb9zap3FxAGVZWp40u_BUMouwMdtLLGm4wpF-0frPsC9d101VM1lApEHgtY-69mw'
    //   })
    // });
    return next.handle(authReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token
          // console.log("Inside custom error "+error.message);
          return throwError("Not Authorized");
        } else {
          // console.log("Inside custom error "+error.message);
          // if (error.status === 404){
          //   return throwError("Not found");
          // }
          return throwError(error);
        }
      })
    ); 
  }
}

export class HttpInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
      // execute an interceptor and pass the reference to the next handler
      return this.interceptor.intercept(req, this.next);
  }
}

@Injectable()
export class CustomAUHttpClient extends HttpClient {
  constructor(handler: HttpBackend, interceptors: HttpInterceptor[]) {
    const chain = interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor), handler);

    super(chain);
  }
}


@Injectable()
export class LPAUCustomHttpClient extends CustomAUHttpClient {
  constructor(handler: HttpBackend) {
    const interceptors: HttpInterceptor[] =[new LPAUInterceptor()];
    super(handler, interceptors);
  }
}