// import { RequestOptions,RequestMethod,Headers } from '../../../node_modules/@angular/http';
// import {Response} from '@angular/http';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'; 
import { IndiaUserCredentials } from '../../models-india/indiaUserCredentials.model';
import { IndiaCandidateAdvisorImage } from '../../models-india/indiaCandidateAdvisorImage.model';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { CandidateAdvisorImage } from '../../../lpus/models/candidateAdvisorImage';
import { Configuration, APIUrls } from '../../../configuration';
@Injectable()
export class IndiaIdBadgeService {
    constructor(private http: LPIndiaCustomHttpClient) { }
    fnGetCandPhoto(): Observable<IndiaCandidateAdvisorImage> {
      return this.http.post<IndiaCandidateAdvisorImage>(Configuration.ResServerBaseURLIndia+APIUrls.fnGetCandPhotoIN,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))}
      ).pipe(map(
      (res) => {
      return res;
  } 
))  
}

  GetEmergencyContact():Observable<String>
  {
    var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this.http.post<String>(Configuration.ResServerBaseURLIndia+APIUrls.GetEmergencyContactIN,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
    } 
  )) 
  }

  InsertEmergencyContact(data:CandidateAdvisorImage):Observable<String>
  {
    var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
    headers=headers.append('Content-Type','application/json')
    return this.http.post<String>(Configuration.ResServerBaseURLIndia+APIUrls.InsertEmergencyContactIN,data,{
    headers:headers
    }).pipe(map(
    (res) => {
    return res;
} 
))
  }
    }
        