import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import {map} from 'rxjs/operators';
import { LPMainCustomHttpClient } from '../../lpmain.interceptor';
import * as CryptoJS from 'crypto-js';
import { SSNOTP, SSNDetails} from '../../Model-Classes/SSN.model';
import { Configuration, APIUrls } from '../../../configuration';

@Injectable()
export class GeosSSNService{
    /**
     *
     */
    constructor(private _http:LPMainCustomHttpClient) { }

    GenerateLPUSSSNOTP(otp:SSNOTP):Observable<boolean>{
        
        return this._http.post<boolean>(Configuration.ResServerBaseURL+APIUrls.GenerateLPUSSSNOTP,otp).pipe(map(
            (res) => {
            //console.log(res);
            return res;
            }
            )) 
    }

    ValidateLPUSSSNOTP(otp:SSNOTP):Observable<boolean>{
        
        return this._http.post<boolean>(Configuration.ResServerBaseURL+APIUrls.ValidateLPUSSSNOTP,otp).pipe(map(
            (res) => {
            //console.log(res);
            return res;
            }
            )) 
    }

    InsertSSNDetails(ssndetails:SSNDetails):Observable<string>{
        
        return this._http.post<string>(Configuration.ResServerBaseURL+APIUrls.InsertSSNDetails,ssndetails).pipe(map(
            (res) => {
            //console.log(res);
            return res;
            }
            )) 
    }

    InsertBSNDetails(ssndetails:SSNDetails):Observable<string>{
        
        return this._http.post<string>(Configuration.ResServerBaseURL+APIUrls.InsertBSNDetails,ssndetails).pipe(map(
            (res) => {
            //console.log(res);
            return res;
            }
            )) 
    }

    GetSSNDetails(ssndetails:SSNDetails):Observable<SSNDetails>{
        
        return this._http.post<SSNDetails>(Configuration.ResServerBaseURL+APIUrls.GetSSNDetails,ssndetails).pipe(map(
            (res) => {
            //console.log(res);
            return res;
            }
            )) 
    }

    GetBSNDetails(ssndetails:SSNDetails):Observable<SSNDetails>{
        
        return this._http.post<SSNDetails>(Configuration.ResServerBaseURL+APIUrls.GetBSNDetails,ssndetails).pipe(map(
            (res) => {
            //console.log(res);
            return res;
            }
            )) 
    }
   
    set(keys, value){
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        //console.log(key);
        var encrypted = CryptoJS.AES.encrypt(CryptoJS.enc.Utf8.parse(value.toString()), key,
        {
            keySize: 136 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return encrypted.toString();
    }
    
    get(keys, value){
        var key = CryptoJS.enc.Utf8.parse(keys);
        var iv = CryptoJS.enc.Utf8.parse(keys);
        //console.log(key);
        var decrypted = CryptoJS.AES.decrypt(value, key, {
            keySize: 136 / 8,
            iv: iv,
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7
        });
    
        return decrypted.toString(CryptoJS.enc.Utf8);
    }
}