import { Injectable } from '@angular/core';
import {  EmpAddress} from "../../models/empAddress.model"
import { CityOnSate } from '../../models/cityOnSate.model';
import { Country } from '../../models/country.model';
import { State} from '../../models/state.model';

import {HttpClient,HttpClientModule, HttpErrorResponse, HttpHeaders} from '@angular/common/http' 
import { Observable } from 'rxjs';
import {map} from 'rxjs/operators';
import { lpUserCredentials, lpUserCredentials1 } from '../../models/lpUserCredentials';
import { LPUSCustomHttpClient } from '../../lpus.interceptor';
import { Configuration, APIUrls } from '../../../configuration';
import { LaptopAddressUS } from 'src/app/models/LaptopAddressUS.model';

@Injectable()
export class ResiaddressService {
    address=new EmpAddress();
    lPUserCredentials=new lpUserCredentials();
    country=new Country();
    state=new State();
    
    constructor(private _http:LPUSCustomHttpClient) { }
    GetUserAddressDetails(addresstype:string):Observable<EmpAddress[]> 
    {  this.lPUserCredentials.AddressType=addresstype;
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
    headers=headers.append('Content-Type','application/json');
    let body = JSON.stringify(this.lPUserCredentials);
    return this._http.post<EmpAddress[]>(Configuration.ResServerBaseURL+APIUrls.GetUserAddressDetails,body,{
    headers:headers
    }).pipe(map(
    (res) => {
    //console.log(res);
    return res;
    }
    ))
    }
    GetCountry():Observable<Country[]> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
        headers=headers.append('Content-Type','application/json');
        return this._http.post<Country[]>(Configuration.ResServerBaseURL+APIUrls.GetCountry,null,{
        headers:headers
        }).pipe(map(
        (res) => {
        //console.log(res);
        return res;
        }
        ))  
        }
         
    GetStates(countryName:String,countryCode:String):Observable<State[]>  
    {this.country.CountryCode=countryCode;
        this.country.CountryName=countryName;
         var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
    headers=headers.append('Content-Type','application/json');
    let body = JSON.stringify(this.country);
    return this._http.post<State[]>(Configuration.ResServerBaseURL+APIUrls.GetStates,body,{
    headers:headers
    }).pipe(map(
    (res) => {
    //console.log(res);
    return res;
    }
    ))  
    }
      
   GetCitiesOnState (StateCode:String,StateName:String,CountryCode:String):Observable<CityOnSate[]> 
   {this.state.CountryCode=CountryCode;
    this.state.StateCode=StateCode;
    this.state.StateName=StateName;
    var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
   headers=headers.append('Content-Type','application/json');
   let body = JSON.stringify(this.state);
   return this._http.post<CityOnSate[]>(Configuration.ResServerBaseURL+APIUrls.GetCitiesOnState,body,{
   headers:headers
   }).pipe(map(
   (res) => {
   //console.log(res);
   return res;
   }
   ))  
   }
   
   
   InsertAddressDetailsNew(newadress:EmpAddress):Observable<String> 
   {var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
   headers=headers.append('Content-Type','application/json');
    let body = JSON.stringify(newadress);
    //console.log(body);
   return this._http.post<String>(Configuration.ResServerBaseURL+APIUrls.InsertAddressDetailsNew,body,{
   headers:headers
   }).pipe(map(
   (res) => {
   //console.log(res);
   return res;
   }
   ))  
   }
   GetInfyDirectoryFlag ():Observable<lpUserCredentials1>{
    var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
    headers=headers.append('Content-Type','application/json')
    return this._http.post<lpUserCredentials1>(Configuration.ResServerBaseURL+APIUrls.GetInfyDirectoryFlag,{"CandidateId":Number(localStorage.getItem("CandidateID"))},{
    headers:headers
    }).pipe(map(
    (res) => {
        //console.log(res);
    return res;
} 
))  
 }

 InsertLaptopAddressDetailsUS(newadress:LaptopAddressUS):Observable<String> 
   {var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
   headers=headers.append('Content-Type','application/json');
    let body = JSON.stringify(newadress);
    //console.log(body);
   return this._http.post<String>(Configuration.ResServerBaseURL+APIUrls.InsertLaptopAddressDetailsUS,body,{
   headers:headers
   }).pipe(map(
   (res) => {
   //console.log(res);
   return res;
   }
   ))  
   }
}
   


  
   
