import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
// import { lpUserCredentials } from '../models/lpUserCredentials';
// import { CandidateAdvisorImage } from '../models/candidateAdvisorImage';
// import { Candidatedata } from '../models/Candidatedata.model';
// import { OnboardingAdvisorDetail } from '../models/OnboardingAdvisorDetail.model';
// import { USPreJoiningLogin } from '../models/USPreJoiningLogin.model';
import { LPMainCustomHttpClient } from '../lpmain.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { StepCompletion } from '../../lpus/models/Candidatedata.model';
import { SeniorExecDetails } from 'src/app/lpus/models/policy.model';
import { FaqBenefit } from '../Model-Classes/Faq.model';
import { PendingActions } from '../Model-Classes/PendingActions.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
@Injectable({
    providedIn: 'root'
})

export class DashboardMainService {
  staticMain:string;
  staticMainContent:string;
  kyw:boolean;
  infyd:boolean;
  infyleaders:boolean;
  seeAll:boolean;
  showProceedScreen:boolean=true;
  OnboardPercent: StepCompletion[] = [];
  constructor(private http: LPMainCustomHttpClient){
    }
;

  private Deccflag = new BehaviorSubject<boolean>(false);
  Brcast = this.Deccflag.asObservable();
  
  NewDecFlag(newFlg) {
    this.Deccflag.next(newFlg);
  }
  private Dccflag = new BehaviorSubject<boolean>(false);
  Bcast = this.Dccflag.asObservable();
  
  NewDeFlag(nwFlg) {
    this.Dccflag.next(nwFlg);
  }

  private DccflagTaiwan = new BehaviorSubject<boolean>(false);
  BcastTaiwan = this.DccflagTaiwan.asObservable();
  
  NewDeFlagTaiwan(nwFlgTaiwan) {
    this.DccflagTaiwan.next(nwFlgTaiwan);
  }
  private DccflagHongKong = new BehaviorSubject<boolean>(false);
  BcastHongKong = this.DccflagHongKong.asObservable();
  
  NewDeFlagHongKong(nwFlgHongKong) {
    this.DccflagTaiwan.next(nwFlgHongKong);
  }
  private DccflagNewZealand = new BehaviorSubject<boolean>(false);
  BcastNewZealand = this.DccflagNewZealand.asObservable();

  NewDeFlagNewZealand(nwFlgNewZealand) {
    this.DccflagNewZealand.next(nwFlgNewZealand);
  }
  private DccflagChina = new BehaviorSubject<boolean>(false);
  BcastChina = this.DccflagChina.asObservable();

  NewDeFlagChina(nwChina) {
    this.DccflagChina.next(nwChina);
  }
    getOnboardingPercentage():Observable<StepCompletion[]>{
      // console.log(Configuration.ResServerBaseURL+APIUrls.getOnboardingPercentage)
      
      var Headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
      Headers=Headers.append('Content-Type','application/json')
      return this.http.post<StepCompletion[]>(Configuration.ResServerBaseURL+APIUrls.getOnboardingPercentageGlobal,{"CandidateEmail":localStorage.getItem("CandidateEmailID"),"CandidateId":localStorage.getItem("CandidateID")},{
        headers:Headers
      }).pipe(map(
        (res) => {
          
          return res;
        }
      ))
    }
    GetFAQContentDetails(user:FaqBenefit):Observable<FaqBenefit[]>{
      
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJmOTM1NDFmOC1iMTJmLTQ0ZTgtYjFjYS1mZTU0ZTA1ZGZkOGIiLCJleHAiOjE1NjM4NjA0NjEsIm5iZiI6MCwiaWF0IjoxNTYyNTY0NDYxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiYmY5Nzk1MDAtMDkxMi00MjE4LTk4MGEtODI2M2E3NzdjZDVhIiwiYXV0aF90aW1lIjoxNTYyNTY0NDYwLCJzZXNzaW9uX3N0YXRlIjoiMDI5YWI2YWEtNTE4My00MzE3LTlhMjUtZWZjNDQ0YzY4YjI5IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.UHdTYZKRKeGuhgrdfdEMEzhVn8djhkLJfeiUJp_5YdL5s6V84-JLS1q0thqBgKTfqpM0bbwMwWbo7SGWY8h-LPZhqJFDLwOXVv3GovuWOw1F4QDRPOhtuG4crDryKlo7c1dS00YT9QhuUzC7DHG37MlYrz9TwqarGVE3eEQKnmsJ728WeIDoiMzhEZ8HZlFn1PdQ5lpDMDhNCZvzu3KrKQEDbrUJUEU2Owms0vclQJrEbP3cFWvAJAIOxj8-DVqC50MLr6JceeWVADz9pegGK2_P0bZ9eQWzzsCkXp2yFpKzeqAzRzjT233WVwKAxqV5t8bdyWJDy4Y2MdZAf39p6Q');
        headers=headers.append('Content-Type','application/json')
        return this.http.post<FaqBenefit[]>(Configuration.ResServerBaseURL+APIUrls.GetFAQContentDetails, user,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }

    getPendingActionsGlobal(): Observable<PendingActions> {
      var  headers = new  HttpHeaders().set('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
      headers = headers.append('Content-Type', 'application/json')
      return  this.http.post<PendingActions>(Configuration.ResServerBaseURL + APIUrls.getPendingActionsGlobal, { "CandidateId": localStorage.getItem("CandidateID"), "CandidateEmail": localStorage.getItem("CandidateEmailID") }, {
          headers: headers
      }).pipe(map(
          (res)  =>  {
              return  res;
          }
      ))
  }

}