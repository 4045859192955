import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { lpUKUserCredentials } from './models/lpukUserCredentials.model';
import { CandidateUKAdvisorImageGlobal } from './models/CandidateUKAdvisorImageGlobal.model';
import { MailContent, Result } from './models/mailContent.model';
import { UKPreJoiningLogin } from './models/UkPrejoining.model';
import { LPUKCustomHttpClient } from './lpuk.interceptor';
import { Configuration, APIUrls } from '../configuration';


@Injectable()
export class LPUKComponentService {
        
    constructor(private _http: LPUKCustomHttpClient) { }

    GetUserDetails(user:lpUKUserCredentials):Observable<UKPreJoiningLogin[]>{
        return this._http.post<UKPreJoiningLogin[]>(Configuration.ResServerBaseURL+APIUrls.getCandidateDetailsAfterAuthenticationUK,user).pipe(map(
        (res) => {
        return res;
        }))
    }
    fnGetCandPhoto(userCredentials:lpUKUserCredentials): Observable<CandidateUKAdvisorImageGlobal> {
        return this._http.post<CandidateUKAdvisorImageGlobal>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhotoUK,{"CandidateId":userCredentials.CandidateID}).pipe(map(
        (res) => {
        return res;
    } 
))  
    }
    _errorHandler(error: HttpErrorResponse) {
        //console.log(error);
        return throwError(error.message || "Server Error");
    }
    SendMailerHelp (mailContent:MailContent): Observable<Result> {
        return this._http.post<Result>(Configuration.ResServerBaseURL+APIUrls.SendMailerHelpUK,{"MailBody":mailContent.MailBody}).pipe(map(
        (res) => {
        return res;
    } 
))  
    }
}