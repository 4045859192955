import { Component, OnInit } from '@angular/core';
import { ForgotPasswordService } from './forgot-password.service';
import { ForgotPwd } from '../models/ForgotPwd.model';
import { NgForm } from "@angular/forms";
import { AppService } from '../app.service';
import { AlertService } from '../utils/alert/alert.service';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})

export class ForgotPasswordComponent implements OnInit {
  mobile: boolean;
  constructor(private forgotservice: ForgotPasswordService,
    private appService: AppService,private alert:AlertService,private myRoute: Router,
  private spinner:NgxSpinnerService) {
    let body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = "#D0332E";
  }
  forgotpwd = new ForgotPwd;
  ngOnInit() {
    this.mobile = this.appService.mobile;
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = "#fff";
  }

  forgotPassword(form: NgForm) {
    this.spinner.show();
    // console.log(form.value.CandidateIdRef)
    // console.log(form.value.CandidateEmailRef)
    this.forgotpwd.CandidateID = form.value.CandidateIdRef
    this.forgotpwd.CandEmailID = form.value.CandidateEmailRef
    this.forgotservice.ForgotPasswordForCandidate(this.forgotpwd).subscribe(
      (res) => {
        
        if(res.Updateresult == "1"){
          this.alert.success("Password changed successfully and has been sent to your email address",this.mobile)
          this.myRoute.navigate(["login"]);
        }
        else{
          this.alert.error(res.Updateresult,this.mobile);
        }
        this.spinner.hide();
      }
    );
  }
}
