import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UKPendingActions } from '../models/UKPendingAction.model';
import { map } from 'rxjs/operators';
import { LPUKCustomHttpClient } from '../lpuk.interceptor';
import { Configuration, APIUrls } from '../../configuration';

@Injectable({
    providedIn: 'root'
})

export class PostjoiningUKService {
    policies: string;

    constructor(private http: LPUKCustomHttpClient) { }

    getPendingActions (): Observable<UKPendingActions> {
            return this.http.post<UKPendingActions>(Configuration.ResServerBaseURL+APIUrls.getPendingActionsUK,{"CandidateId":Number(localStorage.getItem("CandidateID")),"CandidateEmail":localStorage.getItem("CandidateEmailID")}).pipe(map(
            (res) => {
            return res;
        } 
    ))  
    }

         
}