import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Configuration, APIUrls } from '../../../configuration';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { IndiaFetchAchievements } from '../../models-india/IndiaFetchAchievements.model';
import { IndiaFetchAchievementDetails } from '../../models-india/IndiaFetchAchievementDetails.model';
import { IndiaFetchIntClubs } from '../../models-india/IndiaFetchIntClubs.model';
import { LpFetchAchievementsDocNames } from '../../models-india/LpFetchAchievementsDocNames.model';
import { IndiaFetchExtActivities } from '../../models-india/IndiaFetchExtActivities.model';
import { LPDms } from '../../models-india/LPDms.model';
import { DMSInternetPlainDocumentResponse } from '../../models-india/DMSInternetPlainDocumentResponse';
import { UpdateOtherDocDetails } from '../../models-india/UpdateOtherDocDetails.model';
import { DeleteOtherDocDetails } from '../../models-india/DeleteOtherDocDetails.model';
import { DMSDocument } from '../../models-india/DMSDocument.model';
import { IndiaOnboardingPass } from '../../models-india/IndiaFetchOnboardingPass.model';
import { IndiaUserCredentials } from '../../models-india/indiaUserCredentials.model';
import { IndiaCandidateAdvisorImage } from '../../models-india/indiaCandidateAdvisorImage.model';
import { IndiaInputParam } from '../../models-india/IndiaInputParam.model';
import { StatutoryForms } from '../../models-india/StatutoryForms.model';

@Injectable()
export class IndiaOnboardingPassService {

constructor(private _http:LPIndiaCustomHttpClient) {   }

FetchBoardingPassInfo(candid:IndiaInputParam):Observable<IndiaOnboardingPass[]>{
    return this._http.post<IndiaOnboardingPass[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchBoardingPassInfoIN,candid).pipe(map(
        (res) => {
        // console.log(res);
        return res;
        }
        )) 
}

fnGetCandPhoto(userCredentials:IndiaInputParam): Observable<IndiaCandidateAdvisorImage> {    
    return this._http.post<IndiaCandidateAdvisorImage>(Configuration.ResServerBaseURLIndia+APIUrls.fnGetCandPhotoIN,{"CandidateId":userCredentials.CandidateId},).pipe(map(
    (res) => {
    return res;
} 
))  
}

fetchStatutoryDetails(userCredentials:IndiaInputParam): Observable<StatutoryForms[]> {    
    return this._http.post<StatutoryForms[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchStatutoryDetails,userCredentials,).pipe(map(
    (res) => {
    return res;
} 
))  
}

}