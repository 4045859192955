export const environment = {
  production: true,
  resServerBaseURL: "",
  resServerBaseURLIndia:"",
  telemetryServiceHost:"",
  telemetryEndPoint:"",
  notificationServerBaseURL: "",
  keycloakURL: "",
  keycloakRealm: "",
  keycloakClientId: ""
};
