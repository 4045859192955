import { Injectable} from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from 
'@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import {Router} from '@angular/router';

@Injectable()
export class AuthGuardMain implements CanActivate {
  
  constructor(private auth: AuthService,
    private myRoute: Router){
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.auth.isLoggedIn() &&
    (localStorage.getItem("Region").toUpperCase()==="CANADA" || localStorage.getItem("Region").toUpperCase() ==="IPS CANADA" ||
    localStorage.getItem("Region").toUpperCase() ==="IL SWITZERLAND" || localStorage.getItem("Region").toUpperCase()==="IL GERMANY" || 
     localStorage.getItem("Region").toUpperCase() == "IL NEW ZEALAND" || localStorage.getItem("Region").toUpperCase() == "IL  BELGIUM" ||
    localStorage.getItem("Region").toUpperCase() =="IL NETHERLANDS" || localStorage.getItem("Region").toUpperCase() =="IL SINGAPORE"
     || localStorage.getItem("CompanyCode").toUpperCase()=="COMMON")){  //Added by Vibhu
        return true;
    }
    else{
        this.myRoute.navigate(["login"]);
      return true;
    }
  }


  
}