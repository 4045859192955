export class Education
{
    public intCandQualDetId: Number;
    public CandidateId: Number;
    public txtSchoolCollege: String;
    public txtBoardUniversity: String;
    public dtStartDate: Date;
    public dtEndDate: Date;
    public txtDegreeDesc: String;
    public txtDegreeCode: String;
    public txtMarks: String;
    public txtLevelCode: String;
    public txtRollNo: String;
    public flgFullTime: String;
    public scoreType: String;
    public modifiedBy: String;
    public xmlUpdate: String;
    public xmlDelete: String;
    public intSemCount: Number;
    public txtState: String;
    public txtOthersState: String;
    public txtYearOrSemester: String;
    
}