import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { Configuration, APIUrls } from '../../../configuration';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';


@Injectable()
export class PrivacyNoticeIndiaService {    
    
    constructor(private _http: LPIndiaCustomHttpClient) { }
    
    UpdatePrivacyPolicy(): Observable<string> {        
        return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.UpdatePrivacyPolicyIN,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))},
        ).pipe(map(
        (res) => {
        return res;
    } 
))  
    }
    _errorHandler(error: HttpErrorResponse) {
        // console.log(error);
        return throwError(error.message || "Server Error");
    }
    
    
}