import { Observable, Subject } from 'rxjs';
import { Injectable, OnInit } from '@angular/core';

@Injectable()
export class AppService{
    mobile : boolean;
    updateForm  : boolean = false;
    hideDashBoard : Observable<boolean>;
    imageURL: string;
    contactNumber:string;
    seniorCand:boolean;
    faqContent= [];
    isPolicyDone: boolean;
    vaccineArrayDetail: any;
    benefitContent=[];
    tncContent=[];
    routingDetails:Observable<boolean>;
    dmsDocPath: string = "http://isdmslobtst/sites/LaunchPad/LaunchPad001/"; //test
    // dmsDocPath: string = "http://10.68.190.231/sites/LaunchPad001/LaunchPad/"; //prd
    //mobileObserver : Observable<boolean>;
    private updateMobileSubject = new Subject<boolean>();
    hidePolicies : Observable<boolean>;
    //mobileObserver : Observable<boolean>;
    private updatePoliciesSubject = new Subject<boolean>();
    private flgRoutingSubject = new Subject<boolean>();
    claimsEligibilityData = [];
    constructor(){
        this.hideDashBoard = this.updateMobileSubject.asObservable();
        this.hidePolicies = this.updatePoliciesSubject.asObservable();
        this.routingDetails = this.flgRoutingSubject.asObservable();
    }
    updateForms(){
        this.updateForm = false;
    }

    setIsMobile(isMobile){
        //console.log(isMobile);
        this.mobile = isMobile;
        //console.log(isMobile);
        //this.updateMobileSubject.complete();
    }

    setHiddenDashBoard(isHidden){
        this.updateMobileSubject.next(isHidden);
    }

    setHiddenPolicies(isHidden){
        this.updatePoliciesSubject.next(isHidden);
    }

    setHiddenappagenda(isHidden){
        this.updateMobileSubject.next(isHidden);
    }
    setHiddengallery(isHidden){
        this.updateMobileSubject.next(isHidden);
    }
    setFlagRouting(isHidden){
        this.flgRoutingSubject.next(isHidden)
    }
}