export class EmpAddress {  
    public CandidateID: Number ;
    
    public CareOfName: String ;
  
    public ResidingFrom: String ;
    
    public SuiteDetails: String ;
    
    public AreaDetails: String ;

    public StreetDetails: String ;
    public Country: String ;
    public State: String ;
    public City: String ;
    public ZipCode: String ;
    public PhoneNo: String ;
    public PagerNo: String ;
    public CellPhone: String ;
    public EmailID:String ;
    public AddressType: String ;
    public CountryCode: String ;
    public StateCode: String ;
    public CityCode: Number ;
    
  
}
