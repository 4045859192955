import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { USPreJoiningLogin } from '../lpus/models/USPreJoiningLogin.model';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { KeycloakService } from '../keycloak.service';
import { LPUSCustomHttpClient } from '../lpus/lpus.interceptor';
import { Configuration, APIUrls } from '../configuration';
import { LPFetchLoginAndIdentification } from '../lpindia/models-india/LPFetchLoginAndIdentification';//need to check
//need to check import { Ng4LoadingSpinnerService } from '../../../node_modules/ng4-loading-spinner';
import { NgxSpinnerService } from 'ngx-spinner';
// import { LPTelemetryService } from '../services/LPTelemetry.service';
import { LPLoginDetailsBadges } from '../lpindia/models-india/loginDetails.model';
import { AppService } from '../app.service';
import { AlertService } from '../utils/alert/alert.service';
import {  FetchDocNotesForAllGeos, FetchHeadingandSubHeading} from '../models/FetchHeadingandSubHeadings';
import { ChangePasswordService } from './change-password/changePassword.service';
import { FaqBenefit } from '../lpmain/Model-Classes/Faq.model';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { UserCredentials } from '../lpmain/Model-Classes/Residence.model';
import { RoutingNumberDetails } from '../models/RoutingNumberDetails.model';
// import { VaccineDeclaration } from '../lpindia/models-india/VaccineDeclaration.model';
declare function Redirect(url):any;
declare var Keycloak:any;
declare function WebViewLogout() : any;
declare function InsertLoginDetails(input,Token):any;
declare function Exit():any;
@Injectable()
export class AuthService {
  user: USPreJoiningLogin= new USPreJoiningLogin();
  userIndia = new LPFetchLoginAndIdentification();
  login = new LPLoginDetailsBadges();
  mobile:boolean;
  static HeadingList:FetchHeadingandSubHeading[]=[];
  txtCandidateId:string;
  viewType:string;
  tnc= new FaqBenefit;
  // vaccinated: string;
  static DocsNotesList:FetchDocNotesForAllGeos[]=[];
  
  constructor(private myRoute: Router,private http: LPUSCustomHttpClient,private alert:AlertService,
     private kcService:KeycloakService,
    private appService:AppService,private change :ChangePasswordService,private breakpointObserver: BreakpointObserver,
    private spinner:NgxSpinnerService// ,private teleService:LPTelemetryService, 
    ) { this.mobile=this.appService.mobile;}
  sendToken(token: string) {
     localStorage.setItem("Token",token);
    // localStorage.setItem("Token","token");
    this.GetUserRegion(token).subscribe((res)=>
    {      
      let reg = res.toUpperCase();
      localStorage.setItem("Region",reg);
      localStorage.setItem("CompanyCode",reg);   //Added by vibhu
      this.getDPflag();
      //console.log(reg);
      if(reg == "IN")
      {
        this.sendTokenForIndia(token);
      }
      else if(reg == "IL UK" || reg == "BPM UK" || reg == "IL AUSTRALIA" || reg == "US" || reg =="COMMON" || reg == "BPM AUSTRALIA")
      {
      this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.appService.setIsMobile(true);
          this.mobile = true;
          window.scroll(0, 0);
        } else {
          this.appService.setIsMobile(false);
          this.mobile = false;
        }
        this.SendTokenForRest(token);
      });
        
        
        
      }      
      else
      {

         this.spinner.hide();
        this.alert.error("Your account has been expired.",this.mobile);
        setTimeout(()=>{this.logout();WebViewLogout();},3000);

        
      }

    });
    
  }

  getDPflag()
  {
    this.change.getDPFlag().subscribe(
      res=>
      {
        if(res!=null && res.flgPrivacy.trim() == "Y")
        {
          this.change.dpFlag = true;
          localStorage.setItem("PrivacyDpFlag","Y");
        }
        else
        {
          this.change.dpFlag = false;
          localStorage.setItem("PrivacyDpFlag","N");
        }
      }
    )

  }
  getToken() {
    return localStorage.getItem("Token")
  }
  isLoggedIn() {
    return this.getToken() !== null;
  }
  logout() {    
    this.kcService.Logout();
    localStorage.clear();
   // let token1=localStorage.getItem("idToken1");
    //console.log(token1);
    //let token1=this.kcService1.getKeycloakInstance().idToken;
   // window.location.replace("https://infysso-poc.infosysapps.com/auth/realms/launchpad/protocol/openid-connect/logout?post_logout_redirect_uri="+window.location.origin+"&id_token_hint="+token1);
   // localStorage.clear();
  }
  
  // FetchVaccineDetail(vaccine:VaccineDeclaration):Observable<VaccineDeclaration>
  //   {
  //       var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
  //       headers=headers.append('Content-Type','application/json');
  //       let body = JSON.stringify(vaccine);
  //       console.log('vaccineResponse1 '+ body)
  //       return this.http.post<VaccineDeclaration>(Configuration.ResServerBaseURLIndia+APIUrls.FetchVaccineDetails,body,{headers:headers}).pipe(map((res) => {return res[0];}));;
  //   }

  GetUserData(token:string):Observable<USPreJoiningLogin>
  {
    var headers=new HttpHeaders().set('Authorization','Bearer '+ token);
    headers=headers.append('Content-Type','application/json')
    return this.http.post<USPreJoiningLogin>(Configuration.ResServerBaseURL+APIUrls.GetUserDetails,{"UserName":"userName","Password":"Infy@123"},{
    headers:headers
    }).pipe(map(
    (res) => {
    return res;
  }
  ))  
    }

    GetUserRegion(token:string):Observable<string>
  {
    var headers=new HttpHeaders().set('Authorization','Bearer '+ token);
    headers=headers.append('Content-Type','application/json')
    return this.http.post<string>(Configuration.ResServerBaseURL+APIUrls.GetCandidateRegion,null,{
    headers:headers
    }).pipe(map(
    (res) => {
    return res;
  }
  ))  
    }

    GetUserDataForIndia(token:string):Observable<LPFetchLoginAndIdentification>
{
      let CandidateID = 12;
      var headers=new HttpHeaders().set('Authorization','Bearer '+ token);
      headers=headers.append('Content-Type','application/json')
      return this.http.post<LPFetchLoginAndIdentification>(Configuration.ResServerBaseURLIndia+APIUrls.GetCandidateDetailsOnCandidateId,CandidateID,{
      headers:headers
      }).pipe(map(
      (res) => {
      return res;
    }
    ))  
      }
  

    sendTokenForIndia(token: string) {
      //alert("Inside sendTokenForIndia");
      localStorage.setItem("Token",token);
      this.GetUserDataForIndia(token).subscribe((res)=>
      {
        //alert("Inside GetUserDataForIndia");
        console.log(res);
        this.userIndia =res;
        let userIn = this.userIndia.LPFetchCandidateDetailsOnLogin;
        // userIn[0].flgVaccineAcknowledge='N'
        

        let candidateType = this.userIndia.LPFetchCandidateType;
        console.log(userIn[0]);
      this.txtCandidateId=userIn[0].intCandidateId.toString();
       this.spinner.show()

      // fetchVaccine
      // let vaccine = new VaccineDeclaration();
      // vaccine.CandidateID = userIn[0].intCandidateId;
      // this.FetchVaccineDetail(vaccine).subscribe
      // (
      //   res=>
      //   {

      //     console.log(res) 
      //     if(res){
      //         if(res.flgVaccinated=='Y' || res.flgVaccinated=='N'){
      //           this.vaccinated='Y';
      //         }
      //         else{
      //           this.vaccinated='N';
      //         }
      //     } else {
      //       this.vaccinated='N';
      //     }
      //   }
      //   )
      //   console.log(this.vaccinated)

      //Get Top and Bottom Notes
      this.GetHeadingsForGeo(this.txtCandidateId).subscribe((res) =>{
        // localStorage.setItem("HeadingList",res.toString())
        AuthService.HeadingList=res;
         this.spinner.hide()
      });
      localStorage.setItem("intCandidateId",userIn[0].intCandidateId.toString());
      localStorage.setItem("flgFirstLogin",userIn[0].flgFirstLogin);
      localStorage.setItem("flgTermsAndConditions",userIn[0].flgTermsAndConditions);
      localStorage.setItem("OfferStatus",userIn[0].OfferStatus);
      localStorage.setItem("txtPostingCountryCode",userIn[0].txtPostingCountryCode);
      localStorage.setItem("txtPostingLocation",userIn[0].txtPostingLocation);
      localStorage.setItem("txtCountryName",userIn[0].txtCountryName);
      localStorage.setItem("txtCandFirstName",userIn[0].txtCandFirstName);
      localStorage.setItem("txtCandLastName",userIn[0].txtCandLastName);
      localStorage.setItem("dtActiveDOJ",userIn[0].dtActiveDOJ);
      localStorage.setItem("RoleDesignation",userIn[0].RoleDesignation);
      localStorage.setItem("COMPANY",userIn[0].COMPANY);
      localStorage.setItem("flgBioConsent",userIn[0].flgBioConsent);
      localStorage.setItem("dtOfferReleasedDate",userIn[0].dtOfferReleasedDate);
      localStorage.setItem("txtOfferStatus",userIn[0].txtOfferStatus);
      localStorage.setItem("ReqId",userIn[0].ReqId.toString());
      localStorage.setItem("CandidacyId",userIn[0].CandidacyId.toString());
      localStorage.setItem("flgGDCSubmit",userIn[0].flgGDCSubmit);
      localStorage.setItem("flgGDCPDFjob",userIn[0].flgGDCPDFjob);
      localStorage.setItem("flgPWDChngType",userIn[0].flgPWDChngType);
      localStorage.setItem("flgPolicy",userIn[0].flgPolicy);
      localStorage.setItem("flgVideoSeen",userIn[0].flgVideoSeen);
      localStorage.setItem("Identification",candidateType[0].Identification);
      localStorage.setItem("txtEmailId",userIn[0].txtEmailId);
      localStorage.setItem("flgOnboardingPulse",userIn[0].flgOnboardingPulse);  
      localStorage.setItem("flgInductionFeedback",userIn[0].flgInductionFeedback);
      // localStorage.setItem("flgLaunchpadcompletionPopup",userIn[0].flgLaunchpadcompletionPopup);
      localStorage.setItem("DaysLeft",userIn[0].DaysLeft.toString());
      localStorage.setItem("DMSSitePath", userIn[0].DMSSitePath.toString());

      //localStorage.setItem("DaysLeft","-1");
      localStorage.setItem("IsComingFromLogin","Y");
      localStorage.setItem("Region","IN")
      localStorage.setItem("chgpass",userIn[0].flgPWDChngType);
      localStorage.setItem("contact",userIn[0].ContactNumber);
      localStorage.setItem("EmpNumber",userIn[0].EmployeeNumber);

      localStorage.setItem("flgCovid",userIn[0].flgCovidAcknowledge);
      localStorage.setItem("flgVaccine",userIn[0].flgVaccineAcknowledge);

      localStorage.setItem("flgPermanentAdd", userIn[0].flgPermanentAdd);
      localStorage.setItem("flgPastAdd",userIn[0].flgPastAdd);
      // localStorage.setItem("flgNom", userIn[0].flgNom);
      localStorage.setItem("flgDisability",userIn[0].flgDisability);
      localStorage.setItem("flgFamily",userIn[0].flgFamily);
      localStorage.setItem("flgNSR",userIn[0].flgNSR);
      localStorage.setItem("flgPssprt",userIn[0].flgPssprt);
      localStorage.setItem("flgPan",userIn[0].flgPan);
      localStorage.setItem("flgEdu",userIn[0].flgEdu);
      localStorage.setItem("flgMedical",userIn[0].flgMedical);
      // localStorage.setItem("flgGap",userIn[0].flgGap);
      localStorage.setItem("flgWorkEx",userIn[0].flgWorkEx);
      localStorage.setItem("flgUAN",userIn[0].flgUAN);
      localStorage.setItem("flgPension",userIn[0].flgPension);
      localStorage.setItem("flgProvident",userIn[0].flgProvident);
      localStorage.setItem("flgIntworker",userIn[0].flgIntworker);
      // localStorage.setItem("flgAcc",userIn[0].flgAcc);
      // localStorage.setItem("flgAchieve",userIn[0].flgAchieve);
      // localStorage.setItem("flgAspire",userIn[0].flgAspire);
      // localStorage.setItem("flgDocs",userIn[0].flgDocs);
      // localStorage.setItem("flgSSN",userIn[0].flgSSN);
      localStorage.setItem("flgResi",userIn[0].flgResi);
      localStorage.setItem("flgEmergency",userIn[0].flgEmergency);
      // localStorage.setItem("flgBank1",userIn[0].flgPermanentAdd);
      // localStorage.setItem("flgBank2",userIn[0].flgPermanentAdd);
      // localStorage.setItem("flgBank3",userIn[0].flgPermanentAdd);
      // localStorage.setItem("flgCandidateImage", userIn[0].flgCandidateImage);
      // localStorage.setItem("flgEmergency", userIn[0].flgEmergency);
      // localStorage.setItem("flgCorpCard", userIn[0].flgCorpCard);
      // localStorage.setItem("flgW4",userIn[0].flgW4);
      localStorage.setItem("flgCandidateImage",userIn[0].flgCandidateImage);
      localStorage.setItem("flgPersonalDetails",userIn[0].flgPersonalDetails);
      localStorage.setItem("flgAadhar",userIn[0].flgAadhar);
      localStorage.setItem("flgVac",userIn[0].flgVac); 
      localStorage.setItem("flgBankIN",userIn[0].flgBankIN);
      localStorage.setItem("flgRebadging",userIn[0].flgIsRebadging);
      localStorage.setItem("CompanyNameIdentiefier",userIn[0].txtCompanyNameIdentifier);

    // localStorage.setItem("flgSSN",this.user.flgSSN);
    // localStorage.setItem("flgResi",this.user.flgResi);
    // localStorage.setItem("flgEmergency",this.user.flgEmergency);
    // localStorage.setItem("flgBank1",this.user.flgBank1);
    // localStorage.setItem("flgBank2",this.user.flgBank2);
    // localStorage.setItem("flgBank3",this.user.flgBank3);
    // localStorage.setItem("flgCandidateImage", this.user.flgCandidateImage);
    // localStorage.setItem("flgEmergency", this.user.flgEmergency);
    // localStorage.setItem("flgCorpCard", this.user.flgCorpCard);
    // localStorage.setItem("flgW4",this.user.flgW4);
    // localStorage.setItem("flgCandidateImage",this.user.flgCandidateImage);
    // localStorage.setItem("flgPersonalDetails",this.user.flgPersonalDetails);
      
      // this.teleService.start();
      this.login.Platform="Web";
      this.login.CandidateId=parseInt(localStorage.getItem('intCandidateId'));
      this.login.TimeStamp=new Date();
      this.InsertCandidateDetailsOnLogin(this.login);

      //this.myRoute.navigate(["lpindia"]);


      // var flgTC=localStorage.getItem("flgTermsAndConditions");

      
      //console.log(flgTC);
      var flgPolicy=localStorage.getItem("flgPolicy");
      //console.log(flgPolicy);
      var flgFirstLogin=localStorage.getItem("flgFirstLogin");
      var flgcovid = localStorage.getItem("flgCovid");
      // var flgvaccine=localStorage.getItem("flgVaccine");
      var daysLeft = Number(localStorage.getItem("DaysLeft"));
      var company = localStorage.getItem("COMPANY").toString().toUpperCase();
      //------------
      // if(daysLeft<0 && pulse.toUpperCase()!="Y")
      // {
      //   console.log("Inside Pulse");
      //   this.myRoute.navigate(["pulse-ind"]);
      // }
      // else 
      console.log('flgVaccine' + localStorage.getItem("flgVaccine"))
       if(flgPolicy.toUpperCase() != "Y")
      { this.spinner.hide();
       // console.log("inside corona")
       this.myRoute.navigate(["privacynotice"],{queryParams:{"accept":"Y"}});
      

      }
    //  else if(flgTC.toUpperCase() != "Y")
    //   { //need to check this.spinner.hide();
    //     //console.log("inside tnc")
    //   this.myRoute.navigate(["tnc-ind"],{queryParams:{"accept":"Y"}});
    //   }
  
    else if (flgFirstLogin.toUpperCase()!="Y")
    { this.spinner.hide();
      // console.log("chg")
      this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
    }
    else
    {
      if (candidateType[0].Identification.toUpperCase()=="FRESHER" && company != 'EV')
      {
        // console.log("fr")
        if((userIn[0].flgBioConsent.toUpperCase()  != "Y") && (userIn[0].flgBioConsent.toUpperCase()  != "N"))
            { this.spinner.hide();
              // console.log("fr")
              this.myRoute.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
            }
            else
            { this.spinner.hide();
              // console.log("ind")
              Redirect(document.baseURI+"lpindia")
            this.myRoute.navigate(["lpindia"]);
            }
            
      }
      else if (candidateType[0].Identification.toUpperCase()=="LATERAL"  && company != 'EV'){
        // console.log("latrl")
        if(userIn[0].txtOfferStatus.trim().toUpperCase()  == "OFFER RELEASED")
        { this.spinner.hide();
          // console.log("ofr")
          this.myRoute.navigate(["accept"],{queryParams:{"accept":"Y"}});
        }
        else if (userIn[0].flgGDCSubmit.toUpperCase()!="Y"){
          //console.log("Inisde Corona")
           this.spinner.hide();
          this.myRoute.navigate(["declare"],{queryParams:{"accept":"Y"}});
          //this.myRoute.navigate(["corona"]);
        }
        else if((userIn[0].flgBioConsent.toUpperCase()  != "Y" && userIn[0].flgBioConsent.toUpperCase()  != "N"))
        { this.spinner.hide();
         //  console.log("fr")
         this.myRoute.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
        }
        else if(userIn[0].flgCovidAcknowledge.toUpperCase()!="Y"){
          this.myRoute.navigate(["corona"],{queryParams:{"accept":"Y"}});
        }
        
        else if(localStorage.getItem("flgVaccine").toUpperCase()!="Y"){
          this.myRoute.navigate(["vaccine"],{queryParams:{"accept":"Y"}});
        }
      //   else if(userIn[0].flgVideoSeen.toUpperCase()  != "Y"){
              
      //     this.myRoute.navigate(["video-ind"],{queryParams:{"accept":"Y"}})
      // }
      else
      {
        // console.log("ind")
         this.spinner.hide();
        Redirect(document.baseURI+"lpindia")
      this.myRoute.navigate(["lpindia"]);
      }
      }
      else
      {
        // console.log("ind")
         this.spinner.hide();
        Redirect(document.baseURI+"lpindia")
      this.myRoute.navigate(["lpindia"]);
      }
    }
     
}
    )
    }

    SendTokenForRest(token:string)
    {
    localStorage.setItem("Token",token);
      this.GetUserData(token).subscribe((res)=>
    {
      // console.log(res[0]);
      this.user =res[0]

      //tnc for first login
      if(this.mobile){
        this.viewType='mobile'
      }
      else{
        this.viewType='desktop'
      }
      this.tnc.CandidateId=this.user.CandidateID;
      this.tnc.txtViewType=this.viewType;
      this.tnc.txtContentFor='tnc';

      this.GetFAQContentDetails(this.tnc).subscribe((res) => {
        if(res!=null){
          this.appService.tncContent=res;
          
          // console.log(this.user);
      this.txtCandidateId=this.user.CandidateID.toString();
       this.spinner.show()
    
      //Get Top and Bottom Notes
      this.GetHeadingsForGeo(this.txtCandidateId).subscribe((res) =>{
        // localStorage.setItem("HeadingList",res.toString())
         this.spinner.hide()
        AuthService.HeadingList=res;
      });
      let user = new UserCredentials();
      user.CandidateID = this.user.CandidateID;
      this.GetRoutingNumberDetails(user).subscribe(
        res=>{
          if(res!=null && res.length >0)
          {
            this.appService.setFlagRouting(true);
          }
          else
          {
            this.appService.setFlagRouting(false);
          }
        }
      );

      localStorage.setItem("CandDOJ",this.user.CandDOJ);
      localStorage.setItem("CandEmpNo",this.user.CandEmpNo);
      localStorage.setItem("CandFirstName",this.user.CandFirstName);
      localStorage.setItem("CandidateEmailID",this.user.CandidateEmailID);
      localStorage.setItem("CandidateID",this.user.CandidateID.toString());
      localStorage.setItem("CandLastName",this.user.CandLastName);
      localStorage.setItem("DaysLeft",this.user.DaysLeft.toString());
      //console.log("DaysLeft:"+this.user.DaysLeft.toString())
      //localStorage.setItem("DaysLeft","2");
      localStorage.setItem("FlagFirstLogin",this.user.FlagFirstLogin);
      localStorage.setItem("FlagPasswordChangedType",this.user.FlagPasswordChangedType);
      localStorage.setItem("FlagPrivacy",this.user.FlagPrivacy);
      localStorage.setItem("FlagTermsAndCondition",this.user.FlagTermsAndCondition);
      localStorage.setItem("HiringManagerMailId",this.user.HiringManagerMailId);
      localStorage.setItem("HiringManagerName",this.user.HiringManagerName);
      localStorage.setItem("JobLevel",this.user.JobLevel);
      localStorage.setItem("Location",this.user.Location);
      localStorage.setItem("ConfirmedOBLOC",this.user.ConfirmedOBLOC);
      localStorage.setItem("EmpNumber",this.user.CandEmpNo);
      localStorage.setItem("workLocation",this.user.workLocation);  
      localStorage.setItem("flgNric",this.user.flgNric);
      localStorage.setItem("flgKiwi1",this.user.flgKiwi1);
      localStorage.setItem("flgKiwi2",this.user.flgKiwi2);
      localStorage.setItem("flgPsprtmain1",this.user.flgPsprtmain1);
      localStorage.setItem("flgPsprtmain2",this.user.flgPsprtmain2);
      localStorage.setItem("flgResiper",this.user.flgResiper);
      localStorage.setItem("flgHoliatt",this.user.flgHoliatt);
      localStorage.setItem("flgCriminalRecord",this.user.flgCriminalRecord);
      localStorage.setItem("flgContractdoc",this.user.flgContractdoc);
      localStorage.setItem("flgOfferletter",this.user.flgOfferletter);
      localStorage.setItem("flgBonusplan",this.user.flgBonusplan);
      localStorage.setItem("flgAddendum",this.user.flgAddendum);
      localStorage.setItem("flgTaxrelateddoc",this.user.flgTaxrelateddoc);
      localStorage.setItem("flgProvincedoc",this.user.flgProvincedoc);
      localStorage.setItem("flgRulingform",this.user.flgRulingform);
      localStorage.setItem("flgEnrolldoc",this.user.flgEnrolldoc);
      localStorage.setItem("flgMemberdoc",this.user.flgMemberdoc);
      localStorage.setItem("flgMedical1",this.user.flgMedical1);
      
    //localStorage.setItem("Location","Sydney");//hardcoded value
    localStorage.setItem("Region",this.user.Region);
    localStorage.setItem("DMSSitePath", this.user.DMSSitePath.toString());
    //console.log(this.user.Region)
    //localStorage.setItem("Region","IBPO");
   // this.user.Region = "IBPO";
    localStorage.setItem("RoleDesignation",this.user.RoleDesignation);
    localStorage.setItem("SourceType",this.user.SourceType);
    //localStorage.setItem("SourceType","lateral");
    localStorage.setItem("Unit",this.user.Unit);
    localStorage.setItem("IsComingFromLogin","Y");
    localStorage.setItem("CompanyNameIdentifier",this.user.CompanyNameIdentifier);
    localStorage.setItem("ContactUsEmailId",this.user.ContactUsEmailId);
    localStorage.setItem("OfficeAddress",this.user.OfficeAddress);
    localStorage.setItem("flgSSN",this.user.flgSSN);
    localStorage.setItem("flgResi",this.user.flgResi);
    localStorage.setItem("flgEmergency",this.user.flgEmergency);
    localStorage.setItem("flgBank1",this.user.flgBank1);
    localStorage.setItem("flgBank2",this.user.flgBank2);
    localStorage.setItem("flgBank3",this.user.flgBank3);
    localStorage.setItem("flgCandidateImage", this.user.flgCandidateImage);
    localStorage.setItem("flgEmergency", this.user.flgEmergency);
    localStorage.setItem("flgCorpCard", this.user.flgCorpCard);
    localStorage.setItem("flgW4",this.user.flgW4);
    localStorage.setItem("flgCandidateImage",this.user.flgCandidateImage);
    localStorage.setItem("flgPersonalDetails",this.user.flgPersonalDetails);
    localStorage.setItem("flgSocialSecurity",this.user.flgSocialSecurity);
    localStorage.setItem("flgHealthInsurance",this.user.flgHealthInsurance);
    localStorage.setItem("flgTfn",this.user.flgTfn);
    localStorage.setItem("flgSup",this.user.flgSup);
    localStorage.setItem("flgForceResidential",this.user.flgForceResidential);
    localStorage.setItem("flgForceSSN",this.user.flgForceSSN);
    localStorage.setItem("flgPersonalUpdate",this.user.flgPersonalUpdate);
    
    // localStorage.setItem("flgPermanentAdd",this.user.flgPermanentAdd);
    // localStorage.setItem("flgPastAdd",this.user.flgPastAdd);
    // localStorage.setItem("flgNom",this.user.flgNom);
    // localStorage.setItem("flgDisability",this.user.flgDisability);
    // localStorage.setItem("flgFamily",this.user.flgFamily);
    // localStorage.setItem("flgNSR",this.user.flgNSR);
    // localStorage.setItem("flgPssprt",this.user.flgPssprt);
    // localStorage.setItem("flgPan",this.user.flgPan);
    // localStorage.setItem("flgEdu",this.user.flgEdu);
    // localStorage.setItem("flgMedical",this.user.flgMedical);
    // localStorage.setItem("flgGap",this.user.flgGap);
    // localStorage.setItem("flgWorkEx",this.user.flgWorkEx);
    // localStorage.setItem("flgUAN",this.user.flgUAN);
    // localStorage.setItem("flgAcc",this.user.flgAcc);
    // localStorage.setItem("flgAchieve",this.user.flgAchieve);
    // localStorage.setItem("flgAspire",this.user.flgAspire);
    // localStorage.setItem("flgDocs",this.user.flgDocs);
    



      // this.teleService.start();
      
      this.login.Platform="Web";
      this.login.CandidateId=parseInt(localStorage.getItem('CandidateID'));
      this.login.TimeStamp=new Date();
      this.InsertCandidateDetailsOnLogin(this.login);
    
      //UK
      if(this.user.Region == "IL UK" || this.user.Region == "BPM UK")
      {
        // if(this.user.FlagFirstLogin.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
        // }
        //  if(this.user.FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
        // }
        if(this.user.FlagPrivacy.toUpperCase() != "Y")
        { this.spinner.hide();
          this.myRoute.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
        }
        else if(this.user.FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else
        { this.spinner.hide();
          Redirect(document.baseURI+"lpuk")
        this.myRoute.navigate(["lpuk"]);
        }
      }

      //Australia
      else if(this.user.Region.toUpperCase() == "IL AUSTRALIA" || this.user.Region.toUpperCase() == "BPM AUSTRALIA")
      {
        // if(this.user.FlagFirstLogin.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacy-au"],{queryParams:{"accept":"Y"}});
        // }
        //  if(this.user.FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacy-au"],{queryParams:{"accept":"Y"}});
        // }
          if(this.user.FlagPrivacy.toUpperCase() != "Y")
        { this.spinner.hide();
          this.myRoute.navigate(["privacy-au"],{queryParams:{"accept":"Y"}});
        }
        else if(this.user.FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else
        { this.spinner.hide();
          Redirect(document.baseURI+"lpau")
        this.myRoute.navigate(["lpau"]);
        }

      }

      //US
      else if(this.user.Region == "US" || this.user.Region == "KALIDS")
      {
        // if(this.user.FlagFirstLogin.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["pri"],{queryParams:{"accept":"Y"}});
        // }
        //  if(this.user.FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["pri"],{queryParams:{"accept":"Y"}});
        // }
         if(this.user.FlagPrivacy.toUpperCase() != "Y")
        { this.spinner.hide();
          this.myRoute.navigate(["pri"],{queryParams:{"accept":"Y"}});
        }
        else if(this.user.FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else if(this.user.flgForceSSN.toUpperCase()=="Y")
        { this.spinner.hide();
          console.log("enteredssn")         
          this.myRoute.navigate(["ssn"],{queryParams:{"accept":"Y"}});      
        }
        // else if(this.user.flgForceResidential.toUpperCase()=="Y")
        //   {//need to check this.spinner.hide();
        //     console.log("entered1")

        //     this.myRoute.navigate(["force"],{queryParams:{"accept":"Y"}});

        //   }
        //   else if(this.user.flgPersonalUpdate=="Y")
        //   {//need to check this.spinner.hide();
        //     console.log("enteredpersonal")

        //     this.myRoute.navigate(["personal"],{queryParams:{"accept":"Y"}});

        //   }
        else
        { this.spinner.hide();
          console.log("entered2")
          Redirect(document.baseURI+"lpus")
        this.myRoute.navigate(["lpus"]);
        }
      }

       else if(this.user.Region == "BPO US" || this.user.Region == "IBPO" || this.user.Region.toUpperCase() == "MCCAM" || this.user.Region == "IBAUSA" || this.user.Region == "IPS" ){
        // if(this.user.FlagFirstLogin.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacy-bpo"],{queryParams:{"accept":"Y"}});
        // }
        // if(this.user.FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacy-bpo"],{queryParams:{"accept":"Y"}});
        // }
        if(this.user.FlagPrivacy.toUpperCase() != "Y"){
           this.spinner.hide();
          this.myRoute.navigate(['privacy-bpo'],{queryParams:{"accept":"Y"}})
        }
        else if(this.user.FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else if(this.user.flgForceSSN.toUpperCase()=="Y")
          { this.spinner.hide();
            console.log("enteredssn")         
            this.myRoute.navigate(["ssn-usbpo"],{queryParams:{"accept":"Y"}});       
          }
        else{
         this.spinner.hide();
        Redirect(document.baseURI+"lpusbpo")
        this.myRoute.navigate(["lpusbpo"]);
        }
       }
       //New 4 GEOS
       //Changes by Vibhu for new geos
       else if(this.user.Region.toUpperCase()  == "CANADA" || this.user.Region.toUpperCase()  == "IPS CANADA" ||  this.user.Region.toUpperCase()  == "IL SWITZERLAND" || this.user.Region.toUpperCase() == "IL GERMANY" || this.user.Region.toUpperCase() == "IL NEW ZEALAND" || this.user.Region.toUpperCase() == "IL  BELGIUM" || this.user.Region.toUpperCase() == "IL SINGAPORE" || this.user.Region.toUpperCase() == "IL NETHERLANDS" 
        || localStorage.getItem("CompanyCode").toUpperCase()=="COMMON"){
        // if(this.user.FlagFirstLogin.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacymain"],{queryParams:{"accept":"Y"}});
        // }
        // else if(this.user.FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["privacymain"],{queryParams:{"accept":"Y"}});
        // }
        if(this.user.FlagPrivacy.toUpperCase() != "Y"){
           this.spinner.hide();
          this.myRoute.navigate(['privacymain'],{queryParams:{"accept":"Y"}})
        }
        else if(this.user.FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else if(this.user.flgForceSSN.toUpperCase() =="N" && (localStorage.getItem("Region").trim().toUpperCase() == "IBPOPR"))
          { this.spinner.hide();
            console.log("enteredssn")         
            this.myRoute.navigate(["ssn-main"],{queryParams:{"accept":"Y"}});       
          }
        else{
         this.spinner.hide();
        Redirect(document.baseURI+"lpmain")
        this.myRoute.navigate(["lpmain"]);
        }
       }
        }
      })
      
      
    })
    }


  InsertLoginDetailsofCandidate(loginDetails:LPLoginDetailsBadges){
    //console.log(loginDetails)
    return this.http.post<LPFetchLoginAndIdentification>(Configuration.ResServerBaseURLIndia+APIUrls.InsertLoginDetailsOfCandidate,loginDetails).pipe(map(
    (res) => {
    return res;
    }
    ))  
  }

  InsertCandidateDetailsOnLogin(login:LPLoginDetailsBadges){
    if(this.mobile){
      //console.log(localStorage.getItem('Token'))
      InsertLoginDetails(login.CandidateId,localStorage.getItem('Token'));
    }
    else{
      this.InsertLoginDetailsofCandidate(login).subscribe(

        );
    }
    
  }

  GetHeadingsForGeo(CandidateId:string):Observable<FetchHeadingandSubHeading[]>{
    return this.http.post<FetchHeadingandSubHeading[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchHeadings,{ "CandidateId": Number(CandidateId) }).pipe(map(
      (res) => {
      return res;
      
      }
      )) 
    
  }
  GetFAQContentDetails(user:FaqBenefit):Observable<FaqBenefit[]>{
    
    var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJmOTM1NDFmOC1iMTJmLTQ0ZTgtYjFjYS1mZTU0ZTA1ZGZkOGIiLCJleHAiOjE1NjM4NjA0NjEsIm5iZiI6MCwiaWF0IjoxNTYyNTY0NDYxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiYmY5Nzk1MDAtMDkxMi00MjE4LTk4MGEtODI2M2E3NzdjZDVhIiwiYXV0aF90aW1lIjoxNTYyNTY0NDYwLCJzZXNzaW9uX3N0YXRlIjoiMDI5YWI2YWEtNTE4My00MzE3LTlhMjUtZWZjNDQ0YzY4YjI5IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.UHdTYZKRKeGuhgrdfdEMEzhVn8djhkLJfeiUJp_5YdL5s6V84-JLS1q0thqBgKTfqpM0bbwMwWbo7SGWY8h-LPZhqJFDLwOXVv3GovuWOw1F4QDRPOhtuG4crDryKlo7c1dS00YT9QhuUzC7DHG37MlYrz9TwqarGVE3eEQKnmsJ728WeIDoiMzhEZ8HZlFn1PdQ5lpDMDhNCZvzu3KrKQEDbrUJUEU2Owms0vclQJrEbP3cFWvAJAIOxj8-DVqC50MLr6JceeWVADz9pegGK2_P0bZ9eQWzzsCkXp2yFpKzeqAzRzjT233WVwKAxqV5t8bdyWJDy4Y2MdZAf39p6Q');
    headers=headers.append('Content-Type','application/json')
    return this.http.post<FaqBenefit[]>(Configuration.ResServerBaseURL+APIUrls.GetFAQContentDetails, user,{
    headers:headers
    }).pipe(map(
    (res) => {
    return res;
    }))
}

GetRoutingNumberDetails(user:UserCredentials):Observable<RoutingNumberDetails[]>{
    
  return this.http.post<RoutingNumberDetails[]>(Configuration.ResServerBaseURL+APIUrls.GetRoutingNumberDetails, user,{
  
  }).pipe(map(
  (res) => {
  return res;
  }))
}
GetLPDocNotesForAllGeos(CandidateId:string):Observable<FetchDocNotesForAllGeos[]>{
  return this.http.post<FetchDocNotesForAllGeos[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetDocNotesForAllGeos,{ "CandidateId": Number(CandidateId) 
  }).pipe(map(
    (res) => {
    return res;

    }
    )) 

}
}