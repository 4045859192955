export class EducationDocs
    {
        public CandidateID:Number;
        public CandEduDetId:Number;
        public SerialNo:Number;
        public CertOrMark:String;
        public YearorSem:String;
        public OriginalFileName:String;
        public FileUploaded:String;
        public QualificationCode:String;
        public PassingYr:String;
        public markType:String;
        public DMSSitePath: String;
        public txtGUID_DMS: String;
    }
