export class PersonalDetails {
    FirstName: String;
    LastName: String;
    MaritalStatusCode: String;
    MaritalStatusValue: String;
    Nationality: String;
    WorkLocation: String;
    CheckImage: String;
    EmailId: String;
    SSN: String;
    CandidateId: Number;
    Contact: String;
    DateOfBirth: String;
}

export class updatePhoneNumberFromProfile {
    intCandidateId: Number;
    txtPhoneNumber: String;
}