import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { LoggingService } from './services/logging.service';
import { ErrorService } from './services/error.service';
import { NotificationService } from './services/notification.service';
import { NgxSpinnerService } from 'ngx-spinner';

// import { LPTelemetryService } from './services/LPTelemetry.service';
import { KeycloakService } from './keycloak.service';
declare function WebViewLogout() : any;
@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(private injector: Injector,private kcService:KeycloakService, private spinner:NgxSpinnerService
    // ,private teleservice :LPTelemetryService
    ) { }
  
  handleError(error: HttpErrorResponse) {
    const errorService = this.injector.get(ErrorService);
    const logger = this.injector.get(LoggingService);
    const notifier = this.injector.get(NotificationService);

    let message;
    let stackTrace;
    // console.log(error.toString());
    if (error instanceof HttpErrorResponse) {
      // Server error
      message = errorService.getServerErrorMessage(error);
      //stackTrace = errorService.getServerErrorStackTrace(error);
      notifier.showError(message);
    } else {
      // Client Error
      message = errorService.getClientErrorMessage(error);
      notifier.showError(message);
    }
    // Always log errors
    // this.teleservice.Error(message);
    logger.logError(message, stackTrace);
    this.spinner.hide();
    console.error(error);
    if(error.toString().toUpperCase().trim().includes('NOT AUTHORIZED')){
      setTimeout(()=>{localStorage.clear();this.kcService.Logout();WebViewLogout();},3000); 
    }
  }
}