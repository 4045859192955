import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { WorkExperience } from '../../models-india/WorkExperience';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { WorkExperienceUpdate } from '../../models-india/WorkExperienceUpdated';
import { WorkExDoc } from '../../models-india/WorkExpDoc';
import { LPDms } from '../../models-india/LPDms';
import { DMSInternetPlainDocumentResponse } from '../../models-india/DMSInternetPlainDocumentResponse';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { WorkExInputParam, UpdateUANDetails } from '../../models-india/WorkExInputParam';
import { LPFetchSerialNo } from '../../models-india/LPFetchSerialNumber';
import { LPFetchExpDocDetails } from '../../models-india/LPFetchExpDocDetails';
import { DMSDocument } from '../../models-india/DMSDocument';
import { Configuration, APIUrls } from '../../../configuration';
import { DMSNew } from '../../models-india/dmsNew';
import { UpdateOtherDocDetails } from '../../models-india/UpdateOtherDocDetails.model';
import { DeleteOtherDocDetails } from '../../models-india/DeleteOtherDocDetails.model';
import { GetCandidateOtherDocList } from '../../models-india/GetCandidateOtherDocList';
import { LpFetchOtherDocNames } from '../../models-india/LpFetchAchievementsDocNames.model';
import { IndiaInputParam } from '../../models-india/IndiaInputParam.model';
import { BGVDetails } from '../../models-india/BGVDetails.model';

@Injectable({
  providedIn: 'root'
})
export class WorkexperienceService {
  constructor(private _http:LPIndiaCustomHttpClient) { }
  FetchExpDetailsList():Observable<WorkExperience[]> 
  {
    return this._http.post<WorkExperience[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchExpDetailsList,{"CandidateId":localStorage.getItem("intCandidateId")}).pipe(map((res) => {return res;}))    
  }
  UpdateCandWorkExp(experience:WorkExperienceUpdate):Observable<String>
  {
    let body=JSON.stringify(experience);
    // console.log(body);
    return this._http.post<String>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateCandWorkExp,body).pipe(map((res) => {return res;}))
  }
  UploadPlainDocument (lpdms:LPDms):Observable<DMSInternetPlainDocumentResponse>
    {
        let body= JSON.stringify(lpdms);
        return this._http.post<DMSInternetPlainDocumentResponse>(Configuration.ResServerBaseURLIndia+APIUrls.UploadPlainDocument,body).pipe(map((res) => {return res;}))
    }
  FetchExpDocSerialNo(workExInputParam:WorkExInputParam):Observable<LPFetchSerialNo[]>
  {
    let body=JSON.stringify(workExInputParam);
    return this._http.post<LPFetchSerialNo[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchExpDocSerialNo,body).pipe(map((res) => {return res;}))
  }
  FetchWorkExDocDetails(workExInputParam:WorkExInputParam):Observable<LPFetchExpDocDetails[]>
  {
    let body=JSON.stringify(workExInputParam);
    return this._http.post<LPFetchExpDocDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchWorkExDocDetails,body).pipe(map((res) => {return res;}))
  }
  DownloadDocumentFromDMS(lpdms:LPDms): Observable<DMSDocument> 
  {
      let body=JSON.stringify(lpdms);
      return this._http.post<DMSDocument>(Configuration.ResServerBaseURLIndia+APIUrls.DownloadDocumentFromDMS, body).pipe(map((res) => {return res;}));
  }
  InsertWorkExDocDetails(workExDoc:WorkExDoc):Observable<number>
  {
    let body=JSON.stringify(workExDoc);
    return this._http.post<number>(Configuration.ResServerBaseURLIndia+APIUrls.InsertWorkExDocDetails, body).pipe(map((res) => {return res;}));
  }
  DeleteExpDocDetails(workExInputParam:WorkExInputParam):Observable<Number>
    {
        let body= JSON.stringify(workExInputParam);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.DeleteExpDocDetails,body).pipe(map((res) => {return res;}))
    }

  UploadPlainDocumentNew(lpdms:DMSNew):Observable<DMSInternetPlainDocumentResponse>
{
    let body = JSON.stringify(lpdms);
    return this._http.post<DMSInternetPlainDocumentResponse>(Configuration.ResServerBaseURLIndia+APIUrls.UploadPlainDocumentNew,body).pipe(map((res) => {return res;}));
}

FetchUANDetails(data:UpdateUANDetails):Observable<UpdateUANDetails[]>
  {
    let body=JSON.stringify(data);
    return this._http.post<UpdateUANDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetUANDetails,body).pipe(map((res) => {return res;}))
  }

  UpdateUANDetails(data:UpdateUANDetails):Observable<Number>
  {
    let body=JSON.stringify(data);
    return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateUANDetails,body).pipe(map((res) => {return res;}))
  }

  UpdateOtherDocDetails(updateDoc:UpdateOtherDocDetails):Observable<String>
{
    let body = JSON.stringify(updateDoc);
    // console.log(body);
    return this._http.post<String>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateOtherDocDetails,body).pipe(map((res) => {return res;}));
}

DeleteOtherDocDetails(deleteDoc:DeleteOtherDocDetails):Observable<String>
{
    let body = JSON.stringify(deleteDoc);
    // console.log(body);
    return this._http.post<String>(Configuration.ResServerBaseURLIndia+APIUrls.DeleteOtherDocDetails,body).pipe(map((res) => {return res;}));
}

GetCandidateOtherDocList(input :GetCandidateOtherDocList):Observable<LpFetchOtherDocNames[]>
{
    let body = JSON.stringify(input);
    // console.log(body);
    return this._http.post<LpFetchOtherDocNames[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetCandidateOtherDocList,input).pipe(map((res) => {return res;}));
}
DownloadPlainDocument(lpdms:LPDms):Observable<DMSDocument>
{
    let body = JSON.stringify(lpdms);
    // console.log(body);
    return this._http.post<DMSDocument>(Configuration.ResServerBaseURLIndia+APIUrls.DownloadPlainDocumentIN,body).pipe(map((res) => {return res;}));
} 

DeleteGapDetails(edu:IndiaInputParam):Observable<Number>
{
    let body= JSON.stringify(edu);
    return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.DeleteGapDetailsIndia,body).pipe(map((res) => {return res;}))
}
AddBGVDetails(bgv:BGVDetails):Observable<string>{
  var body ={"CandidateId":parseInt(localStorage.getItem("intCandidateId"))}

  return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.AddBGVDetails,bgv).pipe(map(
      (res) => {
      // console.log(res);
      return res;
      }
      )) 

}

GetBGVDetails(bgv:BGVDetails):Observable<BGVDetails[]>{

  return this._http.post<BGVDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetBGVDetails,bgv).pipe(map(
      (res) => {
      // console.log(res);
      return res;
      }
      )) 
}
}