import { Injectable } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { MatSnackBar,  MatSnackBarConfig } from '@angular/material/snack-bar';
// import { LPTelemetryService } from 'src/app/services/LPTelemetry.service';

@Injectable({ providedIn: 'root' })
export class AlertService {
    private subject = new Subject<any>();
    private keepAfterNavigationChange = false;
    private config:MatSnackBarConfig = new MatSnackBarConfig();
    private configSucces: MatSnackBarConfig = {
        verticalPosition : "bottom",
        horizontalPosition : "center",
        duration : 3000,
        panelClass: ['snackbar-success'],    
      };
    
      private configError: MatSnackBarConfig = {
        verticalPosition : "bottom",
        horizontalPosition : "center",
        duration : 3000,
        panelClass: ['snackbar-error'],
      };

      private configInfo: MatSnackBarConfig = {
        verticalPosition : "bottom",
        horizontalPosition : "center",
        duration : 3000,
        panelClass: ['snackbar-info'],
      };
    constructor(private router: Router, private snackbar : MatSnackBar
        // ,private teleService : LPTelemetryService
        ) {
        // clear alert message on route change
        router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                if (this.keepAfterNavigationChange) {
                    // only keep for a single location change
                    this.keepAfterNavigationChange = false;
                } else {
                    // clear alert
                    this.subject.next();
                }
            }
        });
    }

    success(message: string, isMobile:Boolean, keepAfterNavigationChange = false) {
        this.snackbar.open(message,true?" ":undefined,this.configSucces);
        // this.teleService.Log(message);
        
    }

    error(message: string, isMobile:Boolean, keepAfterNavigationChange = false) {          
        this.snackbar.open(message,true?" ":undefined,this.configError);
        // this.teleService.Error(message);
    }

    info(message: string, isMobile:Boolean, keepAfterNavigationChange = false) {
        this.snackbar.open(message,true?" ":undefined,this.configInfo);
    }

    getMessage(): Observable<any> {
        return this.subject.asObservable();
    }
}