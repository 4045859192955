import { Injectable } from '@angular/core';
import { LPIndiaCustomHttpClient } from '../lpindia.interceptor';
import { UpdateBioConsent } from '../models-india/updateBioConsent';
import { HttpHeaders } from '@angular/common/http';
import { Configuration, APIUrls } from '../../configuration';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BioMetricService
{
    constructor(private _http:  LPIndiaCustomHttpClient ) { }
    
    UpdateBioConsentFlag(bioConsent:UpdateBioConsent):Observable<String>
    {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4NzZiYWU4Yi02NjYyLTQ1ZGMtYmQ5Zi0xNWE0NzQ4MGZjYmYiLCJleHAiOjE1NTY2ODYwOTEsIm5iZiI6MCwiaWF0IjoxNTU1MzkwMDkxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgvcmVhbG1zL0lTMSIsImF1ZCI6InBvcnRhbCIsInN1YiI6IjkzYjlmMGQ5LTg0ZjctNDVmNS05MzAxLTZjNGEwMzA4OTNmOCIsInR5cCI6IklEIiwiYXpwIjoicG9ydGFsIiwibm9uY2UiOiJiOThkOTk3My1jNjEyLTRmNjYtYjRmYi1lZGY5MzFlMjRkZDQiLCJhdXRoX3RpbWUiOjE1NTUzMzExNTcsInNlc3Npb25fc3RhdGUiOiIxYmJlMzIyZC0wNjUxLTRlNTgtOTQyNi01MDAzODVmOWI4OGMiLCJhY3IiOiIwIiwibmFtZSI6IkFiaGlzaGVrIE5lZ2kiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0MUBkdW1teS5jb20iLCJnaXZlbl9uYW1lIjoiQWJoaXNoZWsiLCJmYW1pbHlfbmFtZSI6Ik5lZ2kiLCJlbWFpbCI6InRlc3QxQGR1bW15LmNvbSJ9.dR6KzCyPLN-x1gt1SJAz3W9q2JqDESn5gTIh19sy5wgka6Uy7a3H_-km8UoQiUX5qTgg8onjbroWp0FUh45olXmAmVDOcL7cLvQvFuHOxL7OBeo5Dh18a3kUUl5tqPlypuxPQ-eZ7NGSR0T0jd6qn_is1hM1q_xQ3h7Jal8b7IH3012P8C7s51txRt0xH38bQCSSaz7tjgLRu0vc_95Cqwz2xMUq19NIJdEoWqxgw0P-6xGOgq44Np2Y2VypweacEpsEZDGBa9EdBEKcFPLe4rRabR8sE7LWJktX7JWqU1JHPWiWD4szVhlYnHjB2eS3k7hrvGEJ9Dz_gurL_oA8PQ');
        headers=headers.append('Content-Type','application/json');
        return this._http.post<String>
        (Configuration.ResServerBaseURLIndia+APIUrls.UpdateBioConsentFlag,
            bioConsent,{
                headers:headers
                }).pipe(map((res) => {return res;}));
    }
}