import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { AppService } from '../app.service';
import { FeedbackService } from '../feedback/feedback.service';
import { MainBranchDetails } from '../lpmain/Model-Classes/MainBranchDetails.Model';
import { Country } from '../lpus/models/country.model';
import { RoutingNumberDetails } from '../models/RoutingNumberDetails.model';
import { AlertService } from '../utils/alert/alert.service';

declare function LoadOwlCarouselForGallery() :any;
declare function LoadJsForModalBackDrop(): any;
declare function LoadOnBoardingPassModal(): any;
declare function LoadOnBoardingPassCloseModal(): any;
declare function LoadJsForDashBoardMobileCarousel() :any;
declare function RemoveDefaultBadgeImage():any;
declare function SwipeToLeft(): any;
declare function SwipeToRight(): any;
declare function addCssForQrCode(): any;
declare function HideBottomBar() : any;
declare function ShowBottomBar() : any; 
declare function AddBodyscroll() :any;
declare function Redirect(url):any;
declare function modalcloseWeb(): any;
@Component({
  selector: 'app-add-routing-no',
  templateUrl: './add-routing-no.component.html',
  styleUrls: ['./add-routing-no.component.css']
})
export class AddRoutingNoComponent implements OnInit {
  mobile: boolean;
  details:RoutingNumberDetails;
  countries : Country[] = [];
  branchDetails : MainBranchDetails[]=[];
  flgRoutingInfo : boolean = false;
  flgRoutingnoExists:boolean = false;
  txtLabel : string = "";
  ipsCanada : Boolean = localStorage.getItem("Region").trim().toUpperCase() == "IPS CANADA";

  constructor(private breakpointObserver: BreakpointObserver,private _alert: AlertService,
    private appService: AppService, private _service : FeedbackService,private _router:Router,
    private _spinner:NgxSpinnerService,
    private _r :ActivatedRoute)
     {LoadOnBoardingPassModal(); }

  ngOnInit() {
    this._spinner.show();
    this.appService.routingDetails.subscribe((isHidden)=>{
      this.flgRoutingInfo = isHidden;
    });
    if(localStorage.getItem("Region").toUpperCase()=="US" || localStorage.getItem("Region").toUpperCase()=="KALIDS" ||localStorage.getItem("Region").toUpperCase()=="IBPO" || localStorage.getItem("Region").toUpperCase()=="MCCAM" || localStorage.getItem("Region").toUpperCase()=="IBAUSA" ||  localStorage.getItem("Region").toUpperCase()=="IPS"){
       this.txtLabel = "Routing number";
    }
   else if(localStorage.getItem("Region").toUpperCase()=="CANADA" || localStorage.getItem("Region").toUpperCase()=="IPS CANADA"){
    this.txtLabel = "Transit Number (3 digit institution code + 5 digit transit number) :";
   }
   else if(localStorage.getItem("Region").toUpperCase()=="IL FRANCE"){
    this.txtLabel="BIC"
  }
   else
   {
    this.txtLabel = "Swift/Sort/BSB code";
   }
    this.getCountries();
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //console.log('Inside app component observer');
          this.appService.setIsMobile(true);
          this.mobile = true;
          window.scroll(0, 0);
        } else {
          //console.log('Inside app component observer'); 
          this.appService.setIsMobile(false);
          this.mobile = false;
        }
      });
  }

  CallJsForModalFade(){
    LoadJsForModalBackDrop();
  }

  hideModal(){
    LoadOnBoardingPassCloseModal();  
    if(localStorage.getItem("Region")=="IL UK" || localStorage.getItem("Region")=="BPM UK" ){
      this._router.navigate(['/lpuk/step1-uk/bank'], { relativeTo: this._r })
    }
     else if(localStorage.getItem("Region")=="US" || localStorage.getItem("Region")=="KALIDS"){
       Redirect(document.baseURI+"/lpus/step1/bank");
      this._router.navigate(['/lpus/step1/bank'], { relativeTo: this._r })
    }
    else if(localStorage.getItem("Region")=="IBPO" || localStorage.getItem("Region")=="McCam" || localStorage.getItem("Region").toUpperCase()=="IBAUSA" || localStorage.getItem("Region").toUpperCase()=="IPS"){
      Redirect(document.baseURI+"lpusbpo/step1/bank");
     this._router.navigate(['/lpusbpo/step1/bank'], { relativeTo: this._r })
   }
   else if(localStorage.getItem("CompanyCode").toUpperCase()=="COMMON"){
    Redirect(document.baseURI+"lpmain/step1-common/bank-common");
    this._router.navigate(['/lpmain/step1-common/bank-common'], { relativeTo: this._r })
   }  
  }
  
  AddBodyscroll(){
    AddBodyscroll();
  }

  getCountries()
  {
    this._spinner.show();
    this._service.GetCountry().subscribe(
      res=>{
        if(res!=null && res.length > 0)
        {
          this.countries = res;
          this._spinner.hide();
        }
      }
    )
  }

  addRotingNumberDetails(form:NgForm)
  {
    this._spinner.show();
    this.flgRoutingnoExists = false;
    this.details = new RoutingNumberDetails();
    this.details.intCandidateId = Number(localStorage.getItem("CandidateID"));
    this.details.txtCandidateName = localStorage.getItem("CandFirstName");
    this.details.txtBankName = form.value.bname;
    this.details.txtBranchName = form.value.brname;
    this.details.txtRoutingNumber = form.value.routing;
    for(let i of this.countries)
    {
      if(i.CountryCode.trim() == form.value.countryRef.trim()){
        this.details.txtCountryCode = i.CountryCode.toString();
        this.details.txtCountryName = i.CountryName.toString();
      }
    }
    this.details.txtBankaddress = form.value.bankAdd;
    let txtRoutingno = form.value.routing;
    let country = form.value.countryRef;
    this._service.GetBranchDetails(country.trim()).subscribe(
      res=>{
        if(res!=null && res.length>0)
        {
          this.branchDetails = res;
          //console.log("Inside service to fetch branch details")
          for (let i of this.branchDetails)
          {
            //console.log(i);
            if(i.Code.trim().toUpperCase() == txtRoutingno.trim().toUpperCase())
            {
              this._spinner.hide();
              this.flgRoutingnoExists = true;
              this._alert.error("Routing no/ Swift code is already present in the database, please proceed to submit the bank details.",this.mobile);
              break;
            }
          }

          if(!this.flgRoutingnoExists){
            this._service.InsertRoutingNumberDetails(this.details).subscribe(
              res=>
              {
                if(res!=null)
                {
                  if(res.trim() == "1")
                  {
                    this._spinner.hide();
                    //this.appService.routingDetails = true;
                    this.appService.setFlagRouting(true);
                    this.appService.routingDetails.subscribe((isHidden)=>{
                      this.flgRoutingInfo = isHidden;
                    });
                    modalcloseWeb();
                    this._alert.success("Details sent successfully.",this.mobile);
                    
                  }
                  else if(res.trim() == "3")
                  {
                    this._spinner.hide();
                    modalcloseWeb();
                    this._alert.error("This request already exists in the system. Please wait for 48 working hours for the request to be processed",this.mobile);
                  }
                  else if(res.trim() == "2")
                  {
                   this._spinner.hide();
                    modalcloseWeb();
                    this._alert.error("We are unable to trigger mail to concerned team. Please try after sometime.",this.mobile);
                  }
                  else if(res.trim() == "-1")
                  {
                    this._spinner.hide();
                    modalcloseWeb();
                    this._alert.error("Unable to process your request. Please try after sometime.",this.mobile);
                  }
                }
              }
            );
          }
        }
      }
    );
    
    AddBodyscroll();
  }

  
  
}
