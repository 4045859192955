import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Candidate } from '../../models-india/Candidate';
import { Observable } from 'rxjs';
import { LpFetchOfferDetails } from '../../models-india/LPFetchOfferDetails';
import { map } from 'rxjs/operators';
import { OfferStatus } from '../../models-india/OfferStatus';
import { GDC } from '../../models-india/GDC.model';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { Configuration, APIUrls } from '../../../configuration';

@Injectable({
    providedIn: 'root'
  })
export class DeclareService
{   
    gotohome:boolean;
    constructor(private _http: LPIndiaCustomHttpClient) { }

    getOfferDetails(candidate: Candidate):Observable<LpFetchOfferDetails[]>
    {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        let body = JSON.stringify(candidate);
        // console.log(body);
        return this._http.post<LpFetchOfferDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.getOfferDetails,body,{headers:headers}).pipe(map((res) => {return res;}));
    }

    updateOfferStatus(offerStatus:OfferStatus):Observable<String>
    {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        let body = JSON.stringify(offerStatus);
        return this._http.post<String>(Configuration.ResServerBaseURLIndia+APIUrls.updateOfferStatus,body,{headers:headers}).pipe(map((res) => {return res;}));
    }

    updateDataForGDC(gdc:GDC):Observable<Number>
    {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        let body = JSON.stringify(gdc);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.updateDataForGDC,body,{headers:headers}).pipe(map((res) => {return res;}));;
    }
}
