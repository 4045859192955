
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'; 
import { PendingActionsIndia } from '../models-india/PendingActions.model-india';
import { LPIndiaCustomHttpClient } from '../lpindia.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { IndiaInputParam } from '../models-india/IndiaInputParam.model';


@Injectable()
export class PostjoiningIndiaService {
    constructor(private http: LPIndiaCustomHttpClient) { }

        getPendingActions (param:IndiaInputParam): Observable<PendingActionsIndia> {        
            return this.http.post<PendingActionsIndia>(Configuration.ResServerBaseURLIndia+APIUrls.GetPendingActionsIN,param,
            ).pipe(map(
            (res) => {
            return res;
            } 
            ))  
            }
         
        }