
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core';

import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { BranchDetails } from './models/branchdetails.model';
import { BankDetails } from './models/bankdetails.model';
import { SSNOTP } from './models/ssnotp.model';



@Injectable()
export class LpusService {
    

}