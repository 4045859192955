import { Component, OnInit } from '@angular/core';
import { lpUserCredentials } from '../../models/lpUserCredentials';
import { Router, ActivatedRoute } from '@angular/router';
import { IdBadgeService } from './idbadge.service';
import { CandidateAdvisorImageGlobal } from '../../models/CandidateAdvisorImageGlobal.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../../app.service';
import { AlertService } from '../../../utils/alert/alert.service';
import { NgxImageCompressService } from 'ngx-image-compress';
//need to check import { Ng2ImgMaxService } from 'ng2-img-max';
import { throwError } from 'rxjs';
import { PostjoiningService } from 'src/app/lpus/postjoining/postjoining.service';
import { AuthService } from 'src/app/login/auth.service';
import { NoteConstants } from 'src/app/NotesConstants';

declare function AddDefaultBadgeImage(): any;
declare function LoadScrollJS(): any;
declare function ChangeIdBadgeImage(): any;
declare function LoadJsForModalBackDrop(): any;
declare function ChangeEmergencyImage(): any;
declare function RemoveDefaultBadgeImage(): any;
declare function SwipeToLeft(): any;
declare function LoadJsForDashBoardMobileCarousel(): any;
declare function LoadOwlCarousel(): any;
declare function LoadOwlCarouselForGallery(): any;
declare function ShowBottomBar(): any;
declare function LoadStyleForIcons(i: string, c: string, a: string, s: string, b: string): any;
declare function SwipeToRight(): any;
declare function resizeImageFn(params: any): any;
declare function LoadStyleForVanguardIcons(i: string, c: string, a: string, s: string, b: string, w: string, p: string, t: string): any;
@Component({
  selector: 'app-idbadge',
  templateUrl: './idbadge.component.html',
  styleUrls: ['./idbadge.component.css']
})
export class IdbadgeComponent implements OnInit {
  SWIPE_ACTION = { LEFT: 'swipeleft', RIGHT: 'swiperight' };
  public imagePath;
  imgURL: any;
  public message: string;
  show: Boolean = true;
  upload: Boolean;
  showMessage: Boolean;
  sizeError: Boolean = false;
  errorMessage: string;
  uploadImg: Boolean = true;
  base64: any;
  file: any;
  prev: Boolean;
  mobile: Boolean;
  desktop: Boolean;
  first: string = localStorage.getItem("CandFirstName");
  last: string = localStorage.getItem("CandLastName");
  delete: Boolean = false;
  dltButton: Boolean = false;
  candidateAdvisorImg = new CandidateAdvisorImageGlobal;
  userCredentials = new lpUserCredentials
  d: any;
  order: string;
  defaultImage: boolean = true;
  emergency: string;
  empbool: boolean;
  daysLeft = Number(localStorage.getItem("DaysLeft"));
  static fileAsBasestring: string;
  static Base64String: any;
  static arr: any[];
  emp_no: string;
  pendingWeb: Boolean = false;
  width: number;
  height: number;
  vanRegion: boolean = false;
  txtTopNote: string;
  txtBottomNote: string;
  txtTitleName: string;
  txtTopNoteMobile: string;
  txtBottomNoteMobile: string;
  txtTitleNameMobile: string;
  flgEnableTopNote: boolean;
  flgEnableBottomNote: boolean;
  EmailId:string="launchpad_us@infosys.com";
  boolCandImage:Boolean = localStorage.getItem("flgCandidateImage").toUpperCase() == "Y";
  flgUploaded: Boolean = false;
  user= new lpUserCredentials();

  constructor(private idBadgeService: IdBadgeService, private _router: Router,
    private r: ActivatedRoute,
    private breakpointObserver: BreakpointObserver, private appService: AppService, private alert: AlertService,
    private imageCompress: NgxImageCompressService,
    private postService: PostjoiningService,
    private spinner:NgxSpinnerService) {

       this.spinner.show();
      this.idBadgeService.fnGetCandPhotoEMEA().subscribe(
        (res) => {
          if ( res != null ){
          // console.log(res)
          if (res.CandAdvImg != null) {
            this.flgUploaded = true;
            this.emergency = res.EmergencyContact;
            this.imgURL = 'data:image/jpg;base64,' + res.CandAdvImg;
            if(this.imgURL!=null){
              this.defaultImage=false;

              RemoveDefaultBadgeImage();
            }
            IdbadgeComponent.fileAsBasestring = String(this.imgURL).substr(22);

            this.upload = true;
            this.show = false;
          }
          else {
            this.show = true;
            this.upload = false;
          }
           this.spinner.hide();
        }
      }
      );
      this.user = {
        CandidateID: Number(localStorage.getItem("CandidateID")),
        AddressType: null,
        Password: null,
        RequestType: null,
        UserName: localStorage.getItem("CandidateEmailID")
      };
    this.userCredentials.CandidateID = Number(localStorage.getItem("CandidateID"));
    if (this.appService.imageURL == null || this.appService.imageURL == "") {
       this.spinner.show();
      this.idBadgeService.fnGetCandPhoto(this.userCredentials).subscribe(
        (res) => {
          // console.log(res)
          if (res.CandAdvImg != null) {
            this.imgURL = 'data:image/jpg;base64,' + res.CandAdvImg;
            this.appService.imageURL = 'data:image/jpg;base64,' + res.CandAdvImg;
            //console.log(this.imgURL);
            if (this.imgURL != null) {
              this.defaultImage = false;
              RemoveDefaultBadgeImage();
            }
            IdbadgeComponent.fileAsBasestring = String(this.imgURL).substr(22,);
            this.emergency = res.EmergencyContact;
            this.appService.contactNumber = res.EmergencyContact;
            this.upload = true;
            this.show = false;
            if (this.imgURL != null) {
              RemoveDefaultBadgeImage();
            }
          }
          else {
            this.show = true;
            this.upload = false;
          }
           this.spinner.hide();
        });
    }
    else {
      this.imgURL = this.appService.imageURL;
      if (this.imgURL != null) {
        //console.log(this.imgURL);
        if (this.imgURL != null) {
          this.defaultImage = false;
          RemoveDefaultBadgeImage();
        }
        IdbadgeComponent.fileAsBasestring = String(this.imgURL).substr(22,);
        this.emergency = this.appService.contactNumber;
        this.upload = true;
        this.show = false;
        if (this.imgURL != null) {
          RemoveDefaultBadgeImage();
        }
      }
      else {
        this.show = true;
        this.upload = false;
      }
    }
     this.spinner.hide();

  }
  swipe(currentIndex: number, action = this.SWIPE_ACTION.RIGHT) {
    if (action === this.SWIPE_ACTION.RIGHT) {
      SwipeToRight();
    }
    if (action === this.SWIPE_ACTION.LEFT) {
      SwipeToLeft();
    }
  }

  preview(files: any) {
    this.defaultImage = false;

     this.spinner.show();
    if (this.imgURL == null || this.imgURL == "") {
      this.dltButton = true;
      AddDefaultBadgeImage();
    }

    if (files.length === 0) {
       this.spinner.hide();
      return;
    }


    if (this.delete == true) {
      this.imgURL = null;
      this.delete = false;
      this.show = true;
      this.upload = false;
       this.spinner.hide();
      return
    }
    var mimeType = files[0].name;
    if ((files[0].name.substr(files[0].name.length - 3,)).toLowerCase() != "png" && (files[0].name.substr(files[0].name.length - 3,)).toLowerCase() != "jpg"
      && (files[0].name.substr(files[0].name.length - 4,)).toLowerCase() != "jpeg" && (files[0].name.substr(files[0].name.length - 3,)).toLowerCase() != "fif"
      && files[0].name.substr(files[0].name.length - 3,) != "PNG" && files[0].name.substr(files[0].name.length - 3,) != "JPG"
      && files[0].name.substr(files[0].name.length - 4,) != "JPEG" && files[0].name.substr(files[0].name.length - 3,) != "FIF") {
      this.showMessage = true
      this.defaultImage = true;
      this.message = "Images only, please!";
       this.spinner.hide();
      this.alert.error(this.message, this.mobile);
      this.sizeError = false;
      this.imgURL = false;
      this.show = true;
      this.upload = false;
      this.delete = false;
      return;
    }

    if (files[0].size > 5000000) {
      //console.log(files[0].size)
      this.defaultImage = true;
      this.sizeError = true;
       this.spinner.hide();
      this.errorMessage = "You look great! But let's keep that image size within 5 MB."
      this.alert.error(this.errorMessage, this.mobile);
      this.showMessage = false;
      this.imgURL = false;
      this.show = true;
      this.upload = false;
      this.delete = false;
      return;
    }
    var reader = new FileReader();
    var img = new Image();
    this.imagePath = files;
    reader.readAsDataURL(files[0]);
    if (this.desktop || this.mobile) {
      reader.onload = (_event) => {
        this.imgURL = reader.result;
        img.src = this.imgURL;
        img.onload = () => {
          this.width = img.width;
          this.height = img.height;
          //console.log(this.width,this.height);
          if (this.width > this.height) {
            this.defaultImage = true;
            this.alert.error("Please upload only portrait images", this.mobile);
            this.showMessage = false;
            this.imgURL = false;
            this.show = true;
            this.upload = false;
            this.delete = false;
            return;
          }
        };
        this.showMessage = false;
        this.show = false;
        this.upload = true;
        this.sizeError = false;
        this.uploadImg = false;
        this.delete = false;
        //console.log(this.imgURL)
      }

    }

    if (reader.result != null) {
      this.show = false;
      this.upload = true;
      this.sizeError = false;
      this.delete = false;
    }
     this.spinner.hide();
    if (this.imgURL != null) {
      RemoveDefaultBadgeImage();
    }
  }

  uploadImage() {
     this.spinner.show();
    this.uploadImg = true;
    this.delete = false;
    this.candidateAdvisorImg.CandidateId = Number(localStorage.getItem("CandidateID"));

    this.candidateAdvisorImg.ImageAsString = IdbadgeComponent.fileAsBasestring;
    //console.log(this.candidateAdvisorImg.ImageAsString);
    this.idBadgeService.InsertCandidateImage(this.candidateAdvisorImg).subscribe(
      (res) => {
        this.appService.imageURL = null;
         this.spinner.hide();
        this.alert.success("Done! Photo is uploaded. ", this.mobile);
        setTimeout(() => this.nextSection(),3000);
        setTimeout(() => ShowBottomBar(),3000);
       
      },
      (err) => {

      }
    );

    if (this.mobile == true) {
      window.scroll(0, 0);
    }
  }

  dlt() {
    AddDefaultBadgeImage();
    this.delete = true;
    this.preview(File);
    this.defaultImage = true;
  }

  onFileChange($event) {
    //image to base64
    ////need to check this.spinner.show();
    // let me = this;
    let files = $event.target.files[0];

    // //console.log(files);

    // //  imageConversion.compress(file,0.9).then(res=>{
    // //   console.log(res);
    // // })
    // this.defaultImage = false;
    // if (this.imgURL == null || this.imgURL == "") {
    //   this.dltButton = true;
    //   AddDefaultBadgeImage();
    // }


    // if (this.delete == true) {
    //   this.imgURL = null;
    //   this.delete = false;
    //   this.show = true;
    //   this.upload = false;
    //   ////need to check this.spinner.hide();
    //   return
    // }
    // var mimeType = files.name;
    // if ((files.name.substr(files.name.length - 3,)).toLowerCase() != "png" && (files.name.substr(files.name.length - 3,)).toLowerCase() != "jpg"
    //   && (files.name.substr(files.name.length - 4,)).toLowerCase() != "jpeg" && (files.name.substr(files.name.length - 3,)).toLowerCase() != "fif"
    //   && files.name.substr(files.name.length - 3,) != "PNG" && files.name.substr(files.name.length - 3,) != "JPG"
    //   && files.name.substr(files.name.length - 4,) != "JPEG" && files.name.substr(files.name.length - 3,) != "FIF") {
    //   this.showMessage = true
    //   this.defaultImage = true;
    //   this.message = "Images only, please!";
    //   ////need to check this.spinner.hide();
    //   this.alert.error(this.message, this.mobile);
    //   this.sizeError = false;
    //   this.imgURL = false;
    //   this.show = true;
    //   this.upload = false;
    //   this.delete = false;
    //   return;
    // }

    // if (files.size > 5000000) {
    //   //console.log(files[0].size)
    //   this.defaultImage = true;
    //   this.sizeError = true;
    //   ////need to check this.spinner.hide();
    //   this.errorMessage = "You look great! But let's keep that image size within 5 MB."
    //   this.alert.error(this.errorMessage, this.mobile);
    //   this.showMessage = false;
    //   this.imgURL = false;
    //   this.show = true;
    //   this.upload = false;
    //   this.delete = false;
    //   return;
    // }


    // //this.ng2ImgMax.compressImage(file, 0.9).subscribe(
    // //debugger
    // imageConversion.compressAccurately(files, 400).then(
    //   result => {
    //     //console.log(result);
    //     var reader = new FileReader();
    //     reader.readAsDataURL(result);
    //     reader.onloadend = function () {
    //       IdbadgeComponent.Base64String = reader.result;
    //       IdbadgeComponent.arr = String(IdbadgeComponent.Base64String).split(",");
    //       IdbadgeComponent.fileAsBasestring = IdbadgeComponent.arr[1];
    //       //console.log(IdbadgeComponent.fileAsBasestring);

    //     }

    //   });

    resizeImageFn({
      file: files,
      maxSize: 500
    }).then(function (resizedImage) {
      // IdbadgeComponent.Base64String = reader.result;
      // IdbadgeComponent.arr = String(IdbadgeComponent.Base64String).split(",");
      IdbadgeComponent.fileAsBasestring = resizedImage;
      //console.log(resizedImage)
      //console.log("upload resized image")
      // code of Upload image here
    }).catch(function (err) {
      //console.error(err);
    });

  }

  view() {
    this.prev = true;
  }

  ngOnInit() {
    LoadScrollJS()
    ChangeEmergencyImage();
    if (localStorage.getItem("CompanyNameIdentifier").toUpperCase() == "VANGUARD") {
      this.vanRegion = true;
    }
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //this.appService.setIsMobile(true);
          this.mobile = true;
          this.desktop = false;
          window.scroll(0, 0);
        } else {
          //this.appService.setIsMobile(false);
          this.mobile = false;
          this.desktop = true;
        }
      });
    this.r.queryParams.subscribe(params => {
      this.order = params["order"]
      if (this.order == "W") {
        this.pendingWeb = true;
      }
      else {
        this.pendingWeb = false;
      }
    });

    this.emp_no = localStorage.getItem("EmpNumber");
    //console.log(this.emp_no);
    if (this.daysLeft > 0) {
      this.empbool = false;
      //console.log("Inside false");
    }
    else if (this.daysLeft <= 0 && this.emp_no != null && this.emp_no != "") {
      //console.log("true");
      this.empbool = true;
    }
    else {
      //console.log("Inside null");
      this.empbool = false;
    }

    //Fetch top and bottom notes

    if (AuthService.HeadingList.length > 0) {
      for (let ele of AuthService.HeadingList) {
        if (ele.txtSectionCode != null && ele.txtSectionCode == NoteConstants.txtIDbadgeUSSectionCode) {
         
          this.txtTitleName = ele.txtTitle

          if (ele.flgEnableBottomNote == "Y") {
            this.flgEnableBottomNote = true
          }
          else {
            this.flgEnableBottomNote = false
          }
          if (ele.flgEnableTopNote == "Y") {
            this.flgEnableTopNote = true
          }
          else {
            this.flgEnableTopNote = false
          }
        }
        
      }
    }
    else {
      this.flgEnableBottomNote = true
      this.flgEnableTopNote = true
      this.txtTitleName = NoteConstants.txtIDBadgeTitle
    }





  }
  iconChange() {
    if (this.order != "N" || !this.mobile) {
      if (this.order == "P" && this.mobile) {
        LoadJsForDashBoardMobileCarousel();
        LoadOwlCarousel();
        LoadOwlCarouselForGallery();
        this.getPendingAction();
        this.appService.setHiddenDashBoard(false);
        this._router.navigateByUrl('lpau/step1-au/dummy', { skipLocationChange: true });
        setTimeout(() => this._router.navigate(['../lpus']),3000);
        setTimeout(() => ShowBottomBar(),3000);
      }
      else if (this.order == "W" && !this.mobile) {
        this._router.navigate(['../lpus/postjoining']);
      }
      else {
        ChangeIdBadgeImage()
        this._router.navigate(['/lpus/step1/emergency']);
      }
    }
    else {
      this._router.navigate(['notification']);
    }
  }

  nextSection()
  {
    if(!this.pendingWeb){
     if (this.order != "N" || !this.mobile) {
          if (this.order == "P" && this.mobile) {
            LoadJsForDashBoardMobileCarousel();
            LoadOwlCarousel();
            LoadOwlCarouselForGallery();
            this.getPendingAction();
            this.appService.setHiddenDashBoard(false);
            this._router.navigateByUrl('lpau/step1-au/dummy', { skipLocationChange: true });
            setTimeout(() => this._router.navigate(['../lpus']),3000);
            setTimeout(() => ShowBottomBar(),3000);
          }
          else if (this.order == "W") {
            this._router.navigate(['../lpus/postjoining/badge'], { queryParams: { order: 'W' } });
          }
          else {
            this._router.navigate(['/lpus/step1/emergency'], { relativeTo: this.r });
            ChangeIdBadgeImage();
          }
        }
        else {
          this._router.navigate(['notification']);
        }

  }
}

  CallJsForModalFade() {
    //alert('I am inside Back drop');
    if (this.imgURL != null) {

      RemoveDefaultBadgeImage();
    }
    LoadJsForModalBackDrop();
  }

  compressFile() {
     this.spinner.show();
    this.imageCompress.uploadFile().then(({ image, orientation }) => {
      //console.log('Size of image is '+this.imageCompress.byteCount(image));
      //console.log('Image is '+image);

      // console.log(image.indexOf('jpeg'));
      if (image.indexOf('jpeg') === -1 || image.indexOf('jpg') === -1 || image.indexOf('png') === -1) {
        this.showMessage = true
        this.message = "Images only, please!";
        this.alert.error(this.message, this.mobile);
        this.sizeError = false;
        this.imgURL = false;
        this.show = true;
        this.upload = false;
        this.delete = false;
        return;
      }

      this.imageCompress.compressFile(image, orientation, 50, 50).then(result => {
        //console.log('Size of image is '+this.imageCompress.byteCount(result));
        //console.log('New Image is '+result);
        this.imgURL = result;
        IdbadgeComponent.fileAsBasestring = String(result).substr(23,);

      })
    });
     this.spinner.hide();
  }
  getPendingAction() {
    this.postService.getPendingActions().subscribe(
      (res) => {
        if (res != null) {

          if (this.vanRegion) {
            if (res.Address == "N" || res.EmergencyAddress == "N") {
              res.Address = "N";
            }

          }

          if (this.vanRegion) {
            LoadStyleForVanguardIcons(res.ImageUpload, res.Emergency, res.Address, res.SSN, res.BANK, res.W4, res.Policies, res.Tax);
          }
          else {
            LoadStyleForIcons(res.ImageUpload, res.Emergency, res.Address, res.SSN, res.BANK);
          }
        }
      });
  }
}
