export class WorkExInputParam {

    public CandidateId: number;
    public CandExDetID: number;
    public GapID: number;
    public CandExDetIdID: number;
    public sqlGapDetails: number;
    public sqlParameterMarks: number;
    public sqlParameterLanguage: number;
    public flgMode: number;
    public levelCode: number;
    
}
export class UpdateUANDetails {

    public  intCandidateId: number;
    public  flgUAN: string;
    public  UANNumber: string;
    public  flgProvident:string;
    public  flgPension:string;
    public  flgIntworker:string;
}

