import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment.prod';
import { HttpClient } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class EnvironmentLoaderService {

  constructor(private http: HttpClient) { }

  async getEnvData() {
    let envUrl: string = "";

    if (environment.production === true) {
     
      envUrl = 'assets/environments/environment.json';
      
      // console.log('here' +environment.production + envUrl  );
    }
    else {
      envUrl = 'assets/environments/environment-dev.json';
      // console.log('here' +environment.production + envUrl  );
    }
    return await this.http.get(envUrl).toPromise();
  }

}