import { Injectable } from '@angular/core';
import { LPIndiaCustomHttpClient } from '../lpindia.interceptor';
import { Observable } from 'rxjs';
import { HttpHeaders } from '@angular/common/http';
import { Configuration, APIUrls } from '../../configuration';
import { map } from 'rxjs/operators';
import { LPFetchProfileStatus } from '../models-india/LPFetchProfileStatus';


@Injectable()
export class notificationINService {
    
    constructor(private _http: LPIndiaCustomHttpClient) {   }

   
    GetProfileStatus(candidateID:Number):Observable<LPFetchProfileStatus> 
    { var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4MzgwOGFhYi01MDFiLTRlMGMtOTVjYi1lODg2YTA1NzRiOTMiLCJleHAiOjE1NTU1Njc2NTgsIm5iZiI6MCwiaWF0IjoxNTU0MjcxNjU4LCJpc3MiOiJodHRwczovL3dpbmdzcGFuLWRldi5pbmZvc3lzYXBwcy5jb20vYXV0aC9yZWFsbXMvSVMxIiwiYXVkIjoicG9ydGFsIiwic3ViIjoiOTNiOWYwZDktODRmNy00NWY1LTkzMDEtNmM0YTAzMDg5M2Y4IiwidHlwIjoiSUQiLCJhenAiOiJwb3J0YWwiLCJub25jZSI6ImY5ZWYzNDUxLWEzYTctNDc1Mi04YmNhLTQxYjg0NTg1YWU0YyIsImF1dGhfdGltZSI6MTU1NDIwMzM5Miwic2Vzc2lvbl9zdGF0ZSI6IjA5NjFhMDNiLTU2NGItNGYwNy1iMjRjLTgzN2FlYmJhNjY0MCIsImFjciI6IjAiLCJuYW1lIjoiQWJoaXNoZWsgTmVnaSIsInByZWZlcnJlZF91c2VybmFtZSI6InRlc3QxQGR1bW15LmNvbSIsImdpdmVuX25hbWUiOiJBYmhpc2hlayIsImZhbWlseV9uYW1lIjoiTmVnaSIsImVtYWlsIjoidGVzdDFAZHVtbXkuY29tIn0.ncBiZlVLJa5QHpe8KwWlOjcnpoF4rnbuQd70_2vSQbrpy0qdP7-AzLDadEp5U03mjzlWWEJR0KegfwVrJuQIdxRHurlJbZ2M7MCHdWroi4RCDCI4HzDSbvyFsDyLrZgg0WEuo5YO7b4Up1JN4sfVro1MfqqCBoimWV63HfDGC0XKJ6hMVt1UC-sX3NfqO4xtC3w5lTfv84wSmdWGhQNKvaTOlHhjqyXTDg8c2UrCaOvbu-jk3L_hOdNOogizMqIufyBhmOMsJusuO-hH5sQuX5L0BA7DFF1plwqyXm9Y5DdjSVApDYiHr5GCgdVCitLAXmCWN1yA3WlnrZGHATr-yw');
    headers=headers.append('Content-Type','application/json');
    let body = JSON.stringify(candidateID);
    return this._http.post<LPFetchProfileStatus>(Configuration.ResServerBaseURLIndia+APIUrls.GetProfileStatus,{"CandidateID":candidateID},{
    headers:headers
    }).pipe(map(
    (res) => {
    //console.log(res);
    return res;
    }
    ))  
    }
    GetDocumentStatus(candidateID:Number):Observable<LPFetchProfileStatus> 
    { var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4MzgwOGFhYi01MDFiLTRlMGMtOTVjYi1lODg2YTA1NzRiOTMiLCJleHAiOjE1NTU1Njc2NTgsIm5iZiI6MCwiaWF0IjoxNTU0MjcxNjU4LCJpc3MiOiJodHRwczovL3dpbmdzcGFuLWRldi5pbmZvc3lzYXBwcy5jb20vYXV0aC9yZWFsbXMvSVMxIiwiYXVkIjoicG9ydGFsIiwic3ViIjoiOTNiOWYwZDktODRmNy00NWY1LTkzMDEtNmM0YTAzMDg5M2Y4IiwidHlwIjoiSUQiLCJhenAiOiJwb3J0YWwiLCJub25jZSI6ImY5ZWYzNDUxLWEzYTctNDc1Mi04YmNhLTQxYjg0NTg1YWU0YyIsImF1dGhfdGltZSI6MTU1NDIwMzM5Miwic2Vzc2lvbl9zdGF0ZSI6IjA5NjFhMDNiLTU2NGItNGYwNy1iMjRjLTgzN2FlYmJhNjY0MCIsImFjciI6IjAiLCJuYW1lIjoiQWJoaXNoZWsgTmVnaSIsInByZWZlcnJlZF91c2VybmFtZSI6InRlc3QxQGR1bW15LmNvbSIsImdpdmVuX25hbWUiOiJBYmhpc2hlayIsImZhbWlseV9uYW1lIjoiTmVnaSIsImVtYWlsIjoidGVzdDFAZHVtbXkuY29tIn0.ncBiZlVLJa5QHpe8KwWlOjcnpoF4rnbuQd70_2vSQbrpy0qdP7-AzLDadEp5U03mjzlWWEJR0KegfwVrJuQIdxRHurlJbZ2M7MCHdWroi4RCDCI4HzDSbvyFsDyLrZgg0WEuo5YO7b4Up1JN4sfVro1MfqqCBoimWV63HfDGC0XKJ6hMVt1UC-sX3NfqO4xtC3w5lTfv84wSmdWGhQNKvaTOlHhjqyXTDg8c2UrCaOvbu-jk3L_hOdNOogizMqIufyBhmOMsJusuO-hH5sQuX5L0BA7DFF1plwqyXm9Y5DdjSVApDYiHr5GCgdVCitLAXmCWN1yA3WlnrZGHATr-yw');
    headers=headers.append('Content-Type','application/json');
    let body = JSON.stringify(candidateID);
    return this._http.post<LPFetchProfileStatus>(Configuration.ResServerBaseURLIndia+APIUrls.GetDocumentStatus,{"CandidateID":candidateID},{
    headers:headers
    }).pipe(map(
    (res) => {
    //console.log(res);
    return res;
    }
    ))  
    }
}