import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { lpUserCredentials } from '../models/lpUserCredentials';
import { USPreJoiningLogin } from '../models/USPreJoiningLogin.model';
import { CandidateAdvisorImage } from '../models/candidateAdvisorImage';
import { MailContent } from '../models/MailContent.model';
import { Result } from '../models/Result.model';
import { LPFetchCandidateDetailsOnLogin } from '../models/LPFetchCandidateDetailsOnLogin';
import { LPUSCustomHttpClient } from '../lpus.interceptor';
import { Configuration, APIUrls } from '../../configuration';


@Injectable()
export class HeaderService {
    
    
    constructor(private _http: LPUSCustomHttpClient) { }

    GetUserDetails(user:lpUserCredentials):Observable<USPreJoiningLogin[]>{
        //console.log(Configuration.ResServerBaseURL+APIUrls.GetUserDetails)
        return this._http.post<USPreJoiningLogin[]>(Configuration.ResServerBaseURL+APIUrls.GetUserDetails,user).pipe(map(
        (res) => {
        return res;
        }))
    }
    fnGetCandPhoto(userCredentials:lpUserCredentials): Observable<CandidateAdvisorImage> {
        
        return this._http.post<CandidateAdvisorImage>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhoto,{"CandidateId":userCredentials.CandidateID}).pipe(map(
        (res) => {
        return res;
    } 
))  
    }
    _errorHandler(error: HttpErrorResponse) {
        //console.log(error);
        return throwError(error.message || "Server Error");
    }
    SendMailerHelp (mailContent:MailContent): Observable<Result> {
        
        return this._http.post<Result>(Configuration.ResServerBaseURL+APIUrls.SendMailerHelp,{"MailBody":mailContent.MailBody,"MailSubject":"Request for Call back submitted by " + localStorage.getItem("CandFirstName") + " " + localStorage.getItem("CandLastName") + " " + "(" + localStorage.getItem("CandidateID") + ")","Region":localStorage.getItem("Region"),"CompanyNameIdentifier":localStorage.getItem("CompanyNameIdentifier")}).pipe(map(
        (res) => {
        return res;
    } 
))  
    }
  
}