export class RoutingNumberDetails
{
    intCandidateId : Number;
    txtCandidateName : string;
    txtBankName : string;
    txtBranchName : string;
    txtRoutingNumber : string;
    txtCountryName : string;
    txtCountryCode : string;
    txtBankaddress :string;
    flgMailStatus :string;
}