import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '../../../node_modules/@angular/router';
//need to check import { Ng4LoadingSpinnerService } from '../../../node_modules/ng4-loading-spinner';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-lpus-bpo',
  templateUrl: './lpus-bpo.component.html',
  styleUrls: ['./lpus-bpo.component.css']
})
export class LpusBPOComponent implements OnInit {
  mobile: boolean;
  year=localStorage.getItem("footeryear");
  postjoining:Boolean=Number(localStorage.getItem("DaysLeft"))<=0;
  prejoining:Boolean=Number(localStorage.getItem("DaysLeft"))>0;
  constructor(private breakpointObserver:BreakpointObserver,  private appService: AppService,private router:Router,
    private spinner:NgxSpinnerService
  ) { }

    private Deccflag = new BehaviorSubject<boolean>(false);
Brcast3 = this.Deccflag.asObservable();

NewDecFlag(newFlg) {
  this.Deccflag.next(newFlg);
}

  ngOnInit() {

  this.breakpointObserver
    .observe(['(max-width: 599px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.appService.setIsMobile(true);
        this.mobile = true;
      } else {
        this.appService.setIsMobile(false);
        this.mobile = false;
          this.toNavigateUS();
      }
    });
  }

  toNavigateUS(){
    var FlagTermsAndCondition=localStorage.getItem("FlagTermsAndCondition");
    var FlagPrivacy=localStorage.getItem("FlagPrivacy");
    var FlagFirstLogin=localStorage.getItem("FlagFirstLogin");
    var FlagPasswordChangedType=localStorage.getItem("FlagPasswordChangedType");
    var FlagForceSSN=localStorage.getItem("flgForceSSN");
    // if(FlagFirstLogin.toUpperCase() != "Y")
    //     {//need to check this.spinner.hide();
    //     this.router.navigate(["pri"],{queryParams:{"accept":"Y"}});
    //     }
      // if(FlagTermsAndCondition.toUpperCase() != "Y")
      //   {//need to check this.spinner.hide();
      //   this.router.navigate(["pri"],{queryParams:{"accept":"Y"}});
      //   }
         if(FlagPrivacy.toUpperCase() != "Y")
        { this.spinner.hide();
        this.router.navigate(["pri"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.router.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagForceSSN.toUpperCase() == "Y")
          { this.spinner.hide();
            this.router.navigate(["ssn-usbpo"],{queryParams:{"accept":"Y"}});
          }
        else
        { this.spinner.hide();
          if(this.mobile == false){
            //console.log("Inside not mobile");
            if(this.postjoining){
              //if(localStorage.getItem("IsComingFromLogin")=="Y"){
                this.router.navigate(['../lpusbpo/postjoining'])
              //}
            }
            else if(this.prejoining){
              if(localStorage.getItem("IsComingFromLogin")=="Y"){
                localStorage.setItem("IsComingFromLogin","N")
                this.router.navigate(['../lpusbpo/step1/start'])
              }
              
            }
            
          }
        }
  }

}