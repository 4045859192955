export class USPreJoiningLogin{
    CandidateID : number;
    CandidateEmailID: string;
    IsAccountLocked : string;
    CandFirstName : string;
    CandLastName:string;
    CandDOJ:string;
    CandEmpNo: string;
    FlagFirstLogin:string;
    FlagTermsAndCondition: string;
    FlagPasswordChangedType: string;
    JobLevel: string;
    RoleDesignation: string;
    Location: string;
    ConfirmedOBLOC: string;
    FlagPrivacy: string;
    DaysLeft: number;
    Unit: string;
    SourceType: string;
    HiringManagerName: string;
    HiringManagerMailId: string;
    Region: string;
    CompanyNameIdentifier:string;
    ContactUsEmailId:string;
    OfficeAddress:string;
    flgSSN:string;
    flgResi:string;
    flgEmergency:string;
    flgCandidateImage:string;
    flgCorpCard:string;
    flgBank1:string;
    flgBank2:string;
    flgBank3:string;
    flgW4:string;
    flgPersonalDetails:string;
    flgSocialSecurity:string;
    flgHealthInsurance:string;
    flgPastAdd:string;
    flgPermanentAdd:string;
    flgNom:string;
    flgDisability:string;
    flgFamily:string;
    flgPssprt:string;
    flgPan:string;
    flgNric:string
    flgKiwi1:string;
    flgKiwi2:string;
    flgPsprtmain1:string;
    flgPsprtmain2:string;
    flgResiper:string;
    flgHoliatt:string;
    flgCriminalRecord:string;
    flgContractdoc:string;
    flgOfferletter:string;
    flgBonusplan:string;
    flgAddendum:string;
    flgTaxrelateddoc:string;
    flgProvincedoc:string;
    flgRulingform:string;
    flgEnrolldoc:string;
    flgMemberdoc:string;
    flgNSR:string;
    flgEdu:string;
    flgMedical:string;
    flgGap:string;
    flgWorkEx:string;
    flgUAN:string;
    flgProvident:string;
    flgPension:string;
    flgIntworker:string;
    flgAcc:string;
    flgAchieve:string;
    flgAspire:string;
    flgForceResidential:string;
    flgForceSSN:string;
    workLocation : string;
    flgPersonalUpdate:string;
    flgTfn:string;
    flgSup:string;
    flgMedical1:string;
    flgdoc: string;
    DMSSitePath : string;
}