export class WorkExDoc
{
    public CandidateID:Number;
    public strDocTypeCode:String;
    public strOriginalFileName:String;
    public intCandExpDetId:Number;
    public strDocName:String;
    public intSerialNo:Number;
    public tentativeDate:Date;
    public DMSSitePath: String;
    public txtGUID_DMS: String;
}