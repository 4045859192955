import { Component, OnInit, HostListener } from '@angular/core';
import { FeedbackService } from './feedback.service';
import { Question } from '../models/Question.model';
import { BreakpointState, BreakpointObserver } from '@angular/cdk/layout';
import { AppService } from '../app.service';
import { NgForm } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';

import { AlertService } from '../utils/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UpdateResult } from '../lpus/models/UpdateResult.model';
import { Notification } from '../lpus/models/notification.model';
import { AuthService } from '../login/auth.service';

declare function ChangeStar1(id:string):any;
declare function ChangeStar2(id:string):any;
declare function ChangeStar3(id:string):any;
declare function ChangeStar4(id:string):any;
declare function ChangeStar5(id:string):any;
declare function LoadOwlCarouselForMenu():any;
declare function LoadOwlCarousel():any;
declare function AddBodyscroll():any;
declare function LoadFeedbackCloseModalWeb():any;
declare function LoadScrollJS():any;
declare function ChangeSmiley11(id:string):any;
declare function ChangeSmiley22(id:string):any;
declare function ChangeSmiley33(id:string):any;
declare function ChangeSmiley44(id:string):any;
declare function ChangeSmiley55(id:string):any;

@Component({
  selector: 'app-feedback',
  templateUrl: './feedback.component.html',
  styleUrls: ['./feedback.component.css']
})
export class FeedbackComponent implements OnInit {
ques= new Question;
ques1:any;
mobile:Boolean;
err:boolean=true;
questions:Question[] = [];
responsecheck1:string='';
responsecheck2:string='';
responsecheck3:string='';
responsecheck4:string='';
check1: string = "";
order:string="Y";
submitted:Boolean=false;
feebacktype:string = "LDAY3";
mail:string;
list : Question[] = [];
freshers:boolean=localStorage.getItem("SourceType").toLowerCase()=="campus";
region = localStorage.getItem("Region");
input: Notification;
notifications: Notification[];
noNotification:Boolean;
  uslateral: boolean=false;

  B1: Boolean = false;
  B2: Boolean = false;
  B3: Boolean = false;
  B4: Boolean = false;

  B5: Boolean = false;
  B6: Boolean = false;
  B7: Boolean = false;
  B8: Boolean = false;

  constructor(private feedService:FeedbackService, 
    private router: Router,  
    private route: ActivatedRoute,
    private authService:AuthService,
    private alert:AlertService
    ,private appService:AppService,private breakpointObserver: BreakpointObserver,
    private spinner:NgxSpinnerService
    ) {  this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //console.log('Inside app component observer');
          this.appService.setIsMobile(true);
          this.mobile = true;
        } else {
          //console.log('Inside app component observer'); 
          this.appService.setIsMobile(false);
          this.mobile = false;
        }
      });}

  ngOnInit() {
    this.spinner.show();
    LoadScrollJS();
    LoadOwlCarouselForMenu();
    LoadOwlCarousel();
    this.breakpointObserver
    .observe(['(max-width: 599px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        //console.log('Inside app component observer');
        this.appService.setIsMobile(true);
        this.mobile = true;
      } else {
        //console.log('Inside app component observer'); 
        this.appService.setIsMobile(false);
        this.mobile = false;
      }
    });



    this.route.queryParams.subscribe(params =>{
      this.order = params["order"]
      this.feebacktype= params["FeedbackType"]
      if(this.feebacktype=="LDay3" || this.feebacktype=="LDay30"){
        this.uslateral=true;
      }
    });
      this.ques.FeedBackValue=this.feebacktype;
this.feedService.GetFeedbackResponse(this.feebacktype).subscribe((response)=>{
    if(response.Updateresult=="0"){
    this.feedService.GetFeedbackContentDetails(this.ques).subscribe((res) => {
      this.spinner.show();
      if(res!=null){
        this.questions=res; 
        for (let q of this.questions)
        {
          if(q.Quesid.endsWith("A") || q.Quesid.endsWith("B"))
             {
               q.SQuesid =q.Quesid.substring(q.Quesid.length-1);
             }
         else{ q.SQuesid = q.Quesid;}    
         
        }       
        //console.log(this.questions)
        this.submitted=false;
//         for(let a of res){
//           if(a.QuesResponseObject!=null || a.QuesResponseSub!=null){
//             console.log("1")
//             this.submitted=true;
//             return;
//           }else{
//             console.log("2")

//             this.questions=res;
//             this.submitted=false;

//           }
//         }
//         console.log(this.submitted)

// for(var a of this.questions){

// }
 this.spinner.hide();

      }
      
    }, )
    //for(let a of this.questions){
    //  this.ques.SQuesid=a.Quesid;
    //  if(a.Quesid=="4A" || a.Quesid=="4B")
    //  this.ques.SQuesid=" " + a.Quesid.substring(1,2);
   // }
  }
  else{
    this.submitted=true;
  }
})
  



    
  }
  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if(!this.appService.mobile){     
      this.authService.logout();            
    }    
  }
  
  OnListItemClicked(str : string,id1:string){
    //alert('Inside list item click event');
    if(str==='st1'){
      if(this.uslateral==true){
        ChangeSmiley11(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="SD";
          }
        }
      }
      else{
        ChangeStar1(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="SD";
          }
        }
      }
      
    }
    else if(str==='st2'){
      if(this.uslateral==true){
        ChangeSmiley22(id1);
      for(let a of this.questions){
        if(a.Quesid == id1){
          a.QuesResponseObject="D";
        }
      }
      }
      else{
        ChangeStar2(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="D";
          }
        }
      }
      
    }
    else if(str==='st3'){
      if(this.uslateral==true){
        ChangeSmiley33(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="NAD";
          }
        }
      }
      else{
        ChangeStar3(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="NAD";
          }
        }
      }
      
    }
    else if(str==='st4'){
      if(this.uslateral==true){
        ChangeSmiley44(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="A";
          }
        }
      }
      else{
        ChangeStar4(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="A";
          }
        }
      }
      
  }
    else if(str==='st5'){
      if(this.uslateral==true){
        ChangeSmiley55(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="SA";
          }
        }
      }
      else{
        ChangeStar5(id1);
        for(let a of this.questions){
          if(a.Quesid == id1){
            a.QuesResponseObject="SA";
          }
        }
      }
      
  }
}
shownote(id:string){
  this.mail=id;
  console.log("question id "+this.mail);
}
Subjective(form:NgForm,id1:string){
  
  for(let a1 of this.questions){
    
    if(a1.Quesid == id1){
      //console.log(document.getElementById(id1));
      let answer1 = (<HTMLInputElement>document.getElementById(id1)).value;
      if(answer1.length>250){
        this.alert.error("Please note that maximum 250 characters are allowed for suggestions",this.mobile);
      }else{
      a1.QuesResponseSub=answer1;
      //console.log(a1.QuesResponseSub);
      }
    }
  }

}
Subjective1(form:string,id1:string){
  for(let a of this.questions){
    if(a.Quesid == id1){
      // a.QuesResponseSub=form.value.SubjcteValue;
    }
  }

}

check(str: string,id1:string,event){
  
  let response:string;
  for(let a of this.questions){
    
    if(a.Quesid==id1){  
  if(str=='c1' ){
    if(event.target.checked==true){
    this.responsecheck1="Business overview session";
    }else if(event.target.checked==false){
      this.responsecheck1=''; 
    }}
  if(str=='c2' ){
    if(event.target.checked==true){
    this.responsecheck2="Team event";
    }else if(event.target.checked==false){
      this.responsecheck2='';
    }}
  if(str=='c3' ){
    if(event.target.checked==true){
    this.responsecheck3="Campus tour";
    }else if(event.target.checked==false){
      this.responsecheck3=''
    }} 
  if(str=='c4' ){
    if(event.target.checked==true){
    this.responsecheck4="Compliance session";
    }else if(event.target.checked==false){
      this.responsecheck4='';
    }}}
   if(a.Quesid==id1){
    a.QuesResponseObject=this.responsecheck1 +","+ this.responsecheck2 +","+ this.responsecheck3 +","+ this.responsecheck4 ; 
    }
  
   
  }}




      

check2(str:string,id1:string){
  if(id1 == "6"){
  let response:string = ""
  if(str == 'c1'){
    this.B1 = !this.B1
  }
  else if(str == 'c2'){
    this.B2 = !this.B2 
   }
   else if(str == 'c3'){
    this.B3 = !this.B3 
   }
   else if(str == 'c4'){
    this.B4 = !this.B4 
   }

   if(this.B1){response+= "Business overview session" + ", ";}
   if(this.B2){response+= "Team event" + ", ";}
   if(this.B3){response+= "Campus tour" + ", ";}
   if(this.B4){response+= "Compliance sessions  " ;}

   for(let q of this.questions){
     if(q.Quesid == id1){
       q.QuesResponseObject = response;
       q.QuesResponseObject=q.QuesResponseObject.substring(0,q.QuesResponseObject.length-2);
       
     }
   }
  }
  else{
    this.check3(str,id1);
  }
  }

  check3(str:string,id1:string){
    let response:string = "";
    if(str == 'c1'){
      this.B5 = !this.B5
    }
    else if(str == 'c2'){
      this.B6= !this.B6 
     }
     else if(str == 'c3'){
      this.B7 = !this.B7 
     }
     else if(str == 'c4'){
      this.B8 = !this.B8 
     }
  
     if(this.B5){response+= "Business overview session" + ", ";}
     if(this.B6){response+= "Team event" + ", ";}
     if(this.B7){response+= "Campus tour" + ", ";}
     if(this.B8){response+= "Compliance sessions  " ;}
  
     for(let q of this.questions){
       if(q.Quesid == id1){
         q.QuesResponseObject = response;
         q.QuesResponseObject=q.QuesResponseObject.substring(0,q.QuesResponseObject.length-2);

       
       }
     }
  
    }
Radio(id1:string){
  for(let a of this.questions){
    if(a.Quesid == id1){
      a.QuesResponseObject="YES";
    }
  }
}
Radio2(id1:string){
  for(let a of this.questions){
    if(a.Quesid == id1){
      a.QuesResponseObject="NO";
    }
  }
}
SubmitForm(){

  for(let a of this.questions){
    a.EmpNo=localStorage.getItem("CandEmpNo");
    a.EmailID=localStorage.getItem("CandidateEmailID");
    this.list.push(a);
  }
  for (let b of this.list){
    if(b.QuesResponseSub!=null && b.QuesResponseSub.length>=0){
      continue;
    }
    else if(b.QuesResponseObject==null || b.QuesResponseObject.length===0)
    {
        this.alert.error("All fields are mandatory",this.mobile);
        this.list=[];
        return;
    }
    
  }

  for (let b of this.list){
    if(b.QuesResponseObject!=null && b.QuesResponseObject.length>=0){
      continue;
    }
    else if(b.QuesResponseSub==null || b.QuesResponseSub.length===0)
    {
      this.alert.error("All fields are mandatory",this.mobile);
      this.list=[];
      return;
    }
  }

    for(let b of this.list){
      if(b.SQuesid=="A" || b.SQuesid=="B" ){
        // b.SQuesid=b.SQuesid;
        b.Quesid=b.Quesid.substring(0,b.Quesid.length-1);
      }
      else{
        b.SQuesid="";
      }    

    }
    

  
  this.alert.success("Feedback successfully updated",this.mobile);
    this.err=true;
    this.submitted=true;
    AddBodyscroll();
    LoadFeedbackCloseModalWeb();
    //console.log(this.list);
    

  this.feedService.InsertFeedbackResponseDetails(this.list).subscribe((res) => {
    if(res!=null){
     // console.log(this.list);
    }
  },)
  if(this.mobile==true){
  this.router.navigate(['notification']);
  }
  
}
OnStepBackClicked(){
  this.router.navigate(['notification']);

}
AddBodyscroll(){
  AddBodyscroll();
}

HomeClicked(){
  if(this.region == "IL UK" || this.region == "BPM UK"){
    this.router.navigate(["/lpuk"]);
  }
  else if(this.region == "IL AUSTRALIA" || this.region == "BPM AUSTRALIA"){
    this.router.navigate(["/lpau"]);
  }
  else if(this.region == "US"){
    this.router.navigate(["/lpus"]);
  }
}
FAQClicked(){
  if(this.region == "IL UK" || this.region == "BPM UK"){
    this.router.navigate(["/lpukstatic/faq-uk"]);
  }
  else if(this.region == "IL AUSTRALIA" || this.region == "BPM AUSTRALIA"){
    this.router.navigate(["/lpaustatic/faq-au"]);
  }
  else if(this.region == "US"){
    this.router.navigate(["/lpusstatic/faq"]);
  }
}
BenefitsClicked(){
  if(this.region == "IL UK" || this.region == "BPM UK"){
    this.router.navigate(["/lpukstatic/benefits-uk"]);
  }
  else if(this.region == "IL AUSTRALIA" || this.region == "BPM AUSTRALIA"){
    this.router.navigate(["/lpaustatic/benefits-au"]);
  }
  else if(this.region == "US"){
    this.router.navigate(["/lpusstatic/benefits"]);
  }
}
  PrivacyClicked(){
    if(this.region == "IL UK" || this.region == "BPM UK"){
      this.router.navigate(["/privacy-uk"]);
    }
    else if(this.region == "IL AUSTRALIA" || this.region == "BPM AUSTRALIA"){
      this.router.navigate(["/privacy-au"]);
    }
    else if(this.region == "US"){
      this.router.navigate(["/pri"]);
    }
  }

  iconChange(){
    this.router.navigate(['notification']);
  }

}