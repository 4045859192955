import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { ResiAddress } from '../../models-india/resiaddress.model';
import { ResiAddress,UpdateLaptopAddress } from '../../models-india/resiaddress.model';
import { map } from 'rxjs/operators';
import { FetchResiAddress } from '../../models-india/fetchresiAddress.model';
import { UpdateResiAddress } from '../../models-india/updateResiaddress.model';
import { Relationship } from '../../models-india/relations.model';
import { CountryForLpIndia } from '../../models-india/CountriesForIndia.model';
import { StateForLpIndia } from '../../models-india/StatesForIndia.models';
import { CityOnSateForLpIndia } from '../../models-india/CityonStateForIndia.model';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { Configuration, APIUrls } from '../../../configuration';
import { ValidatePastAddress } from '../../models-india/ValidatePastAddress';
import { LPValidPastAddress } from '../../models-india/LPValidPastAddress';
import { LPFetchPastAddressDetails } from '../../models-india/LPFetchPastAddressDetails';
import { FetchPastAddressDetails } from '../../models-india/FetchPastAddressDetails';
import { DeletePastAddress } from '../../models-india/DeletePastAddress';
import { InsertPastAddress } from '../../models-india/InsertPastAddress';
import { HolidaysForIndia } from '../../models-india/HolidaysForIndia.model';


@Injectable()
export class ResiaddressIndiaService {
    country=new CountryForLpIndia();
    state=new StateForLpIndia();
    
    
    constructor(private _http: LPIndiaCustomHttpClient) { }

    GetAddressDetails(fetchresiaddressdetails:FetchResiAddress):Observable<ResiAddress[]>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<ResiAddress[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetAddressDetails,fetchresiaddressdetails,{
            headers:headers
            }).pipe(map(
            (res) => {
            // console.log(res);
            return res;
            }
            )) 
    }

    AddAddressDetails(presentresiaddressdetails:UpdateResiAddress):Observable<string>{
        //console.log(presentresiaddressdetails)
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.AddAddressDetails,presentresiaddressdetails,{
            headers:headers
            }).pipe(map(
            (res) => {
            // console.log(res);
            return res;
            }
            )) 
    }

    FetchLaptopAddress(data:UpdateLaptopAddress):Observable<UpdateLaptopAddress[]>
  {
    let body=JSON.stringify(data);
    return this._http.post<UpdateLaptopAddress[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchLaptopAddress,body).pipe(map((res) => {return res;}))
  }

  UpdateLaptopAddress(data:UpdateLaptopAddress):Observable<Number>
  {
    let body=JSON.stringify(data);
    return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateLaptopAddress,body).pipe(map((res) => {return res;}))
  } 

    GetRelation():Observable<Relationship[]>{
        var body={"flgMode":"reln"}
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<Relationship[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetRelations,body,{
            headers:headers
            }).pipe(map(
            (res) => {
            // console.log(res);
            return res;
            }
            )) 
    }

    GetCountry():Observable<CountryForLpIndia[]> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        return this._http.post<CountryForLpIndia[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetCountryIN,null,{
        headers:headers
        }).pipe(map(
        (res) => {
        // console.log(res);
        return res;
        }
        ))  
    }
    GetHolidayDetailsIN():Observable<HolidaysForIndia[]> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        return this._http.post<HolidaysForIndia[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetHolidayDetailsIN,null,{
        headers:headers
        }).pipe(map(
        (res) => {
         //console.log(res);
        return res;
        }
        ))  
    }
         
    GetStates(countryCode:string):Observable<StateForLpIndia[]>{
        let body={"strCountryCode":countryCode}; 
        this.country.txtCountryCode=countryCode;
        //this.country.txtCountryName=countryName;
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        //let body = JSON.stringify(this.country);
        return this._http.post<StateForLpIndia[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetStatesIN,body,{
        headers:headers
        }).pipe(map(
        (res) => {
        // console.log(res);
        return res;
        }
        ))  
    }
     
   GetCitiesOnState (countryCode:String,stateCode:String):Observable<CityOnSateForLpIndia[]>{ 
        let body={"strCountryCode":countryCode,"strStateCode":stateCode};
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json');
        //let body = JSON.stringify(this.state);
        return this._http.post<CityOnSateForLpIndia[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetCitiesOnStateIN,body,{
        headers:headers
        }).pipe(map(
        (res) => {
        // console.log(res);
        return res;
        }
        ))  
   }

   InsertPastAddresses(newadd:InsertPastAddress):Observable<string>{
    
    var body =newadd
    var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
    headers=headers.append('Content-Type','application/json')
    return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.InsertPastAddresses,body,{
        headers:headers
        }).pipe(map(
        (res) => {
        // console.log(res);
        return res;
        }
        )) 
   }
   DeletePastAddress(deldata:DeletePastAddress):Observable<string>
   {
    var body =deldata;
    var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
    headers=headers.append('Content-Type','application/json')
    return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.DeletePastAddress,body,{
        headers:headers
        }).pipe(map(
        (res) => {
        // console.log(res);
        return res;
        }
        )) 
   }
    FetchAddressDetailsForPast(indata:FetchPastAddressDetails):Observable<LPFetchPastAddressDetails[]>
       {
      var body =indata;
      var headers =  new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
      headers=headers.append('Content-Type','application/json')
      return this._http.post<LPFetchPastAddressDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchAddressDetailsForPast,body,{
        headers:headers
        }).pipe(map(
        (res) => {
        // console.log(res);
        return res;
        }
        )) 
       }
    ValidatePastAddress(data:ValidatePastAddress):Observable<LPValidPastAddress>{
        var body =data
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<LPValidPastAddress>(Configuration.ResServerBaseURLIndia+APIUrls.ValidatePastAddress,body,{
            headers:headers
            }).pipe(map(
            (res) => {
            // console.log(res);
            return res;
            }
            )) 
    }

}