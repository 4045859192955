export class lpUserCredentials
    {
         UserName :string;
         Password :string;
        CandidateID :Number;
        RequestType :string;
         AddressType :string;
         
    }
    export class lpUserCredentials1
    {
         UserName :string;
         Password :string;
        CandidateID :Number;
        RequestType :string;
         AddressType :string;
         infyDirectoryConsent:string;
    }