import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileComponent } from '../../lpus/profile/profile.component';
import { FormsModule } from '../../../../node_modules/@angular/forms';
import { CollaborationContentComponent } from '../../collaboration-content/collaboration-content.component';
import { RequestACallComponent } from '../../lpindia/request-acall/request-acall.component';
import {  MatInputModule} from '@angular/material/input'
// import { MatNativeDateModule } from '@angular/material/';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { FeedbackComponent } from '../../feedback/feedback.component';
import { RouterModule } from '../../../../node_modules/@angular/router';
import { MoreCommonComponent } from '../../lpuk/more-common/more-common.component';
import { AddRoutingNoComponent } from 'src/app/add-routing-no/add-routing-no.component';
import { AttendanceComponent } from 'src/app/lpus/attendance/attendance.component'
import { ConfirmPopupComponent } from './../confirm-popup/confirm-popup.component';



@NgModule({
    declarations: [ProfileComponent,
        AddRoutingNoComponent,
        CollaborationContentComponent,
        RequestACallComponent,
        FeedbackComponent,
        MoreCommonComponent,
        AttendanceComponent,
        ConfirmPopupComponent
    ],
    imports: [
        CommonModule, FormsModule, MatFormFieldModule, MatSelectModule, MatDatepickerModule, MatRadioModule,
        // MatNativeDateModule,
        MatInputModule,
        RouterModule
    ],
    exports: [
        ProfileComponent,
        AddRoutingNoComponent,
        CollaborationContentComponent,
        RequestACallComponent,
        FeedbackComponent,
        MoreCommonComponent,
        AttendanceComponent
    ]
})
export class SharedModule { }
