import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
//import { Response } from '@angular/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { LPUSCustomHttpClient } from '../lpus.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { UpdateResult } from '../models/UpdateResult.model';


@Injectable({
    providedIn: 'root'
})
export class TNCService {
    constructor(private http: LPUSCustomHttpClient) { }
    AcceptTNC(): Observable<UpdateResult> {
        return this.http.post<UpdateResult>(Configuration.ResServerBaseURL+APIUrls.UpdateTermsDetails, { "CandidateId": localStorage.getItem("CandidateID") }, {
        }).pipe(map(
            (res) => {
               // console.log(res);
                return res;
            }
        ))
    }

    
}