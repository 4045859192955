import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { lpUKUserCredentials } from '../models/lpukUserCredentials.model';
import { CandidateUKAdvisorImageGlobal } from '../models/CandidateUKAdvisorImageGlobal.model';
import { UKPreJoiningLogin } from '../models/UkPrejoining.model';
import { LPUKCustomHttpClient } from '../lpuk.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { StepCompletion } from '../../lpus/models/Candidatedata.model';

@Injectable({
    providedIn: 'root'
})

export class UKDashboardService {
  knowyourwork:string;
  kyw:boolean;
  infyd:boolean;
  infyleaders:boolean;
  seeAll:boolean;
  showProceedScreen:boolean=true;
  OnboardPercent: StepCompletion[] = [];
  constructor(private http: LPUKCustomHttpClient){
    
    }

  getOnboardingPercentage():Observable<StepCompletion[]>{
    
    return this.http.post<StepCompletion[]>(Configuration.ResServerBaseURL+APIUrls.getOnboardingPercentageUK,{"CandidateEmail":localStorage.getItem("CandidateEmailID"),"CandidateId":localStorage.getItem("CandidateID")}).pipe(map(
    (res) => {        
      return res;
    }
    ))
    }
  
  fnGetCandPhoto(userCredentials:lpUKUserCredentials): Observable<CandidateUKAdvisorImageGlobal> {
    
    return this.http.post<CandidateUKAdvisorImageGlobal>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhotoUK,{"CandidateId":userCredentials.CandidateID}).pipe(map(
    (res) => {
    return res;
    } 
    ))  
    }

  getCandidateDetailsAfterAuthentication(userCredentials:lpUKUserCredentials):Observable<UKPreJoiningLogin>{
    return this.http.post<UKPreJoiningLogin>(Configuration.ResServerBaseURL+APIUrls.getCandidateDetailsAfterAuthenticationUK,{"CandidateId":userCredentials.CandidateID}).pipe(map(
    (res) => {  
      //console.log(res)    
    return res;
    }
    ))
    }

}