import { Injectable, HostListener } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from 
'@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import {Router} from '@angular/router';
import { AppService } from '../app.service';
import { NgxSpinnerService } from 'ngx-spinner';



@Injectable()
export class AuthGuard implements CanActivate {
  
  constructor(private auth: AuthService,  private appService: AppService, private spinner: NgxSpinnerService,
    private myRoute: Router
    ){
  }
  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.auth.isLoggedIn()){
      return true;
    }
    else{
      this.myRoute.navigate(["login"]);
      return true;
    }
  }

  toNavigate(){
    var flgTC=localStorage.getItem("flgTermsAndConditions");
      //console.log(flgTC);
      var flgPolicy=localStorage.getItem("flgPolicy");
      var flgFirstLogin=localStorage.getItem("flgFirstLogin");
       if(flgPolicy.toUpperCase() != "Y")
      {this.spinner.hide();
        //console.log("inside privacy")
      this.myRoute.navigate(["privacynotice"],{queryParams:{"accept":"Y"}});

      }
    //  else if(flgTC.toUpperCase() != "Y")
    //   { //need to check this.spinner.hide();
    //     //console.log("inside tnc")
    //   this.myRoute.navigate(["tnc-ind"],{queryParams:{"accept":"Y"}});
    //   }
  
    else if (flgFirstLogin.toUpperCase()!="Y")
    { this.spinner.hide();
      // console.log("chg")
      this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
    }
    else
    {
      if (localStorage.getItem("Identification").toUpperCase()=="FRESHER")
      {
        // console.log("fr")
        if((localStorage.getItem("flgBioConsent").toUpperCase()  != "Y") && (localStorage.getItem("flgBioConsent").toUpperCase()  != "N"))
            {this.spinner.hide();
              // console.log("fr")
            this.myRoute.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
            }
            else
            {this.spinner.hide();
              // console.log("ind")
            }
            
      }
      else if (localStorage.getItem("Identification").toUpperCase()=="LATERAL"){
        // console.log("latrl")
        // if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  != "OFFER ACCEPTED")
        if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  == "OFFER RELEASED")
        {this.spinner.hide();
          // console.log("ofr")
        this.myRoute.navigate(["accept"],{queryParams:{"accept":"Y"}});
        }
        else if (localStorage.getItem("flgGDCSubmit").toUpperCase()!="Y"){
          //console.log("gdc")
           this.spinner.hide();
          this.myRoute.navigate(["declare"],{queryParams:{"accept":"Y"}});
        }
        else if((localStorage.getItem("flgBioConsent").toUpperCase()  != "Y" && localStorage.getItem("flgBioConsent").toUpperCase()  != "N"))
        {this.spinner.hide();
         //  console.log("fr")
        this.myRoute.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
        }
      //   else if(userIn[0].flgVideoSeen.toUpperCase()  != "Y"){
              
      //     this.myRoute.navigate(["video-ind"],{queryParams:{"accept":"Y"}})
      // }
        else
        {
          // console.log("ind")
          this.spinner.hide();        
        }
      }
      else
      {
        // console.log("ind")
        this.spinner.hide();       
      }
    }
  }

  toNavigateAU(){
    var FlagTermsAndCondition=localStorage.getItem("FlagTermsAndCondition");
    var FlagPrivacy=localStorage.getItem("FlagPrivacy");
    var FlagFirstLogin=localStorage.getItem("FlagFirstLogin");
    var FlagPasswordChangedType=localStorage.getItem("FlagPasswordChangedType");
    // if(FlagFirstLogin.toUpperCase() != "Y")
    //     {//need to check this.spinner.hide();
    //     this.myRoute.navigate(["privacy-au"],{queryParams:{"accept":"Y"}});
    //     }
        // else if(FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.myRoute.navigate(["tnc-au"],{queryParams:{"accept":"Y"}});
        // }
         if(FlagPrivacy.toUpperCase() != "Y")
        {this.spinner.hide();
        this.myRoute.navigate(["privacy-au"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagPasswordChangedType.toUpperCase() != "U")
        {this.spinner.hide();
        this.myRoute.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else
        {this.spinner.hide();
        }
  }
}