
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http'
import { Injectable } from '@angular/core';

import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'; 
import { ChangePasswordEnt } from '../../models/ChangePasswordEnt.model';
import { ChangePasswordResult } from '../../models/ChangePasswordResult.model';
import { LPUSCustomHttpClient } from '../../lpus/lpus.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { UpdatePassword } from '../../models/ChangePasswordIN';
import { DPOutput, DPPrivacyFlag, GetDPContent, SaveUserConsent } from 'src/app/models/Privacy.model';


@Injectable()
export class ChangePasswordService {
    dpFlag:Boolean;
    constructor(private http: LPUSCustomHttpClient) { }

    ChangePasswordForCandidateUS(changepwd:ChangePasswordEnt): Observable<ChangePasswordResult> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI5NTdhNzhiNC0zOWYzLTQyMWItOWQ2My1hZGNlN2QyNTI4ZTciLCJleHAiOjE1NjMzNDE4NDAsIm5iZiI6MCwiaWF0IjoxNTYyMDQ1ODQwLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiNWI5OTdhNmItMWVhOC00MWVhLTljMmEtMWE5ZDM0YzFjMGQxIiwiYXV0aF90aW1lIjoxNTYyMDQ1MzQ4LCJzZXNzaW9uX3N0YXRlIjoiOGQwZTQwYjMtZjFjNS00MDkwLTlmODctOGQ5YzhjMmVhNDYzIiwiYWNyIjoiMCIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.K-s0JKceFXWvcnIZPdcwuXiWTvh2mOHEVeVdhTvk_1KOCF0w0VIcFs2omPnB1Tz7PyaWVi_Uu4on1QUJHm7k1c3Jmr379Sye7WAHJMfQQUlhoU8dJLj5ByaIH5plsKFp977Ur_mNwZD9Yt1J137eWx0CcFG-a_wD7NZFAp-Lp88jHPCQssDY7my_aZ2VFOMHIoWzIzns33vNPUc-Xwf5QVvBi1EM0GmzF0KjFfIiy-zB8aFEF0SAq0FRcmp36Kv-7TxmaGtRZGcXCbKC14o_fNa8Efog_01YnIxe-95yMJGu4apOjyT3kOP5XCes7zMXHpRyCDZZ5KK6WTYAcr8rOw');
        headers=headers.append('Content-Type','application/json')
        return this.http.post<ChangePasswordResult>(Configuration.ResServerBaseURL+APIUrls.ChangePasswordForCandidate,{"CurrentPassword":changepwd.CurrentPassword,"NewPassword":changepwd.NewPassword},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        } 
        ))  
    }

    ChangePasswordForCandidateEMEA(changepwd:ChangePasswordEnt): Observable<ChangePasswordResult> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4NzZiYWU4Yi02NjYyLTQ1ZGMtYmQ5Zi0xNWE0NzQ4MGZjYmYiLCJleHAiOjE1NTY2ODYwOTEsIm5iZiI6MCwiaWF0IjoxNTU1MzkwMDkxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgvcmVhbG1zL0lTMSIsImF1ZCI6InBvcnRhbCIsInN1YiI6IjkzYjlmMGQ5LTg0ZjctNDVmNS05MzAxLTZjNGEwMzA4OTNmOCIsInR5cCI6IklEIiwiYXpwIjoicG9ydGFsIiwibm9uY2UiOiJiOThkOTk3My1jNjEyLTRmNjYtYjRmYi1lZGY5MzFlMjRkZDQiLCJhdXRoX3RpbWUiOjE1NTUzMzExNTcsInNlc3Npb25fc3RhdGUiOiIxYmJlMzIyZC0wNjUxLTRlNTgtOTQyNi01MDAzODVmOWI4OGMiLCJhY3IiOiIwIiwibmFtZSI6IkFiaGlzaGVrIE5lZ2kiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0MUBkdW1teS5jb20iLCJnaXZlbl9uYW1lIjoiQWJoaXNoZWsiLCJmYW1pbHlfbmFtZSI6Ik5lZ2kiLCJlbWFpbCI6InRlc3QxQGR1bW15LmNvbSJ9.dR6KzCyPLN-x1gt1SJAz3W9q2JqDESn5gTIh19sy5wgka6Uy7a3H_-km8UoQiUX5qTgg8onjbroWp0FUh45olXmAmVDOcL7cLvQvFuHOxL7OBeo5Dh18a3kUUl5tqPlypuxPQ-eZ7NGSR0T0jd6qn_is1hM1q_xQ3h7Jal8b7IH3012P8C7s51txRt0xH38bQCSSaz7tjgLRu0vc_95Cqwz2xMUq19NIJdEoWqxgw0P-6xGOgq44Np2Y2VypweacEpsEZDGBa9EdBEKcFPLe4rRabR8sE7LWJktX7JWqU1JHPWiWD4szVhlYnHjB2eS3k7hrvGEJ9Dz_gurL_oA8PQ');
        headers=headers.append('Content-Type','application/json')
        return this.http.post<ChangePasswordResult>(Configuration.ResServerBaseURL+APIUrls.ChangePasswordForCandidateEMEA,{"CurrentPassword":changepwd.CurrentPassword,"NewPassword":changepwd.NewPassword},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        } 
        ))  
    }

    ChangePasswordForCandidateIN(changepwd:UpdatePassword): Observable<ChangePasswordResult> {
        // console.log(changepwd);
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4NzZiYWU4Yi02NjYyLTQ1ZGMtYmQ5Zi0xNWE0NzQ4MGZjYmYiLCJleHAiOjE1NTY2ODYwOTEsIm5iZiI6MCwiaWF0IjoxNTU1MzkwMDkxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgvcmVhbG1zL0lTMSIsImF1ZCI6InBvcnRhbCIsInN1YiI6IjkzYjlmMGQ5LTg0ZjctNDVmNS05MzAxLTZjNGEwMzA4OTNmOCIsInR5cCI6IklEIiwiYXpwIjoicG9ydGFsIiwibm9uY2UiOiJiOThkOTk3My1jNjEyLTRmNjYtYjRmYi1lZGY5MzFlMjRkZDQiLCJhdXRoX3RpbWUiOjE1NTUzMzExNTcsInNlc3Npb25fc3RhdGUiOiIxYmJlMzIyZC0wNjUxLTRlNTgtOTQyNi01MDAzODVmOWI4OGMiLCJhY3IiOiIwIiwibmFtZSI6IkFiaGlzaGVrIE5lZ2kiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0MUBkdW1teS5jb20iLCJnaXZlbl9uYW1lIjoiQWJoaXNoZWsiLCJmYW1pbHlfbmFtZSI6Ik5lZ2kiLCJlbWFpbCI6InRlc3QxQGR1bW15LmNvbSJ9.dR6KzCyPLN-x1gt1SJAz3W9q2JqDESn5gTIh19sy5wgka6Uy7a3H_-km8UoQiUX5qTgg8onjbroWp0FUh45olXmAmVDOcL7cLvQvFuHOxL7OBeo5Dh18a3kUUl5tqPlypuxPQ-eZ7NGSR0T0jd6qn_is1hM1q_xQ3h7Jal8b7IH3012P8C7s51txRt0xH38bQCSSaz7tjgLRu0vc_95Cqwz2xMUq19NIJdEoWqxgw0P-6xGOgq44Np2Y2VypweacEpsEZDGBa9EdBEKcFPLe4rRabR8sE7LWJktX7JWqU1JHPWiWD4szVhlYnHjB2eS3k7hrvGEJ9Dz_gurL_oA8PQ');
        headers=headers.append('Content-Type','application/json')
        return this.http.post<ChangePasswordResult>(Configuration.ResServerBaseURLIndia+APIUrls.ChangePasswordForINDIA,changepwd,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        } 
        ))  
    }

    getContent(input:GetDPContent):Observable<DPOutput> {
        return this.http.post<DPOutput>(Configuration.ResServerBaseURL+APIUrls.GetDPContent,input, {
        }).pipe(map(
            (res) => {
                //console.log(res);
                return res;
            }
        ))
    }

    saveConsent(input:SaveUserConsent):Observable<boolean> {
        return this.http.post<boolean>(Configuration.ResServerBaseURL+APIUrls.SaveUserConsent,input, {
        }).pipe(map(
            (res) => {
                //console.log(res);
                return res;
            }
        ))
    }

    getDPFlag():Observable<DPPrivacyFlag> {
        return this.http.post<DPPrivacyFlag>(Configuration.ResServerBaseURL+APIUrls.getPrivacyFlagForDP,null, {
        }).pipe(map(
            (res) => {
                //console.log(res);
                return res;
            }
        ))
    }

    ChangePasswordOnKeycloak(changepwd:ChangePasswordEnt): Observable<Boolean> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI5NTdhNzhiNC0zOWYzLTQyMWItOWQ2My1hZGNlN2QyNTI4ZTciLCJleHAiOjE1NjMzNDE4NDAsIm5iZiI6MCwiaWF0IjoxNTYyMDQ1ODQwLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiNWI5OTdhNmItMWVhOC00MWVhLTljMmEtMWE5ZDM0YzFjMGQxIiwiYXV0aF90aW1lIjoxNTYyMDQ1MzQ4LCJzZXNzaW9uX3N0YXRlIjoiOGQwZTQwYjMtZjFjNS00MDkwLTlmODctOGQ5YzhjMmVhNDYzIiwiYWNyIjoiMCIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.K-s0JKceFXWvcnIZPdcwuXiWTvh2mOHEVeVdhTvk_1KOCF0w0VIcFs2omPnB1Tz7PyaWVi_Uu4on1QUJHm7k1c3Jmr379Sye7WAHJMfQQUlhoU8dJLj5ByaIH5plsKFp977Ur_mNwZD9Yt1J137eWx0CcFG-a_wD7NZFAp-Lp88jHPCQssDY7my_aZ2VFOMHIoWzIzns33vNPUc-Xwf5QVvBi1EM0GmzF0KjFfIiy-zB8aFEF0SAq0FRcmp36Kv-7TxmaGtRZGcXCbKC14o_fNa8Efog_01YnIxe-95yMJGu4apOjyT3kOP5XCes7zMXHpRyCDZZ5KK6WTYAcr8rOw');
        headers=headers.append('Content-Type','application/json')
        return this.http.post<Boolean>(Configuration.ResServerBaseURL+APIUrls.ChangePasswordOnKeycloak,{"CurrentPassword":changepwd.CurrentPassword,"NewPassword":changepwd.NewPassword},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        } 
        ))  
    }
}

    