export class MarksDetails
{
    public intQualMarkId: Number;
    public intCandQualDetId: Number;
    public intMarksObtained: Number;
    public intTotalMarks: Number;
    public intCgpaPercent: Number;
    public txtMarksType: String;
    public txtScoreType: String;
    public txtDegreeCode: String;
}