// import { RequestOptions,RequestMethod,Headers } from '../../../node_modules/@angular/http';
// import {Response} from '@angular/http';
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http'
import { Injectable } from '@angular/core';

import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'; 

import { CandidateAdvisorImage } from '../../models/candidateAdvisorImage';
import { lpUserCredentials } from '../../models/lpUserCredentials';
import { CandidateAdvisorImageGlobal } from '../../models/CandidateAdvisorImageGlobal.model';
import { LPUSCustomHttpClient } from '../../lpus.interceptor';
import { Configuration, APIUrls } from '../../../configuration';


@Injectable()
export class IdBadgeService {
    constructor(private http: LPUSCustomHttpClient) { }

    	fnGetCandPhoto(userCredentials:lpUserCredentials): Observable<CandidateAdvisorImage> {
            //console.log(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhoto)
                var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
                headers=headers.append('Content-Type','application/json')
                return this.http.post<CandidateAdvisorImage>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhoto,{"CandidateId":userCredentials.CandidateID},{
                headers:headers
                }).pipe(map(
                (res) => {
                return res;
            } 
        ))  
         }
         fnGetCandPhotoEMEA(): Observable<CandidateAdvisorImage> {
            var headers = new HttpHeaders().set('Authorization', 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJjMmJlNTI3Mi0yM2JhLTRjY2UtYTNhYS01MjVjMWU4YmViZTYiLCJleHAiOjE1NjA0OTQ1NDUsIm5iZiI6MCwiaWF0IjoxNTU5MTk4NTQ1LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI1OTQ0Y2U5YS0wZjlmLTRhMjktYTU5Ny05ZDdlNWRhY2VjZWQiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiNjQ4OGFjYTQtMzRhMi00OGM1LWFkMDYtY2NjZGFiYzAwZmI2IiwiYXV0aF90aW1lIjoxNTU5MTk4NTQ1LCJzZXNzaW9uX3N0YXRlIjoiNTIzZTAwMGEtN2Q0Mi00OTQ2LThhMjYtNGY4YWJmZDE5NDVlIiwiYWNyIjoiMSIsIm5hbWUiOiJEaXZ5YW5zaHUgQmhhcmdhdmEiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJkaXZ5YW5zaHVAaW5mb3N5cy5jb20iLCJnaXZlbl9uYW1lIjoiRGl2eWFuc2h1IiwiZmFtaWx5X25hbWUiOiJCaGFyZ2F2YSIsImVtYWlsIjoiZGl2eWFuc2h1QGluZm9zeXMuY29tIn0.fjMtvH6qQ8_ifPTIKzzIvuT45XV4yese1cIBD1keiJE2h-xdbID3I8yQMGc_sTx4j_vpWi-ryyLBjeyqEAhGkWCzKwbslEMGvJSQpGuCNg9jMQu6GZvGSf8pwgRSfJSlaY0gj5i3LpTwT2Jqds8JghYwWZOmNwBky1pFzGhVeFP0Ijtti1qib0trTBWkBAVmoQm7543X_sMz4PYRYT9RBBiptnPgMzLFLgSApLcLJ7j8TOqRsBXkDYPHhmuPQk6EiOVk_9Un45inhuRG5hbfq9Tb9zap3FxAGVZWp40u_BUMouwMdtLLGm4wpF-0frPsC9d101VM1lApEHgtY-69mw');
            headers = headers.append('Content-Type', 'application/json')
            return this.http.post<CandidateAdvisorImage>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhotoGlobal, { "CandidateId": Number(localStorage.getItem("CandidateID")) }, {
                headers: headers
            }).pipe(map(
                (res) => {
                    return res;
                }
            ))
        }
         InsertCandidateImage(candAdvisorImgGlobal:CandidateAdvisorImageGlobal):Observable<String>{
            
            var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
            headers=headers.append('Content-Type','application/json')
            return this.http.post<String>(Configuration.ResServerBaseURL+APIUrls.InsertCandidateImage,{"ImageAsString":candAdvisorImgGlobal.ImageAsString},{
            headers:headers
            }).pipe(map(
            (res) => {
                
                return res;
            }
        ))   
         }
        }