import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators'
import { catchError } from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { lpUKUserCredentials } from '../../models/lpukUserCredentials.model';
import { CandidateUKAdvisorImage } from '../../models/CandidateUKAdvisorImage.model';
import { LPUKCustomHttpClient } from '../../lpuk.interceptor';
import { Configuration, APIUrls } from '../../../configuration';
import { MailContent, Result } from '../../models/mailContent.model';

@Injectable()
export class UKFaqService {
    constructor(private _http: LPUKCustomHttpClient) { }
    fnGetCandPhoto(userCredentials: lpUKUserCredentials): Observable<CandidateUKAdvisorImage> {
        return this._http.post<CandidateUKAdvisorImage>(Configuration.ResServerBaseURL + APIUrls.fnGetCandPhotoUK, { "CandidateId": userCredentials.CandidateID }).pipe(map(
            (res) => {
                return res;
            }
        ))
    }
    SendMailerHelp(mailContent: MailContent): Observable<Result> {

        return this._http.post<Result>(Configuration.ResServerBaseURL + APIUrls.SendMailerHelp, { "MailBody": mailContent.MailBody, "MailSubject": "Request for Call back submitted by " + localStorage.getItem("CandFirstName") + " " + localStorage.getItem("CandLastName") + " " + "(" + localStorage.getItem("CandidateID") + ")", "Region": localStorage.getItem("Region") }).pipe(map(
            (res) => {
                return res;
            }
        ))
    }
}