export class UpdateAttendanceDetails{
    intCandidateId:number;
    txtEvent:string;
    sessionDate : string;
    attProvided:string;
    agreement:string;
    inIndia:string;
    backlog:string;
    lastWorkingDay:string ;
    noticePeriod:string;
    extension:string;
    txtCourse:string;
    flgSemResult:string;
    txtCandidateName:string;
    code:number;
    txtLocation:string;
    
}

export class GetAttendanceDetails 
{
    code : number;
    txtEvent : string;
    dtDateofSession : string;
    FromTime: string;
    ToTime :string;
}

export class DatatoGetDetails
{
    Doj : string;
    location : string;
    sessionDate : string;
    identification : string;
    ConfirmedOBLOC : string;
    candidateid   :number;
    SectionCode: string;
}

export class PolicyCount{
    flgPolicy: string;
}