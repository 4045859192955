export class lpUKUserCredentials
{
     UserName :string;
     Password :string;
     CandidateID :Number;
     RequestType :string;
     AddressType :string;
}

export class lpUKUserCredentials1
{
     UserName :string;
     Password :string;
     CandidateID :Number;
     RequestType :string;
     AddressType :string;
     infyDirectoryConsent:string;
}