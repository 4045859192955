import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER,ErrorHandler, Injectable,CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA} from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
//import { ResiaddressService } from './lpus/step1onboarding/resiaddress/resiaddress.service';
//import { LayoutModule } from '@angular/cdk/layout';
import { AppService } from './app.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { ForgotPasswordService } from './forgot-password/forgot-password.service';
import { ChangePasswordService } from './login/change-password/changePassword.service';
//import { NgbDatepickerConfig, NgbDateParserFormatter, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateFRParserFormatter } from "./NgbDateFRParserFormatter";
import { AuthService } from './login/auth.service';
import { AuthGuard } from './login/auth.guard';
// import { KeycloakService,KeycloakAngularModule } from 'keycloak-angular';
// import { initializer } from './utils/app-init';
import {NgxSpinnerModule} from 'ngx-spinner';
import { ProfileService } from './lpus/profile/profile.service';
import { FaqService } from './lpus/faq/faq.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule, NgbDateParserFormatter, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { KeycloakService } from './keycloak.service';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialogModule, MAT_DIALOG_DEFAULT_OPTIONS } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {NgxImageCompressService} from 'ngx-image-compress';
import { CollaborationContentComponent } from './collaboration-content/collaboration-content.component';
import { GlobalErrorHandler } from './global-error-handler';
import { FeedbackComponent } from './feedback/feedback.component';
// import { Ng2ImgMaxModule } from 'ng2-img-max';
import { FeedbackService } from './feedback/feedback.service';
import {
    HammerGestureConfig,
    HAMMER_GESTURE_CONFIG,
  } from '@angular/platform-browser';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { LPUSInterceptor, LPUSCustomHttpClient } from './lpus/lpus.interceptor';
import { LPIndiaInterceptor, LPIndiaCustomHttpClient } from './lpindia/lpindia.interceptor';
import { LPAUInterceptor, LPAUCustomHttpClient } from './lpau/lpau.interceptor';
import { LPUKInterceptor, LPUKCustomHttpClient } from './lpuk/lpuk.interceptor';
import { LPUSBPOInterceptor,LPUSBPOCustomHttpClient } from './lpus-bpo/lpus-bpo.interceptor';
import { PostjoiningService } from './lpus/postjoining/postjoining.service';
import { HeaderService } from './lpus/header/header.service';
import { HeaderIndiaService } from './lpindia/header-india/header-india.service';
import { OnBoardingVideoService } from './lpindia/header-india/on-boarding-video/on-boarding-video.service';
import { IndiaOnboardingPassService } from './lpindia/header-india/boarding-pass/boarding-pass.service';
import { DeclareService } from './lpindia/step1onboarding-india/declarenauthorize/declare.service';
import { notificationINService } from './lpindia/notification/notification.service';
import { PulseIndiaService } from './lpindia/header-india/pulse-india/pulse-india.service';
import { InductionfeedbackIndiaService } from './lpindia/header-india/inductionfeedback-india/inductionfeedback-india.service';
import { BioMetricService } from './lpindia/biometric/biometric.service';
import { AcceptOfferService } from './lpindia/acceptoffer/acceptoffer.service';
import { DashboardIndiaService } from './lpindia/dashboard-india/dashboard-india.service';
import { PostjoiningIndiaService } from './lpindia/postjoining/postjoining.serivce';
import { PrivacyNoticeIndiaService } from './lpindia/header-india/privacynotice/privacynotice.service';
import { TermsCondIndiaService } from './lpindia/header-india/tnc-india/tnc-ind.service';
import { IndiaNotificationService } from './lpindia/header-india/notifications-india/notifications-india.service';
import { LpusService } from './lpus/lpus.service';
import { LpusBankingService } from './lpus/step1onboarding/banking/banking.service';
import { IdBadgeService } from './lpus/step1onboarding/idbadge/idbadge.service';
import { LpusSSNService } from './lpus/step1onboarding/ssn/ssn.service';
import { GeosSSNService } from './lpmain/step1onboarding/ssn-main/ssn-main.service';
import { HRRepresentativeService } from './lpus/header/hr-representative/hr-representative.service';
import { EmergencyContactService } from './lpus/step1onboarding/emergencycontact/emergencycontact.service';
import { NotificationService } from './lpus/header/notifications/notifications.service';
import { DashboardService } from './lpus/dashboard/dashboard.service';
import { AgendaService } from './lpus/step3joiningday/agenda/agenda.service';
import { DashboardComponent } from './lpus/dashboard/dashboard.component';
import { w4Service } from './lpus/postjoining/w4form/w4form.service';
import { TNCService } from './lpus/tnc/tnc.service';
import { PrivacyService } from './lpus/privacy/privacy.service';
import { ResiaddressService } from './lpus/step1onboarding/resiaddress/resiaddress.service';
import { LpukComponent } from './lpuk/lpuk.component';
import { LPUKComponentService } from './lpuk/lpuk.service';
import { UKFaqService } from './lpuk/header-uk/faq-uk/faq-uk.service';
import { UKDashboardService } from './lpuk/dashboard-uk/dashboard-uk.service';
import { DialogLogout, ProfileComponent } from './lpus/profile/profile.component';
import { SharedModule } from './shared/shared/shared.module';
import { ResiaddressIndiaService } from './lpindia/step1onboarding-india/resiaddress-india/resiaddress-india.service';
// import { AngularTelemetrySdkModule } from '@infosys-it/angular-telemetry-sdk';
import { OwlModule } from 'ngx-owl-carousel';
import { AdminComponent } from './admin/admin.component';
import { DatePipe } from '@angular/common';
import { AuthGuardAUS } from './login/authAUS.guard';
import { AuthGuardIndia } from './login/authIN.guard';
import { AuthGuardUK } from './login/authUK.guard';
import { AuthGuardUS } from './login/authUS.guard';
// import { LPTelemetryService } from './services/LPTelemetry.service';
import { UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './utils/LowerCaseUrlSerializer';
import { CoronaComponent } from './lpindia/header-india/corona/corona.component';
import { TNCBPOService } from './lpus-bpo/tnc-usbpo/tnc-usbpo.service';
import { ResiaddressUsbpoService } from './lpus-bpo/step1onboarding-usbpo/resiaddress-usbpo/resiaddress-usbpo.service';
import { LpUsbpoSSNService } from './lpus-bpo/step1onboarding-usbpo/ssn-usbpo/ssn-usbpo.service';
import { AuthGuardUSBPO } from './login/authUSBPO.guard';
import { PrivacyBPOService } from './lpus-bpo/privacy-usbpo/privacy-usbpo.service';
import { FaqUsbpoService } from './lpus-bpo/faq-usbpo/faq-usbpo.service';
import { HeaderUsbpoService } from './lpus-bpo/header-usbpo/header-usbpo.service';
import { HrRepresentativeUsbpoService } from './lpus-bpo/header-usbpo/hr-representative/hr-representative.service';
import { NotificationsUsbpoService } from './lpus-bpo/header-usbpo/notifications/notifications.service';
import { LpusBPOComponent } from './lpus-bpo/lpus-bpo.component';
import { LpmainComponent } from './lpmain/lpmain.component';
import { LPMainCustomHttpClient, LPMainInterceptor } from './lpmain/lpmain.interceptor';
import { AuthGuardMain } from './login/authMain.guard';
import { LPMainComponentService } from './lpmain/lpmain.service';
import { DashboardMainService } from './lpmain/dashboard/dashboard.service';
import { DialogLogoutEducation } from './lpindia/step1onboarding-india/education/education.component';
import { DialogLogoutWorkExp } from './lpindia/step1onboarding-india/workexperience/workexperience.component';
import { AddRoutingNoComponent } from './add-routing-no/add-routing-no.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';


export function kcFactory(keycloakService: KeycloakService) {
  return () => keycloakService.init();
}
declare var Hammer: any;
@Injectable()
export class MyHammerConfig extends HammerGestureConfig  {
    buildHammer(element: HTMLElement) {
      let mc = new Hammer(element, {
        touchAction: "pan-y"
      });
      return mc;
    }
  }

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ForgotPasswordComponent,
        ChangePasswordComponent, DialogLogout, AdminComponent, DialogLogoutEducation, DialogLogoutWorkExp
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        //LpusModule,
        //LpindiaModule,
        // LpauModule,
        //NgxSpinnerModule,
        FormsModule,
        ReactiveFormsModule,
        //AppRoutingModule,
        NgxSpinnerModule,
        NgbModule,
        // Ng4LoadingSpinnerModule.forRoot(),
        //KeycloakAngularModule
        //LpukModule,
        //LpnzModule,
        MatSnackBarModule,
        BrowserAnimationsModule,
        MatFormFieldModule,
        MatSelectModule,
        MatRadioModule,
        MatCheckboxModule,
        MatInputModule,
        //LpswzModule,
        // Ng2ImgMaxModule,
        MatDialogModule,
        SharedModule,
        // AngularTelemetrySdkModule,
        OwlModule,
        PdfViewerModule
    ],
    providers: [NgbActiveModal, AppService, ProfileService, FaqService, FeedbackService, ForgotPasswordService, ChangePasswordService, AuthService, AuthGuard, NgbDateFRParserFormatter,
        LPUSCustomHttpClient, FaqUsbpoService, HeaderUsbpoService, HrRepresentativeUsbpoService, NotificationsUsbpoService,
        LPUSBPOCustomHttpClient,
        HeaderIndiaService,
        OnBoardingVideoService,
        IndiaOnboardingPassService,
        DeclareService,
        DashboardIndiaService,
        ResiaddressUsbpoService,
        LpUsbpoSSNService,
        notificationINService,
        PulseIndiaService,
        InductionfeedbackIndiaService,
        BioMetricService,
        AcceptOfferService,
        PrivacyNoticeIndiaService,
        PostjoiningIndiaService,
        LPIndiaCustomHttpClient,
        TermsCondIndiaService,
        IndiaNotificationService,
        ResiaddressIndiaService,
        CoronaComponent,
        TNCBPOService,
        PrivacyBPOService,
        LPAUCustomHttpClient,
        LpusService, LpusBankingService, IdBadgeService, ResiaddressService,
        LpusSSNService, HRRepresentativeService, EmergencyContactService, NotificationService,
        DashboardService, GeosSSNService, AgendaService, HeaderService, DashboardComponent, UKFaqService, DashboardMainService,
        PostjoiningService, w4Service, TNCService, ProfileService, PrivacyService, UKDashboardService,
        { provide: NgbDateParserFormatter, useClass: NgbDateFRParserFormatter },
        LPUKCustomHttpClient,
        PostjoiningService, LpusBPOComponent,
        HeaderService, LpukComponent, LPUKComponentService, AuthGuardAUS, AuthGuardIndia, AuthGuardUK, AuthGuardUS, AuthGuardUSBPO,
        // LPTelemetryService,
        PostjoiningService, LPMainCustomHttpClient, LpmainComponent, AuthGuardMain, LPMainComponentService,
        { provide: HTTP_INTERCEPTORS, useClass: LPUSInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LPIndiaInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LPAUInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LPUKInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LPUSBPOInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LPMainInterceptor, multi: true },
        { provide: ErrorHandler, useClass: GlobalErrorHandler },
        KeycloakService,
        {
            provide: APP_INITIALIZER,
            useFactory: kcFactory,
            deps: [KeycloakService],
            multi: true
        },
        { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { hasBackdrop: false } },
        NgxImageCompressService,
        {
            // hammer instantion with custom config
            provide: HAMMER_GESTURE_CONFIG,
            useClass: MyHammerConfig,
        },
        DatePipe,
        {
            provide: UrlSerializer,
            useClass: LowerCaseUrlSerializer
        }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
    bootstrap: [AppComponent]
})
export class AppModule { }

  