import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Configuration, APIUrls } from '../../configuration';
import { UpdateResult } from '../models/UpdateResultBPO.model';
import { LPUSBPOCustomHttpClient } from '../lpus-bpo.interceptor';
import { GetDPContent, DPOutput, SaveUserConsent, UpdateEmpDetails } from 'src/app/models/Privacy.model';



@Injectable({
    providedIn: 'root'
})
export class PrivacyBPOService {
    constructor(private http: LPUSBPOCustomHttpClient) { }
    Accept(): Observable<UpdateResult> {
        return this.http.post<UpdateResult>(Configuration.ResServerBaseURL+APIUrls.UpdatePrivacyConsent, { "CandidateId": localStorage.getItem("CandidateID") }, {
        }).pipe(map(
            (res) => {
                //console.log(res);
                return res;
            }
        ))
    }

    // getContent(input:GetDPContent):Observable<DPOutput> {
    //     return this.http.post<DPOutput>(Configuration.ResServerBaseURL+APIUrls.GetDPContent,input, {
    //     }).pipe(map(
    //         (res) => {
    //             //console.log(res);
    //             return res;
    //         }
    //     ))
    // }

    // saveConsent(input:SaveUserConsent):Observable<string> {
    //     return this.http.post<string>(Configuration.ResServerBaseURL+APIUrls.SaveUserConsent,input, {
    //     }).pipe(map(
    //         (res) => {
    //             //console.log(res);
    //             return res;
    //         }
    //     ))
    // }

    
    
}