export class FaqBenefit {
    CandidateId: number;
    FAQID: string;
    txtHead: string;
    txtBody: string;
    txtGeo: string;
    txtViewType: string;
    txtContentFor: string;
}

export class MailContent {
    CodeDesc: string;
    FromMailID: string;
    MailSubject: string;
    MailBody: string;
}
