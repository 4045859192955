import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { LPFetchEducationDetails } from '../../models-india/LPFetchEducationDetails';
import { Education } from '../../models-india/Education.model';
import { StateForLpIndia } from '../../models-india/StatesForIndia.models';
import { Degrees } from '../../models-india/LPFillDegreeDropDown.model';
import { Levels } from '../../models-india/LPFillLevelDropDown.model';
import { LPFetchFresherEducationDetails } from '../../models-india/LPFetchFresherEducationDetail.model';
import { LPFetchEduDegreeDesc } from '../../models-india/LPFetchEduDegreeDesc';
import { LPFetchEduSerialNo } from '../../models-india/LPFetchEduSerialNo';
import { LPDms } from '../../models-india/LPDms';
import { EducationDocs } from '../../models-india/EducationDocs';
import { DMSInternetPlainDocumentResponse } from '../../models-india/DMSInternetPlainDocumentResponse';
import { DMSDocument } from '../../models-india/DMSDocument';
import { LPFetchEduDocDetails } from '../../models-india/LPFetchEduDocDetails';
import { FetchEducationDocs } from '../../models-india/FetchEducationDocs';
import {  LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { Configuration, APIUrls } from '../../../configuration';
import { FetchEducation } from '../../models-india/FetchEducation';
import { LPGetQualTypes } from '../../models-india/LPGetQualTypes';
import { LPFetchEducationalDetailsFreshers } from '../../models-india/LPFetchEducationalDetailsFreshers';
import { DMSNew } from '../../models-india/dmsNew';
import { IndiaInputParam } from '../../models-india/IndiaInputParam.model';
import { BGVDetails } from '../../models-india/BGVDetails.model';


@Injectable({
    providedIn: 'root'
  })
export class EducationService
{
    constructor(private _http:LPIndiaCustomHttpClient ) { }

    GetCandidateEducationDetails():Observable<LPFetchEducationDetails[]>
    {
        return this._http.post<LPFetchEducationDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetCandidateEducationDetails,{"CandidateId":localStorage.getItem("intCandidateId")}).pipe(map((res) => {return res;}))    
    }

    // FetchFreshersEduDetails():Observable<LPFetchFresherEducationDetails>
    // {
    //     var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
    //     headers=headers.append('Content-Type','application/json');
    //     console.log("Inside Fetch Service");
    //     return this._http.post<LPFetchFresherEducationDetails>(Configuration.ResServerBaseURLIndia+APIUrls.FetchFreshersEduDetails,{"CandidateId":"12"},{headers:headers}).pipe(map((res) => {return res;}))   
    // }
    UpdateEduDetails(edu:Education):Observable<Number>
    {
        let body=JSON.stringify(edu);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateEduDetails,body).pipe(map((res) => {return res;}))
    }

    UpdateFreshersEduDetail(edu:Education):Observable<Number>
    {
        let body=JSON.stringify(edu);
        
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateFreshersEduDetail,body).pipe(map((res) => {return res;}))
    }
    InsertUpdateEduDetails(edu:Education):Observable<Number>
    {
        let body=JSON.stringify(edu);
        //console.log(body);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.InsertUpdateEduDetails,body).pipe(map((res) => {return res;}))
    }
    GetStates():Observable<StateForLpIndia[]>
    {
        return this._http.post<StateForLpIndia[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetStatesIN,{"strCountryCode":"IN"}).pipe(map((res) => {return res;}))    
    }

    GetDegrees():Observable<Degrees[]>
    {
        return this._http.post<Degrees[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetDegrees,{"flgMode":"DGR"}).pipe(map((res) => {return res;}))    
    }
    GetLevels():Observable<Levels[]>
    {
        return this._http.post<Levels[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetLevels,{"flgMode":"LVL"}).pipe(map((res) => {return res;}))    
    }
    DeleteEduDocDetails(edu:EducationDocs):Observable<Number>
    {
        let body= JSON.stringify(edu);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.DeleteEduDocDetails,body).pipe(map((res) => {return res;}))
    }
    
    FetchDegreeDescription (education:Education):Observable<LPFetchEduDegreeDesc[]>
    {
        education.CandidateId=Number(localStorage.getItem("intCandidateId"));
        let body= JSON.stringify(education);
        return this._http.post<LPFetchEduDegreeDesc[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchDegreeDescription,body).pipe(map((res) => {return res;}))
    }
    FetchEduDocSerialNo (educationDocs:EducationDocs):Observable<LPFetchEduSerialNo[]>
    {
        let body= JSON.stringify(educationDocs);
        return this._http.post<LPFetchEduSerialNo[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchEduDocSerialNo,body).pipe(map((res) => {return res;}))
    }
    UploadPlainDocument (lpdms:LPDms):Observable<DMSInternetPlainDocumentResponse>
    {
        let body= JSON.stringify(lpdms);
        return this._http.post<DMSInternetPlainDocumentResponse>(Configuration.ResServerBaseURLIndia+APIUrls.UploadPlainDocument,body).pipe(map((res) => {return res;}))
    }
    DownloadDocumentFromDMS(lpdms:LPDms): Observable<DMSDocument> 
    {
        let body=JSON.stringify(lpdms);
        return this._http.post<DMSDocument>(Configuration.ResServerBaseURLIndia+APIUrls.DownloadDocumentFromDMS, body).pipe(map((res) => {return res;}));
    }
    InsertEduDocDetails(eduDocDetails:EducationDocs):Observable<number>
    {
        let body= JSON.stringify(eduDocDetails);
        return this._http.post<number>(Configuration.ResServerBaseURLIndia+APIUrls.InsertEduDocDetails,body).pipe(map((res) => {return res;}))
    }
    FetchEduDocDetails(educationDoc:FetchEducationDocs):Observable<LPFetchEduDocDetails[]>
    {
        let body= JSON.stringify(educationDoc);
        return this._http.post<LPFetchEduDocDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.FetchEduDocDetails,body).pipe(map((res) => {return res;}))
    }
    GetQualTypes(education:FetchEducation):Observable<LPGetQualTypes[]>
    {
        let body= JSON.stringify(education);
        return this._http.post<LPGetQualTypes[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetQualTypes,body).pipe(map((res) => {return res;}))
    }
    UpdateFreshersEduDetails(education:Education):Observable<number>
    {
        let body= JSON.stringify(education);
        return this._http.post<number>(Configuration.ResServerBaseURLIndia+APIUrls.UpdateFreshersEduDetails,body).pipe(map((res) => {return res;}))
    }
    FetchFreshersEduDetails (education:FetchEducation):Observable<LPFetchEducationalDetailsFreshers>
    {
        let body= JSON.stringify(education);
        return this._http.post<LPFetchEducationalDetailsFreshers>(Configuration.ResServerBaseURLIndia+APIUrls.FetchFreshersEduDetails,body).pipe(map((res) => {return res;}))
    }
    UploadPlainDocumentNew(lpdms:DMSNew):Observable<DMSInternetPlainDocumentResponse>
{
    let body = JSON.stringify(lpdms);
    // console.log(body);
    return this._http.post<DMSInternetPlainDocumentResponse>(Configuration.ResServerBaseURLIndia+APIUrls.UploadPlainDocumentNew,body).pipe(map((res) => {return res;}));
}
    // UpdateEduDetails(education:Education):Observable<number>
    // {
    //     var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
    //     headers=headers.append('Content-Type','application/json');
    //     let body= JSON.stringify(education);
    //     return this._http.post<number>(Configuration.ResServerBaseURLIndia+APIUrls.GetQualTypes,body,{headers:headers}).pipe(map((res) => {return res;}))
    // }


    DeleteGapDetails(edu:IndiaInputParam):Observable<Number>
    {
        let body= JSON.stringify(edu);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia+APIUrls.DeleteGapDetailsIndia,body).pipe(map((res) => {return res;}))
    }
    AddBGVDetails(bgv:BGVDetails):Observable<string>{
        var body ={"CandidateId":parseInt(localStorage.getItem("intCandidateId"))}
        
        return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.AddBGVDetails,bgv).pipe(map(
            (res) => {
            // console.log(res);
            return res;
            }
            )) 
    
    }

    GetBGVDetails(bgv:BGVDetails):Observable<BGVDetails[]>{
        
        return this._http.post<BGVDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetBGVDetails,bgv).pipe(map(
            (res) => {
            // console.log(res);
            return res;
            }
            )) 
    }
}