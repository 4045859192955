import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../../app.service';
import { HeaderIndiaService } from '../../header-india/header-india.service';
import { NgForm } from '@angular/forms';
import { MailContent } from '../../../lpus/models/MailContent.model';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { CovidDeclaration } from '../../models-india/CovidDeclaration';
import { AlertService } from '../../../utils/alert/alert.service';
import { CoronaService } from './corona.service';
import { NgbDateFRParserFormatter } from '../../../NgbDateFRParserFormatter';

declare function Redirect(url):any;
declare let ClientIP: any;
@Component({
  selector: 'app-corona',
  templateUrl: './corona.component.html',
  styleUrls: ['./corona.component.css']
})
export class CoronaComponent implements OnInit {

  mobile:Boolean;
  errormsg:String;
  year = localStorage.getItem("footeryear");
  candidateid:String=localStorage.getItem("intCandidateId");
  name:String=localStorage.getItem("txtCandFirstName");
  fname:String;
  mailContent=new MailContent;
  mssg:any;
  err:String;
  cand : any;
  dateofjoining:Date;
  flgCityVisited:Boolean = false;
  countryName:String;
  Dateofjourney:String;
  flgFever:Boolean=false;
  flgCough:Boolean=false;
  flgResDistress:Boolean = false;
  corona:CovidDeclaration;
  flgAcknowledge:Boolean = false;
  todayDate=new Date();
  privateIP;
  ibpm:boolean = localStorage.getItem("COMPANY").toString().toUpperCase()=="IBPM INDIA";
    constructor(
      private breakpointObserver:BreakpointObserver,
      private _appService:AppService,
      private _service:HeaderIndiaService,
      private router:Router,
      private r :ActivatedRoute,
      private alert: AlertService,
      private service: CoronaService,
      private dateformatter : NgbDateFRParserFormatter,
      private spinner:NgxSpinnerService
     ) {}
     SendRequest(form:NgForm){
      this.mailContent.MailBody=form.value.mail;
      this._service.SendMailerHelp(this.mailContent).subscribe(
        (res)=>{
         this.mssg=res.Updateresult;
       
        }
      );
    }
    ngOnInit() {
       this.spinner.hide();
      this.privateIP = ClientIP;
      if(this.mobile){window.scroll(0, 0);}
    if(localStorage.getItem("IsComingFromLogin")=="Y"){
      this.toNavigate();
    }
      this.dateofjoining = new Date(localStorage.getItem("dtActiveDOJ"));
      this.breakpointObserver
        .observe(['(max-width: 599px)'])
        .subscribe((state: BreakpointState) => {
          if (state.matches) {
            //this.appService.setIsMobile(true);
            this.mobile = true;
           
          } else {
            //this.appService.setIsMobile(false);
            this.mobile = false;
            
          }
        });
        
    }
  
    
 
  
    skipclick(){
        
        this.navigate();
    }
  
  
  navigate() {
    localStorage.setItem("flgCovid","Y");
    
    // if(localStorage.getItem("flgVaccine").toUpperCase()!="Y"){
    //   this.router.navigate(["vaccine"],{queryParams:{"accept":"Y"}});
    // }
    // else{
    //   Redirect(document.baseURI+"lpindia")
    // }
    

    if(this.mobile){
      this.router.navigate(["/lpindia"]);
     }else{
    this.router.navigate(["/lpindia/step1-india/personal"]);
    }


  }
Acknowledge(){
  this.flgAcknowledge = !this.flgAcknowledge;
}
  updateCoronaDetails(form:NgForm)
  {
     this.spinner.show();
    if(!this.flgAcknowledge){
      this.alert.error("Please select declaration.",this.mobile);
       this.spinner.hide();
    }
    else{
      this.corona = new CovidDeclaration();
      this.corona.CandidateID = Number(localStorage.getItem("intCandidateId"));
      if(this.flgCityVisited){
      this.corona.flgCountryVisit = "Y";
      if(this.flgFever){
        this.corona.flgFever = "Y";
      }
      else{
        this.corona.flgFever = "N";
      }

      if(this.flgResDistress){
        
      this.corona.flgRespiratoryDistress = "Y";
      }
      else{
        
      this.corona.flgRespiratoryDistress = "N";
      }

      if(this.flgCough){
        this.corona.flgCough = "Y";
      }
      else{
        this.corona.flgCough = "N";
      }
      
      this.corona.CountryName = form.value.countryRef;
      this.corona.DateofJourney = this.dateformatter.formatMatDate(form.value.journeyref);
      }
      else{
        this.corona.flgCountryVisit = "N";
        this.corona.CountryName = "";
        this.corona.DateofJourney = "";
        this.corona.flgCough = "N";
        this.corona.flgFever = "N";
        this.corona.flgRespiratoryDistress = "N";
      }
      
      this.corona.flgAcknowledge = 'Y'
      this.corona.IpAddress = this.privateIP;
      //console.log(this.corona);
      this.service.InsertCovidDetails(this.corona).subscribe
      (
        res=>
        { 
          //console.log(res);
        this.alert.success("Done! Form submitted successfully.",this.mobile);
         this.spinner.hide();
        this.navigate();
        // this.router.navigate["vaccine"];
        }
        )
    }
  }

  visited(value:string){
    if(value == 'Y'){
      this.flgCityVisited = true;
    }
    else{
      this.flgCityVisited = false;
    }
  }

  symptoms(value:string){
    if(value == 'F'){
      this.flgFever = !this.flgFever;
    }
    else if(value == 'C'){
      this.flgCough = !this.flgCough;
    }

  else if(value == 'RD'){
    this.flgResDistress = !this.flgResDistress;
  }

  }
  toNavigate(){
    var flgTC=localStorage.getItem("flgTermsAndConditions");
      //console.log(flgTC);
      var flgPolicy=localStorage.getItem("flgPolicy");
      var flgFirstLogin=localStorage.getItem("flgFirstLogin");
       if(flgPolicy.toUpperCase() != "Y")
      { this.spinner.hide();
        //console.log("inside privacy")
        this.router.navigate(["privacynotice"],{queryParams:{"accept":"Y"}});
  
      }
    //  else if(flgTC.toUpperCase() != "Y")
    //   { //need to check this.spinner.hide();
    //     //console.log("inside tnc")
    //     this.router.navigate(["tnc-ind"],{queryParams:{"accept":"Y"}});
    //   }
  
    else if (flgFirstLogin.toUpperCase()!="Y")
    { this.spinner.hide();
      // console.log("chg")
      this.router.navigate(["change"],{queryParams:{"accept":"Y"}});
    }
    else
    {
      if (localStorage.getItem("Identification").toUpperCase()=="FRESHER")
      {
        // console.log("fr")
        if((localStorage.getItem("flgBioConsent").toUpperCase()  != "Y") && (localStorage.getItem("flgBioConsent").toUpperCase()  != "N"))
            { this.spinner.hide();
              // console.log("fr")
            this.router.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
            }
            else
            { this.spinner.hide();
              // console.log("ind")
            }
            
      }
      else if (localStorage.getItem("Identification").toUpperCase()=="LATERAL"){
        // console.log("latrl")
        // if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  != "OFFER ACCEPTED")
        if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  == "OFFER RELEASED")
        { this.spinner.hide();
          // console.log("ofr")
        // this.router.navigate(["accept"],{queryParams:{"accept":"Y"}});
          this.router.navigate(["accept"],{queryParams:{"accept":"Y"}});
        }
        else if (localStorage.getItem("flgGDCSubmit").toUpperCase()!="Y"){
          //console.log("gdc")
           this.spinner.hide();
          //this.router.navigate(["corona"]);
          this.router.navigate(["declare"],{queryParams:{"accept":"Y"}});
        }
        // else if(localStorage.getItem("flgCovid").toUpperCase()!="Y" && !this.ibpm){
        //   this.router.navigate(["corona"],{queryParams:{"accept":"Y"}});
        // }
        // else if(localStorage.getItem("flgVaccine").toUpperCase()!="Y"){
        //   this.router.navigate(["vaccine"],{queryParams:{"accept":"Y"}});
        // }
        else if((localStorage.getItem("flgBioConsent").toUpperCase()  != "Y" && localStorage.getItem("flgBioConsent").toUpperCase()  != "N"))
        { this.spinner.hide();
         //  console.log("fr")
        this.router.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
        }
      //   else if(userIn[0].flgVideoSeen.toUpperCase()  != "Y"){
              
      //     this.myRoute.navigate(["video-ind"],{queryParams:{"accept":"Y"}})
      // }
        else
        {
          // console.log("ind")
          Redirect(document.baseURI+"lpindia")
         this.router.navigate(["lpindia"]);
           this.spinner.hide();        
        }
      }
      else
      {
        // console.log("ind")
         this.spinner.hide();       
      }
    }
  }
  
}
