import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { FormBuilder, Validators, NgForm } from '@angular/forms';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';
import { USPreJoiningLogin } from '../lpus/models/USPreJoiningLogin.model';
import { KeycloakService } from '../keycloak.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { AppService } from '../app.service';

declare function SendLexTokenAndroid(token): any;
declare function SendLexTokenSwift(token): any;

@Component({ 
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  public model: any;
  userName: string = "";
  password: string = "";
  mobile: boolean;
  keycloak: boolean = false;
  UserData: USPreJoiningLogin = new USPreJoiningLogin();
  isLoggedin : boolean = false;
  year:number;
  date=new Date();

  constructor(private fb: FormBuilder,
    private myRoute: Router,
    private auth: AuthService,
    private kcService: KeycloakService,
    private appService: AppService,
    private spinner:NgxSpinnerService
  ) {
    // //need to check this.spinner.show();
    
    
  }

  ngOnInit() {
    this.year=this.date.getFullYear();

    localStorage.setItem("footeryear",this.year.toString());
    this.mobile = this.appService.mobile;
    if (this.kcService.getToken() != undefined){
    localStorage.setItem("Token",this.kcService.getToken());
    this.isLoggedin=true;
    }
    else if(this.auth.getToken()!=null)
    {
    this.isLoggedin=true;
    }
    if (this.isLoggedin) {
      SendLexTokenAndroid(this.auth.getToken());
      SendLexTokenSwift(this.auth.getToken());
      localStorage.setItem("refToken", this.kcService.getRefToken());
      this.auth.sendToken(this.auth.getToken());
      this.keycloak = true;
      this.spinner.show();
    }
    else if(this.mobile)
    {
      this.kcService.Login();
    }

    if(!this.mobile)
    {
      let body = document.getElementsByTagName('body')[0];
      body.style.backgroundColor = "#2672DE";
    }
  }

  ngOnDestroy() {
    let body = document.getElementsByTagName('body')[0];
    body.style.backgroundColor = "#fff";
  }

  login() {

    this.kcService.Login();
    //this.auth.sendToken(this.kcService.getToken());
    // console.log("Inside login component");
  }
  ForgetPassoword() {

  }
}
