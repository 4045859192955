  import { Component, OnInit } from '@angular/core';
import { ChangePasswordService } from './changePassword.service';
import { ChangePasswordEnt } from '../../models/ChangePasswordEnt.model';
import { NgForm } from '@angular/forms';
import { AppService } from '../../app.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth.service';
import { AlertService } from '../../utils/alert/alert.service';
import { ChangePasswordResult } from '../../models/ChangePasswordResult.model';
import { UpdatePassword } from '../../models/ChangePasswordIN';
import { NgxSpinnerService } from 'ngx-spinner';

//import { AlertService } from '../../../utils/alert/alert.service';
declare function Redirect(url):any;
declare function ShowBottomBar():any;
declare function HideBottomBar():any;
declare function WebViewLogout(): any;
declare function snackHeight():any;

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {
  mobile:boolean;
  mob:boolean;
  isHidden:boolean;
  location:string;
  currentpwd:string;
  region:string;
  response:ChangePasswordResult;
  responseIn:any;
  backShow: Boolean = true;
  //ev: boolean = localStorage.getItem("COMPANY").toString().toUpperCase()=="EV";
  ev: boolean = localStorage.getItem("COMPANY")=="EV";
  //msg: String;
  //errormsg: String;

  constructor(
    private changeservice:ChangePasswordService,
    private appService : AppService,private breakpointObserver: BreakpointObserver,
    private router:Router,
    private r :ActivatedRoute,
    private authService:AuthService, 
    private alert:AlertService,
    private route: ActivatedRoute,
    private spinner:NgxSpinnerService
    ) { 
      let body = document.getElementsByTagName('body')[0];
      body.style.backgroundColor="#2672DE"; 
    }
    
  changepwd = new ChangePasswordEnt;
  changepwdIN = new UpdatePassword();
  
  ngOnInit() {
    
    HideBottomBar();
      this.route.queryParams.subscribe(params =>{
        if (params["accept"] == "Y") {
          this.backShow = false;
        }
      });

    this.breakpointObserver
    .observe(['(max-width: 599px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        //console.log('Inside app component observer');
        this.appService.setIsMobile(true);
        this.mobile = true;
      } else {
        //console.log('Inside app component observer'); 
        this.appService.setIsMobile(false);
        this.mobile = false;
      }
    });
      this.mob = this.appService.mobile;
      this.appService.hideDashBoard.subscribe((isHidden)=>{
      this.isHidden = isHidden;
    });

    this.location=localStorage.getItem('Location');
    // console.log(this.location)
    // this.region=this.authService.user.Region
    this.region=localStorage.getItem("Region");
    if(this.region.toUpperCase()=="IN"){
      if(localStorage.getItem("IsComingFromLogin")=="Y"){
        this.toNavigate();
      }
    }
    if(this.region.toUpperCase()=="IL AUSTRALIA" || this.region.toUpperCase()=="BPM AUSTRALIA"){
      if(localStorage.getItem("IsComingFromLogin")=="Y"){
        this.toNavigateAU();
      }
    }
    if(this.region.toUpperCase()=="IL UK" || this.region.toUpperCase()=="BPM UK"){
      if(localStorage.getItem("IsComingFromLogin")=="Y"){
        this.toNavigateUK();
      }
    }
  }
 
  ChangePassword(form : NgForm){
    //console.log(form.value.CurrentPwdRef)
    //console.log(form.value.NewPwdRef)
    //console.log(form.value.ConfirmPwdRef)
    //console.log(this.currentpwd)
    //console.log(this.region)
    //console.log(this.location)
    this.changepwdIN.intCandidateId = Number(localStorage.getItem("intCandidateId"));
    this.changepwdIN.txtNewPassword = form.value.NewPwdRef;
    this.changepwdIN.txtOldPassword = form.value.CurrentPwdRef;
    this.changepwdIN.txtUserName = localStorage.getItem("txtEmailId")
    this.changepwd.CurrentPassword=form.value.CurrentPwdRef
    this.changepwd.NewPassword=form.value.NewPwdRef

      if(form.value.NewPwdRef==form.value.ConfirmPwdRef){
       if(form.value.CurrentPwdRef != form.value.NewPwdRef)
       {

        if(this.region=="US" || this.region=="KALIDS"){
          this.spinner.show();
          this.changeservice.ChangePasswordForCandidateUS(this.changepwd).subscribe(
            (res)=>{
             this.response = res;

             let desc = this.response.MessageDesc;
              if(this.response.MessageCode == "003"){
                this.spinner.hide();
                setTimeout(() => { ShowBottomBar(); }, 4500);
                this.alert.success(desc,this.mobile);
                if(this.backShow)
                {      
                  this.spinner.hide();             
                  setTimeout(()=>{Redirect(document.baseURI+"lpus");},4000); 
                  ShowBottomBar();                 
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();

                //this.router.navigate(['../lpus'], { relativeTo: this.r });
                },4000);
                //  this.router.navigate(['../lpus'], { relativeTo: this.r })
              }
              else{
                this.spinner.hide();
                this.alert.error(desc,this.mobile);
              }
              
             
            }  
          );
        }
        else if(this.region.toUpperCase()=="IBPO" || this.region.toUpperCase()=="MCCAM" || this.region.toUpperCase()=="IBAUSA" || this.region.toUpperCase()=="IPS" ){
         this.spinner.show();
          this.changeservice.ChangePasswordForCandidateUS(this.changepwd).subscribe(
            (res)=>{
             this.response = res;

             let desc = this.response.MessageDesc;
              if(this.response.MessageCode == "003"){
                this.spinner.hide();
                setTimeout(() => { ShowBottomBar(); }, 4500);
                this.alert.success(desc,this.mobile);
                if(this.backShow)
                {      
                 this.spinner.hide();             
                  setTimeout(()=>{Redirect(document.baseURI+"lpusbpo");},4000); 
                  ShowBottomBar();                 
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();

                //this.router.navigate(['../lpus'], { relativeTo: this.r });
                },4000);
                //  this.router.navigate(['../lpus'], { relativeTo: this.r })
              }
              else{
                this.spinner.hide();
                this.alert.error(desc,this.mobile);
              }
              
             
            }  
          );
        }
        else if(this.region=="IL AUSTRALIA" || this.region=="IL UK" || this.region=="BPM UK" ||  this.region.toUpperCase() == "BPM AUSTRALIA"){
          this.spinner.show();
          this.changeservice.ChangePasswordForCandidateEMEA(this.changepwd).subscribe(
            (res)=>{
             this.response = res;
             let desc = this.response.MessageDesc;
              if(this.response.MessageCode == "003"){
                this.spinner.hide();
                this.alert.success(desc,this.mobile);
              
              if(localStorage.getItem("Region")=="IL UK" || localStorage.getItem("Region")=="BPM UK"){
                if(this.backShow)
                { 
                  this.spinner.hide();         
                  setTimeout(()=>{Redirect(document.baseURI+"lpuk");},4000);
                  ShowBottomBar();
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();

                  //this.router.navigate(['../lpuk'], { relativeTo: this.r });
                },4000);
              //  this.router.navigate(['../lpuk'], { relativeTo: this.r })
             }
             else if(localStorage.getItem("Region")=="IL AUSTRALIA" || localStorage.getItem("Region") =="BPM AUSTRALIA"){
              if(this.backShow)
              { 
                this.spinner.hide();                
                setTimeout(()=>{Redirect(document.baseURI+"lpau");},4000);
                ShowBottomBar();
              }
              this.spinner.hide(); 
              // setTimeout(()=>{ localStorage.setItem('FlagPasswordChangedType','U')
              //   this.router.navigate(['../lpau'], { relativeTo: this.r });},4000);
              setTimeout(()=>{               
                localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();
                
                
                },4000);
              //  this.router.navigate(['../lpau'], { relativeTo: this.r })
             }
            }
              else{
                this.spinner.hide();
                this.alert.error(desc,this.mobile);
              }
              
              
            }  
          );
        }
        else if(this.region.toUpperCase()=="CANADA" || this.region.toUpperCase()=="IPS CANADA"|| this.region.toUpperCase()=="IL SWITZERLAND" ||this.region.toUpperCase()=="IL GERMANY" || this.region.toUpperCase()=="IL NEW ZEALAND" || this.region.toUpperCase()=="IL  BELGIUM" || this.region.toUpperCase() == "IL SINGAPORE" || this.region.toUpperCase() == "IL NETHERLANDS" 
        || localStorage.getItem("CompanyCode").toUpperCase()=="COMMON" ){  //Added by Vibhu
          
          if(this.region.toUpperCase()=="CANADA" || this.region.toUpperCase()== "IPS CANADA"){
            this.spinner.show();
          this.changeservice.ChangePasswordForCandidateUS(this.changepwd).subscribe(
            (res)=>{
             this.response = res;

             let desc = this.response.MessageDesc;
              if(this.response.MessageCode == "003"){
                this.spinner.hide();
                this.alert.success(desc,this.mobile);
                if(this.mobile){
                snackHeight(); 
                }
                setTimeout(() => { ShowBottomBar(); }, 4500);
                if(this.backShow)
                {      
                  this.spinner.hide();             
                  setTimeout(()=>{Redirect(document.baseURI+"lpmain");},4000); 
                  ShowBottomBar();                 
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();
                },4000);
              }
              else{
                 this.spinner.hide();
                this.alert.error(desc,this.mobile);
              }
                
          }
          );
        }
          else{
           this.spinner.show();
          this.changeservice.ChangePasswordForCandidateEMEA(this.changepwd).subscribe(
            (res)=>{
             this.response = res;
             let desc = this.response.MessageDesc;
              if(this.response.MessageCode == "003"){
                this.spinner.hide();
                this.alert.success(desc,this.mobile);
                if(this.mobile){
                  snackHeight(); } 
                if(this.backShow)
                { 
                  this.spinner.hide();         
                  setTimeout(()=>{Redirect(document.baseURI+"lpmain");},4000);
                  ShowBottomBar();
                }
               this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();
                },4000);
             
            }
              else{
                this.spinner.hide();
                this.alert.error(desc,this.mobile);
              }
              
              
            }  
          );
          }
        }
        else{
          //console.log(this.changepwdIN);
          this.spinner.show();
          this.changeservice.ChangePasswordForCandidateIN(this.changepwdIN).subscribe(
            (res)=>{
              this.responseIn = res;
              let desc = this.responseIn.MessageDesc
              //console.log(res);
              if(this.responseIn.MessageCode == "003"){
                if(this.backShow)
                {      
                 this.spinner.hide();            
                  setTimeout(()=>{Redirect(document.baseURI+"lpindia");},3000);
                  ShowBottomBar();
                }
                this.spinner.hide();
                this.alert.success(desc,this.mobile);
                localStorage.setItem("flgFirstLogin","Y")
                setTimeout(()=>{
                  //this.GoToNextSection();
                  //localStorage.setItem('flgFirstLogin','')
                  this.authService.logout();
                  WebViewLogout();
                },3000);
                               
              }
              else{
                this.spinner.hide();
                this.alert.error(desc,this.mobile);
              }
            }  
          );
        }
       }
       else{
        this.spinner.hide();
        this.alert.error("New passwords should not be same as current password",this.mobile);
       }     
           
      }
    
    else{
      this.spinner.hide();
      this.alert.error("New passwords do not match",this.mobile);
      this.router.navigate(['../change'], { relativeTo: this.r })
        }
    
        }
    
NewChangePassword(form : NgForm)
{
  this.spinner.show()
  this.changepwd.NewPassword = form.value.NewPwdRef;
  this.changepwd.NewPassword=form.value.NewPwdRef;

    if(form.value.NewPwdRef==form.value.ConfirmPwdRef)
    {
      this.changeservice.ChangePasswordOnKeycloak(this.changepwd).subscribe(
        (res)=>
        {
          console.log("Main response",res);
          if(res){
            if(this.region=="US" || this.region=="KALIDS"){
              if(res){
                this.spinner.hide();
                setTimeout(() => { ShowBottomBar(); }, 4500);
                this.alert.success("Password changed successfully.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
                if(this.backShow)
                {      
                 this.spinner.hide();             
                  setTimeout(()=>{Redirect(document.baseURI+"lpus");},4000); 
                  ShowBottomBar();                 
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();
                },4000);
              }
              else{
                this.spinner.hide();
                this.alert.error("Unable to changes password. Please try after sometime.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
              }
            }
            else if(this.region.toUpperCase()=="IBPO" || this.region.toUpperCase()=="MCCAM" || this.region.toUpperCase()=="IBAUSA" || this.region.toUpperCase()=="IPS" ){
              
              if(res){
                this.spinner.hide();
                setTimeout(() => { ShowBottomBar(); }, 4500);
                this.alert.success("Password changed successfully.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
                if(this.backShow)
                {      
                  this.spinner.hide();             
                  setTimeout(()=>{Redirect(document.baseURI+"lpusbpo");},4000); 
                  ShowBottomBar();                 
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();

                },4000);
              }
              else{
                this.spinner.hide();
                this.alert.error("Unable to changes password. Please try after sometime.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
              }   
            }
            else if(this.region=="IL AUSTRALIA" || this.region=="IL UK" || this.region=="BPM UK" || this.region=="BPM AUSTRALIA"){
              if(res){
                this.spinner.hide();
                this.alert.success("Password changed successfully.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
              if(localStorage.getItem("Region")=="IL UK" || localStorage.getItem("Region")=="BPM UK"){
                if(this.backShow)
                { 
                  this.spinner.hide();         
                  setTimeout(()=>{Redirect(document.baseURI+"lpuk");},4000);
                  ShowBottomBar();
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();
                },4000);
              }
              else if(localStorage.getItem("Region")=="IL AUSTRALIA" || localStorage.getItem("Region")=="BPM AUSTRALIA"){
              if(this.backShow)
              { 
                this.spinner.hide();                
                setTimeout(()=>{Redirect(document.baseURI+"lpau");},4000);
                ShowBottomBar();
              }
              this.spinner.hide(); 
              setTimeout(()=>{               
                localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();
                },4000);
              }
              }
              else{
                this.spinner.hide();
                this.alert.error("Unable to changes password. Please try after sometime.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
              }
            }
            else if(this.region.toUpperCase()=="CANADA" || this.region.toUpperCase()=="IL SWITZERLAND" ||this.region.toUpperCase()=="IL GERMANY" || this.region.toUpperCase()=="IL NEW ZEALAND" || this.region.toUpperCase()=="IL  BELGIUM" || this.region.toUpperCase() == "IL SINGAPORE" || this.region.toUpperCase() == "IL NETHERLANDS" 
            || localStorage.getItem("CompanyCode").toUpperCase()=="COMMON" ){  //Added by Vibhu
              
              if(this.region.toUpperCase()=="CANADA"){
                if(res){
                  this.spinner.hide();
                  this.alert.success("Password changed successfully.",this.mobile);
                  if(this.mobile){
                    snackHeight(); 
                    } 
                  setTimeout(() => { ShowBottomBar(); }, 4500);
                  if(this.backShow)
                  {      
                    this.spinner.hide();             
                    setTimeout(()=>{Redirect(document.baseURI+"lpmain");},4000); 
                    ShowBottomBar();                 
                  }
                  this.spinner.hide(); 
                  setTimeout(()=>{
                    localStorage.setItem('FlagPasswordChangedType','U')
                    this.authService.logout();
                    WebViewLogout();
                  },4000);
                }
                else{
                  this.spinner.hide();
                  this.alert.error("Unable to changes password. Please try after sometime.",this.mobile);
                  if(this.mobile){
                    snackHeight(); 
                    }
                }
                    
              
            }
            else{  
              if(res)
              {
                this.spinner.hide();
                this.alert.success("Password changed successfully.",this.mobile);
                if(this.mobile){
                  snackHeight(); } 
                if(this.backShow)
                { 
                  this.spinner.hide();         
                  setTimeout(()=>{Redirect(document.baseURI+"lpmain");},4000);
                  ShowBottomBar();
                }
                this.spinner.hide(); 
                setTimeout(()=>{
                  localStorage.setItem('FlagPasswordChangedType','U')
                  this.authService.logout();
                  WebViewLogout();
                },4000);
                
              }
              else{
                this.spinner.hide();
                this.alert.error("Unable to changes password. Please try after sometime.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
              }       
            }
            }
            else{
              console.log("inside else response",res);
              if(res){
                if(this.backShow)
                {      
                  this.spinner.hide();            
                  setTimeout(()=>{Redirect(document.baseURI+"lpindia");},3000);
                  ShowBottomBar();
                }
                this.spinner.hide();
                this.alert.success("Password changed successfully.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
                localStorage.setItem("flgFirstLogin","Y")
                setTimeout(()=>{
                  this.authService.logout();
                  WebViewLogout();
                },3000);
                                
              }
              else{
                this.spinner.hide();
                this.alert.error("Unable to changes password. Please try after sometime.",this.mobile);
                if(this.mobile){
                  snackHeight(); 
                  }
              }
                  
            }
          }
          else{
            this.spinner.hide();
            this.alert.error("Unable to changes password. Please try after sometime.",this.mobile);
            if(this.mobile){
              snackHeight(); 
              }
            }
        }
      )
    }
  
  else{
    this.spinner.hide();
    this.alert.error("New passwords do not match",this.mobile);
    this.router.navigate(['../change'], { relativeTo: this.r })
      }
  
}

  OnStepBackClicked(){
    if(localStorage.getItem("Region")=="IL UK" || localStorage.getItem("Region")=="BPM UK"){
      this.router.navigate(['../lpuk'], { relativeTo: this.r })
    }
    else if(localStorage.getItem("Region")=="IL AUSTRALIA" || localStorage.getItem("Region")=="BPM AUSTRALIA"){
      this.router.navigate(['../lpau'], { relativeTo: this.r })
    }
     else if(localStorage.getItem("Region")=="US" || localStorage.getItem("Region")=="KALIDS"){
       Redirect(document.baseURI+"lpus");
      this.router.navigate(['../lpus'], { relativeTo: this.r })
    }
    else if(localStorage.getItem("Region")=="IBPO" || localStorage.getItem("Region")=="McCam"){
      Redirect(document.baseURI+"lpusbpo");
     this.router.navigate(['../lpusbpo'], { relativeTo: this.r })
   }
   else if(this.region.toUpperCase()=="CANADA" || this.region.toUpperCase()=="IL SWITZERLAND" ||
   this.region.toUpperCase()=="IL GERMANY" || this.region.toUpperCase()=="IL NEW ZEALAND" || this.region.toUpperCase()=="IL  BELGIUM" || this.region.toUpperCase() == "IL SINGAPORE" || this.region.toUpperCase() == "IL NETHERLANDS"){
    Redirect(document.baseURI+"lpmain");
    this.router.navigate(['../lpmain'], { relativeTo: this.r })
   }
          
    else{  
       Redirect(document.baseURI+"lpindia");
       this.router.navigate(['../lpindia'], { relativeTo: this.r })
    }
    ShowBottomBar();
  }

  GoToNextSection(){
    if (localStorage.getItem("Identification").toUpperCase()=="FRESHER"){
    // console.log("fr")
      if(localStorage.getItem("flgBioConsent").toUpperCase() != "Y") {
        // console.log("bio")
        this.router.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
      }
      else
      {
        // console.log("ind")
      Redirect(document.baseURI+"lpindia");
      this.router.navigate(["lpindia"]);
      }    
    }
    else if (localStorage.getItem("Identification").toUpperCase()=="LATERAL"){
    //console.log("latrl")
      // if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  != "OFFER ACCEPTED")
      if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  == "OFFER RELEASED")
      {
        // console.log("ofr")
      this.router.navigate(["accept"],{queryParams:{"accept":"Y"}});
      }
      else if (localStorage.getItem("flgGDCSubmit").toUpperCase()!="Y"){
        // console.log("gdc")
        this.router.navigate(["declare"],{queryParams:{"accept":"Y"}});
      }
      else if(localStorage.getItem("flgBioConsent").toUpperCase() != "Y"  && localStorage.getItem("flgBioConsent").toUpperCase()  != "N")
      {
        // console.log("bio")
      this.router.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
      }
      //   else if(userIn[0].flgVideoSeen.toUpperCase()  != "Y"){
            
      //     this.router.navigate(["video-ind"],{queryParams:{"accept":"Y"}})
      // }
      else
      {
        // console.log("ind")
      Redirect(document.baseURI+"lpindia")
      this.router.navigate(["lpindia"]);
      }

    }
    else
    {
      // console.log("ind")
    Redirect(document.baseURI+"lpindia")
    this.router.navigate(["lpindia"]);
    }      
  }

  ngOnDestroy() {
  let body = document.getElementsByTagName('body')[0];
  body.style.backgroundColor="#fff";
  } 

//Used only for flow after login
  toNavigate(){
    var flgTC=localStorage.getItem("flgTermsAndConditions");
      //console.log(flgTC);
      var flgPolicy=localStorage.getItem("flgPolicy");
      var flgFirstLogin=localStorage.getItem("flgFirstLogin");
      if(flgPolicy.toUpperCase() != "Y")
      {this.spinner.hide();
        //console.log("inside privacy")
        this.router.navigate(["privacynotice"],{queryParams:{"accept":"Y"}});

      }
    // else if(flgTC.toUpperCase() != "Y")
    //   { //need to check this.spinner.hide();
    //     //console.log("inside tnc")
    //     this.router.navigate(["tnc-ind"],{queryParams:{"accept":"Y"}});
    //   }

    else if (flgFirstLogin.toUpperCase()!="Y")
    {this.spinner.hide();
      // console.log("chg")
      this.router.navigate(["change"],{queryParams:{"accept":"Y"}});
    }
    else if(this.ev){
      if(this.mobile == false){
        if(localStorage.getItem("IsComingFromLogin")=="Y"){
          localStorage.setItem("IsComingFromLogin","N")
        }                    
      }
    }
    else
    {
      if (localStorage.getItem("Identification").toUpperCase()=="FRESHER")
      {
        // console.log("fr")
        if((localStorage.getItem("flgBioConsent").toUpperCase()  != "Y") && (localStorage.getItem("flgBioConsent").toUpperCase()  != "N"))
            {this.spinner.hide();
              // console.log("fr")
            this.router.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
            }
            else
            {this.spinner.hide();
              // console.log("ind")
            }
            
      }
      else if (localStorage.getItem("Identification").toUpperCase()=="LATERAL"){
        // console.log("latrl")
        // if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  != "OFFER ACCEPTED")
        if(localStorage.getItem("txtOfferStatus").trim().toUpperCase()  == "OFFER RELEASED")
        {this.spinner.hide();
          // console.log("ofr")
        this.router.navigate(["accept"],{queryParams:{"accept":"Y"}});
        }
        else if (localStorage.getItem("flgGDCSubmit").toUpperCase()!="Y"){
          //console.log("gdc")
          this.spinner.hide();
          this.router.navigate(["declare"],{queryParams:{"accept":"Y"}});
        }
        else if((localStorage.getItem("flgBioConsent").toUpperCase()  != "Y" && localStorage.getItem("flgBioConsent").toUpperCase()  != "N"))
        {this.spinner.hide();
        //  console.log("fr")
        this.router.navigate(["biometric-ind"],{queryParams:{"accept":"Y"}});
        }
      //   else if(userIn[0].flgVideoSeen.toUpperCase()  != "Y"){
              
      //     this.myRoute.navigate(["video-ind"],{queryParams:{"accept":"Y"}})
      // }
        else
        {
          // console.log("ind")
          this.spinner.hide();        
        }
      }
      else
      {
        // console.log("ind")
        this.spinner.hide();       
      }
    }
  }

  toNavigateAU(){
    var FlagTermsAndCondition=localStorage.getItem("FlagTermsAndCondition");
    var FlagPrivacy=localStorage.getItem("FlagPrivacy");
    var FlagFirstLogin=localStorage.getItem("FlagFirstLogin");
    var FlagPasswordChangedType=localStorage.getItem("FlagPasswordChangedType");
    // if(FlagFirstLogin.toUpperCase() != "Y")
    //     {//need to check this.spinner.hide();
    //     this.router.navigate(["privacy-au"],{queryParams:{"accept":"Y"}});
    //     }
        // else if(FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.router.navigate(["tnc-au"],{queryParams:{"accept":"Y"}});
        // }
         if(FlagPrivacy.toUpperCase() != "Y")
        {this.spinner.hide();
        this.router.navigate(["privacy-au"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagPasswordChangedType.toUpperCase() != "U")
        {this.spinner.hide();
        this.router.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else
        {this.spinner.hide();
        }
  }
  toNavigateUK(){
    var FlagTermsAndCondition=localStorage.getItem("FlagTermsAndCondition");
    var FlagPrivacy=localStorage.getItem("FlagPrivacy");
    var FlagFirstLogin=localStorage.getItem("FlagFirstLogin");
    var FlagPasswordChangedType=localStorage.getItem("FlagPasswordChangedType");
    // if(FlagFirstLogin.toUpperCase() != "Y")
    //     {//need to check this.spinner.hide();
    //     this.router.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
    //     }
        // else if(FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.router.navigate(["tc-uk"],{queryParams:{"accept":"Y"}});
        // }
        if(FlagPrivacy.toUpperCase() != "Y")
        {this.spinner.hide();
        this.router.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagPasswordChangedType.toUpperCase() != "U")
        {this.spinner.hide();
        this.router.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else
        {this.spinner.hide();
        }
  }
}
