export class Question
    {
        EmpNo :string;
         EmailID :string
        Quesid :string;
        SQuesid:string;
       Quesdesc :string;
        Questype :string;
        QuesResponseSub :string;
        QuesResponseObject :string;
        FeedBackValue:string;
        flgSubmitted :string;
        dtSubmittedOn :string;
        LastModifiedBy :string;
        dtLastModifiedOn :string;
        Region :string;
    }


    
export class UpdateResult
{
Updateresult:string;

}
