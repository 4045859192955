import { Injectable } from '@angular/core';
import { LPUSCustomHttpClient } from '../lpus/lpus.interceptor';
import { Configuration, APIUrls } from '../configuration';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class LoggingService {
    constructor(private http: LPUSCustomHttpClient) { }
    
    logError(message: string, stack: string) {
        // Send errors to server here

        this.http.post(Configuration.ResServerBaseURL+APIUrls.HandleExceptionFromMobile,{"ExceptionStackTrace":message}).pipe(map(
            (res) => {
                console.log("RADB");
          }));

        console.log('LoggingService: ' + message);
    }
}
