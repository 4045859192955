// import { RequestOptions,RequestMethod,Headers } from '../../../node_modules/@angular/http';
// import {Response} from '@angular/http';
import  { HttpClient, HttpHeaders,  HttpErrorResponse }  from  '@angular/common/http'
import  {  Injectable  }  from  '@angular/core';

import  { map }  from  'rxjs/operators'
import  { catchError }  from  'rxjs/operators'
import  {  Observable,  throwError  }  from  'rxjs';

import { PendingActions } from '../models/PendingActions.model';
import { LPUSCustomHttpClient } from '../lpus.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { DMSDocument } from '../models/DMSDocument.model';
import { LPDms } from '../models/LPDms.model';
import { Policies } from '../models/policies.model';
import { policy } from '../models/policy.model';
import { SeniorExecDetails } from 'src/app/lpus/models/policy.model';


@Injectable()
export class PostjoiningService {
    policies: string;
    constructor(private http: LPUSCustomHttpClient) { }

    getPendingActions(): Observable<PendingActions> {
    
        return  this.http.post<PendingActions>(Configuration.ResServerBaseURL + APIUrls.getPendingActions, { "CandidateId": localStorage.getItem("CandidateID"), "CandidateEmail": localStorage.getItem("CandidateEmailID") }).pipe(map(
            (res)  =>  {
                return  res;
            }
        ))
    }


    DownloadPlainDocument(lpdms: LPDms): Observable<DMSDocument> {

        let body = JSON.stringify(lpdms);
        return this.http.post<DMSDocument>(Configuration.ResServerBaseURLIndia + APIUrls.DownloadPlainDocumentIN, body).pipe(map((res)  =>  { return  res; }));
    }

    FetchPolicyPageDetailsUS(policyinput: policy): Observable<String> {
        
        return this.http.post<String>(Configuration.ResServerBaseURL + APIUrls.FetchPolicyPageDetailsUS, policyinput).pipe(map(
            (res) => {
               // console.log(res);
                return res;
            }
        ))
    }

    FetchPolicyPageDetailsUSTimestamp(policyinput: policy): Observable<String[]> {
        
        return this.http.post<String[]>(Configuration.ResServerBaseURL + APIUrls.FetchPolicyPageDetailsUSTimestamp, policyinput).pipe(map(
            (res) => {
               // console.log(res);

                return res;
            }
        ))
    }


    InsertPolicesDetailsUS(policydetails: Policies): Observable<String> {
        
        return this.http.post<String>(Configuration.ResServerBaseURL + APIUrls.InsertPolicesDetailsUS, policydetails).pipe(map(
            (res) => {
                // console.log(res);
                return res;
            }
        ))
    }

}