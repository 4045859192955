import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import { APIUrls, Configuration } from '../configuration';
import { LPMainCustomHttpClient } from './lpmain.interceptor';
import { GetStepOneDetails, StepOneFlow } from './Model-Classes/GetStepOneDeatils';
import { PendingActions } from './Model-Classes/PendingActions.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

declare function ChangeImageIcon(pre:string[], ids:string, post:string[],num:number,flg:string): any;
declare function LoadStyleForIconsGlobal(i:string, c:string, a:string, b:string, p:string, s:string,
  t:string, po:string, f:string, pp:string, cc:string, ki:string, resip: string, contdoc: string, taxF: string, nric: string, hol: string,ben:string, cfn:string,medical:string,natId:string,disability:string)   
@Injectable()
export class LPMainComponentService {
    stepOneIcons: StepOneFlow[] = [];    
    constructor(private _http: LPMainCustomHttpClient) { }

    private Deccflag = new BehaviorSubject<boolean>(false);
  Brcast5 = this.Deccflag.asObservable();
  
  NewDecFlag(newFlg) {
    this.Deccflag.next(newFlg);
  }

    GetStepOneFlow(input:GetStepOneDetails):Observable<StepOneFlow[]>{
        return this._http.post<StepOneFlow[]>(Configuration.ResServerBaseURL+APIUrls.GetStepOneFlow,input).pipe(map(
        (res) => {
        return res;
        }))
    }
    
    _errorHandler(error: HttpErrorResponse) {
        //console.log(error);
        return throwError(error.message || "Server Error");
    }

    OnListItemClicked(step:StepOneFlow)
  {
    let flgCanada : string = "N";
    let canada = localStorage.getItem("Region").toUpperCase();
    if(canada.trim() == "CANADA")
    {
      flgCanada = "Y"
    }
    else
    {
      flgCanada = "N"
    }
    let pre:string[] = [];
    let post:string[] = [];
    for(let st of this.stepOneIcons)
    {
      if(st.orderId < step.orderId)
      {
        pre.push(st.iconId);
      }
      else if(st.orderId > step.orderId)
      {
        post.push(st.iconId);
      }
    }
    ChangeImageIcon(pre,step.iconId,post,step.orderId,flgCanada);
  }

  getPendingActionsGlobal(): Observable<PendingActions> {
    return  this._http.post<PendingActions>(Configuration.ResServerBaseURL + APIUrls.getPendingActionsGlobal, { "CandidateId": localStorage.getItem("CandidateID"), "CandidateEmail": localStorage.getItem("CandidateEmailID")}).pipe(map(
        (res)  =>  {
            return  res;
        }
    ))
}

getPendingActions()
{
  this.getPendingActionsGlobal().subscribe(
    res=>{
      if(res!=null){
      LoadStyleForIconsGlobal(res.ImageUpload,res.Emergency,res.Address,res.BANK,res.Personal,res.SSN,
        res.Tax,res.Policies,res.Family,res.Passport,res.CorpCard,res.KiwiSaver,res.ResiPermit, res.ContractDoc,
        res.TaxField, res.NRIC, res.HolidayAttest, res.Insurance, res.CFN,res.MedicalInsurance,res.NationalId,res.Disability);
      }
    }
  )
}
}