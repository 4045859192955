import { Component, OnInit } from '@angular/core';

declare function LoadAccordianJs() : any;

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  pnationality: String = "";
  USCheck:Boolean;
  IndiaCheck:Boolean;
  UKCheck:Boolean;
  AUSCheck:Boolean;
  India:string="India";

  constructor() { }

  ngOnInit() {
    LoadAccordianJs();
    this.USCheck = false;
      this.IndiaCheck = true;
      this.UKCheck = false;
      this.AUSCheck = false;
  }

  openDiv(value:string)
  {
    LoadAccordianJs();
    //console.log("Inside Method",value);
    if(value == "US"){
      this.USCheck = true;
      this.IndiaCheck = false;
      this.UKCheck = false;
      this.AUSCheck = false;
    }
    else if(value == "India"){
      this.USCheck = false;
      this.IndiaCheck = true;
      this.UKCheck = false;
      this.AUSCheck = false;
    }
    else if(value == "UK"){
      this.USCheck = false;
      this.IndiaCheck = false;
      this.UKCheck = true;
      this.AUSCheck = false;
    }
    else if(value == "AUS"){
      this.USCheck = false;
      this.IndiaCheck = false;
      this.UKCheck = false;
      this.AUSCheck = true;
    }
  }

}
