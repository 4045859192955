import { Component, OnInit, HostListener } from '@angular/core';
import { PrivacyUkComponent } from './header-uk/privacy-uk/privacy-uk.component';
import { LPUKComponentService } from './lpuk.service';
import { lpUKUserCredentials } from './models/lpukUserCredentials.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { AlertService } from '../utils/alert/alert.service';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../app.service';
import { Router } from '../../../node_modules/@angular/router';
import { AuthService } from '../login/auth.service';
import { PostjoiningUKService } from './postjoining/postjoining.service';

declare function LoadStyleForIconsUK(i:string,c:string,a:string,p:string,b:string,policy:string,tax:string, insurance: string,resipermit:string,passport:string,joiningdocs:string):any;
declare function LoadOwlCarouselForMenu_UK_Mobile() : any;

@Component({
  selector: 'app-lpuk',
  templateUrl: './lpuk.component.html',
  styleUrls: ['./lpuk.component.css']
})
export class LpukComponent implements OnInit {
  year=localStorage.getItem("footeryear");
  postjoining:Boolean=Number(localStorage.getItem("DaysLeft"))<=0;
  prejoining:Boolean=Number(localStorage.getItem("DaysLeft"))>0;
  postd:Boolean;
  privacy:Boolean;
  tc:Boolean=true;
  user:lpUKUserCredentials;
  today=new Date();
  DOJ:Date;
  s:string;
  mobile:boolean;
  idbadge:Boolean=false;
  emergency:Boolean=false;
  personal:Boolean=false;
  address:Boolean=false;
  bank:Boolean=false;
  taxuk:Boolean=false;
  tax: boolean;
  insurance: boolean;
  policy: boolean;
  message:boolean;
  forAction:Boolean = false;
  resipermit:Boolean=false;
  passport:Boolean=false;
  joiningdocs:Boolean=false;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private appService:AppService,
    private postService:PostjoiningUKService,
    private authService:AuthService, 
    private alert:AlertService,
  private router :Router,
  private spinner: NgxSpinnerService) {
      // this.user = {CandidateID:101,AddressType:null,Password:null,RequestType:null,UserName:"aditya@infosys.com"};
      // //need to check this.spinner.show()
      // this._service.GetUserDetails(this.user).subscribe(
      //   res=>{
      //     if(res.length>0){
      //       console.log(res[0].CandDOJ)
      //       this.DOJ=new Date(res[0].CandDOJ.trim().slice(0,9))
      //       console.log(this.DOJ)
      //       console.log(this.today)
      //       if(this.DOJ>this.today){
      //         this.prejoining=true;
      //         console.log('working')
      //       }
      //       else{
      //         this.postjoining=true;
      //         console.log('postj')
      //       }
      //     }
      //     else{
      //       this.prejoining=true
      //       this.alert.error("Data not found",this.mobile)
      //     }//need to check this.spinner.hide();},        
          
      //   err=>{//need to check this.spinner.hide();
      //   this.alert.error("Some error occured",this.mobile)
      //   }
      // )
   }

  ngOnInit() {

      this.mobile=this.appService.mobile;
      if(this.mobile == false){
        if(this.postjoining){

          this.toNavigateUK();
        }
        else if(this.prejoining){

          this.toNavigateUK();
        }
        
      }
      this.authService.GetHeadingsForGeo(localStorage.getItem("CandidateID")).subscribe((res) =>{
        // localStorage.setItem("HeadingList",res.toString())
        AuthService.HeadingList=res;
      });
      this.postService.getPendingActions().subscribe(
        (res)=>{  

          LoadOwlCarouselForMenu_UK_Mobile();
        //  console.log(res);          
          if(res.ImageUpload!="Y"){
            this.idbadge=true;
          }
          if(res.Emergency!="Y"){
            this.emergency=true;
          }
          if(res.Personal!="Y"){
            this.personal=true;          
          }
          if(res.Address!="Y"){
            this.address=true;
          }
          if(res.TaxUK!="Y"){
            this.taxuk=true;
          }
          if(res.BANK!="Y"){
            this.bank=true;
          }
          if(res.Insurance!="Y"){
            this.insurance=true;
          }
          if(res.ResiPermit!="Y"){
            this.resipermit=true;
          }
          if(res.Passport!="Y"){
            this.passport=true;
          }
          if(res.Joiningdocs!="Y"){
            this.joiningdocs=true;
          }
          if(res.Policies!="Y"){
            this.policy=true;
          }
          if(this.idbadge==false && this.emergency==false && this.personal==false && this.address==false && this.bank==false && this.policy==false && this.taxuk==false && this.insurance == false && this.resipermit == false && this.passport == false && this.joiningdocs == false){
            this.message= true;
            this.forAction = true;
          }
          LoadStyleForIconsUK(res.ImageUpload,res.Emergency,res.Address,res.Personal,res.BANK,res.Policies,res.TaxUK, res.Insurance , res.ResiPermit,res.Passport,res.Joiningdocs);
           this.spinner.hide();
        },        
      );
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if(!this.appService.mobile){     
      this.authService.logout();            
    }    
  }

  gotoPrivacy(){
    this.privacy=true;
    this.tc=false;
  }

  gotodashboard(){
    this.privacy=false;
    this.tc=false;
  }
  toNavigateUK(){
    var FlagTermsAndCondition=localStorage.getItem("FlagTermsAndCondition");
    var FlagPrivacy=localStorage.getItem("FlagPrivacy");
    var FlagFirstLogin=localStorage.getItem("FlagFirstLogin");
    var FlagPasswordChangedType=localStorage.getItem("FlagPasswordChangedType");
    // if(FlagFirstLogin.toUpperCase() != "Y")
    //     {//need to check this.spinner.hide();
    //     this.router.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
    //     }
        //  if(FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.router.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
        // }
         if(FlagPrivacy.toUpperCase() != "Y")
        { this.spinner.hide();
        this.router.navigate(["privacy-uk"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.router.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else
        { this.spinner.hide();
          if(this.mobile == false){
            //console.log("Inside Web");
            if(this.postjoining){
              //console.log(this.postjoining);
            this.router.navigate(['/lpuk/postjoining-uk']);
                //this.callPedningAction();
            }
            else if(this.prejoining){
              if(localStorage.getItem("IsComingFromLogin")=="Y"){
                localStorage.setItem("IsComingFromLogin","N")
                this.router.navigate(['/lpuk/step1-uk/idbadgeuk'])
              }
              
            }
            
          }
        }
  }

//   callPedningAction()
// {
//   this.postService.getPendingActions().subscribe(
//     (res)=>{
//     console.log(res);
//       if(res.ImageUpload!="Y"){
//           this.idbadge=true;
//         }
//         if(res.Emergency!="Y"){
//           this.emergency=true;
//         }
//         if(res.Personal!="Y"){
//           this.personal=true;          
//         }
//         if(res.Address!="Y"){
//           this.address=true;
//         }
//         if(res.BANK!="Y"){
//           this.bank=true;
//         }
//         if(res.Tax!="Y"){
//           this.tax=true;
//         }
//         if(res.Insurance!="Y"){
//           this.insurance=true;
//         }
//         if(res.Policies!="Y"){
//           this.policy=true;
//         }
//         if(this.idbadge==false && this.emergency==false && this.personal==false && this.address==false && this.bank==false && this.tax==false  && this.policy==false && this.insurance == false){
//           this.message= true;
//           this.forAction = true;
//           this.router.navigate(['/lpuk/step1-uk/idbadgeuk']);
//           return;
//         }
//       LoadStyleForIconsUK(res.ImageUpload,res.Emergency,res.Address,res.Personal,res.BANK,res.Policies,res.Tax,res.Insurance);        


//       if(this.idbadge==true){
//         this.router.navigate(['lpuk/postjoining-uk/idbadgeuk'], { queryParams: { order: 'W' } });
//         return;
//       }
//       if(this.emergency==true){
//         this.router.navigate(['lpuk/postjoining-uk/emergency'], { queryParams: { order: 'W' } });
//         return;
//       }
//       if(this.personal==true){
//         this.router.navigate(['lpuk/postjoining-uk/personal'], { queryParams: { order: 'W' } });
//         return;       
//       }
//       if(this.address==true){
//         this.router.navigate(['lpuk/postjoining-uk/address'], { queryParams: { order: 'W' } });
//         return;
//       }
//       if(this.tax==true){
//         this.router.navigate(['lpuk/postjoining-uk/taxuk'], { queryParams: { order: 'W' } });
//         return;
//       }
//       if(this.bank==true){
//         this.router.navigate(['lpuk/postjoining-uk/bank'], { queryParams: { order: 'W' } });
//         return;
//       }      
//       if(this.insurance==true){
//         this.router.navigate(['lpuk/postjoining-uk/insuranceuk'], { queryParams: { order: 'W' } });
//         return;
//       }
//       if(this.policy==true){
//         this.router.navigate(['lpuk/postjoining-uk/policy-uk'], { queryParams: { order: 'W' } });
//         return;
//       }
//   });
// }

}
