export class NoteConstants{
    public static txtBankDetailsTitle:string = "Share your bank account details for payroll processing";
    public static txtBankDetailsTopNote:string="These details will ensure your payroll is processed on time.";
    public static txtBankDetailsTopNoteAForUS:string="You can add a maximum of three bank accounts. These details will ensure your payroll is processed on time.";
    public static txtBankDetailsTopNoteBForUS:string="Ensure that 'Bank one' is the account that you regularly operate.";
    public static txtBankDetailsTopNoteCForUS:string="If your account number starts with zeroes, ask your bank if the first two zeroes pertain to direct deposit. If yes, omit the two zeroes from your account number.";
    public static txtBankDetailsBottomNoteGermany:string="Please note, in case you are not able to find your Bank or Swift/Sort/BSB Code from the list, you may please write to :";
    public static txtEmergencyDetailsTitle:string="Share Your Emergency Contact Number";
    public static txtEmergencyDetailsTopNote:string="This number will be used in case you are in an emergency situation";
    public static txtIDBadgeTopNote:string="Id Badge Preview";
    public static txtIDBadgeTitle:string="Upload your photo for ID badge";
    public static txtInsuranceTitle:string="Provide your insurance information";
    public static txtInsuranceTopNote:string="Let’s gather your insurance information, this will save you time, post joining.";
    public static txtPersonalDetailsTitle:string="Provide your personal details";
    public static txtPersonalDetailsTopNote:string="Let's gather your details- this will help with your benefits enrollment process";
    public static txtPersonalDetailsBottomNote:string="This section is now frozen as the details were to be filled and submitted within 2 days post your joining. Please login to InfyMe and navigate to the profile tab in order to provide the necessary inputs required in this section";
    public static txtPersonalDetailsBottomNoteTemp:string="This section needs to be filled and submitted within 2 days from your Date of Joining. Please ensure you do so in this timeline.";
    public static txtPolicyDetailsTitle:string="Documents To Be Digitally Acknowledged";
    public static txtPolicyDetailsTopNote:string="Here's a list of the forms that you need to digitally acknowledge before joining.";
    public static txtPolicyDetailsTopNoteNethBelgium:string="Here's a list of the mandatory forms that you need to digitally acknowledge before joining or on the date of joining.<br>These are related to Data Privacy Clauses and Code of Conduct policy of Infosys Limited. If you have any questions regarding this section, you can discuss with Geo HR in Induction session.";
    public static txtAddressDetailsTitle:string="Provide your residential address";
    public static txtAddressDetailsTopNote:string="We will use the address you provide in our internal systems as well as for payroll processing";
    public static txtAddressDetailsBottomNote:string="Please save my personal phone number in Infosys Directory. I understand that phone numbers saved in Infosys Directory are visible to all other Infosys employees.";
    public static txtAddressTempTopNote:string="We will use the address you provide in our internal systems as well as for payroll processing.";
    public static txtAddressTempBottomNote:string="Please note, in case you are not able to find your city from the list, you may please write to:";
    public static txtTaxDetailsTitle:string="Provide your tax information";
    public static txtTaxDetailsopNote:string="This will help with accurate tax deductions.";
    public static txtStartStep1:string="Let's start the process of gathering details for your ID badge.<br/><br/> This will take just a minute."
    public static txtBenefitDetailsTitle="Benefits";
    public static txtContractDocumentsTitle="Contracts Document";
    public static txtContractDocumentsTopNoteNethBelgium="Important information related to Contract Documents submission | -Please upload the following documents here using the 'Attach supporting document' link. Make sure you sign every page on the documents (i.e. digital signatures or in ink) and then upload the same.";
    public static txtContractDocumentsTopNoteRest="Please upload the following documents here using the 'Attach supporting document' link.";
    public static txtCorpCardMainTitle="Share few details to apply for corporate credit card";
    public static txtCorpCardMainTopNoteNethBelgium="Please do discuss with your managers if you would require a Corporate Credit Card for business transactions.";
    public static txtCorpCardMainTopNoteRest="The details will be used for processing your credit card with a limit of US $4000 to meet official expenses.";
    public static txtEducationDetailsTitle:string = "Provide your educational details";
    public static txtEducationDetailsTopNote:string="Let's add details to your education data. This makes your background check quicker. In case the information is missing or incorrect for any of the following fields (Education Level, Country, End Date and Start Date), please write an email to";
    public static txtFamilyChangesTopNote:string="Let’s add your family details. This makes your benefits enrollment easier.";
    public static txtFamilyChangsTitle:string="Provide your family details";
    public static txtHolidayAttestationTopNote:string="Please upload the holiday attestation document from here using the 'Attach supporting document' link.";
    public static txtHolidayAttestationTitle:string="Holiday Attestation Document";
    public static txtCriminalRecordTopNote:string="Please upload the criminal record document from here using the 'Attach supporting document' link."
    public static txtCriminalRecordTitle: string ="Criminal Record Document"
    public static txtInsuranceMainTitleRest:string="Social Security Document";
    public static txtInsuranceMainTitleNetherlands:string="Insurance";
    public static txtInsuranceMainTopNoteNetherlands:string="NA";
    public static txtInsuranceMainTopNoteRest:string="Please upload Social Security document using the 'Attach supporting document' link.";
    public static txtKiwiSaverMainTitle:string="KiwiSaver Form";
    public static txtMedicalInsuranceTitle:string="Onboarding Related Documents Section";
    public static txtKiwiSaverMainTopNote:string="Please download the KiwiSaver Form, fill it up and upload it here using the 'Attach supporting document' link.";
    public static txtMedicalInsuranceMainTopNote:string="Please navigate to each sub section shown below to download the associated document, fill in the required details and upload document back into Launchpad here using the 'Attach supporting document'."
    public static txtDisabilityMainTopNote:string="Infosys values diversity and is committed to an inclusion strategy that integrates persons with disabilities into the Infosys family. If you have a disability, we encourage you to update your disability-related information and upload your certificate for differently-abled, in this section. We assure you that we will respect the confidentiality of the information disclosed.";
    public static txtDisabiltyMainTitle:string="Provide your disability details.";
    public static txtPassportDetailsTitle:string="Provide your passport details";
    public static txtNationalIdDetailsTitle:string="National ID details";
    public static txtPassportDetailsTopNote:string="Providing your passport details here will make the background check go faster";
    public static txtResidencePermitTitle:string="Residence Permit";
    public static txtResidencePermitTopNote:string="Please upload the residence permit document using the 'Attach supporting document' link.";
    public static txtSSNTitleNetherlands:string="Share your BSN(Social Security number) details for payroll processing";
    public static txtSSNTopNoteNetherlands:string="Valid BSN details will ensure that your payroll is processed on time";
    public static txtSSNTempTopNoteNetherlands:string="Verify if your name matches BSN details. In case of a mismatch, kindly update as per BSN.";
    public static txtSSNTitleRest:string="Share your SIN details for payroll processing";
    public static txtSSNTopNoteRest:string="Valid SIN details will ensure that your payroll is processed on time";
    public static txtSSNTempTopNoteRest:string="Verify if your name matches SIN details. In case of a mismatch, kindly update as per SIN.";
    public static txtSSNTitleUS:string="Share your SSN details for payroll processing";
    public static txtSSNTopNoteUS:string="Valid SSN details will ensure that your payroll is processed on time";
    public static txtSSNTempTopNoteUS:string="Verify if your name matches SSN details. In case of a mismatch, kindly update as per SSN.";
    public static txtTaxDetailsMainTitleNetherlands:string="Tax Information ";
    public static txtTaxDetailsMainTopNoteNetherlands:string="The 30% reimbursement ruling (also known as the 30% facility) is a tax advantage for highly skilled migrants moving to the Netherlands for a specific employment role. When the necessary conditions are met, the employer can grant a tax-free allowance equivalent to 30% of the gross salary subject to Dutch payroll tax. This reimbursement is intended as compensation for the extra costs that international employees can incur when moving to a new country for their work.Please refer to below link to read about the 30% ruling and it’s applicability in detail -";
    public static txtTaxDetailsMainTitleRest:string="Tax Related Document";
    public static txtTaxDetailsMainTopNoteRest:string="Please download the tax filing number declaration form, fill it up and upload it here using the 'Attach supporting document' link.";
    public static txtWorkexperienceTitle:string="Provide your work experience details";
    public static txtWorkexperienceTopNote:string="Let’s add your work history here. This makes your background check quicker. In case the information is missing or incorrect for any of the following fields (Start Date, End Date, Country and Organization Name), please write an email to";
    public static txtTravelDetailsMainTitle:string="Know Your Eligibility For Travel";
    public static txtTravelDetailsMainTopNote:string="You will be joining us at {{Location}}.<br/> <br />You book your travel directly or Infosys can book your travel for you. If you choose to book your travel directly, please be mindful of your eligible limit";
    public static txtRelocationTitle:string="KNOW YOUR ELIGIBILITY FOR RELOCATION";
    public static txtRelocationTopNote:string="'In case you are relocating for business reasons, you can avail reimbursement within the specified limits";
    public static txtEmployeeEligibilityVerTopNote:string="All employees in the United States are required to complete the employment verification process through form I-9.If you have not already, you will recieve an email from our Remote I-9 team on instructions to complete the same. Incase you haven't, please let us know at";
    public static txtEmployeeEligibilityVerTitle:string="Employment eligibility verification";
    public static txtW4FormTopNote:string="We will use the address you provide in our internal systems as well as for payroll processing";
    public static txtW4FormTitle:string="Form W4 Completion";
    public static txtW4FormTempTopNote:string="Your W4 declaration was last updated on";
    public static txtW4FormBottomNote:string="Please Note: If you have already updated any of these fields on Harmony, kindly login to Harmony to submit w4 as data on Harmony won’t be overwritten. You are allowed to update W4 details only once on launchpad. ";
    public static txtBenefitSelectionTitle:string="BENEFIT SELECTION";
    public static txtBenefitSelectionTopNote:string="Use the bswift portal to enroll and choose your benefits.";
    public static txtBenefitSelectionTempTopNote:string="Use the bswift portal to enroll and choose your benefits. It takes approximately ten days to reflect on the system.";
    public static txtBenefitSelectionBottomNote:string="Please note: It takes 3-4 business days after joining for your log in information to unlock the benefits portal. Please allow 10 business days for Salary information to reflect in BSwift.";
    public static txtResidentialAddressSectionCode="ResidentialAddressMain";
    public static txtHealthInsuranceTitle:string="Health Insurance Membership Certificate";
    public static txtHealthInsuranceTopNote:string="Please upload Health Insurance Membership Certificate using the 'Attach supporting document' link.";
    public static txtInsuranceEnrollmentTitle:string="Insurance Enrolment form";
    public static txtInsuranceEnrollmentTopNote:string="Please upload Insurance Enrolment form using the 'Attach supporting document' link.";
    public static txtPostjoiningBenefitsMainTitle:string="Benefits Document";
    public static txtPostjoiningBenefitsMainTopNote:string="Please download the Application for Enrollment document, fill it up and upload it here using the 'Attach supporting document' link.";
    public static txtPostjoiningMembersChangeTitle:string="Member's Change Request form";
    public static txtPostjoiningMembersChangeTopNote:string="Please download the Member's Change Request form, fill it up and upload it here using the 'Attach supporting document' link.";
    public static txtPersonalDetailsIndiaTopNote:string="Providing these details now will save you time on your date of joining";
    public static txtDisabilityDetailsIndiaTopNote:string="Infosys values diversity and is committed to an inclusion strategy that integrates persons with disabilities into the Infosys family. If you have a disability, we encourage you to update your disability-related information and upload your certificate for differently-abled, in this section. We assure you that we will respect the confidentiality of the information disclosed.";
    public static txtDisabilityDetailsIndiaTitle:string="Provide your disability details";
    public static txtDisabilityDetailsIndiaBottomNote:string="Maximum five documents are allowed to upload.";
    public static txtEducationDetailsIndiaTopNote:string="Let's add details to your education data. This makes your background check quickly.";
    public static txtWorkExperienceIndiaTopNote:string="Let’s add your work history here. This makes your background check quicker.";
    public static txtWorkEducationGapTitle:string="Provide your gaps between work experience and education";
    public static txtWorkEducationGapTopNote:string="We need to know of any gaps in your education or work experience to get to know you better";
    public static txtNSRIndiaTitle:string="Provide your NSR details";
    public static txtNSRIndiaTopNote:string="The TIN number is the number shared with you post the NSR payment & registration/biometric completion. Once the registration is complete, the ITPIN number is sent in a mail by the NSR team in a couple of days.";
    public static txtPANIndiaTitle:string="Provide your PAN details";
    public static txtPANIndiaTopNote:string="Providing your PAN details here will enable us to process your payroll in time";
    public static txtAadharIndiaTitle:string="Provide your Aadhar details";
    public static txtAadharIndiaTopNote:string="Your Aadhar details are mandatory and need to be shared with the EPFO team.";
    public static txtAddressIndiaTitle:string="Provide your address details";
    public static txtAddressIndiaTopNote:string="We need your address details to keep in touch";
    public static txtBankIndiaTitle:string="Provide your banking details";
    public static txtBankIndiaTopNote:string="Your salary will be credited to this account. You can enter an existing account.";
    public static txtNominationIndiaTopNote:string="Nominate the person(s) to receive retiral benefits. Any nomination which is made in favour of a person, who is not a family member, shall be considered void.'If you want to add any other family members then please add details in family section";
    public static txtNominationIndiaTitle:string="Provide your nomination details";
    public static txtIDBadgeIndiaTitle:string="Your emergency contact number";
    public static txtPlaceholderIndiaTopNote:string="The below documents are important from Compliance stand point, hence please follow the below instructions carefully while filling them up.";
    // public static txtPlaceholderIndiaTopNoteLateral:string="Please refer to this section on your date of joining and follow the instructions for the respective documents. This is important for you to be compliant to joining formalities.";
    public static txtPlaceholderIndiaTopNoteLateral:string="Please complete the declarations before joining and refer to this section again on your date of joining to complete pending items. Please follow the instructions for the respective documents. This is important for you to be compliant to joining formalities."
    public static txtPlaceholderIndiaTitle:string="Documents for Onboarding";
    public static txtNATSIndiaTitle:string="Provide your UAN details";
    public static txtStatutoryIndiaTitle:string="Statutory Form";
    public static txtStatutoryIndiaBottomNote:string="Please note that you would no longer be able to acknowledge the Statutory Forms on Launchpad, since it is applicable only till your Date of Joining. Kindly reach out to <Launchpad Team ID> for further steps."
    public static txtStatutoryIndiaBottomNoteTemp:string="Please note that it is mandatory to acknowledge the Statutory Forms prior to your Date of Joining as a part of Compliance. You would be able to acknowledge the Statutory Forms on Launchpad only till your Date of Joining."
    public static txtAuthenticateIndiaTitle: string="Authenticate and generate background check & personal data forms";
    public static txtAuthenticateIndiaTopNote: string="I hereby have validated and authenticated that all the information provided by me is complete and accurate.";
    public static txtAchievementsIndiaTopNote:string="Tell us more about your achievements, interests, and skills to help us know your true potential.";
    public static txtAchievementsIndiaTitle:string="Provide your achievements / extracurricular activities";
    public static txtAspirationIndiaTitle: string="Provide your aspiration in Mysore";
    public static txtAspirationIndiaTopNote: string="Let’s add your Clubs so that we can help you pursue them during the training.";
    public static txtMedicalIndiaTitle: string="Provide your medical details";
    public static txtMedicalIndiaTopNote: string="Providing your medical details here will make the background check go faster";
    public static txtPersonalDetailsBottomNoteUS: string="This section needs to be filled and submitted within 2 days from your Date of Joining. Please ensure you do so in this timeline";
    public static txtAddressDetailsUSBPOBottomNote: string="By providing your contact number you acknowledge that your number will flow to our Infosys directory on the day of your joining and it will be visible to all Infosys employees.";
    public static txtEmployeeEligibilityVerUSBPOTopNote: string="All employees in the United States are required to complete the employment verification process through form i9 within 3 days/72 hrs of joining.";
    public static txtEmployeeEligibilityVerUSBPOTempTopNote: string="All U.S. employers must properly validate an I-9 Employment Verification Form for each individual they hire for legal employment in the United States. As a future employee of Infosys, you will be requested to produce acceptable documentation verifying your identity and employment authorization.";
    public static txtEmployeeEligibilityVerUSBPOTempTopNoteB: string="All employees in the United States are required to complete the employment verification process through federal form I-9. If you have not already, you will receive an email from IPS mailbox <a style='color: #3367d6' href='mailto:'RemoteIPS_I9@infosys.com''>RemoteIPS_I9@infosys.com</a> detailing how to complete the same. In case you haven’t, please let us know at <a style='color: #3367d6' href='mailto:'RemoteIPS_I9@infosys.com''>launchpad.ips@infosys.com</a>.";
    public static txtEmployeeEligibilityVerUSBPOTempTopNoteC: string="All employees in the United States are required to complete the employment verification process through federal form I-9. If you have not already, you will receive an email from IPS mailbox<a style='color: #3367d6' (click)='OpenMailer('RemoteIPS_I9@infosys.com')'> RemoteIPS_I9@infosys.com </a>detailing how to complete the same. In case you haven’t, please let us know at<a style='color: #3367d6' (click)='OpenMailer('launchpad.ips@infosys.com')'> launchpad.ips@infosys.com</a>.";
    public static txtEmployeeEligibilityVerUSBPOTempTopNoteD: string="<div class='h7_style'>Important Information</div><ul class='list-items'> <ol type='1'> <li> If you have not already, you will receive an email from our I-9 team that you have been setup in our system to complete the Employment Verification process (I-9) remotely.</li><li>After completing section one and the electronic signature requirement, you will be automatically directed to the appointment page to setup your appointment for completion of Section 2.</li><li> If you were unable to complete this step for any reason, an email was sent to you from the system with a link that will enable you to schedule an appointment.</li></ol></ul><br>";
    public static txtEmployeeEligibilityVerUSBPOTempTopNoteE: string="<h3 class='title-4 mt-24'>Important Information</h3><ul class='bullet-list mt-20'><li> If you have not already, you will receive an email from our I-9 team that you have been setup in our system to complete the Employment Verification process (I-9) remotely.</li><li>After completing section one and the electronic signature requirement, you will be automatically directed to the appointment page to setup your appointment for completion of Section 2.</li><li> If you were unable to complete this step for any reason, an email was sent to you from the system with a link that will enable you to schedule an appointment.</li></ul><br><br>";
    public static txtPolicyUSBPOTitle: string="Documents To Be Digitally Acknowledged";
    public static txtPolicyUSBPOTopNote: string="Here’s a list of the forms that you need to digitally acknowledge before joining.";
    public static txtBankDetailsTopNoteForAU: string="You can add only 3 bank accounts. These details will ensure your payroll is processed on time. Ensure 'Bank One' is the account that you regularly operate.";
    public static txtBankDetailsTopNoteAForAU: string="If your account number starts with zeroes, ask your bank if the first two zeroes pertain to direct deposit. If yes, exclude two zeroes while entering your account number on this form.";
    public static txtSuperannuationDetailsTitle: string="Share your superannuation information";
    public static txtSuperannuationDetailsBottomNote: string="The pre-filled information here pertains to Infosys’ default super fund with MLC. If your preference is for Infosys to open a super fund with MLC and future contributions into this account, please do not change these details. However, you will need to download below form and fill in with relevant details and upload it in this section. The membership number will remain blank and will be updated once the fund has been created after the payroll gets processed. If you have an existing super fund and would like Infosys to pay future contributions into it, please download the form below and fill in with relevant details and upload it in this section.";
    public static txtSuperannuationDetailsBottomNoteTemp: any="Hi, if you want to update your super details, you can go to the path mentioned below and additionally email Noune Indjeian Noune_Indjeian@infosys.com with updated super details.<br>You can access the same path to view/update your Superannuation details. The Harmony+ app under MyData > Personal Data > Global Tax and Legal Information > Enter OTP > drop down menu select TFN and Select Fund- Australia> Add Super fund details > add> delete the existing and then add new details";
    public static txtPersonalDetailsBottomNoteUSNew: string="This section needs to be filled and submitted within 2 days from your Date of Joining. Please ensure you do so in this timeline.";
    public static txtBankDetailsMainSectionCode:string="BankDetailsMain";
    public static txtBenefitDetailsMainSectionCode:string="BenefitDetailsMain";
    public static txtContractDocumentMainSectionCode:string="ContractDocumentMain";
    public static txtNRICMainSectionCode:string="NRIC";
    public static txtContractDocumentNethBelgiumSectionCode:string="ContractDocumentNethBelgium";
    public static txtCorpCardMainSectionCode:string="CorpCardMain";
    public static txtCorpCardNethBelgiumSectionCode:string="CorpCardNethBelgium";
    public static txtEducationDetailsMainSectionCode:string="EducationDetailsMain";
    public static txtEmergencyContactMainSectionCode:string="EmergencyContactMain";
    public static txtFamilyDetailsMainSectionCode:string="FamilyDetailsMain";
    public static txtHolidayAttestationMainSectionCode:string="HolidayAttestationMain";
    public static txtCriminalRecordSectionCode:string="CriminalRecordMain"
    public static txtIDbadgeMainSectionCode:string="IDbadgeMain";
    public static txtInsuranceDetailsNetherlandsSectionCode="InsuranceDetailsNetherlands";
    public static txtInsuranceDetailsRestSectionCode:string="InsuranceDetailsRest";
    public static txtHealthInsuranceDetailsSectionCode:string="HealthInsuranceDetails";
    public static txtInsuranceEnrollmentDetailsSectionCode:string="InsuranceEnrollmentDetails";
    public static txtKiwiSaverDetailsMainSectionCode:string="KiwiSaverDetailsMain";
    public static txtMedicalInsuranceMainSectionCode:string="MedicalInsuranceMain";
    public static txtDisabilityMainSectionCode:string="DisabilityMain";
    public static txtPassportDetailsMainSectionCode:string="PassportDetailsMain";
    public static txtPersonalDetailsMainSectionCode:string="PersonalDetailsMain";
    public static txtNationalIdDetailsMainSectionCode:string="NationalIdDetailsMain"
    public static txtPolicyDetailsMainSectionCode:string="PolicyDetailsMain";
    public static txtPolicyDetailsNethBelgiumSectionCode:string="PolicyDetailsNethBelgium";
    public static txtResidencePermitMainSectionCode:string="ResidencePermitMain";
    public static txtSSNDetailsMainNetherlandsSectionCode:string="SSNDetailsMainNetherlands";
    public static txtSSNDetailsMainRestSectionCode:string="SSNDetailsMainRest";
    public static txtTaxDetailsMainSectionCode:string="TaxDetailsMain";
    public static txtTaxDetailsNetherlandsSectionCode:string="TaxDetailsNetherlands";
    public static txtWorkExpDetailsMainSectionCode:string="WorkExpDetailsMain";
    public static txtRelocationMainSectionCode:string="RelocationMain";
    public static txtTravelDetailsMainSectionCode:string="TravelDetailsMain";
    public static txtPostjoiningBenefitsMainDetailsSC:string="PostjoiningBenefitsMainDetails";
    public static txtPostjoiningMembersChangeDetailsSC:string="PostjoiningMembersChangeDetails";
    public static txtBankDetailsUSSectionCode:string="BankDetailsUS";
    public static txtCorpCardUSSectionCode:string="CorpCardUS";
    public static txtEligibilityVerificationUSSectionCode:string="EligibilityVerificationUS";
    public static txtEmergencyContactUSSectionCode:string="EmergencyContactUS";
    public static txtIDbadgeUSSectionCode:string="IDbadgeUS";
    public static txtPersonalDetailsUSSC:string="PersonalDetailsUS";
    public static txtResidentialAddressUSSectionCode:string="ResidentialAddressUS";
    public static txtSSNUSSectionCode:string="SSNUS";
    public static txtInsuranceUSSectionCode:string="InsuranceUS";
    public static txtW4FormeUSSectionCode:string="W4FormeUS";
    public static txtBankDetailsUKSectionCode:string="BankDetailsUK";
    public static txtEmergencyContactUKSectionCode:string="EmergencyContactUK";
    public static txtIDbadgeUKSectionCode:string="IDbadgeUK";
    public static txtInsuranceDetailsUKSectionCode:string="InsuranceDetailsUK";
    public static txtPersonalDetailsUKSectionCode:string="PersonalDetailsUK";
    public static txtPolicyDetailsUKSectionCode:string="PolicyDetailsUK";
    public static txtResidentialAddressUKSectionCode:String="ResidentialAddressUK";
    public static txtTaxDetailsUKSectionCode:string="TaxDetailsUK";
    public static txtPersonalDetailsIndiaSectionCode: string="PersonalDetailsIndia";
    public static txtBGVMssage: string="BGV";
    public static txtFamilyDetailsIndiaSectionCode: string="FamilyDetailsIndia";
    public static txtDisablityIndiaSectionCode:string="DisabilityDetailsIndia";
    public static txtEducationIndiaSectionCode: string="EducationDetailsIndia";
    public static txtWorkExperienceIndiaSectionCode: string="WorkExperienceDetailsIndia";
    public static txtWorkEducationGapIndiaSectionCode: string="WorkEducationGapDetailsIndia";
    public static txtNSRIndiaSectionCode: string="NSRIndia";
    public static txtPassportIndiaSectionCode: string="PassportDetailsIndia";
    public static txtPANIndiaSectionCode: string="PANDetailsIndia";
    public static txtAadharCardIndiaSectionCode: string="AadharDetailsIndia";
    public static txtAddressIndiaSectionCode: string="AddressDetailsIndia";
    public static txtBankIndiaSectionCode: string="BankDetailsIndia";
    public static txtNominationIndiaSectionCode: string="NominationDetailsIndia";
    public static txtIDBadgeIndiaSectionCode: string="IDBadgeDetailsIndia";
    public static txtIDCardIndiaSectionCode: string="IDcardIndia";
    public static txtPlaceholderIndiaSectionCode: string="PlaceholderDetailsIndia";
    public static txtNatsIndiaSectionCode: string="UANDetailsIndia";
    public static txtStatutoryIndiaSectionCode: string="StatutoryDetailsIndia";
    public static txtAuthenticationIndiaSectionCode: string="AuthenticationDetailsIndia";
    public static txtAchievementsIndiaSectionCode:string="AchievementsDetailsIndia";
    public static txtAspirationIndiaSectionCode: string="AspirationDetailsIndia";
    public static txtMedicalIndiaSectionCode:string="MedicalDetailsIndia";
    public static txtIDbadgeUSBPOSectionCode: string="IDbadgeUSBPO";
    public static txtPersonalDetailsUSBPO: string="PersonalDetailsUSBPO";
    public static txtResidentialAddressUSBPOSectionCode: string="ResidentialAddressUSBPO";
    public static txtEmergencyContactUSBPOSectionCode: string="EmergencyContactUSBPO";
    public static txtBankDetailsUSBPOSectionCode: string="BankDetailsUSBPO";
    public static txtEligibilityVerificationUSBPOSectionCode: string="EligibilityVerificationUSBPO";
    public static txtSSNUSBPOSectionCode: string="SSNUSBPO";
    public static txtCorpCardUSBPOUSSectionCode: string="CorpCardUSBPO";
    public static txtInsuranceUSBPOSectionCode: string="InsuranceUSBPO";
    public static txtW4FormeUSBPOSectionCode: string="W4FormeUSBPO";
    public static txtPolicyUSBPOSectionCode: string="PolicyDetailsUSBPO";
    public static txtIDbadgeAUSectionCode: string="IDbadgeAU";
    public static txtEmergencyContactAUSectionCode: string="EmergencyContactAU";
    public static txtBankDetailsAUSectionCode: string="BankDetailsAU";
    public static txtDisabilityAUSectionCode:string="DisabilityAU";
    public static txtResidentialAddressAUSectionCode: string="ResidentialAddressAU";
    public static txtSuperAnnDetailsAUSectionCode: string="SuperAnnuationDetailsAU";
  public static txtPassportDetailsIndiaTopNote: string = "Providing your passport details will help with a faster completion of the background check and will make you travel ready.";
  public static txtSSNTitleILIGAM: string="Share your TIN (Tax Identification Number) details for payroll processing";
  public static txtSSNTopNoteILIGAM: string="Valid TIN details will ensure that your payroll is processed on time";
  public static txtSSNTempTopNoteILIGAM: string="Verify if your name matches TIN details. In case of a mismatch, kindly update as per TIN.";
  public static txtaccomadationIndiaBottomNote:string="You are required to attend your Induction physically at your preferred location without exceptions. Please contact your Recruiter for further support with rescheduling your date of joining if needed.";  
  public static txtSSNReqCanada: string="Please complete the SIN section first.";
  public static txtNricMainTopNote :string;
  public static  txtKiwiNricMainTitle : string;
  public static txtpassportDetailsBottomNote:string;
  public static txtSuperAnnuationBPMAusNoteText: string="Hi, if you want to update your superannuation details, please complete the superannuation choice form and email to olivia.chan@infosys.com & BPM.AustraliaLnA@infosys.com";
  public static txtresipermitTitle:string;
  public static txtresipermitTopNote :string;
  public static txtresipermitUKSectionCode:string;
  public static txtjoiningdocsSectionCode:"JoiningdocsUK";
  public static txtjoiningdocsTopNote:string;
  public static txtjoiningdocsTitle:string;
  public static txtPassportDetailsUKSectionCode:"PassportUK"



}
