export class StepOneFlow
{
    public orderId : number;
    public iconId : string;
    public iconClass : string;
    public iconClicked : string;
    public iconRoute : string;
    public flgPrePost : string;
    public nextRoute : string;

}

export class GetStepOneDetails 
{
    public candidateID : number;
    public txtCountry : string;
    public txtCompanyCode : string;
    public txtJobLevel : string;
    public flgMobile : string;
    public flgPrePost : string;
}