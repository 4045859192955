import { Injectable, HostListener } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from 
'@angular/router';
import { Observable } from 'rxjs/Observable';
import { AuthService } from './auth.service';
import {Router} from '@angular/router';
import { AppService } from '../app.service';

@Injectable()
export class AuthGuardIndia implements CanActivate {
  
  constructor(private auth: AuthService,  private appService: AppService,
    private myRoute: Router,
    ){
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if(this.auth.isLoggedIn() && localStorage.getItem("Region").toUpperCase()==="IN"){
        return true;
    }
    else{
        this.myRoute.navigate(["login"]);
      return true;
    }
  }

  
}