import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { Question } from '../models/Question.model';
import { Configuration, APIUrls } from '../configuration';
import { LPUSCustomHttpClient } from '../lpus/lpus.interceptor';
import { UpdateResult } from '../lpus/models/UpdateResult.model';
import { Notification } from '../lpus/models/notification.model';
import { RoutingNumberDetails } from '../models/RoutingNumberDetails.model';
import { Country } from '../lpus/models/country.model';
import { MainBranchDetails } from '../lpmain/Model-Classes/MainBranchDetails.Model';
import { UserCredentials } from '../lpmain/Model-Classes/Residence.model';




@Injectable()
export class FeedbackService {
    
    
    constructor(private _http: LPUSCustomHttpClient) { }

    GetNotifications(notifs:Notification):Observable<Notification[]>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<Notification[]>(Configuration.ResServerBaseURL+APIUrls.GetNotifications,notifs,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }

    GetNotificationsUK(notifs:Notification):Observable<Notification[]>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<Notification[]>(Configuration.ResServerBaseURL+APIUrls.GetNotificationsUK,notifs,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }

    GetNotificationsAUS(notifs:Notification):Observable<Notification[]>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<Notification[]>(Configuration.ResServerBaseURL+APIUrls.GetNotificationsAUS,notifs,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }

    GetFeedbackContentDetails  (user:Question):Observable<Question[]>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJmOTM1NDFmOC1iMTJmLTQ0ZTgtYjFjYS1mZTU0ZTA1ZGZkOGIiLCJleHAiOjE1NjM4NjA0NjEsIm5iZiI6MCwiaWF0IjoxNTYyNTY0NDYxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiYmY5Nzk1MDAtMDkxMi00MjE4LTk4MGEtODI2M2E3NzdjZDVhIiwiYXV0aF90aW1lIjoxNTYyNTY0NDYwLCJzZXNzaW9uX3N0YXRlIjoiMDI5YWI2YWEtNTE4My00MzE3LTlhMjUtZWZjNDQ0YzY4YjI5IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.UHdTYZKRKeGuhgrdfdEMEzhVn8djhkLJfeiUJp_5YdL5s6V84-JLS1q0thqBgKTfqpM0bbwMwWbo7SGWY8h-LPZhqJFDLwOXVv3GovuWOw1F4QDRPOhtuG4crDryKlo7c1dS00YT9QhuUzC7DHG37MlYrz9TwqarGVE3eEQKnmsJ728WeIDoiMzhEZ8HZlFn1PdQ5lpDMDhNCZvzu3KrKQEDbrUJUEU2Owms0vclQJrEbP3cFWvAJAIOxj8-DVqC50MLr6JceeWVADz9pegGK2_P0bZ9eQWzzsCkXp2yFpKzeqAzRzjT233WVwKAxqV5t8bdyWJDy4Y2MdZAf39p6Q');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<Question[]>(Configuration.ResServerBaseURL+APIUrls.GetFeedbackContentDetails,{"FeedBackValue":user.FeedBackValue},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }

    GetFeedbackResponse  (day:string):Observable<UpdateResult>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJmOTM1NDFmOC1iMTJmLTQ0ZTgtYjFjYS1mZTU0ZTA1ZGZkOGIiLCJleHAiOjE1NjM4NjA0NjEsIm5iZiI6MCwiaWF0IjoxNTYyNTY0NDYxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiYmY5Nzk1MDAtMDkxMi00MjE4LTk4MGEtODI2M2E3NzdjZDVhIiwiYXV0aF90aW1lIjoxNTYyNTY0NDYwLCJzZXNzaW9uX3N0YXRlIjoiMDI5YWI2YWEtNTE4My00MzE3LTlhMjUtZWZjNDQ0YzY4YjI5IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.UHdTYZKRKeGuhgrdfdEMEzhVn8djhkLJfeiUJp_5YdL5s6V84-JLS1q0thqBgKTfqpM0bbwMwWbo7SGWY8h-LPZhqJFDLwOXVv3GovuWOw1F4QDRPOhtuG4crDryKlo7c1dS00YT9QhuUzC7DHG37MlYrz9TwqarGVE3eEQKnmsJ728WeIDoiMzhEZ8HZlFn1PdQ5lpDMDhNCZvzu3KrKQEDbrUJUEU2Owms0vclQJrEbP3cFWvAJAIOxj8-DVqC50MLr6JceeWVADz9pegGK2_P0bZ9eQWzzsCkXp2yFpKzeqAzRzjT233WVwKAxqV5t8bdyWJDy4Y2MdZAf39p6Q');
        headers=headers.append('Content-Type','application/json')
        //console.log(localStorage.getItem("CandEmpNo"));
        return this._http.post<UpdateResult>(Configuration.ResServerBaseURL+APIUrls.GetFeedbackResponse,{"Empno":localStorage.getItem("CandEmpNo"),"strDay":day},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }
  
    InsertFeedbackResponseDetails  (ques:Question[]):Observable<UpdateResult>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJmOTM1NDFmOC1iMTJmLTQ0ZTgtYjFjYS1mZTU0ZTA1ZGZkOGIiLCJleHAiOjE1NjM4NjA0NjEsIm5iZiI6MCwiaWF0IjoxNTYyNTY0NDYxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiYmY5Nzk1MDAtMDkxMi00MjE4LTk4MGEtODI2M2E3NzdjZDVhIiwiYXV0aF90aW1lIjoxNTYyNTY0NDYwLCJzZXNzaW9uX3N0YXRlIjoiMDI5YWI2YWEtNTE4My00MzE3LTlhMjUtZWZjNDQ0YzY4YjI5IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.UHdTYZKRKeGuhgrdfdEMEzhVn8djhkLJfeiUJp_5YdL5s6V84-JLS1q0thqBgKTfqpM0bbwMwWbo7SGWY8h-LPZhqJFDLwOXVv3GovuWOw1F4QDRPOhtuG4crDryKlo7c1dS00YT9QhuUzC7DHG37MlYrz9TwqarGVE3eEQKnmsJ728WeIDoiMzhEZ8HZlFn1PdQ5lpDMDhNCZvzu3KrKQEDbrUJUEU2Owms0vclQJrEbP3cFWvAJAIOxj8-DVqC50MLr6JceeWVADz9pegGK2_P0bZ9eQWzzsCkXp2yFpKzeqAzRzjT233WVwKAxqV5t8bdyWJDy4Y2MdZAf39p6Q');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<UpdateResult>(Configuration.ResServerBaseURL+APIUrls.InsertFeedbackResponseDetails,ques,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }

    InsertRoutingNumberDetails  (details : RoutingNumberDetails):Observable<string>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJmOTM1NDFmOC1iMTJmLTQ0ZTgtYjFjYS1mZTU0ZTA1ZGZkOGIiLCJleHAiOjE1NjM4NjA0NjEsIm5iZiI6MCwiaWF0IjoxNTYyNTY0NDYxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiYmY5Nzk1MDAtMDkxMi00MjE4LTk4MGEtODI2M2E3NzdjZDVhIiwiYXV0aF90aW1lIjoxNTYyNTY0NDYwLCJzZXNzaW9uX3N0YXRlIjoiMDI5YWI2YWEtNTE4My00MzE3LTlhMjUtZWZjNDQ0YzY4YjI5IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.UHdTYZKRKeGuhgrdfdEMEzhVn8djhkLJfeiUJp_5YdL5s6V84-JLS1q0thqBgKTfqpM0bbwMwWbo7SGWY8h-LPZhqJFDLwOXVv3GovuWOw1F4QDRPOhtuG4crDryKlo7c1dS00YT9QhuUzC7DHG37MlYrz9TwqarGVE3eEQKnmsJ728WeIDoiMzhEZ8HZlFn1PdQ5lpDMDhNCZvzu3KrKQEDbrUJUEU2Owms0vclQJrEbP3cFWvAJAIOxj8-DVqC50MLr6JceeWVADz9pegGK2_P0bZ9eQWzzsCkXp2yFpKzeqAzRzjT233WVwKAxqV5t8bdyWJDy4Y2MdZAf39p6Q');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<string>(Configuration.ResServerBaseURL+APIUrls.InsertRoutingNumberDetails,details,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }
    GetCountry():Observable<Country[]> {
        return this._http.post<Country[]>(Configuration.ResServerBaseURL+APIUrls.GetCountryUK,null).pipe(map(
        (res) => {
        //console.log(res);
        return res;
    }
    ))  
    }
    GetBranchDetails(txtCountryCode:string):Observable<MainBranchDetails[]>{   
        return this._http.post<MainBranchDetails[]>(Configuration.ResServerBaseURL+APIUrls.GetBranchDetailsUK,{"countryCode":txtCountryCode}).pipe(map(
            (res) => {
            //console.log(res);
            return res;
            }
            )) 
    }
    GetRoutingNumberDetails(user:UserCredentials):Observable<RoutingNumberDetails[]>{
    
        return this._http.post<RoutingNumberDetails[]>(Configuration.ResServerBaseURL+APIUrls.GetRoutingNumberDetails, user,{
        
        }).pipe(map(
        (res) => {
        return res;
        }))
      }
}