import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { lpUserCredentials } from '../models/lpUserCredentials';
import { CandidateAdvisorImage } from '../models/candidateAdvisorImage';
import { Candidatedata } from '../models/Candidatedata.model';
import { OnboardingAdvisorDetail } from '../models/OnboardingAdvisorDetail.model';
import { USPreJoiningLogin } from '../models/USPreJoiningLogin.model';
import { StepCompletion } from '../models/Candidatedata.model';
import { LPUSCustomHttpClient } from '../lpus.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { SeniorExecDetails } from 'src/app/lpus/models/policy.model';
import { GetAttendanceDetails, UpdateAttendanceDetails, DatatoGetDetails, PolicyCount } from '../models/Attendace-us.model';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';


@Injectable({
    providedIn: 'root'
})

export class DashboardService {
  seeAll:boolean;
  showProceedScreen:boolean=true;
  OnboardPercent: StepCompletion[] = [];
  constructor(private http: LPUSCustomHttpClient){
    }
;

private Deccflag = new BehaviorSubject<boolean>(false);
Brcast1 = this.Deccflag.asObservable();

NewDecFlag(newFlg) {
  this.Deccflag.next(newFlg);
}
    getOnboardingPercentage():Observable<StepCompletion[]>{
      // console.log(Configuration.ResServerBaseURL+APIUrls.getOnboardingPercentage)
      
      var Headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
      Headers=Headers.append('Content-Type','application/json')
      return this.http.post<StepCompletion[]>(Configuration.ResServerBaseURL+APIUrls.getOnboardingPercentage,{"CandidateEmail":localStorage.getItem("CandidateEmailID"),"CandidateId":localStorage.getItem("CandidateID")},{
        headers:Headers
      }).pipe(map(
        (res) => {
          
          return res;
        }
      ))
    }

 

    fnGetCandPhoto(userCredentials:lpUserCredentials): Observable<CandidateAdvisorImage> {
      var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
      headers=headers.append('Content-Type','application/json')
      return this.http.post<CandidateAdvisorImage>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhoto,{"CandidateId":userCredentials.CandidateID},{
      headers:headers
      }).pipe(map(
      (res) => {
      return res;
  } 
))  
}
GetOnboardingDetailsNew(candidateData:Candidatedata): Observable<OnboardingAdvisorDetail> {
  var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<OnboardingAdvisorDetail>(Configuration.ResServerBaseURL+APIUrls.GetOnboardingDetails,{"CandidCandLocation":candidateData.CandLocation,"Source":candidateData.Source},{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
} 
))  
}
GetCandidateDetailsAfterAuthentication(candidateData:Candidatedata): Observable<USPreJoiningLogin[]> {
  var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<USPreJoiningLogin[]>(Configuration.ResServerBaseURL+APIUrls.getCandidateDetailsAfterAuthenticationUK,{"CandidCandLocation":candidateData.CandLocation,"Source":candidateData.Source},{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
} 
))  
}
GETSeniorExecDetails():Observable<SeniorExecDetails[]> 
{
  var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
  headers=headers.append('Content-Type','application/json');
  return this.http.post<SeniorExecDetails[]>(Configuration.ResServerBaseURL+APIUrls.GETSeniorExecDetails,null,{headers:headers}).pipe(map((res) => {return res;}))    
}

fetchPolicyDetails(): Observable<PolicyCount> {
  var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
  headers=headers.append('Content-Type','application/json')
  return this.http.post<PolicyCount>(Configuration.ResServerBaseURL+APIUrls.fetchPolicyDetails,{ "CandidateId": localStorage.getItem("CandidateID")},{
  headers:headers
  }).pipe(map(
  (res) => {
  return res;
} 
))  
}
// GetAttendanceDetails(input:DatatoGetDetails):Observable<GetAttendanceDetails[]>{
//   var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
//   headers=headers.append('Content-Type','application/json')
//   return this.http.post<GetAttendanceDetails[]>(Configuration.ResServerBaseURL+APIUrls.GetAttendanceDetails,input,{
//   headers:headers
//   }).pipe(map(
//   (res) => {
//   return res;
//   }))
// }

// InsertAttendanceDetails(input:UpdateAttendanceDetails):Observable<Number>{
//   var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
//   headers=headers.append('Content-Type','application/json')
//   return this.http.post<Number>(Configuration.ResServerBaseURL+APIUrls.InsertAttendanceDetails,input,{
//   headers:headers
//   }).pipe(map(
//   (res) => {
//   return res;
//   }))
// }
// GetEnteredAttendanceDetails(input:UpdateAttendanceDetails):Observable<UpdateAttendanceDetails[]>{
//   var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
//   headers=headers.append('Content-Type','application/json')
//   return this.http.post<UpdateAttendanceDetails[]>(Configuration.ResServerBaseURL+APIUrls.GetEnteredAttendanceDetails,input,{
//   headers:headers
//   }).pipe(map(
//   (res) => {
//   return res;
//   }))
// }

}