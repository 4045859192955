import { Component, OnInit, Inject } from '@angular/core';
import { ProfileService } from './profile.service';
import { LpusBankingService } from '../step1onboarding/banking/banking.service';
import { BankDetails } from '../models/bankdetails.model';
import { PersonalDetails } from '../models/personaldetails.model';
import { ResiaddressService } from '../step1onboarding/resiaddress/resiaddress.service';
import { EmpAddress } from '../models/empAddress.model';
import { IdbadgeComponent } from '../step1onboarding/idbadge/idbadge.component';
import { IdBadgeService } from '../step1onboarding/idbadge/idbadge.service';
import { lpUserCredentials } from '../models/lpUserCredentials';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../app.service';
import { AuthService } from '../../login/auth.service';
import { Router } from '@angular/router';
import { lpUKUserCredentials } from '../../lpuk/models/lpukUserCredentials.model';
import { IdbadgeComponentindia } from '../../lpindia/step1onboarding-india/idbadge-india/idbadgeindia.component';
import { NgxSpinnerService } from 'ngx-spinner';

import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { updatePhoneNumberFromProfile } from '../models/personaldetails.model';
import { NgForm } from '@angular/forms';
import { AlertService } from 'src/app/utils/alert/alert.service';

declare function LoadAccordianJs() : any;
declare function WebViewLogout() : any;
declare function RemoveDefaultBadgeImage():any;
declare function RemoveClassHeaderFixed():any;
declare function AddBodyscroll():any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  // personaldata:PersonalDetails[];
  personaldata: any;
  details: BankDetails;
  bankdetails: BankDetails[];
  bank2: boolean;
  showMsgDiv: boolean;
  errorMsg: string;
  detailsAdded: string;
  AccountHolderName: string;
  BankAccountNumber: string;
  RaccountNumber: string;
  RoutingNumber: string;
  accounttype1: string;
  accounttype2: string;
  accounttype3: string;
  Amount: number;
  AccountHolderNameB2: string;
  BankAccountNumberB2: string;
  RaccountNumberB2: string;
  RoutingNumberB2: string;
  AmountB2: number;
  AccountHolderNameB3: string;
  BankAccountNumberB3: string;
  RaccountNumberB3: string;
  RoutingNumberB3: string;
  AmountB3: number;
  emailId: string;
  id: String = "";
  BankCheck: boolean;
  checking: boolean;
  saving: boolean;
  AccType: string;
  BankAvailability: boolean;
  public BankOneCode: string;
  public BranchOneCode: string;
  OTP: boolean;
  OTPvalue: string;
  submitbutton: boolean;
  candidateID: Number;
  dateofbirth: String;
  contact: String;
  contactIN: String;
  nationality: String;
  firstname: String=localStorage.getItem("CandFirstName");
  lastname: String= localStorage.getItem("CandLastName");
  //emergency:String;
  designation: String=localStorage.getItem("RoleDesignation");
  maritalstatus: String;
  addresslist: EmpAddress[];
  pcountry: String;
  presifrom: String;
  pstreet: String;
  parea: String;
  psuite: String;
  pzip: String;
  pstate: String;
  pcity: String;
  pphone: String;
  countrycode: String;
  addresstype: string = "6";
  email: string = localStorage.getItem("CandidateEmailID");
  imgURL: String = "../../../assets/images/icon_frame_1.png";
  show: Boolean = true;
  upload: Boolean;
  bank1istrue: Boolean = false;
  bank2istrue: Boolean = false;
  bank3istrue: Boolean = false;
  mobile: Boolean;
  credenrtials = new lpUserCredentials;
  Vanguard: Boolean = false;
  editable: boolean = false;
  updatePhoneNumberDetails = new updatePhoneNumberFromProfile;
  token: string;
  common:boolean = localStorage.getItem("CompanyCode").toUpperCase()=="COMMON" || localStorage.getItem("Region").toUpperCase()=="CANADA" || localStorage.getItem("CompanyCode").toUpperCase()=="IN"  || localStorage.getItem("CompanyCode").toUpperCase()=="IL UK" || localStorage.getItem("CompanyCode").toUpperCase()=="BPM UK" || localStorage.getItem("CompanyCode").toUpperCase()=="IL AUSTRALIA" || localStorage.getItem("CompanyCode").toUpperCase()=="US" || localStorage.getItem("CompanyCode").toUpperCase()=="BPM AUSTRALIA";
  location:string =localStorage.getItem("Location");

  constructor(
    private _profileservice: ProfileService, 
    private _usBankService: LpusBankingService,
    private _addressService: ResiaddressService, 
    private _IdBadgeService: IdBadgeService,
    private breakpointObserver: BreakpointObserver, 
    private appService: AppService,
    private authService: AuthService, 
    private router: Router,
    public dialog: MatDialog,
    private alert: AlertService,
    private spinner:NgxSpinnerService) {
  }

  ngOnInit() {
    LoadAccordianJs();
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //console.log('Inside app component observer');
          this.appService.setIsMobile(true);
          this.mobile = true;
        } else {
          //console.log('Inside app component observer'); 
          this.appService.setIsMobile(false);
          this.mobile = false;
        }
      });

    //Changes by Divyanshu for editable phone number field IN IL Fresher
    if ((localStorage.getItem("Region").toUpperCase() == "IN") &&
      (localStorage.getItem("Identification").toUpperCase() == "FRESHER")) {
      console.log('IN,FRESHER');
      this.editable = true;
      this.token = localStorage.getItem("Token");
      this.authService.GetUserDataForIndia(this.token).subscribe((res) => {
        this.contactIN = res.LPFetchCandidateDetailsOnLogin[0].ContactNumber;
      })
    }

    this.candidateID = Number(localStorage.getItem('CandidateID'));
    this.emailId = localStorage.getItem('CandidateEmailID');
    if (localStorage.getItem("Region") == "US" || localStorage.getItem("Region") == "IBPO" || localStorage.getItem("Region").toUpperCase() == "MCCAM" 
    || localStorage.getItem("Region") == "KALIDS" || localStorage.getItem("Region").toUpperCase() == "CANADA" || localStorage.getItem("Region") == "IBAUSA" || localStorage.getItem("Region") == "IPS"
    || localStorage.getItem("CompanyCode").toUpperCase()=="US"   //Added by Vibhu
    ) {
       this.spinner.show();
      this.Vanguard = localStorage.getItem("CompanyNameIdentifier").toUpperCase() == "VANGUARD";
      this.candidateID = Number(localStorage.getItem('CandidateID'));
      this.emailId = localStorage.getItem('CandidateEmailID');
      this._profileservice.GetPersonalDetails().subscribe(
        responsePersonaldata => {
          if (responsePersonaldata != null && responsePersonaldata.length > 0) {
            this.personaldata = responsePersonaldata;
            for (var arr of this.personaldata) {
              this.dateofbirth = arr.DateOfBirth;
              this.contact = arr.Contact;
              this.nationality = arr.Nationality;
              this.maritalstatus = arr.MaritalStatusValue;
              this.firstname = arr.FirstName;
              this.lastname = arr.LastName;
              // this.id=arr.EmailId;

            }
          }
        });

      this.credenrtials.CandidateID = Number(localStorage.getItem("CandidateID"));
      this.credenrtials.UserName = localStorage.getItem("CandidateEmailID");
      this._profileservice.fnGetCandPhoto().subscribe(
        (res) => {
          //console.log(res);
          if (res != null) {
            if (res.CandAdvImg != null) {
              this.imgURL = 'data:image/jpg;base64,' + res.CandAdvImg;
              IdbadgeComponent.fileAsBasestring = String(this.imgURL).substr(22,);
              RemoveDefaultBadgeImage();
              this.upload = true;
              this.show = false;
               this.spinner.hide();
            }
            else {
              this.imgURL = "assets/images/icon_frame_1.png";
              this.show = true;
              this.upload = false;
               this.spinner.hide();
            }
          }
        }
      );
    }
    else if (localStorage.getItem("Region") == "IL UK" || localStorage.getItem("Region") == "BPM UK" || localStorage.getItem("Region") == "IL AUSTRALIA" ||
    localStorage.getItem("Region").toUpperCase() == "IL SWITZERLAND" || localStorage.getItem("Region").toUpperCase() == "IL GERMANY" || 
    localStorage.getItem("Region").toUpperCase() == "IL NEW ZEALAND" || localStorage.getItem("Region").toUpperCase() == "IL  BELGIUM" ||
    localStorage.getItem("Region").toUpperCase() =="IL NETHERLANDS" || localStorage.getItem("Region").toUpperCase() =="IL SINGAPORE" || localStorage.getItem("Region") == "BPM AUSTRALIA"
    || localStorage.getItem("CompanyCode").toUpperCase()=="COMMON"   //Added by Vibhu
    ) {
       this.spinner.show();
      let credential = new lpUKUserCredentials();
      credential.CandidateID = Number(localStorage.getItem("CandidateID"));
      credential.UserName = localStorage.getItem("CandidateEmailID");
      this._profileservice.GetPersonalDetailsforEMEA()
        .subscribe(
          responsePersonaldata => {
            if (responsePersonaldata != null && responsePersonaldata.length > 0) {
              this.personaldata = responsePersonaldata;
              for (var arr of this.personaldata) {
                this.contact = arr.Contact;
                this.firstname = arr.FirstName;
                this.lastname = arr.LastName;
                // this.id=arr.EmailId;

              }
            }
          }
        );

      this._profileservice.fnGetCandPhotoforEMEA().subscribe(
        (res) => {
          //console.log(res);
          if (res.CandAdvImg != null) {
            this.imgURL = 'data:image/jpg;base64,' + res.CandAdvImg;
            IdbadgeComponent.fileAsBasestring = String(this.imgURL).substr(22,);
             this.spinner.hide();
            this.upload = true;
            this.show = false;
          }
          else {
            this.show = true;
            this.upload = false;
             this.spinner.hide();
          }
        }
      );
    }

    else {
      this.candidateID = Number(localStorage.getItem("intCandidateId"));
       this.spinner.show();
      this.emailId = localStorage.getItem("txtEmailId");
      this.firstname = localStorage.getItem("txtCandFirstName");
      this.lastname = localStorage.getItem("txtCandLastName");
      this.contact = localStorage.getItem("contact");


      this._profileservice.fnGetCandPhotoIN().subscribe(
        (res) => {
          //console.log(res);
          if (res != null) {
            //console.log(res);
            this.imgURL = 'data:image/jpg;base64,' + res;
            IdbadgeComponentindia.fileAsBasestring = String(this.imgURL).substr(22,);
             this.spinner.hide();
            this.upload = true;
            this.show = false;
          }
          else {
             this.spinner.hide();
          }
        }
      );
    }
  }

  updatePhone(form: NgForm) {
     this.spinner.show();
    console.log("Inside update");
    this.updatePhoneNumberDetails.intCandidateId = Number(localStorage.getItem("intCandidateId"));
    this.updatePhoneNumberDetails.txtPhoneNumber = form.value.contactNo;
    console.log(this.contact);
    console.log(this.updatePhoneNumberDetails.txtPhoneNumber);
    console.log(form.value.contactNo);
    this._profileservice.updatePhoneNumberFromProfileIN(this.updatePhoneNumberDetails).subscribe(
      res => {
        if (res == "1") {
           this.spinner.hide();
          this.alert.success("Done! Phone number details are updated.", this.mobile);
        } else {
           this.spinner.hide();
          this.alert.error("Some error occurred. Please try again.", this.mobile);
        }
      }
    )
  }

  signOut() {
    // this.authService.logout();
    // WebViewLogout();
    const dialogRef = this.dialog.open(DialogLogout, {
      width: '350px',
      height: '216px',

    });
  }

  RemoveClassHeaderFixed() {
    AddBodyscroll();
    RemoveClassHeaderFixed();
    this.dialog.closeAll();
  }

}
 
declare function logoutdialog(): any;
@Component({
  selector: 'dialog-Logout',
  templateUrl: 'dialog-Logout.html',
})
export class DialogLogout {
  mobile: boolean;
  constructor(
    public dialogRef: MatDialogRef<DialogLogout>, private authService: AuthService, private router: Router, private Appservice: AppService) {

    this.mobile = this.Appservice.mobile;
    if (!this.mobile) { logoutdialog() }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  onYesClick(): void {
    this.dialogRef.close();
    this.authService.logout();
    //this.router.navigate(["../login"]);
    WebViewLogout();
  }


}
