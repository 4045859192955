import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './login/auth.service';
// import { environment } from 'src/environments/environment';
import { environment } from '../environments/environment.prod';
import { EnvironmentLoaderService } from './core/services/environment-loader.service'
// import  * as Keycloak  from  'keycloak-js';
import {Configuration} from './configuration'
declare var Keycloak:any;
@Injectable({
  providedIn: 'root'
})
export class KeycloakService {
  private keycloakAuth: any;
  init(): Promise<any> {
   return new Promise(async(resolve, reject) => {
    await this.setBaseData()
    console.log(environment)
      const config = {        
        'url':environment.keycloakURL,
        'realm':environment.keycloakRealm,
        'clientId':environment.keycloakClientId,
        'ssl-required': 'external',
        'resource': 'launchpad',  
        'public-client': true,
        'confidential-port': 0
      };
   
      console.log(config)
      this.keycloakAuth = new Keycloak(config);
      this.keycloakAuth.init({ onLoad: 'check-sso',checkLoginIframe: false})
        .success(() => {
          resolve(true);
        })
        .error(error => {
          console.error('An error occurred during initialization', error);
          console.log('An error log occurred during initialization', error);
          reject(false);
        });
 
    //   let initOptions = {
    //     url: environment.keycloakURL, realm: environment.keycloakRealm, clientId: environment.keycloakClientId
    // }
    // let keycloak = Keycloak(initOptions);
    // keycloak.init({ onLoad: "login-required" }).success((auth) => {
    //   console.log(auth)
    //     if (!auth) {
    //         window.location.reload();
    //     } else {
    //         // // console.log("Authenticated");
    //         // keycloak.loadUserProfile().success((loggedInUser)  =>  {
   
    //         //           localStorage.setItem("loggedInUser",  JSON.stringify(loggedInUser));
    //         //    }
    //         // )
    //     }
    //   })
      });
  }
   getidToken(): string{
     //console.log(this.keycloakAuth.idToken + "  "+ "Get keycloakauth idToken line no. 50");
     return this.keycloakAuth.idToken;
   }
  getToken(): string {
    localStorage.setItem("idToken1",this.keycloakAuth.idToken);
   // console.log(this.keycloakAuth.url + "  "  + "this is url");
   // console.log((localStorage.getItem("accessToken")+ "  "+ "Get keycloakauth idToken line no. 55"));
    return this.keycloakAuth.token;
  }
  getRefToken(): string {
    return this.keycloakAuth.refreshToken;
  }
  Logout()
  {
   // this.keycloakAuth.logout();
    let token1=localStorage.getItem("idToken1");
    //console.log(token1);
    //let token1=this.kcService1.getKeycloakInstance().idToken;
    window.location.replace(environment.keycloakURL + "realms/launchpad/protocol/openid-connect/logout?post_logout_redirect_uri="+window.location.origin+"&id_token_hint="+token1);
    //window.location.replace(this.keycloakAuth.url+"/realms/"+this.keycloakAuth.realm+"/protocol/openid-connect/logout?post_logout_redirect_uri="+window.location.origin+"&id_token_hint="+token1);
    localStorage.clear();
  }
  Login(){
    this.keycloakAuth.login();
    // console.log("Inside keycloakAuth component")
  }
  constructor(private envLoaderSerive:EnvironmentLoaderService) {
  }
 
  async setBaseData(){
    let envjson = await this.envLoaderSerive.getEnvData();
    console.log(envjson)
    environment.resServerBaseURL = envjson['ResServerBaseURL'];
    environment.resServerBaseURLIndia = envjson['ResServerBaseURLIndia'];
    environment.notificationServerBaseURL = envjson['NotificationServerBaseURL'];
    environment.keycloakURL = envjson['KeycloakURL'];
    environment.keycloakClientId = envjson['KeycloakClientId'];
    environment.keycloakRealm = envjson['KeycloakRealm'];
    environment.telemetryServiceHost = envjson['telemetryServiceHost'];
    environment.telemetryEndPoint = envjson['telemetryEndPoint'];
    Configuration.ResServerBaseURL = environment.resServerBaseURL;
    Configuration.ResServerBaseURLIndia = environment.resServerBaseURLIndia
    return true
  }
 
}