export class WorkExperienceUpdate
{
    public CandidateID:Number;
    public CandExDetIdID:Number;
    public strCompanyAdd:String;
    public strCompanyNo:String;
    public strEmpno:String;
    public strDepartment:String;
    public strManagerName:String;
    public strManagerNo:String;
    public strManagerMailId:String;
    public strAgency:String;
    public strReason:String;
    public strResponsibility:String;
    public strPosition:String;
    public strFromDate:String;
    public strToDate:String;
    public strDesignation:String;
    public strOrgName: String; 
    public flgInsertUpdate: String;
    public flgEmploymentInIndia: String;
    public txtCountryCode: String;
}
