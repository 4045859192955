import { Component, OnInit } from '@angular/core';
import { NgForm } from '../../../../node_modules/@angular/forms';
import { RequestACallDetails, RequestACallSlotDetails, RequestACallReasonDetails } from '../models-india/requestACallDetails';
import { RequestACall } from '../models-india/requestACall';
import { NgbDateFRParserFormatter } from '../../NgbDateFRParserFormatter';
import { HeaderIndiaService } from '../header-india/header-india.service';
import { AlertService } from '../../utils/alert/alert.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ResiaddressIndiaService } from '../step1onboarding-india/resiaddress-india/resiaddress-india.service';
import { HolidaysForIndia } from '../models-india/HolidaysForIndia.model';
import { NgxSpinnerService } from 'ngx-spinner';

declare function LoadRequestCallCloseModalWeb(): any;
declare function AddBodyscroll(): any;
declare function HideBottomBar(): any;
declare function ShowBottomBar(): any;

@Component({
  selector: 'app-request-acall',
  templateUrl: './request-acall.component.html',
  styleUrls: ['./request-acall.component.css']
})
export class RequestACallComponent implements OnInit {
  requestACall: RequestACall;
  timeError: Boolean = false;
  requestDetails: RequestACallDetails;
  errMsg: string;
  update: Boolean = false;
  reqErr: Boolean;
  success: string;
  dtString: string = "";
  otString: string = "";
  slotString: string = "";
  reString: string = "";
  mobile: boolean;
  showQuery: Boolean;
  slots: RequestACallSlotDetails[];
  reasons: RequestACallReasonDetails[];
  today = new Date();
  holidayList: HolidaysForIndia[] = [];
  minyear = this.today.getFullYear();
  holidayDate: String[] = [];
  holidayDate1: String[] = [];
  minmonth = this.today.getMonth() + 1;
  minday = this.today.getDate();
  todayDate = new Date(this.minyear, this.minmonth - 1, this.minday);

  constructor(private dateformatter: NgbDateFRParserFormatter,
    private _resiIndiaService: ResiaddressIndiaService,
    private _service: HeaderIndiaService, private alert: AlertService, private spinner:NgxSpinnerService) { }

  ngOnInit() {
    this.getRequestDetails();
    HideBottomBar()
    this._resiIndiaService.GetHolidayDetailsIN()
      .subscribe(
        response => {
          this.holidayList = response;
          // console.log(this.holidayList)         
          for (let c of this.holidayList) {
            let name = c.Date;
            //  console.log(name)
            //this.holidayDate.push(name);

            let date = name.substring(0, 3);
            // console.log(date);
            let mon = name.substring(3, 7);
            // console.log(mon);
            let year = name.substring(7, 11);
            // console.log(year);
            let day23 = mon + date + year;
            // console.log(day23);
            this.holidayDate1.push(day23);



          }

        }
      );
    //console.log(this.holidayDate1);
  }

  myFilter = (d: Date | null): boolean => {
    const day = (d || new Date()).getDay();
    return day !== 0 && day !== 6;
  }
  InsertRequestDetails(form: NgForm) {
    this.timeError = false;
    //console.log("Inside Insert method");
    this.requestACall = new RequestACall();
    this.requestACall.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
    this.requestACall.txtSlot = form.value.slotRef;
    let strtime = this.requestACall.txtSlot.split(":");
    let time = parseInt(strtime[0]);
    let currentTime = this.today.getHours();
    let selectedDate: Date = new Date(this.dateformatter.formatMatDate(form.value.dor));

    //  console.log(selectedDate);

    for (let a of this.holidayDate1) {
      if (a == selectedDate.toString().trim().substring(4, 15) || selectedDate.toString().trim().substring(0, 3).toUpperCase() == "SAT" || selectedDate.toString().trim().substring(0, 3).toUpperCase() == "SUN") {
        this.timeError = true;
        this.errMsg = "Sorry, It is a Holiday for us. Can you please select any other working date. Thank You :)";
        break;
      }
      else if (selectedDate.getFullYear() == this.today.getFullYear() && selectedDate.getMonth() == this.today.getMonth() && selectedDate.getDate() == this.today.getDate()) {
        // console.log(this.today.getTime());
        // console.log("Time",time);
        // console.log("Current Time",currentTime);
        if (time <= currentTime) {
          this.timeError = true;
          // console.log("Time Constraint");
          this.errMsg = "Please select slot that's at least 30 minutes from now.";
        }
        else {
          this.timeError = false;
          this.errMsg = "";
        }
      }


    }
    // if(selectedDate.toString().trim().substring(0,3).toUpperCase() == "SAT" || selectedDate.toString().trim().substring(0,3).toUpperCase() == "SUN")
    // {
    //  this.timeError = true;
    //  this.errMsg = "This date cannot be selected as it is not a working day";
    // }
    // else if(selectedDate.getFullYear() == this.today.getFullYear() && selectedDate.getMonth() == this.today.getMonth() && selectedDate.getDate()== this.today.getDate())
    // {
    //   // console.log(this.today.getTime());
    //  // console.log("Time",time);
    //  // console.log("Current Time",currentTime);
    //  if(time <= currentTime)
    // {
    // this.timeError = true;
    //// console.log("Time Constraint");
    //this.errMsg = "Please select slot that's at least 30 minutes from now.";
    // }
    //else
    //{
    //this.timeError = false;
    // this.errMsg = "";
    // }
    // }
    this.requestACall.dtDate = new Date((new Date(form.value.dor)).getFullYear(), (new Date(form.value.dor)).getMonth(), (new Date(form.value.dor)).getDate(), 9, 0, 0);
    this.requestACall.txtIssue = form.value.reasonRef;
    if (form.value.query1 == undefined) { this.requestACall.txtOther = ""; }
    else { this.requestACall.txtOther = form.value.query1; }
    this.requestACall.txtModifiedBy = localStorage.getItem("intCandidateId");
    this.requestACall.txtaction = "I";
    // console.log(this.requestACall);
    if (!this.timeError) {
      this.spinner.show();
      this._service.InsertRequestCallDetails(this.requestACall)
        .subscribe(
          res => {
            //console.log(res);
            this.success = res;
            this.reqErr = false;
            if (this.success == "1") {
              this.update = false;
              this.dtString = "";
              this.reString = "";
              this.slotString = "";
              this.otString = "";
              this.alert.success("Done! Details are updated.", this.mobile);
              LoadRequestCallCloseModalWeb();
            }
            else if (this.success == "31") {
              this.update = false;
              this.reqErr = true;
              this.errMsg = "Uh-oh! This slot is already occupied. Please select the next slot.";
            }
            else if (this.success == "3") {
              this.update = true;
            }
            // console.log(this.success);
            this.spinner.hide();
          }
        );
    }

  }
  UpdateRequestDetails(form: NgForm) {
    this.requestACall = new RequestACall();
    this.requestACall.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
    this.requestACall.txtSlot = form.value.slotRef;
    let strtime = this.requestACall.txtSlot.split(":");
    let time = parseInt(strtime[0]);
    let currentTime = this.today.getHours();
    let selectedDate: Date = new Date(this.dateformatter.format(form.value.dor));
    // console.log(selectedDate);

    if (selectedDate.getFullYear() == this.today.getFullYear() && selectedDate.getMonth() == this.today.getMonth() && selectedDate.getDate() == this.today.getDate()) {
      // console.log(this.today.getTime());
      // console.log("Time",time);
      // console.log("Current Time",currentTime);
      if (time <= currentTime) {
        this.timeError = true;
        // console.log("Time Constraint");
        this.errMsg = "Please select slot that's at least 30 minutes from now.";
      }

      else {
        this.timeError = false;
      }
    }
    // for(let a of this.holidayDate1){
    //   if(a==selectedDate.toString().trim().substring(4,15) || selectedDate.toString().trim().substring(0,3).toUpperCase() == "SAT" || selectedDate.toString().trim().substring(0,3).toUpperCase() == "SUN"){
    //     this.timeError = true;
    //     this.errMsg = "Sorry, It is a Holiday for us. Can you please select any other working date. Thank You :)";
    //     break;    
    //   }
    //   else if(selectedDate.getFullYear() == this.today.getFullYear() && selectedDate.getMonth() == this.today.getMonth() && selectedDate.getDate()== this.today.getDate())
    //   {
    //     // console.log(this.today.getTime());
    //     // console.log("Time",time);
    //     // console.log("Current Time",currentTime);
    //     if(time <= currentTime)
    //     {
    //       this.timeError = true;
    //       // console.log("Time Constraint");
    //       this.errMsg = "Please select slot that's at least 30 minutes from now.";
    //     }
    //     else
    //     {
    //       this.timeError = false;
    //       this.errMsg = "";
    //     }
    //   }


    // }
    this.requestACall.dtDate = new Date((new Date(form.value.dor)).getFullYear(), (new Date(form.value.dor)).getMonth(), (new Date(form.value.dor)).getDate(), 9, 0, 0);
    this.requestACall.txtIssue = form.value.reasonRef;
    if (form.value.query1 == undefined) { this.requestACall.txtOther = ""; }
    else { this.requestACall.txtOther = form.value.query1; }
    this.requestACall.txtModifiedBy = localStorage.getItem("intCandidateId");
    this.requestACall.txtaction = "U";
    // console.log(this.requestACall);
    if (!this.timeError) {
      this.spinner.show();
      this._service.InsertRequestCallDetails(this.requestACall)
        .subscribe(
          res => {
            this.success = res;
            this.reqErr = false;
            if (this.success == "1" || this.success == "2") {
              this.alert.success("Done! Details are updated.", this.mobile);
            }
            else if (this.success == "31") {
              this.reqErr = true;
              this.errMsg = "Uh-oh! This slot is already occupied. Please select the next slot.";
            }
            // console.log(this.success);
            this.update = false;
            this.dtString = "";
            this.reString = "";
            this.slotString = "";
            this.otString = "";
            this.spinner.hide();
            LoadRequestCallCloseModalWeb();
          }

        );
    }
  }
  onNoClick() {
    this.dtString = "";
    this.reString = "";
    this.slotString = "";
    this.otString = "";
    this.update = false;
    LoadRequestCallCloseModalWeb();
  }
  getRequestDetails() {
    this.spinner.show();
    // console.log(this.todayDate);
    this._service.GetRequestCallDetails()
      .subscribe(
        res => {

          if (res != null) {
            this.requestDetails = res;
            this.slots = this.requestDetails.lstSlotDetails;
            this.reasons = this.requestDetails.lstReasonDetails;
            this.spinner.hide();
          }
          else {
            this.spinner.hide();
          }
        }
      )
  }
  compare(value: string) {
    if (value == "6") {
      this.showQuery = true;
    }
    else {
      this.showQuery = false;
    }
  }
  AddBodyscroll() {
    ShowBottomBar();
    AddBodyscroll();

  }
  resetform() {
    this.update = false;
    this.dtString = "";
    this.reString = "";
    this.slotString = "";
    this.otString = "";
    this.errMsg="";
    this.AddBodyscroll();
  }


}
