import { Component, OnInit } from '@angular/core';
import { AppService } from '../../app.service';
import { DashboardService } from './dashboard.service';
import { AgendaComponent } from '../step3joiningday/agenda/agenda.component';
import { Router, ActivatedRoute } from '@angular/router';
import { Candidatedata } from '../models/Candidatedata.model';
import { PostjoiningService } from '../postjoining/postjoining.service';
import { NgxSpinnerService } from 'ngx-spinner';

import { OnboardingAdvisorDetail } from '../models/OnboardingAdvisorDetail.model';
import { StepCompletion } from '../models/Candidatedata.model';
import { HRRepresentativeService } from '../header/hr-representative/hr-representative.service';
import { AlertService } from '../../utils/alert/alert.service';
import { DatatoGetDetails} from '../models/Attendace-us.model';
import { DashboardIndiaService } from '../../lpindia/dashboard-india/dashboard-india.service'

declare function LoadStyleForIcons(i:string,c:string,a:string,s:string,b:string):any;
declare function LoadStyleForVanguardIcons(i:string,c:string,a:string,s:string,b:string,w:string,p:string,t:string,d:string,v:string):any;
declare function changePayrollIcon():any;
declare function LoadStyleForUSPendingIcons(i:string,c:string,a:string,s:string,b:string,w:string,p:string,t:string,d:string,v:string):any;
declare function changeInsuranceIcon():any;
declare function changeW4Icon():any;
declare function ChangeW4ImageVanguard():any;
declare function ChangePolicyIcon():any;
declare function ChangeIdBadgeImage() : any;
declare function ChangeEmergencyImage() : any;
declare function ChangeAddressImage() : any;
declare function ChangeDisabilityImage():any;
declare function ChangeVeteranImage():any;
declare function Changei9Image() : any;
declare function ChangeSsnImage() : any;
declare function LoadOwlCarousel() : any;
declare function LoadJsForModalBackDrop():any;
declare function LoadOwlCarouselForGallery():any;
declare function LoadOwlCarouselForGalleryForWeb():any;
declare function LoadOwlCarouselforpost():any;
declare function ChangeBankImage() : any;
declare function LoadJsForDashBoardMobileCarousel() : any;
declare function ShowBottomBar():any;
declare function OpenMailer(email) :any;
declare function OpenDialler(email) :any; 
declare function StopSpinner():any;
declare function LoadOwlCarouselForStaticWeb():any;
declare function LoadOwlCarouselForStaticWeb1():any;
declare function LoadOwlCarouselForStaticWeb2():any;
declare function AddBodyScroll_Stay():any;
declare function AddBodyscroll(): any;
declare function HideBottomBar(): any;
declare function RemoveBodyScroll_Stay():any;
declare function RemoveBodyscroll():any;
declare function LoadScrollJS():any;
declare function ChangeTaxImageVanguard(): any;
declare function OpenInfyMeUrl():any;
declare function StartModalClose1(): any;

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  mobile: boolean;
  isHidden: boolean;
  obpercent: number;
  postjoinin:boolean=Number(localStorage.getItem("DaysLeft"))<=0;
  prejoining:boolean=Number(localStorage.getItem("DaysLeft"))>0;
  days_left :boolean = Number(localStorage.getItem("DaysLeft"))<-14;
  name:String = '';
  Designation:String = '';
  name1:String=localStorage.getItem("HiringManagerName");
  i:any;
  Designation1:String;
  desktop:Boolean;
  candidateData=new Candidatedata;
  idbadge:Boolean=false;
  emergency:Boolean=false; 
  ssn:Boolean=false;
  address:Boolean=false;
  agenda:Boolean=Number(localStorage.getItem("DaysLeft"))==0;
  bank:Boolean=false;
  disable:Boolean=false;
  veteran:Boolean=false;
  EmailId:string;
  FirstName:string;
  email1:String=localStorage.getItem("HiringManagerMailId");
  mobile1:string;
  LastName:string;
  MobileWork:string;
  Location:string;
  RoleDesignation:string;
  Company:string;
  AdvisorIntro:string;
  OnboardingPercent: StepCompletion[] = [];
  intCompletedSteps: number;
  intTotalSteps: number;
  //img:any;
  isPolicyDone: boolean;
  img:string = "./assets/images/IDCardPic_default.png";
  d:any;
  AdvisorImage:any;
  input:OnboardingAdvisorDetail;
  Kallidus:Boolean;
  location1:String = localStorage.getItem("Region");
  vanguard:string= localStorage.getItem("CompanyNameIdentifier");
  vanRegion:Boolean=false;
  // usCurrentSession:GetAttendanceDetails = null;
  // uscodeDetails:GetAttendanceDetails[] = [];
  // uscurrentEvent:string = "";
  // uscurrentAvail:Boolean = false;
  intro:any;
  order:string;
  errorMsg:string;
  fresher:boolean=localStorage.getItem("SourceType").toLowerCase()=="campus";
  year=localStorage.getItem("footeryear");
  haveInfo:Boolean = false;
  pendingW4:Boolean = false;
  candidateid:String=String(localStorage.getItem("CandidateID"));
  CandidateIDList: String[] = [];
  pendingpolicy: boolean;
  tax: boolean;
  //showUSAttendanceIcon: Boolean = Number(localStorage.getItem("DaysLeft")) <= 0 && Number(localStorage.getItem("DaysLeft")) >= -2;
  showUSAttendanceIcon: Boolean = Number(localStorage.getItem("DaysLeft")) <= 0;


  constructor(
    private _usService: DashboardService,
    private attendanceServ: DashboardIndiaService,
    private appService: AppService,
    private router:Router,
    private alert:AlertService,
    private postService:PostjoiningService, 
    private _service:HRRepresentativeService,
    private route: ActivatedRoute,
    private spinner:NgxSpinnerService) {
      LoadOwlCarouselForGallery();
      LoadOwlCarouselForGalleryForWeb();
      LoadJsForDashBoardMobileCarousel();
      LoadOwlCarousel();
      LoadOwlCarouselForStaticWeb();
      LoadOwlCarouselForStaticWeb1();
      LoadOwlCarouselForStaticWeb2();
    // this._usService.getOnboardingPercentage().subscribe((res) => {

    //   this.obpercent = res;
    // }, (err) => {
    //   // console.log(err);
    // })
    if(localStorage.getItem("SourceType").toLowerCase()=="campus"){
      this.input = {AdvisorImage:null,AdvisorIntro:null,CandLocation:localStorage.getItem("Location"),Source:"Fresher",Company:null,EmailID:null,FirstName:null,LastName:null,Location:null,MobileWork:null,RoleDesignation:null}}
      else{
        this.input = {AdvisorImage:null,AdvisorIntro:null,CandLocation:localStorage.getItem("Location"),Source:"Lateral",Company:null,EmailID:null,FirstName:null,LastName:null,Location:null,MobileWork:null,RoleDesignation:null}}
    this._service.GetOnboardingDetails(this.input).subscribe(
      responseOBAData => {
        //console.log(responseOBAData);
        if(responseOBAData!=null){
          this.haveInfo = true;
          //console.log('Inside If',this.haveInfo);
        // console.log(responseOBAData)
        if(responseOBAData.EmailID.trim().endsWith('@infosys.com')){
          this.EmailId = responseOBAData.EmailID;
        }
        else{
          this.EmailId = responseOBAData.EmailID + '@infosys.com';
        }
        this.FirstName = responseOBAData.FirstName;
        this.LastName = responseOBAData.LastName;
        this.MobileWork = responseOBAData.MobileWork;
        this.Location = responseOBAData.Location;
        this.RoleDesignation = responseOBAData.RoleDesignation;
        this.Company = responseOBAData.Company;
        this.AdvisorIntro = responseOBAData.AdvisorIntro;
        this.AdvisorImage = 'data:image/jpg;base64,' + responseOBAData.AdvisorImage;
       } },
     
      
    );
    // this._usService.GetCandidateDetailsAfterAuthentication(this.candidateData).subscribe((res) => {
    //   console.log(res);
    //   for(this.i of res){
    //   this.name1=this.i.HiringManagerName;
    //   ;
    //   }
    // })
    this.postService.getPendingActions().subscribe(
      (res)=>{
       
        if(res.ImageUpload=="N"){
          this.idbadge=true;
        }
        if(res.Emergency=="N"){
          this.emergency=true;
        }
        if(res.SSN=="N"){
          this.ssn=true;
          
        }
       
        if(res.BANK=="N"){
          this.bank=true;
        }
        if(res.Disability=="N"){
          this.disable=true;
        }
        if(res.Veteran=="N"){
          this.veteran=true;
        }
        if(res.W4 == "N")
        {
          this.pendingW4 = true;
        }
        if(res.Policies == "N")
        {
          this.pendingpolicy = true;
        }

        if(this.vanRegion)         
        {         
        if(res.Address=="N" || res.EmergencyAddress == "N")         
        {            
        this.address=true;            
        res.Address = "N";          
        }          
        if(res.Tax == "N")          
        {            
        this.tax = true;          
        }        
        }        
        else
        {          
        if(res.Address=="N")
        {            
        this.address=true;          
        }        
        }

        if(this.vanRegion)
        {
          LoadStyleForVanguardIcons(res.ImageUpload,res.Emergency,res.Address,res.SSN,res.BANK,res.W4,res.Policies,res.Tax,res.Disability,res.Veteran);
        }
        else{
          LoadStyleForUSPendingIcons(res.ImageUpload,res.Emergency,res.Address,res.SSN,res.BANK,res.W4,res.Policies,res.Tax,res.Disability,res.Veteran);
        }
         this.spinner.hide();
      }
     
    )
   
  }

  
  ngOnInit() {
    //console.log("Fresher:"+this.fresher);
    //console.log("Lateral/Fresher",localStorage.getItem("SourceType"));
    StopSpinner();
    this.refreshPercentage();
    this.GetAttendanceCodeDetails();
    // console.log("dashboard checkInfySeeAll:"+localStorage.getItem("checkInfySeeAll"))
    // console.log("dashboard checkLeadersSeeAll:"+localStorage.getItem("checkLeadersSeeAll"))
    localStorage.setItem("checkInfySeeAll","N");
    localStorage.setItem("checkLeadersSeeAll","N");
    if(this.location1.toUpperCase() == "KALIDS" ){
      this.Kallidus = true;
      
    } 
    if(this.vanguard=="Vanguard"){
      this.vanRegion=true;              
    }else{
      this.vanRegion=false;
    }
    this._usService.GETSeniorExecDetails().subscribe(
      res => {
        for (var i of res) {
          this.CandidateIDList.push(i.intCandidateId);
        }
        for(let arr of this.CandidateIDList){
          if(arr.trim()==this.candidateid.trim()){
          this.appService.seniorCand=true;
            break;
          }
          else{
            this.appService.seniorCand=false;
          }
        }
      //   this.spinner.hide();
      }
    ); 
    
    // console.log("dashboard checkInfySeeAll:"+localStorage.getItem("checkInfySeeAll"))
    // console.log("dashboard checkLeadersSeeAll:"+localStorage.getItem("checkLeadersSeeAll"))

    //this.getW4Status();
    LoadOwlCarouselForGallery();
    LoadOwlCarouselForGalleryForWeb();
    LoadOwlCarouselForStaticWeb();
    LoadOwlCarouselForStaticWeb1();
    LoadOwlCarouselForStaticWeb2();
    LoadOwlCarouselforpost();
    LoadJsForDashBoardMobileCarousel();
    //LoadJsForDashBoardMobileCarousel();
    // this.breakpointObserver
    //   .observe(['(max-width: 599px)'])
    //   .subscribe((state: BreakpointState) => {
    //     if (state.matches) {
    //       this.mobile = true;
    //     } else {
    //       this.mobile = false;
    //     }
    //   });
    this.mobile = this.appService.mobile;
    if(this.mobile){
      this.isPolicyDone = this.appService.isPolicyDone;
      this.fetchPolicyDetails();
      window.scroll(0, 0);
      LoadScrollJS();
    }
    this.appService.hideDashBoard.subscribe((isHidden) => {
      this.isHidden = isHidden;
    });
    if(this.email1!=null){
      if(this.email1.trim().endsWith('@infosys.com')){}
      else{this.email1 = this.email1.trim() + '@infosys.com'}
    }
      this.route.queryParams.subscribe(params =>{
        this.order = params["order"]
        if(this.order == "N")
        {

          this.appService.setHiddenDashBoard(true);
        }
      });
    this.candidateData.CandLocation=localStorage.getItem("Location");
    this.candidateData.Source=localStorage.getItem("SourceType");
    this._usService.GetOnboardingDetailsNew(this.candidateData).subscribe((res) => {
      if(res!=null){
        //console.log(res);
      this.haveInfo = true;
      //console.log("Inside If");
      this.name=res.FirstName;
      this.Designation=res.RoleDesignation;
      }
    })
    // this._usService.GetCandidateDetailsAfterAuthentication(this.candidateData).subscribe((res) => {
    //   if(res!=null){
    //      console.log(res);
    //   for(this.i of res){
    //   this.name1=this.i.HiringManagerName;
    //   //this.d=this.i.RoleDesignation;
    //   //this.intro=this.i.AdvisorIntro;
    //   //this.email1=this.i.EmailID;
    //   //this.mobile1=this.i.MobileWork;
    //   }}
    // })
    this.postService.getPendingActions().subscribe(
      (res)=>{
       
        if(res.ImageUpload=="N"){
          this.idbadge=true;
        }
        if(res.Emergency=="N"){
          this.emergency=true;
        }
        if(res.SSN=="N"){
          this.ssn=true;
          
        }
       
        if(res.BANK=="N"){
          this.bank=true;
        }
        if(res.Disability=="N"){
          this.disable=true;
        }
        if(res.Veteran=="N"){
          this.veteran=true;
        }

        if(res.W4 == "N")
        {
          this.pendingW4 = true;
        }
        if(res.Policies == "N")
        {
          this.pendingpolicy = true;
        }

        if(this.vanRegion)         
        {         
        if(res.Address=="N" || res.EmergencyAddress == "N")         
        {            
        this.address=true;            
        res.Address = "N";          
        }          
        if(res.Tax == "N")          
        {            
        this.tax = true;          
        }        
        }        
        else
        {          
        if(res.Address=="N")
        {            
        this.address=true;          
        }        
        }

        if(this.vanRegion)
        {
          LoadStyleForVanguardIcons(res.ImageUpload,res.Emergency,res.Address,res.SSN,res.BANK,res.W4,res.Policies,res.Tax,res.Disability,res.Veteran);
        }
        else{
          LoadStyleForUSPendingIcons(res.ImageUpload,res.Emergency,res.Address,res.SSN,res.BANK,res.W4,res.Policies,res.Tax,res.Disability,res.Veteran);
        }
        
      }
     
    )

    let path = window.location.pathname.substring(1).split('/');
    if(path.length>1)
    {
      this.appService.setHiddenDashBoard(true);
    }
  }

  GetAttendanceCodeDetails(){
    let dataUS = new DatatoGetDetails();
    dataUS.Doj = localStorage.getItem("CandDOJ");
    dataUS.location = localStorage.getItem("Location");
    dataUS.ConfirmedOBLOC = localStorage.getItem("ConfirmedOBLOC");
    dataUS.candidateid = Number(localStorage.getItem("CandidateID"));
    if(this.fresher){
      dataUS.identification = "F";
    }
    else{
      dataUS.identification = "L";
    }
    let todayUS = new Date();
    let currentHourUS = todayUS.getHours();
    let currentMinUS = todayUS.getMinutes();
    this.attendanceServ.GetAttendanceDetails(dataUS).subscribe(
      response=>{
        if(response!=null && response.length >0 ){
        //  this.uscodeDetails = response;
          for(let i of response){
          let fromhUS= Number(i.FromTime.split(":")[0]);
          let frommUS = Number(i.FromTime.split(":")[1]);
          let tohUS= Number(i.ToTime.split(":")[0]);
          let tomUS = Number(i.ToTime.split(":")[1]);
         console.log("db ",fromhUS,tohUS,frommUS,tomUS);
          if(currentHourUS == fromhUS && currentHourUS == tohUS && currentMinUS >= frommUS && currentMinUS <= tomUS)
          {
            // this.usCurrentSession = i;
            // this.uscurrentEvent = this.usCurrentSession.txtEvent;
            // this.showUSAttendanceIcon = true;
            // this.uscurrentAvail = true;
            this.showUSAttendanceIcon = true;
          }
          else if(currentHourUS == fromhUS && currentHourUS < tohUS && currentMinUS >= frommUS){
            // this.usCurrentSession = i;
            // this.uscurrentEvent = this.usCurrentSession.txtEvent;
            // this.showUSAttendanceIcon = true;
            // this.uscurrentAvail = true;
            this.showUSAttendanceIcon = true;
          }
          else if(currentHourUS > fromhUS && currentHourUS == tohUS && currentMinUS <= tomUS){
            // this.usCurrentSession = i;
            // this.uscurrentEvent = this.usCurrentSession.txtEvent;
            // this.showUSAttendanceIcon = true;
            // this.uscurrentAvail = true;
            this.showUSAttendanceIcon = true;
          }
          else if(currentHourUS > fromhUS && currentHourUS < tohUS)
          {
            // this.usCurrentSession = i;
            // this.uscurrentEvent = this.usCurrentSession.txtEvent;
            // this.showUSAttendanceIcon = true;
            // this.uscurrentAvail = true;
            this.showUSAttendanceIcon = true;
          }
        }
      }
      })
  }
  
  fetchPolicyDetails(){
    this._usService.fetchPolicyDetails().subscribe(
      res => {
         this.spinner.show();
        if(res != null){
          if(res.flgPolicy == 'Y'){this.isPolicyDone = true;}
          else{
            this.isPolicyDone = false;
            if(!this.mobile){this.alert.error("Please complete reading and acknowledging the required policy documents available in this section to be able to provide your attendance for the day",this.mobile);}
          }
           this.spinner.hide();
        }
      });
  }
  navigateToPolicy(){
    StartModalClose1();
  }

  CallJsForModalFade(){
    //alert('I am inside Back drop');
    AddBodyscroll();
    HideBottomBar();
    LoadJsForModalBackDrop();
    
  }
  RemoveBodyscroll(){
    RemoveBodyscroll();
  }
  OnStep1Clicked(str : string) {
    if(str==='pay'){
      changePayrollIcon();

    }
    else if(str==='ins'){
      changeInsuranceIcon();

    }
    else if(str=='disabilityUS'){
      ChangeDisabilityImage();
    }
    else if(str=='veteranUS'){
      ChangeVeteranImage();
    }
    
  
    else if(str==='w4'){
      if(this.vanRegion){ChangeW4ImageVanguard();}
      else{
      changeW4Icon(); }


    }
    else if(str==='policyus'){
      ChangePolicyIcon(); 


    }

    else if(str==='ss'){
      ChangeSsnImage(); 

    }
    else if(str==='bank'){
      ChangeBankImage(); 

    }
    else if(str==='emer'){
     ChangeIdBadgeImage();
    }
    else if(str==='badge'){
    ChangeEmergencyImage();

    }
    else if(str==='add'){
      ChangeAddressImage();

    }  
    else if  (str=='tfn'){
      ChangeTaxImageVanguard();
    }
    this.appService.setHiddenDashBoard(true);
    
  }
  OnSeeAllClicked(){    
    this._usService.seeAll=true;
    this.appService.setHiddenDashBoard(true);
  }

  OnForyourActionClick() {
    this.appService.setHiddenDashBoard(true);
    this.appService.setHiddenappagenda(true);
  }
  faq(){
    window.scroll(0, 0);

    this.router.navigate(['../lpusstatic/faq-us']);
  }
  Benefits(){
    window.scroll(0, 0);

    this.router.navigate(['../lpusstatic/benefits-us']);
  }

  Attendance(){
    this.router.navigate(['../attendance']);  
  }
  changePassword(){
    this.router.navigate(['/change']); 
  }
  contact(){
    window.scroll(0, 0);

    this.router.navigate(['../lpusstatic/contactus-us']);
  }
  
  privacy(){
    this.router.navigate(['../pri']);  
  }

  terms(){
    this.router.navigate(['../tnc']);    
  }

  claims(){
    this.router.navigate(['../lpusstatic/us-claims']);
  }
  OpenGallery(){
    LoadOwlCarousel()
    window.scroll(0, 0);

    this.router.navigate(['../lpusstatic/gallery-us']);
  }

  OnStep1Clickedxx1(){
    this.router.navigate(['/lpusstatic/leadersspeakseeall-us']);
    this.appService.setHiddenDashBoard(true);
  }
  OnStep1Clickedxx111(){
    this.router.navigate(['/lpusstatic/infydiariesspeakseeall-us']);
    this.appService.setHiddenDashBoard(true);
  }
  GoToKnowMore(){
    this.appService.setHiddenDashBoard(true);
    this.router.navigate(['../collaborationcontent']);
  }
  OpenMailer(){
    OpenMailer(this.EmailId);
  }
  
  OpenDialler(){
    OpenDialler(this.MobileWork);
  } 
  
  OpenMailer1(){
    OpenMailer(this.email1);
  }
  
  OpenDialler1(){
    OpenDialler(this.mobile1);
  } 
  AddBodyScroll_Stay(){
    AddBodyScroll_Stay();
  }
  RemoveBodyScroll_Stay(){
    RemoveBodyScroll_Stay();
  }

  Step1RouteForWeb(){
    ChangeEmergencyImage();
    this.router.navigate(['/lpus/step1/badge'])
  }
  Step2RouteForWeb(){
    // if(this.fresher){
    //   this.router.navigate(['/lpus/step2/step2Start'])
    // }
    // else{
    //   this.router.navigate(['/lpus/step2/step2Start'])
    // }
    this.router.navigate(['/lpus/step2/step2Start'])
  }
  Step3RouteForWeb(){
    this.router.navigate(['/lpus/step3'])
  }

  PendingActionsRouteForWeb(){
    this.router.navigate(['/lpus/postjoining'])
  }

  refreshPercentage()
  {
    this._usService.getOnboardingPercentage().subscribe((res) => {
      if(res!=null){
        this._usService.OnboardPercent = res;
        this.OnboardingPercent = this._usService.OnboardPercent;
        for(var i of this.OnboardingPercent){
          this.obpercent = i.OnboardingPercent;
          this.intTotalSteps = i.TotalCount;
          this.intCompletedSteps = i.StepCount;
        }
      }
    }, (err) => {

    });
  }
  refreshPercentageOnClick()
  {
     this.spinner.show();
    this._usService.getOnboardingPercentage().subscribe((res) => {
     if(res != null)
     {
      this._usService.OnboardPercent = res;
      this.OnboardingPercent = this._usService.OnboardPercent;
      for(var i of this.OnboardingPercent){
        this.obpercent = i.OnboardingPercent;
        this.intTotalSteps = i.TotalCount;
        this.intCompletedSteps = i.StepCount;
      }
       this.spinner.hide();
      this.alert.success("Percentage completion updated successfully.",this.mobile);
     }
    }, (err) => {

    });
  }

  InstallInfyMeApp(){
    OpenInfyMeUrl();
  }
}