
import {HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import { Observable } from 'rxjs';
import { PersonalDetails } from '../models/personaldetails.model';
import { LPUSCustomHttpClient } from '../lpus.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { UKPersonalDetails } from '../../lpuk/models/UKPersonalDetails.model';
import { CandidateUKAdvisorImage } from '../../lpuk/models/CandidateUKAdvisorImage.model';
import { LPFetchPersonalDetails } from '../../lpindia/models-india/LPFetchPersonalDetails';
import { CandidateAdvisorImage } from '../models/candidateAdvisorImage';
import { IndiaCandidateAdvisorImage } from '../../lpindia/models-india/indiaCandidateAdvisorImage.model';
import { updatePhoneNumberFromProfile } from '../models/personaldetails.model';


@Injectable({
    providedIn: "root"
})
export class ProfileService {
    updatePhoneBody : updatePhoneNumberFromProfile;
  
    constructor(private _http:LPUSCustomHttpClient){}

    GetPersonalDetails ():Observable<PersonalDetails[]> 
    {  
    var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
    headers=headers.append('Content-Type','application/json');
    return this._http.post<PersonalDetails[]>(Configuration.ResServerBaseURL+APIUrls.GetPersonalDetails,{"CandidateId":Number(localStorage.getItem("CandidateID"))},{
    headers:headers
    }).pipe(map(
    (res) => {
    //console.log(res);
    return res;
    }
    ))  
    }

    GetPersonalDetailsforEMEA ():Observable<UKPersonalDetails[]> {  
        return this._http.post<UKPersonalDetails[]>(Configuration.ResServerBaseURL+APIUrls.GetPersonalDetailsUK,{"CandidateId":Number(localStorage.getItem("CandidateID"))}).pipe(map(
        (res) => {
        //console.log(res);
        return res;
    }
    ))  
    }
  
    fnGetCandPhotoforEMEA(): Observable<CandidateUKAdvisorImage> {
        return this._http.post<CandidateUKAdvisorImage>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhotoUK,{"CandidateId":Number(localStorage.getItem("CandidateID"))}).pipe(map(
        (res) => {
        return res;
    } 
))  
    }

    GetPersonalDetailsIN ():Observable<LPFetchPersonalDetails[]> 
    { 
    var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4MzgwOGFhYi01MDFiLTRlMGMtOTVjYi1lODg2YTA1NzRiOTMiLCJleHAiOjE1NTU1Njc2NTgsIm5iZiI6MCwiaWF0IjoxNTU0MjcxNjU4LCJpc3MiOiJodHRwczovL3dpbmdzcGFuLWRldi5pbmZvc3lzYXBwcy5jb20vYXV0aC9yZWFsbXMvSVMxIiwiYXVkIjoicG9ydGFsIiwic3ViIjoiOTNiOWYwZDktODRmNy00NWY1LTkzMDEtNmM0YTAzMDg5M2Y4IiwidHlwIjoiSUQiLCJhenAiOiJwb3J0YWwiLCJub25jZSI6ImY5ZWYzNDUxLWEzYTctNDc1Mi04YmNhLTQxYjg0NTg1YWU0YyIsImF1dGhfdGltZSI6MTU1NDIwMzM5Miwic2Vzc2lvbl9zdGF0ZSI6IjA5NjFhMDNiLTU2NGItNGYwNy1iMjRjLTgzN2FlYmJhNjY0MCIsImFjciI6IjAiLCJuYW1lIjoiQWJoaXNoZWsgTmVnaSIsInByZWZlcnJlZF91c2VybmFtZSI6InRlc3QxQGR1bW15LmNvbSIsImdpdmVuX25hbWUiOiJBYmhpc2hlayIsImZhbWlseV9uYW1lIjoiTmVnaSIsImVtYWlsIjoidGVzdDFAZHVtbXkuY29tIn0.ncBiZlVLJa5QHpe8KwWlOjcnpoF4rnbuQd70_2vSQbrpy0qdP7-AzLDadEp5U03mjzlWWEJR0KegfwVrJuQIdxRHurlJbZ2M7MCHdWroi4RCDCI4HzDSbvyFsDyLrZgg0WEuo5YO7b4Up1JN4sfVro1MfqqCBoimWV63HfDGC0XKJ6hMVt1UC-sX3NfqO4xtC3w5lTfv84wSmdWGhQNKvaTOlHhjqyXTDg8c2UrCaOvbu-jk3L_hOdNOogizMqIufyBhmOMsJusuO-hH5sQuX5L0BA7DFF1plwqyXm9Y5DdjSVApDYiHr5GCgdVCitLAXmCWN1yA3WlnrZGHATr-yw');
    headers=headers.append('Content-Type','application/json');
    return this._http.post<LPFetchPersonalDetails[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetPersonalDetailsIN,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))},{
    headers:headers
    }).pipe(map(
    (res) => {
    return res;
    }
    ))  
    }

    fnGetCandPhoto(): Observable<CandidateAdvisorImage> {
            var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiJlMTlkYmU1MS04N2E2LTRkMGYtOTFlNS1jZjI0YzUwMzRiMGUiLCJleHAiOjE1NjA0OTA1OTQsIm5iZiI6MCwiaWF0IjoxNTU5MTk0NTk0LCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiI5M2I5ZjBkOS04NGY3LTQ1ZjUtOTMwMS02YzRhMDMwODkzZjgiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiOGE0NmU1NmItYmQzNC00NmYwLWEwODEtODhmZDIxOTk4NWQ4IiwiYXV0aF90aW1lIjoxNTU5MTk0NTkzLCJzZXNzaW9uX3N0YXRlIjoiZGZhOGU0M2YtMGFiZC00ZWU4LTgyMTMtMDBmZjc1ZjFjZTM2IiwiYWNyIjoiMSIsIm5hbWUiOiJBYmhpc2hlayBOZWdpIiwicHJlZmVycmVkX3VzZXJuYW1lIjoidGVzdDFAZHVtbXkuY29tIiwiZ2l2ZW5fbmFtZSI6IkFiaGlzaGVrIiwiZmFtaWx5X25hbWUiOiJOZWdpIiwiZW1haWwiOiJ0ZXN0MUBkdW1teS5jb20ifQ.V5Yo9G-QlEZK-MQF7U8RNFTNwhCG2jC1s680zBRSxdzqBy5A6l8YcUNaRUjScvDuRr73ikrZJlaWFv84R9XUAPKp3x2Lhg8xYh0kdkYr9ZoYGArpuw7uPaaSAsNaMNzPGN3fel9mwpsumR0cexOI1t5n71eQ5dpKmqQgT5_BJmiVrFKo5pDkX9c34FqucLQXbGqVuoucT6VQ78L6Vmz3l6GFzGmHQ87UpKeLbSjVgZBF3lrRxF1VpnD7WmRwQB0L4vtiUQVbGC3mIRMywy1RvNtPmJ5gFM1xT3nl0frm2_JWXpVFOazoKszehVw7MO4X9EWsLyyQWptVl7EsP8pI4w');
            headers=headers.append('Content-Type','application/json')
            return this._http.post<CandidateAdvisorImage>(Configuration.ResServerBaseURL+APIUrls.fnGetCandPhoto,{"CandidateId":Number(localStorage.getItem("CandidateID"))},{
            headers:headers
            }).pipe(map(
            (res) => {
            return res;
        } 
    ))  
     }

     fnGetCandPhotoIN(): Observable<IndiaCandidateAdvisorImage> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<IndiaCandidateAdvisorImage>(Configuration.ResServerBaseURLIndia+APIUrls.fnGetCandPhotoIN,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
    } 
  ))  
  }

    updatePhoneNumberFromProfileIN(updatePhoneBody: updatePhoneNumberFromProfile): Observable<string> {
        var headers = new HttpHeaders().set('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers = headers.append('Content-Type', 'application/json')
        let body = JSON.stringify(updatePhoneBody);
        return this._http.post<string>(Configuration.ResServerBaseURLIndia + APIUrls.updatePhoneNumberFromProfileIN, body, {
            headers: headers
        }).pipe(map(
            (res) => {
                return res;
            }
        ))
    }
}