export class GDC
{
    public CandidateID: Number;
    public familyID: Number;
    public FirstName: String; 
    public LastName: String; 
    public nationality: String; 
    public AddressType: String; 
    public AddressLine1: String; 
    public AddressLine2: String; 
    public Area: String; 
    public Country: String; 
    public State: String; 
    public StateCode: String; 
    public City: String; 
    public landMark: String;
    public Pin: String;
    public txtContent: String;
}

export class BGV {
    public flgBGV: String;
}

export class BGVPopup{
    public flgShow: String;
}

export class UpdateBGVflg{

}

export class SectionShow{
    public intCandidateId: string;
    public txtSection: any;
}