import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { FormControl, NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { WorkexperienceService } from './workexperience.service';
import { WorkExperience } from '../../models-india/WorkExperience';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../../app.service';
import { WorkExperienceUpdate } from '../../models-india/WorkExperienceUpdated';
import { NgbDateFRParserFormatter } from '../../../NgbDateFRParserFormatter';
import { NgbDate, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { WorkExDoc } from '../../models-india/WorkExpDoc';
import { WorkExInputParam, UpdateUANDetails } from '../../models-india/WorkExInputParam';
import { LPDms } from '../../models-india/LPDms';
import { LPFetchExpDocDetails } from '../../models-india/LPFetchExpDocDetails';
import { DMSInternetPlainDocumentResponse } from '../../models-india/DMSInternetPlainDocumentResponse';
import { NgxSpinnerService } from 'ngx-spinner';

import { AlertService } from '../../../utils/alert/alert.service';
import { WorkEducationgapComponent } from '../work-educationgap/work-educationgap.component';
import { DMSNew } from '../../models-india/dmsNew';
import { NgxImageCompressService } from 'ngx-image-compress';
//need to check import { Ng2ImgMaxService } from 'ng2-img-max';
import { DashboardIndiaService } from '../../dashboard-india/dashboard-india.service';
import { PostjoiningIndiaService } from 'src/app/lpindia/postjoining/postjoining.serivce';
import { IndiaInputParam } from 'src/app/lpindia/models-india/IndiaInputParam.model';
import { DeleteOtherDocDetails } from '../../models-india/DeleteOtherDocDetails.model';
import { UpdateOtherDocDetails } from '../../models-india/UpdateOtherDocDetails.model';
import { GetCandidateOtherDocList } from '../../models-india/GetCandidateOtherDocList';
import { DMSDocument } from '../../models-india/DMSDocument';
import { Guid } from '../../models-india/Guid.model';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/app/login/auth.service';
import { NoteConstants } from 'src/app/NotesConstants';
import { BGVDetails } from '../../models-india/BGVDetails.model';
import { DocName } from 'src/app/lpau/models-au/docName.model';
import { Candidate } from '../../models-india/Candidate';
import { BGV, BGVPopup, SectionShow } from '../../models-india/GDC.model';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BgvcheckComponent } from '../../bgvcheck/bgvcheck.component';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { ResiaddressIndiaService } from '../resiaddress-india/resiaddress-india.service';
declare function GetBase64Data(stream, name): any;
declare function LoadDatePickerJs(): any;
declare function ChggapIN(): any;
declare function ChgwrkexIN(): any;
declare function ChgnsrIN(): any;
declare function getElement(id): any;
declare function UpdateButtonClick(id): any;
declare function LoadOwlCarouselForGallery(): any;
declare function LoadJsForDashBoardMobileCarousel(): any;
declare function ShowBottomBar(): any;
declare function LoadDataSubmitModal(): any;
declare function LoadJsForModalBackDrop(): any;
declare function AddBodyscroll(): any;
declare function CloseDataSubmitModal(): any;
declare function LoadStyleForIconsIndia(i: string, e: string, b: string, ba: string, f: string, c: string, edu: string,
  exp: string, p: string, g: string, dis: string, nsr: string, pan: string, aadhar: string, acc: string, med: string, ach: string, nats: string, statutory: string): any;
@Component({
  selector: 'app-workexperience',
  templateUrl: './workexperience.component.html',
  styleUrls: ['./workexperience.component.css']
})
export class WorkexperienceComponent implements OnInit {
  attachFormFlag: boolean = false;
  today = new Date();
  maxyear = this.today.getFullYear();
  maxmonth = this.today.getMonth() + 3;
  maxday = this.today.getDate();
  maxdate = new Date(this.maxyear, this.maxmonth, this.maxday);
  mindate = new Date(1900, 1, 1)
  workexperiences: WorkExperience[];
  experienceForm: boolean = false;
  pjoiningdate: String = "Unknown";
  selectedExperience: WorkExperience;
  workExperienceUpdated: WorkExperienceUpdate;
  errormsg: String;
  msg: String;
  porgName: String;
  pdesignation: String;
  pcompanyAddress: String;
  pcomapanyContactNo: String;
  pempno: String;
  preasonofseparation: String;
  panelOpenState = false;
  pmgrName: String;
  pmgremailid: String;
  pmgrcontact: String;
  proleres: String;
  pagency: String;
  fromDate: Date;
  fromDateNew: Date;
  fromDateOld: Date;
  toDate: Date;
  toDateNew: Date;
  toDateOld: Date
  pfromDate: Date = new Date();
  ptoDate: Date = new Date();
  myFile0: File;
  myFile1: File;
  Name: String;
  fname0: String; fname1: String;
  fsize0: number; fsize1: number;
  mobile: boolean;
  desktop: boolean;
  fresher: Boolean;
  lateral: Boolean;
  launchpadFileName: string;
  workExpDoc: WorkExDoc;
  serialNumber: number;
  attach: Boolean = false;
  attachName: String;
  lastExp: WorkExperience;
  lpFetchExpDocDetailsList: LPFetchExpDocDetails[];
  uploadDocResponse: DMSInternetPlainDocumentResponse;
  dateError: boolean;
  datapresent: Boolean;
  isgap: Boolean = localStorage.getItem("isGap") == "Y";
  static fileAsBasestring: string;
  static Base64String: any;
  static arr: any[];
  camera: boolean;
  order: string;
  yesUan: boolean;
  validUan: string;
  pendingWeb: Boolean = false;
  isSKAVA: Boolean = localStorage.getItem("COMPANY").toUpperCase() == "KALSKV";
  isFileChoosen: Boolean;
  attachNameUAN: string;
  attachUAN: Boolean = false;
  success: String;
  dmsDocument: DMSDocument;
  dmsDocumentUAN: DMSDocument;
  uploadRes: DMSInternetPlainDocumentResponse;
  input: GetCandidateOtherDocList;
  docList: any;
  guid = Guid.newGuid();
  fname: string;
  fsize: number;
  myFile: File;
  uanumber: string;
  UANReaspone: UpdateUANDetails = new UpdateUANDetails;
  UANdeclaration: string;
  txtTopNote: string;
  txtBottomNote: string;
  txtTitleName: string;
  flgEnableTopNote: boolean;
  flgEnableBottomNote: boolean;
  minstartdate = new Date(1900, 1, 1)
  maxstartdate = new Date(this.maxdate.getFullYear(), this.maxdate.getMonth() + 3, 1);
  minenddate = new Date(1900, 1, 1)
  maxenddate = new Date(this.maxdate.getFullYear(), this.maxdate.getMonth() + 3, 1);
  fileValidate: File;
  fileBool: boolean = false;
  ibpm: boolean = localStorage.getItem("COMPANY").toString().toUpperCase() == "IBPM INDIA";
  EmailId: string;
  flgWorkEx: Boolean = localStorage.getItem("flgWorkEx").toUpperCase() == "Y";
  flgIsRebadging: Boolean = localStorage.getItem("flgRebadging") == "True";
  addNewWorkExp: boolean = false;
  countNewFlags: number = 0;
  txtDocTypeCode: string;
  inpString: string;
  selectedValue: string = "0";
  fname2: string;
  fsize2: number;
  myFile2: File;
  attachOffer: boolean = false;
  offerattached: any;
  attachPage: any;
  lpFetchExpDocDetailsListWorkExp: LPFetchExpDocDetails[] = [];
  lpFetchExpDocDetailsListOfferLetter: LPFetchExpDocDetails[] = [];
  lpFetchExpDocDetailsListPaySlips: any;
  attachPaySlips: boolean = false;
  PaySlipsAttached: any;
  BankStatementsAttached: any;
  FormAttached: any;
  PFAttached: any;
  WorkPermitAttached: any;
  ContractAgreementAttached: any;
  PayStubsAttached: any;
  W2Attached: any;
  attachW2: boolean = false;
  attachPayStubs: boolean = false;
  attachContract: boolean = false;
  attachWorkPermit: boolean = false;
  attachPFStatement: boolean = false;
  attachForm16: boolean = false;
  attachBankStatement: boolean = false;
  lpFetchExpDocDetailsListBankStatement: any;
  lpFetchExpDocDetailsListForm16: any;
  lpFetchExpDocDetailsListPFStatement: any;
  lpFetchExpDocDetailsListWorkPermit: any;
  lpFetchExpDocDetailsListContractAgreement: any;
  lpFetchExpDocDetailsListPayStubs: any;
  lpFetchExpDocDetailsListW2: any;
  fname3: string;
  fsize3: number;
  myFile3: File;
  fname4: string;
  fsize4: number;
  myFile4: File;
  fname5: string;
  fsize5: number;
  myFile5: File;
  fname6: string;
  fsize6: number;
  myFile6: File;
  fname7: string;
  fsize7: number;
  myFile7: File;
  fname8: string;
  fsize8: number;
  myFile8: File;
  fname9: string;
  fsize9: number;
  myFile9: File;
  myFile10: File;
  fsize10: number;
  fname10: string;
  fname11: string;
  fsize11: number;
  myFile11: File;
  flgShowOfferLetter: boolean = false;
  flgShowW2: boolean = false;
  flgShowContractAgreement: boolean = false;
  flgShowPayStubs: boolean = false;
  flgShowWorkPermit: boolean = false;
  flgShowPFStatement: boolean = false;
  flgShowForm16: boolean = false;
  flgShowBankStatement: boolean;
  flgShowPaySlips: boolean = false;
  flgShowBankLOA: boolean = false;
  flgRelieveingLetter: boolean = false;
  flgOnsite: boolean = false;
  flgIndia: boolean = false;
  flgRelieveingLetterNO: boolean = false;
  flgRelieveingLetterYES: boolean = false;
  attachBankLOA: boolean = false;
  lpFetchExpDocDetailsListBankLOA: any[];
  BankLOAAttached: String;
  fname13: String;
  fsize13: number;
  myFile13: any;
  flgRelieveingLetterValue: any = 'N';
  flgIndiaOnsiteValue: any = '';
  txtReason: any;
  intCandQualDetIdForBGV: number;
  flgCheckNoReason: boolean;
  docDeclaration: String = '';
  tentativeDate: Date = new Date();
  callBGV: boolean;
  doj = new Date(localStorage.getItem("dtActiveDOJ"));
  lastDate: Date;
  flgResignationAcceptance: boolean = false;
  flgWorkExPost: Boolean = Number(localStorage.getItem("DaysLeft")) < 1;
  flgWorkExVerified: boolean = false;
  WorkExRejected: boolean;
  params: IndiaInputParam = new IndiaInputParam();
  educationalDetails: Boolean;
  experienceDetails: Boolean;
  passportDetails: Boolean;
  achievement: Boolean;
  placeholder: Boolean;
  natsDetails: Boolean;
  statutory: boolean;
  agenda: Boolean;
  accomodation: Boolean;
  medical: Boolean;
  basicDetails: Boolean;
  familyDetails: Boolean;
  contactDetails: Boolean;
  gapDetails: Boolean;
  disabilitydetails: Boolean;
  nsrDetails: Boolean;
  pandetails: Boolean;
  aadharDetails: Boolean;
  emergency: Boolean;
  bank: Boolean;
  message: Boolean;
  flgBGV: Boolean = false;
  candidate: Candidate;
  bgv: BGV;
  bgvShow: BGVPopup;
  popShow: boolean = false;
  combMsgShow: boolean = false;
  updateBGV = new SectionShow;
  txtBGVMessage: String;
  flgBGVMessage: Boolean = false;
  flgWorkExpValue: any;
  flgWorkExpValueYes: boolean;
  flgWorkExpValueNo: boolean;
  flgRAM: any;
  flgRAMNo: boolean;
  flgRAMYes: boolean;
  tentativeDate1: Date = new Date();
  flgWorkIndia: string;
  flgWorkIndiaNo: boolean;
  countryName : String []=[];
  errC: boolean;
  internationalCountry: any;
  countryList: any;
  country: any;
  countrycode: any;
  attachRAM: boolean;
  constructor(
    public dialog: MatDialog,
    private _workexperienceservice: WorkexperienceService,
    private router: Router,
    private r: ActivatedRoute,
    private alert: AlertService,
    private dateformatter: NgbDateFRParserFormatter,
    private cd: ChangeDetectorRef,
    private breakpointObserver: BreakpointObserver,
    private appService: AppService,
    private imageCompress: NgxImageCompressService,
    private dashboard: DashboardIndiaService,
    private _postService: PostjoiningIndiaService,
    private _resiIndiaService:ResiaddressIndiaService,
    public activeModal: NgbActiveModal,
    public modal: MatDialog,
    private spinner: NgxSpinnerService ) {
    this.r.queryParams.subscribe(params => {
      this.order = params["order"]
      if (this.order == "W") {
        this.pendingWeb = true;
      }
      else {
        this.pendingWeb = false;
        ChgwrkexIN();
      }
    });

    if (this.dashboard.Actions === true) {
      getElement('wrkex');
    }

  }

  ngOnInit() {
    this.spinner.show();
    this.getAllCountries();
    LoadDatePickerJs();
     this.spinner.show();
    this._workexperienceservice.FetchExpDetailsList().subscribe(
      responseData => {
        if (responseData != null && responseData.length > 0) {
          //console.log(responseData);
          this.workexperiences = responseData;
          this.countNewFlags = this.workexperiences.length;
          // console.log(JSON.stringify(this.workexperiences));
          let maxDate: Date = null;
          let temp: Date = null
          let lwd: Date = null;
          for (let i of this.workexperiences) {
            lwd = new Date(i.dtToDate as string);
            const diffTime = (this.doj.valueOf() - lwd.valueOf());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays <= 45 && diffDays > 0)
              this.flgResignationAcceptance = true;
            break;
          }
          for (let ex of this.workexperiences) {
            temp = new Date(ex.dtToDate as string);
            if (maxDate == null) {
              maxDate = temp;
              this.lastExp = ex;
            }
            else if (maxDate < temp) {
              maxDate = temp;
              this.lastExp = ex;
            }
          }
          if (this.workexperiences.length > 0 || this.lateral) { this.datapresent = true; }
          else (this.datapresent = false)
           this.spinner.hide();
        }

        else {
           this.spinner.hide();

        }
      });
    // this.getAttachments();
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          //this.appService.setIsMobile(true);
          this.mobile = true;
          this.desktop = false;
          this.r.queryParams.subscribe(params => {
            this.order = params["order"]
          });
          if (this.order === "N") {
            getElement('wrkex');
          }
        } else {
          //this.appService.setIsMobile(false);
          this.mobile = false;
          this.desktop = true;
        }
      });

    if (localStorage.getItem("Identification").toUpperCase() == "FRESHER") {
      // console.log("ISFresher");
      this.fresher = true;
      this.lateral = false;
    }
    else {
      this.lateral = true;
      this.fresher = false;
      // console.log("ISLateral");
    }
    if (this.fresher) {
      this.EmailId = "Infosys_LPCampus@infosys.com";
    }
    else {
      if (!this.ibpm) {
        this.EmailId = "Infosys_Launchpad@infosys.com";
      }
      else if (this.ibpm) {
        this.EmailId = "IBPM_Launchpad@infosys.com";
      }
    }
    //Fetch top and bottom notes
    if (AuthService.HeadingList.length > 0) {
      for (let ele of AuthService.HeadingList) {
        if (ele.txtSectionCode != null && ele.txtSectionCode == NoteConstants.txtWorkExperienceIndiaSectionCode) {
          this.txtTopNote = ele.txtTopNote
          this.txtTitleName = ele.txtTitle

          if (ele.flgEnableBottomNote == "Y") {
            this.flgEnableBottomNote = true
          }
          else {
            this.flgEnableBottomNote = false
          }
          if (ele.flgEnableTopNote == "Y") {
            this.flgEnableTopNote = true
          }
          else {
            this.flgEnableTopNote = false
          }
        }
        if(ele.txtSectionCode!=null && ele.txtSectionCode==NoteConstants.txtBGVMssage){
          this.txtBGVMessage=ele.txtTopNote

          if(ele.flgEnableTopNote=="Y"){
            this.flgBGVMessage=true;
          }
       }
      }
    }
    else {
      this.flgEnableBottomNote = true
      this.flgEnableTopNote = true
      this.txtTitleName = NoteConstants.txtWorkexperienceTitle
      this.txtTopNote = NoteConstants.txtWorkExperienceIndiaTopNote

    }
    if(!this.ibpm){
      this.candidate = { CandidateID: parseInt(localStorage.getItem("intCandidateId")) }
       this.spinner.show();
      this.dashboard.GetBGVStatus(this.candidate).subscribe(
        (res) => {
          if (res != null) {
            this.bgv = res;
            if (this.bgv.flgBGV == 'Y') { this.flgBGV = true; }
          }
          else { this.flgBGV = false; }
           this.spinner.hide();
  
        }
      );
    }
  }
  mouseHover(e) {
    // console.log('hovered', e);
    if (this.workexperiences != null && this.workexperiences.length > 0 && this.workexperiences[0].intcandexpdetid == this.selectedExperience.intcandexpdetid && !this.ibpm) {
      this.alert.error("Dates can only be changed within three months.", this.mobile);
    }
    else if (this.workexperiences != null && this.workexperiences.length > 0 && this.workexperiences[0].intcandexpdetid == this.selectedExperience.intcandexpdetid && this.ibpm) {
      this.alert.error("Dates can only be changed within one month.", this.mobile);
    }
    else {
      this.alert.error("You cannot change date beyond the one month, if you wish to change please drop a mail to launchpad team.", this.mobile);
    }
  }
  docType(input: string) {

    if (this.workexperiences != null && this.workexperiences.length > 0 && this.workexperiences[0].intcandexpdetid == this.selectedExperience.intcandexpdetid) {
      this.inpString = input
      if (this.inpString === '2') {
        this.txtDocTypeCode = 'WRERAL'
        this.selectedValue = this.inpString
        // console.log('2 val in doctype' + this.selectedValue );
        // console.log(this.inpString,this.txtDocTypeCode)

      }
      else if (this.inpString === '1') {
        this.txtDocTypeCode = 'WRKEXRL'
        this.selectedValue = this.inpString
        //  console.log('1 val in doctype' + this.selectedValue );
        //  console.log(this.inpString,this.txtDocTypeCode)

      }

      //console.log(this.inpString,this.txtDocTypeCode)

    }
    else {
      this.txtDocTypeCode = 'WRKEXRL'
    }
    // if(this.txtDocTypeCode=='WRKEXRL' || this.txtDocTypeCode=='WRERAL'){
    // this.flgRelieveingLetterValue='yes0'
    // }

  }

  isAvailable(experience: WorkExperience): boolean {
    if (this.lpFetchExpDocDetailsList != null && this.lpFetchExpDocDetailsList.length > 0) {
      for (let file of this.lpFetchExpDocDetailsList) {
        if (file.intExpId == experience.intcandexpdetid) {
          this.attach = true;
          this.attachName = file.txtorigdocname;
          return true;
        }
      }
    }
    else {
      this.attach = false;
      return false;
    }
  }
  attachForm() {
    this.attachFormFlag = !this.attachFormFlag;
  }
  fileChange(files: any, index: number, exp: WorkExperience) {
    //console.log(index);
    this.fileValidate = files[0];
    if (index == -1) {
      //this.fileChangeUAN(files);
    }
    //console.log(files);
    else {
      if (files[0] != null) {
        if (index == 0) {
          this.fsize0 = files[0].size;
          this.myFile0 = files[0];
          this.fname0 = files[0].name;
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000) {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname0 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname0 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname0)) {
              this.alert.error("File name must be alphanumeric.", this.mobile);
            }
            else if (!this.validateFileSize(this.fsize0)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname0)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname0) && this.validateFileSize(this.fsize0) &&
            // this.validateFileFormat(this.fname0)){this.uploadFile(0);}
            // console.log("In section 1");
          }
        }
        else if (index == 1) {
          this.fname1 = files[0].name;
          this.fsize1 = files[0].size;
          this.myFile1 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname1 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname1 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname1)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize1)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname1)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 2) {
          this.fname2 = files[0].name;
          this.fsize2 = files[0].size;
          this.myFile2 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname2 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname2 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname2)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize2)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname2)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 3) {
          this.fname3 = files[0].name;
          this.fsize3 = files[0].size;
          this.myFile3 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname3 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname3 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname3)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize3)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname3)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 4) {
          this.fname4 = files[0].name;
          this.fsize4 = files[0].size;
          this.myFile4 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname4 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname4 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname4)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize4)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname4)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 5) {
          this.fname5 = files[0].name;
          this.fsize5 = files[0].size;
          this.myFile5 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname5 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname5 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname5)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize5)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname5)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 6) {
          this.fname6 = files[0].name;
          this.fsize6 = files[0].size;
          this.myFile6 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname6 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname6 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname6)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize6)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname6)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 7) {
          this.fname7 = files[0].name;
          this.fsize7 = files[0].size;
          this.myFile7 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname7 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname7 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname7)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize7)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname7)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 8) {
          this.fname8 = files[0].name;
          this.fsize8 = files[0].size;
          this.myFile8 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname8 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname8 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname8)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize8)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname8)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 9) {
          this.fname9 = files[0].name;
          this.fsize9 = files[0].size;
          this.myFile9 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname9 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname9 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname9)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize9)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname9)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 10) {
          this.fname10 = files[0].name;
          this.fsize10 = files[0].size;
          this.myFile10 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname10 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname10 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname10)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize10)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname10)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 11) {
          this.fname11 = files[0].name;
          this.fsize11 = files[0].size;
          this.myFile11 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname11 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname11 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname11)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize11)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname11)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
        else if (index == 13) {
          this.fname13 = files[0].name;
          this.fsize13 = files[0].size;
          this.myFile13 = files[0];
          let file_name = files[0].name;
          let ext = file_name.substring(file_name.lastIndexOf('.') + 1);
          let current_time = new Date().getTime();
          let modified_time = files[0].lastModified;
          let diff = current_time - modified_time;
          if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
            if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
              this.camera = true;
              if (file_name.substring(0, file_name.lastIndexOf('.')).length > 20) {
                this.fname13 = file_name.substring(0, 20) + "." + ext;
              }
              else {
                this.fname13 = files[0].name;
              }
              this.uploadDocument(exp, index);
            }
            else {
              this.alert.error("Unable to upload file.", this.mobile);
            }
          }
          else {
            this.camera = false;
            if (!this.validateFileName(this.fname13)) {
              this.alert.error("File name must be alphanumeric and upto 20 characters long only.", this.mobile)
            }
            else if (!this.validateFileSize(this.fsize13)) {
              this.alert.error("Let's keep that file size within 5MB.", this.mobile);
            }
            else if (!this.validateFileFormat(this.fname13)) {
              this.alert.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
            }
            else {
              this.uploadDocument(exp, index);
            }
            //   if(this.validateFileName(this.fname1) && this.validateFileSize(this.fsize1) &&
            // this.validateFileFormat(this.fname1)){this.uploadFile(1);}
            // console.log("In section 2");
          }
        }
      }

      else {
        if (index == 0) {
          this.fname0 = null;
          this.fsize0 = null;
          this.myFile0 = null;
        }
        else if (index == 1) {
          this.fname1 = null;
          this.fsize1 = null;
          this.myFile1 = null;
        }
        else if (index == 2) {
          this.fname2 = null;
          this.fsize2 = null;
          this.myFile2 = null;
        }
        else if (index == 3) {
          this.fname3 = null;
          this.fsize3 = null;
          this.myFile3 = null;
        }
        else if (index == 4) {
          this.fname4 = null;
          this.fsize4 = null;
          this.myFile4 = null;
        }
        else if (index == 5) {
          this.fname5 = null;
          this.fsize5 = null;
          this.myFile5 = null;
        }
        else if (index == 6) {
          this.fname6 = null;
          this.fsize6 = null;
          this.myFile6 = null;
        }
        else if (index == 7) {
          this.fname7 = null;
          this.fsize7 = null;
          this.myFile7 = null;
        }
        else if (index == 8) {
          this.fname8 = null;
          this.fsize8 = null;
          this.myFile8 = null;
        }
        else if (index == 9) {
          this.fname9 = null;
          this.fsize9 = null;
          this.myFile9 = null;
        }
        else if (index == 10) {
          this.fname10 = null;
          this.fsize10 = null;
          this.myFile10 = null;
        }
        else if (index == 11) {
          this.fname11 = null;
          this.fsize11 = null;
          this.myFile11 = null;
        }
      }
    }
    this.getAttachments();
    if (this.attachForm16 && this.flgIndia) {
      this.flgShowForm16 = true
    }

    if (this.attachOffer && (this.flgIndia || this.ibpm)) {
      this.flgShowOfferLetter = true

    }

    if (this.attachPaySlips && this.flgIndia) {
      this.flgShowPaySlips = true

    }

    if (this.attachBankLOA && this.flgIndia) {
      this.flgShowBankLOA = true

    }

    if (this.attachBankStatement && this.flgIndia) {
      this.flgShowBankStatement = true

    }

    if (this.attachPFStatement && this.flgIndia) {
      this.flgShowPFStatement = true

    }

    if (this.attachWorkPermit && this.flgOnsite) {
      this.flgShowWorkPermit = true

    }

    if (this.attachW2 && this.flgOnsite) {
      this.flgShowW2 = true

    }

    if (this.attachContract && this.flgOnsite) {
      this.flgShowContractAgreement = true

    }

    if (this.attachPayStubs && this.flgOnsite) {
      this.flgShowPayStubs = true

    }

  }
  uploadFile(index: Number) {
    this.docType(this.inpString);
    let files: File;
    let fileName: String;
    if (index == 0) {
      files = this.myFile0;
      fileName = this.fname0;
    }
    else if (index == 1) {
      files = this.myFile1;
      fileName = this.fname1;
    }
    else if (index == 2) {
      files = this.myFile2;
      fileName = this.fname2;
    }
    else if (index == 3) {
      files = this.myFile3;
      fileName = this.fname3;
    }
    else if (index == 4) {
      files = this.myFile4;
      fileName = this.fname4;
    }
    else if (index == 5) {
      files = this.myFile5;
      fileName = this.fname5;
    }
    else if (index == 6) {
      files = this.myFile6;
      fileName = this.fname6;
    }
    else if (index == 7) {
      files = this.myFile7;
      fileName = this.fname7;
    }
    else if (index == 8) {
      files = this.myFile8;
      fileName = this.fname8;
    }
    else if (index == 9) {
      files = this.myFile9;
      fileName = this.fname9;
    }
    else if (index == 10) {
      files = this.myFile10;
      fileName = this.fname10;
    }
    else if (index == 11) {
      files = this.myFile11;
      fileName = this.fname11;
    }
    else if (index == 13) {
      files = this.myFile13;
      fileName = this.fname13;
    }

    if (!this.camera) {
      // let reader = new FileReader();
       this.spinner.show();
      // reader.readAsArrayBuffer(files);
      // //need to check this.spinner.show();
      // reader.onload = () => {
      //   let fileArrayBuffer: ArrayBuffer = reader.result as ArrayBuffer;
      //   // console.log("UploadFile:" + fileArrayBuffer);
      //   let lpdms: DMSNew = new DMSNew();
      //   lpdms.filename = fileName as string;
      //   lpdms.UserName = localStorage.getItem("intCandidateId");
      //   lpdms.documentTitle = fileName as string;
      //   let uintSet = new Uint8Array(fileArrayBuffer);
      //   var fileByteArray = new Array();
      //   for (var val in uintSet) {
      //     fileByteArray.push(uintSet[val]);
      //   }
      //   const STRING_CHAR = uintSet.reduce((data, byte)=> {
      //     return data + String.fromCharCode(byte);
      //     }, '');
      //   let base64String = btoa(STRING_CHAR);
      //   // console.log(base64String);
      //   //lpdms.docBytesAsBase64String = base64String;
      //   lpdms.docBytesAsBase64String = base64String;
      //console.log(JSON.stringify(lpdms));
      var reader = new FileReader();
      reader.readAsDataURL(files);
      reader.onloadend = (e) => {
        WorkexperienceComponent.Base64String = reader.result;
        WorkexperienceComponent.arr = String(WorkexperienceComponent.Base64String).split(",");
        WorkexperienceComponent.fileAsBasestring = WorkexperienceComponent.arr[1];
        let lpdms: DMSNew = new DMSNew();
        lpdms.filename = this.launchpadFileName;
        lpdms.UserName = localStorage.getItem("txtEmailId");
        lpdms.documentTitle = fileName as string;
        lpdms.DMSDocPath = localStorage.getItem("DMSSitePath");
        lpdms.docBytesAsBase64String = WorkexperienceComponent.fileAsBasestring;
        //console.log(lpdms);
        this._workexperienceservice.UploadPlainDocumentNew(lpdms).subscribe(response => {
          this.uploadDocResponse = response

          if (this.uploadDocResponse != null && this.uploadDocResponse.documentGUIDField.length > 0) {
            let workExDoc: WorkExDoc = new WorkExDoc();
            workExDoc.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
            workExDoc.intCandExpDetId = this.selectedExperience.intcandexpdetid;
            workExDoc.intSerialNo = this.serialNumber;
            workExDoc.strDocName = this.launchpadFileName;
            // workExDoc.strDocTypeCode = "WRKEXRL";
            workExDoc.strOriginalFileName = files.name;
            if (this.inpString == '2') {
              workExDoc.strDocTypeCode = 'WRERAL';

            }
            else if (this.inpString == '1') {
              workExDoc.strDocTypeCode = 'WRKEXRL';
            }
            workExDoc.strDocTypeCode = this.txtDocTypeCode
            if(!this.ibpm){
              if(index == 0){
               workExDoc.strDocTypeCode = 'WRKEXRL';
             }
             else if(index == 1){
               workExDoc.strDocTypeCode = 'WRERAL';
             }
             
           }
            if (index == 2) {
              workExDoc.strDocTypeCode = "OFFLETTER";
            }
            else if (index == 3) {
              workExDoc.strDocTypeCode = "PAYSLIPS";

            }
            else if (index == 4) {
              workExDoc.strDocTypeCode = "BANKSTAT";

            }
            else if (index == 5) {
              workExDoc.strDocTypeCode = "FORM16";

            }
            else if (index == 6) {
              workExDoc.strDocTypeCode = "PFSTAT";

            }
            else if (index == 7) {
              workExDoc.strDocTypeCode = "WORKPERMIT";

            }
            else if (index == 8) {
              workExDoc.strDocTypeCode = "CONTRACT";

            }
            else if (index == 9) {
              workExDoc.strDocTypeCode = "PAYSTUBS";

            }
            else if (index == 10) {
              workExDoc.strDocTypeCode = "W2STAT";

            }
            else if (index == 13) {
              workExDoc.strDocTypeCode = "BANKLOA";

            }
            else {
              // workExDoc.strDocTypeCode = "WRKEXRL";
              if(this.ibpm){
                if (this.inpString == '2') {
                  workExDoc.strDocTypeCode = 'WRERAL';
  
                }
                else if (this.inpString == '1') {
                  workExDoc.strDocTypeCode = 'WRKEXRL';
                }
                workExDoc.strDocTypeCode = this.txtDocTypeCode
              } 
            }
            workExDoc.tentativeDate = this.tentativeDate;
            workExDoc.DMSSitePath = localStorage.getItem("DMSSitePath");
            workExDoc.txtGUID_DMS = this.uploadDocResponse.documentGUIDField;

            // console.log(workExDoc);
            //console.log("Server Upload Response: " + JSON.stringify(this.uploadDocResponse));
            //console.log("Inserting Document Entry:" + JSON.stringify(workExDoc));
            this._workexperienceservice.InsertWorkExDocDetails(workExDoc).subscribe(response => {
              ////need to check this.spinner.hide();
              if (response == 0) {
                // console.log("Document uploaded Unsuccessful.");
                this.alert.error("Oops! Something went wrong while uploading, please try again.", this.mobile);
              }
              else if (response == 1) {
                // console.log("Document uploaded suceessfully.");
                this.callBGV = true;

                if (workExDoc.strDocTypeCode == 'WRKEXRL' || workExDoc.strDocTypeCode == 'WRERAL') {
                  this.AddAnswersforBGV(null);
                }

                this.alert.success("Success! Document is uploaded.", this.mobile);
              }
              else if (response == 2) {
                // console.log("Oops! An error occurred in document upload.");
                this.alert.error("Oops! An error occurred in document upload.", this.mobile);
              }
              else if (response == 3) {
                // console.log("Oops! An error occurred in document upload. Kindly contact HR to fix this issue.");
                this.alert.error("Oops! An error occurred in document upload. Kindly contact HR to fix this issue", this.mobile);
              }
              this.getAttachments();
            });
          }  this.spinner.hide();
        });
      }
    }
    if (this.camera) {
      this.docType(this.inpString);
       this.spinner.show();
       var reader = new FileReader();
    reader.onload = (event: any) => {
      var orientation = -1;
      this.imageCompress.compressFile(event.target.result, orientation, 60, 60).then(
        result => {
          var lpdmsCam = new DMSNew();
          WorkexperienceComponent.Base64String = result;
          WorkexperienceComponent.arr = String(WorkexperienceComponent.Base64String).split(",");
          WorkexperienceComponent.fileAsBasestring = WorkexperienceComponent.arr[1];
          // this.lpdmsCam=new DMSNew();
          lpdmsCam.filename = this.launchpadFileName;
          lpdmsCam.UserName = localStorage.getItem("txtEmailId");
          lpdmsCam.documentTitle = fileName as string;
          lpdmsCam.DMSDocPath = localStorage.getItem("DMSSitePath");
          lpdmsCam.docBytesAsBase64String = WorkexperienceComponent.fileAsBasestring;
          //console.log(lpdmsCam);
          this._workexperienceservice.UploadPlainDocumentNew(lpdmsCam).subscribe(response => {
            this.uploadDocResponse = response

              if (this.uploadDocResponse != null && this.uploadDocResponse.documentGUIDField.length > 0) {
                let workExDoc: WorkExDoc = new WorkExDoc();
                workExDoc.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
                workExDoc.intCandExpDetId = this.selectedExperience.intcandexpdetid;
                workExDoc.intSerialNo = this.serialNumber;
                workExDoc.strDocName = this.launchpadFileName;
                // workExDoc.strDocTypeCode = "WRKEXRL";
                workExDoc.strOriginalFileName = fileName;
                workExDoc.DMSSitePath = localStorage.getItem("DMSSitePath");
                workExDoc.txtGUID_DMS = this.uploadDocResponse.documentGUIDField;
                
                if (this.inpString == '2') {
                  workExDoc.strDocTypeCode = 'WRERAL';

                }
                else if (this.inpString == '1') {
                  workExDoc.strDocTypeCode = 'WRKEXRL';
                }
                workExDoc.strDocTypeCode = this.txtDocTypeCode

                if(!this.ibpm){
                   if(index == 0){
                    workExDoc.strDocTypeCode = 'WRKEXRL';
                  }
                  else if(index == 1){
                    workExDoc.strDocTypeCode = 'WRERAL';
                  }
                  
                }
                if (index == 2) {
                  workExDoc.strDocTypeCode = "OFFLETTER";
                }
                else if (index == 3) {
                  workExDoc.strDocTypeCode = "PAYSLIPS";

              }
              else if (index == 4) {
                workExDoc.strDocTypeCode = "BANKSTAT";

              }
              else if (index == 5) {
                workExDoc.strDocTypeCode = "FORM16";

              }
              else if (index == 6) {
                workExDoc.strDocTypeCode = "PFSTAT";

              }
              else if (index == 7) {
                workExDoc.strDocTypeCode = "WORKPERMIT";

              }
              else if (index == 8) {
                workExDoc.strDocTypeCode = "CONTRACT";

              }
              else if (index == 9) {
                workExDoc.strDocTypeCode = "PAYSTUBS";

              }
              else if (index == 10) {
                workExDoc.strDocTypeCode = "W2STAT";

              }
              else if (index == 13) {
                workExDoc.strDocTypeCode = "BANKLOA";

                }
                else {
                  if(this.ibpm){
                    if (this.inpString == '2') {
                      workExDoc.strDocTypeCode = 'WRERAL';
      
                    }
                    else if (this.inpString == '1') {
                      workExDoc.strDocTypeCode = 'WRKEXRL';
                    }
                    workExDoc.strDocTypeCode = this.txtDocTypeCode
                  } 
                  workExDoc.tentativeDate = this.tentativeDate;
                }
                // console.log("Server Upload Response: " + JSON.stringify(this.uploadDocResponse));
                // console.log("Inserting Document Entry:" + JSON.stringify(workExDoc));
                this._workexperienceservice.InsertWorkExDocDetails(workExDoc).subscribe(response => {
                  //this.spinner.hide();
                  if (response == 0) {
                    this.callBGV = true;

                  if (workExDoc.strDocTypeCode == 'WRKEXRL' || workExDoc.strDocTypeCode == 'WRERAL') {
                    this.AddAnswersforBGV(null);
                  }

                  // console.log("Document uploaded Unsuccessful.");
                  this.alert.error("Oops! Something went wrong while uploading, please try again.", this.mobile);
                }
                else if (response == 1) {
                  // console.log("Document uploaded suceessfully.");
                  this.alert.success("Success! Document is uploaded.", this.mobile);
                }
                else if (response == 2) {
                  // console.log("Oops! An error occurred in document upload.");
                  this.alert.error("Oops! An error occurred in document upload.", this.mobile);
                }
                else if (response == 3) {
                  // console.log("Oops! An error occurred in document upload. Kindly contact HR to fix this issue.");
                  this.alert.error("Oops! An error occurred in document upload. Kindly contact HR to fix this issue", this.mobile);
                }
                this.getAttachments();
              });
            } this.spinner.hide();
          });
        });
      }
        reader.readAsDataURL(files[0]);
       //need to check this.ng2ImgMax.compressImage(files, 0.4).subscribe(
      //   result => {
      //     var lpdmsCam = new DMSNew();
      //     let reader = new FileReader();
      //     reader.readAsDataURL(result);
      //     reader.onloadend = (e) => {
      //       WorkexperienceComponent.Base64String = reader.result;
      //       WorkexperienceComponent.arr = String(WorkexperienceComponent.Base64String).split(",");
      //       WorkexperienceComponent.fileAsBasestring = WorkexperienceComponent.arr[1];
      //       // this.lpdmsCam=new DMSNew();
      //       lpdmsCam.filename = this.launchpadFileName;
      //       lpdmsCam.UserName = localStorage.getItem("txtEmailId");
      //       lpdmsCam.documentTitle = fileName as string;
      //       lpdmsCam.DMSDocPath = localStorage.getItem("DMSSitePath");
      //       lpdmsCam.docBytesAsBase64String = WorkexperienceComponent.fileAsBasestring;
      //       //console.log(lpdmsCam);
      //       this._workexperienceservice.UploadPlainDocumentNew(lpdmsCam).subscribe(response => {
      //         this.uploadDocResponse = response

      //         if (this.uploadDocResponse != null && this.uploadDocResponse.documentGUIDField.length > 0) {
      //           let workExDoc: WorkExDoc = new WorkExDoc();
      //           workExDoc.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
      //           workExDoc.intCandExpDetId = this.selectedExperience.intcandexpdetid;
      //           workExDoc.intSerialNo = this.serialNumber;
      //           workExDoc.strDocName = this.launchpadFileName;
      //           // workExDoc.strDocTypeCode = "WRKEXRL";
      //           workExDoc.strOriginalFileName = fileName;
      //           workExDoc.DMSSitePath = localStorage.getItem("DMSSitePath");
      //           if (this.inpString == '2') {
      //             workExDoc.strDocTypeCode = 'WRERAL';

      //           }
      //           else if (this.inpString == '1') {
      //             workExDoc.strDocTypeCode = 'WRKEXRL';
      //           }
      //           workExDoc.strDocTypeCode = this.txtDocTypeCode
      //           if (index == 2) {
      //             workExDoc.strDocTypeCode = "OFFLETTER";
      //           }
      //           else if (index == 3) {
      //             workExDoc.strDocTypeCode = "PAYSLIPS";

      //           }
      //           else if (index == 4) {
      //             workExDoc.strDocTypeCode = "BANKSTAT";

      //           }
      //           else if (index == 5) {
      //             workExDoc.strDocTypeCode = "FORM16";

      //           }
      //           else if (index == 6) {
      //             workExDoc.strDocTypeCode = "PFSTAT";

      //           }
      //           else if (index == 7) {
      //             workExDoc.strDocTypeCode = "WORKPERMIT";

      //           }
      //           else if (index == 8) {
      //             workExDoc.strDocTypeCode = "CONTRACT";

      //           }
      //           else if (index == 9) {
      //             workExDoc.strDocTypeCode = "PAYSTUBS";

      //           }
      //           else if (index == 10) {
      //             workExDoc.strDocTypeCode = "W2STAT";

      //           }
      //           else if (index == 13) {
      //             workExDoc.strDocTypeCode = "BANKLOA";

      //           }
      //           else {
      //             // workExDoc.strDocTypeCode = "WRKEXRL";
      //             if (this.inpString == '2') {
      //               workExDoc.strDocTypeCode = 'WRERAL';

      //             }
      //             else if (this.inpString == '1') {
      //               workExDoc.strDocTypeCode = 'WRKEXRL';
      //             }
      //             workExDoc.strDocTypeCode = this.txtDocTypeCode
      //             workExDoc.tentativeDate = this.tentativeDate;
      //           }
      //           // console.log("Server Upload Response: " + JSON.stringify(this.uploadDocResponse));
      //           // console.log("Inserting Document Entry:" + JSON.stringify(workExDoc));
      //           this._workexperienceservice.InsertWorkExDocDetails(workExDoc).subscribe(response => {
      //             ////need to check this.spinner.hide();
      //             if (response == 0) {
      //               this.callBGV = true;

      //               if (workExDoc.strDocTypeCode == 'WRKEXRL' || workExDoc.strDocTypeCode == 'WRERAL') {
      //                 this.AddAnswersforBGV(null);
      //               }

      //               // console.log("Document uploaded Unsuccessful.");
      //               this.alert.error("Oops! Something went wrong while uploading, please try again.", this.mobile);
      //             }
      //             else if (response == 1) {
      //               // console.log("Document uploaded suceessfully.");
      //               this.alert.success("Success! Document is uploaded.", this.mobile);
      //             }
      //             else if (response == 2) {
      //               // console.log("Oops! An error occurred in document upload.");
      //               this.alert.error("Oops! An error occurred in document upload.", this.mobile);
      //             }
      //             else if (response == 3) {
      //               // console.log("Oops! An error occurred in document upload. Kindly contact HR to fix this issue.");
      //               this.alert.error("Oops! An error occurred in document upload. Kindly contact HR to fix this issue", this.mobile);
      //             }
      //             this.getAttachments();
      //           });
      //         } //need to check this.spinner.hide();
      //       });
      //     }
      //     //console.log(lpdmsCam.docBytesAsBase64String)
      //     //this.uploadFileCamera(lpdmsCam);
      //   },
      //   error => {
      //     //console.log('Oh no!', error);
      //   }
      // );
    }
  }
  uploadDocument(exp: WorkExperience, index: number) {
    //alert("Upload Document Method Invoked");
    //this.selectedExperience = exp;
    let workExInputParam = new WorkExInputParam();
    //console.log(this.selectedExperience);
    workExInputParam.CandExDetIdID = this.selectedExperience.intcandexpdetid as number;
    this._workexperienceservice.FetchExpDocSerialNo(workExInputParam).subscribe(
      responseData => {
        this.serialNumber = responseData[0].intserialno;
        // console.log(responseData[0].intserialno); 
        this.generateFileName(index);
        this.uploadFile(index);
      });
  }
  getAttachments() {
    // this.attachOffer=false
    // this.attach=false
    // this.attachPaySlips=false
    // this.attachBankStatement=false
    // this.attachForm16=false
    // this.attachPFStatement=false
    // this.attachWorkPermit=false
    // this.attachContract=false
    // this.attachPayStubs=false
    // this.attachW2=false
    // this.attachBankLOA=false
    // this.getBGVDetails()
    this.callBGV = false;

    this.lpFetchExpDocDetailsListOfferLetter = []
    this.lpFetchExpDocDetailsListWorkExp = []
    this.lpFetchExpDocDetailsListPaySlips = []
    this.lpFetchExpDocDetailsListBankStatement = []
    this.lpFetchExpDocDetailsListForm16 = []
    this.lpFetchExpDocDetailsListPFStatement = []
    this.lpFetchExpDocDetailsListWorkPermit = []
    this.lpFetchExpDocDetailsListContractAgreement = []
    this.lpFetchExpDocDetailsListPayStubs = []
    this.lpFetchExpDocDetailsListW2 = []
    this.lpFetchExpDocDetailsListBankLOA = []


    // this.flgRelieveingLetterYES=false
    // this.flgRelieveingLetterNO=false
    // this.flgOnsite=false
    // this.flgIndia=false
    this.dateError = false;
     this.spinner.show();
    let workExInputParam: WorkExInputParam = new WorkExInputParam();
    workExInputParam.CandidateId = this.selectedExperience.intcandexpdetid as number;
    this.intCandQualDetIdForBGV = this.selectedExperience.intcandexpdetid as number;
    this._workexperienceservice.FetchWorkExDocDetails(workExInputParam).subscribe(
      response => {
        if (response != null && response.length > 0) {
          //console.log(response);
          this.lpFetchExpDocDetailsList = response;
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() === "Work Experience/Relieving letter" || i.txtDocTypeCode.trim() === "Resignation Acceptance Letter/Mail") {

              this.lpFetchExpDocDetailsListWorkExp.push(i)

            }
            if (i.txtDocTypeCode.trim() === "BG Offer Letter") {
              this.lpFetchExpDocDetailsListOfferLetter.push(i)

            }
            if (i.txtDocTypeCode.trim() === "Pay Slips") {
              this.lpFetchExpDocDetailsListPaySlips.push(i)

            }
            if (i.txtDocTypeCode.trim() === "Bank Statements") {
              this.lpFetchExpDocDetailsListBankStatement.push(i)

            }
            if (i.txtDocTypeCode.trim() === "Form 16") {
              this.lpFetchExpDocDetailsListForm16.push(i)

            }
            if (i.txtDocTypeCode.trim() === "PF Statement") {
              this.lpFetchExpDocDetailsListPFStatement.push(i)

            }
            if (i.txtDocTypeCode.trim() === "Work Permit") {
              this.lpFetchExpDocDetailsListWorkPermit.push(i)

            }
            if (i.txtDocTypeCode.trim() === "Contract Agreement") {
              this.lpFetchExpDocDetailsListContractAgreement.push(i)

            }
            if (i.txtDocTypeCode.trim() === "Pay Stubs") {
              this.lpFetchExpDocDetailsListPayStubs.push(i)

            }
            if (i.txtDocTypeCode.trim() === "W2 Statements") {
              this.lpFetchExpDocDetailsListW2.push(i)

            }
            if (i.txtDocTypeCode.trim() === "Bank LOA Form") {
              this.lpFetchExpDocDetailsListBankLOA.push(i)

            }
          }


          for (var i of this.lpFetchExpDocDetailsList) {
            if ((i.txtDocTypeCode.trim() === "Work Experience/Relieving letter") || (i.txtDocTypeCode.trim() === 'Resignation Acceptance Letter/Mail')) {
              this.attach = (this.ibpm === true) ? true : false; 
              
              // console.log(i.txtDocTypeCode)
              // console.log("entereddFirst")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.attachPage = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.attachPage = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.attachPage = i.txtorigdocname;
              } if (i.intVerifiedStatus == 4 || i.intVerifiedStatus == 5) {
                this.flgWorkExVerified = true;
              }
              else {
                this.flgWorkExVerified = false;
              }
              if (i.intVerifiedStatus == 3) {
                this.WorkExRejected = true
              }
              else {
                this.WorkExRejected = false;
              }
               this.spinner.hide();

              if (i.txtDocTypeCode == 'Resignation Acceptance Letter/Mail') {
                this.selectedValue = '2';
                //console.log("inside 2")
                this.flgRAMYes = true;
                this.flgRAM ="yesram";
                this.flgWorkExpValueNo = true;
                this.flgWorkExpValueYes = false;
                this.flgWorkExpValue ="No";
                this.attachRAM = true;
                this.attach = (this.ibpm === true) ? true : false;  
             }
              else if (i.txtDocTypeCode == 'Work Experience/Relieving letter' || i.txtDocTypeCode == 'Work Experience Letter') {
              this.selectedValue = '1';

              this.flgWorkExpValueYes = true;
              this.flgWorkExpValue ="yes";
              this.flgWorkExpValueNo = false;
              this.attach = true;
              this.attachRAM = false;
                //console.log("inside 1")
              }
              this.tentativeDate = i.tentDate;
              break;
            }
            else {
              if(this.ibpm){
                this.attach = false
              }else if (!this.ibpm){
                this.attach = false
                this.attachRAM = false

              }
              
            }
          }

          for (var i of this.lpFetchExpDocDetailsList) {

            if (i.txtDocTypeCode.trim() == "BG Offer Letter") {
              this.attachOffer = true;
              // console.log("enteredoffer")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.offerattached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.offerattached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.offerattached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachOffer = false;
              this.flgShowOfferLetter = false;
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "Pay Slips") {
              this.attachPaySlips = true;
              // console.log("enteredpayslips")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.PaySlipsAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.PaySlipsAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.PaySlipsAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachPaySlips = false;
              this.flgShowPaySlips = false
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "Bank Statements") {
              this.attachBankStatement = true;
              console.log("enteredbankstatement")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.BankStatementsAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.BankStatementsAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.BankStatementsAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachBankStatement = false;
              this.flgShowBankStatement = false;
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "Form 16") {
              this.attachForm16 = true;
              console.log("enteredform16")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.FormAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.FormAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.FormAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachForm16 = false;
              this.flgShowForm16 = false;
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "PF Statement") {
              this.attachPFStatement = true;
              console.log("enteredpf")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.PFAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.PFAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.PFAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachPFStatement = false;
              this.flgShowPFStatement = false;
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "Work Permit") {
              this.attachWorkPermit = true;
              console.log("enteredworkpermit")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.WorkPermitAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.WorkPermitAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.WorkPermitAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachWorkPermit = false;
              this.flgShowWorkPermit = false
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "Contract Agreement") {
              this.attachContract = true;
              console.log("enteredcontract")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.ContractAgreementAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.ContractAgreementAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.ContractAgreementAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachContract = false;
              this.flgShowContractAgreement = false
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "Pay Stubs") {
              this.attachPayStubs = true;
              console.log("enteredpaystubs")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.PayStubsAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.PayStubsAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.PayStubsAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachPayStubs = false;
              this.flgShowPayStubs = false;
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "W2 Statements") {
              this.attachW2 = true;
              console.log("enteredw2")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.W2Attached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.W2Attached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.W2Attached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachW2 = false;
              this.flgShowW2 = false
            }
          }
          for (var i of this.lpFetchExpDocDetailsList) {
            if (i.txtDocTypeCode.trim() == "Bank LOA Form") {
              this.attachBankLOA = true;
              console.log("enteredw2")
              if (i.txtorigdocname.length > 20 && this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.BankLOAAttached = i.txtorigdocname.substring(0, 15) + "..." + ext;
              }
              else if (i.txtorigdocname.length > 35 && !this.mobile) {
                let ext = i.txtorigdocname.substring(i.txtorigdocname.lastIndexOf('.') + 1);
                this.BankLOAAttached = i.txtorigdocname.substring(0, 25) + "..." + ext;
              }
              else {
                this.BankLOAAttached = i.txtorigdocname;
              }

              //console.log("inside Attach");
              break;
            }
            else {
              //console.log("Not Attach");
              this.attachBankLOA = false;
              this.flgShowBankLOA = false;
            }
          }

          //console.log("inside Attach");

          if (this.attachForm16 && this.flgIndia) {
            this.flgShowForm16 = true
          }

          if (this.attachOffer && (this.flgIndia || this.ibpm)) {
            this.flgShowOfferLetter = true

          }

          if (this.attachPaySlips && this.flgIndia) {
            this.flgShowPaySlips = true

          }

          if (this.attachBankLOA && this.flgIndia) {
            this.flgShowBankLOA = true

          }

          if (this.attachBankStatement && this.flgIndia) {
            this.flgShowBankStatement = true

          }

          if (this.attachPFStatement && this.flgIndia) {
            this.flgShowPFStatement = true

          }

          if (this.attachWorkPermit && this.flgOnsite) {
            this.flgShowWorkPermit = true

          }

          if (this.attachW2 && this.flgOnsite) {
            this.flgShowW2 = true

          }

          if (this.attachContract && this.flgOnsite) {
            this.flgShowContractAgreement = true

          }

          if (this.attachPayStubs && this.flgOnsite) {
            this.flgShowPayStubs = true
          }
        }
        else if (response.length == 0) {
          if (this.attachContract) {

            this.attachContract = false
            this.flgShowContractAgreement = false
          }
          if (this.attachWorkPermit) {

            this.attachWorkPermit = false
            this.flgShowWorkPermit = false

          }
          if (this.attachW2) {

            this.attachW2 = false
            this.flgShowW2 = false
          }
          if (this.attachPayStubs) {

            this.attachPayStubs = false
            this.flgShowPayStubs = false
          }
          //India
          if (this.attachOffer) {

            this.attachOffer = false
            this.flgShowOfferLetter = false
          }
          if (this.attachBankStatement) {

            this.attachBankStatement = false
            this.flgShowBankStatement = false
          }
          if (this.attachBankLOA) {

            this.attachBankLOA = false
            this.flgShowBankLOA = false
          }
          if (this.attachPaySlips) {

            this.attachPaySlips = false
            this.flgShowPaySlips = false
          }
          if (this.attachForm16) {

            this.attachForm16 = false
            this.flgShowForm16 = false

          }
          if (this.attachPFStatement) {

            this.attachPFStatement = false
            this.flgShowPFStatement = false

          }
          if (this.attach) {
            this.attach = false;
          }
          if (this.attachRAM) {
            this.attachRAM = false;
          }

        }

        // console.log("Documents Found:" + JSON.stringify(this.lpFetchExpDocDetailsList));

        else {
          //console.log("else Not Attach");
           this.spinner.hide();
          // this.attach=false

          // this.attachOffer=false
          // this.attachPFStatement=false
          // this.attachForm16=false
          // this.attachPaySlips=false
          // this.attachBankLOA=false
          // this.attachBankStatement=false
          // this.attachContract=false
          // this.attachW2=false
          // this.attachWorkPermit=false
          // this.attachPayStubs=false
        }
         this.spinner.hide();
      }
    );
  }
  downloadFile(i: number, index: number) {
    let lpdms = new LPDms();
    // lpdms.documentTitle = this.lpFetchExpDocDetailsList[i].txtdocname;
    for (var ele of this.lpFetchExpDocDetailsList) {
      if (index == 0) {
        lpdms.documentTitle = this.lpFetchExpDocDetailsListWorkExp[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListWorkExp[i].txtDMSDocPath;
      }
      else if(index==1){
        lpdms.documentTitle = this.lpFetchExpDocDetailsList[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsList[i].txtDMSDocPath; 
      }
      else if(index==2){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListOfferLetter[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListOfferLetter[i].txtDMSDocPath;
      }
      else if(index==3){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListPaySlips[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListPaySlips[i].txtDMSDocPath;
      }
      else if(index==4){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListBankStatement[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListBankStatement[i].txtDMSDocPath;
      }
      else if(index==5){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListForm16[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListForm16[i].txtDMSDocPath;
      }
      else if(index==6){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListPFStatement[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListPFStatement[i].txtDMSDocPath;
      }
      else if(index==7){
        lpdms.documentTitle= this.lpFetchExpDocDetailsListWorkPermit[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListWorkPermit[i].txtDMSDocPath;
      }
      else if(index==8){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListContractAgreement[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListContractAgreement[i].txtDMSDocPath;
      }
      else if(index==9){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListPayStubs[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListPayStubs[i].txtDMSDocPath;
      }
      else if(index==10){
        lpdms.documentTitle = this.lpFetchExpDocDetailsListW2[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListW2[i].txtDMSDocPath;
      }
      else if(index==13){
        lpdms.documentTitle= this.lpFetchExpDocDetailsListBankLOA[i].txtdocname;
        lpdms.DMSDocPath = this.lpFetchExpDocDetailsListBankLOA[i].txtDMSDocPath;
      }
    }



    // console.log("Downloaded doc: " + JSON.stringify(lpdms));
     this.spinner.show();
    this._workexperienceservice.DownloadDocumentFromDMS(lpdms).subscribe(
      (res) => {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
           this.spinner.hide();
          GetBase64Data(res.FileData, res.FileName);
          var byteArray = this.base64ToArrayBuffer(res.FileData);
          var blob = new Blob([byteArray], { type: 'application/pdf' });
          (window.navigator as any).msSaveOrOpenBlob(blob, res.FileName);
        } else {
          GetBase64Data(res.FileData, res.FileName);
          var byteArray = this.base64ToArrayBuffer(res.FileData);
          var blob = new Blob([byteArray], { type: 'application/jpg' });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          // a.download = this.lpFetchExpDocDetailsList[i].txtorigdocname as string;
          for (var ele of this.lpFetchExpDocDetailsList) {
            if (index == 0) {
              a.download = this.lpFetchExpDocDetailsListWorkExp[i].txtorigdocname as string;


            }
            else if (index == 1) {
              a.download = this.lpFetchExpDocDetailsList[i].txtorigdocname as string;
            }
            else if (index == 2) {
              a.download = this.lpFetchExpDocDetailsListOfferLetter[i].txtorigdocname as string;


            }
            else if (index == 3) {
              a.download = this.lpFetchExpDocDetailsListPaySlips[i].txtorigdocname as string;

            }
            else if (index == 4) {
              a.download = this.lpFetchExpDocDetailsListBankStatement[i].txtorigdocname as string;


            }
            else if (index == 5) {
              a.download = this.lpFetchExpDocDetailsListForm16[i].txtorigdocname as string;


            }
            else if (index == 6) {
              a.download = this.lpFetchExpDocDetailsListPFStatement[i].txtorigdocname as string;


            }
            else if (index == 7) {
              a.download = this.lpFetchExpDocDetailsListWorkPermit[i].txtorigdocname as string;


            }
            else if (index == 8) {
              a.download = this.lpFetchExpDocDetailsListContractAgreement[i].txtorigdocname as string;


            }
            else if (index == 9) {
              a.download = this.lpFetchExpDocDetailsListPayStubs[i].txtorigdocname as string;


            }
            else if (index == 10) {
              a.download = this.lpFetchExpDocDetailsListW2[i].txtorigdocname as string;


            }
            else if (index == 13) {
              a.download = this.lpFetchExpDocDetailsListBankLOA[i].txtorigdocname as string;


            }
          }

          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
           this.spinner.hide();
        }
      }
    );
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
       this.spinner.hide();
    }
  }
  downloadDoc(value: String) {
     this.spinner.show();
    // console.log("Inside Doc download");

    let lpdms = new LPDms();
    lpdms.documentTitle = value;
    this._workexperienceservice.DownloadPlainDocument(lpdms)
      .subscribe(
        response => {
          if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
             this.spinner.hide();
            this.dmsDocument = response;
            GetBase64Data(response.FileData, response.FileName);
            var byteArray = this.base64ToArrayBuffer(response.FileData);
            var blob = new Blob([byteArray], { type: 'application/pdf' });
            (window.navigator as any).msSaveOrOpenBlob(blob, response.FileName);
          }
          else {
            this.dmsDocument = response;
            GetBase64Data(response.FileData, response.FileName);
            var byteArray = this.base64ToArrayBuffer(response.FileData);
            var blob = new Blob([byteArray], { type: 'application/pdf' });

            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            document.body.appendChild(a);
            a.setAttribute('style', 'display: none');
            a.href = url;
            a.download = response.FileName;
            a.click();
            window.URL.revokeObjectURL(url);
            a.remove();
             this.spinner.hide();

          }

        });
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
       this.spinner.hide();
    }
  }
  deleteFile(i: number, index: number) {
    //for (let ele of this.lpFetchExpDocDetailsList) {
     this.spinner.show();
    let workExInputParam: WorkExInputParam = new WorkExInputParam();
    for (var ele of this.lpFetchExpDocDetailsList) {
      if (index == 0) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListWorkExp[i].intExpId;


      }
      else if (index == 1) {

        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListWorkExp[i].intExpId;

      }
      else if (index == 2) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListOfferLetter[i].intExpId;


      }
      else if (index == 3) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListPaySlips[i].intExpId;

      }
      else if (index == 4) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListBankStatement[i].intExpId;


      }
      else if (index == 5) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListForm16[i].intExpId;


      }
      else if (index == 6) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListPFStatement[i].intExpId;


      }
      else if (index == 7) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListWorkPermit[i].intExpId;


      }
      else if (index == 8) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListContractAgreement[i].intExpId;


      }
      else if (index == 9) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListPayStubs[i].intExpId;


      }
      else if (index == 10) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListW2[i].intExpId;


      }
      else if (index == 13) {
        workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsListBankLOA[i].intExpId;


      }
    }
    // workExInputParam.CandExDetIdID = this.lpFetchExpDocDetailsList[i].intExpId;
    // console.log("Attachment Deleted:" + workExInputParam.CandExDetIdID);
    // console.log(JSON.stringify(workExInputParam))

    this._workexperienceservice.DeleteExpDocDetails(workExInputParam).subscribe(
      response => {
        // console.log("Delete Status:" + response);
        this.getAttachments();
        this.alert.success("Done! File is deleted.", this.mobile)
         this.spinner.hide();
        // this.ngOnInit();
      }
    );
    //}

    if (this.attachForm16 && this.flgIndia) {
      this.flgShowForm16 = true
    }

    if (this.attachOffer && (this.flgIndia || this.ibpm)) {
      this.flgShowOfferLetter = true

    }

    if (this.attachPaySlips && this.flgIndia) {
      this.flgShowPaySlips = true

    }

    else if (this.attachBankLOA && this.flgIndia) {
      this.flgShowBankLOA = true

    }

    if (this.attachBankStatement && this.flgIndia) {
      this.flgShowBankStatement = true

    }

    if (this.attachPFStatement && this.flgIndia) {
      this.flgShowPFStatement = true

    }

    if (this.attachWorkPermit && this.flgOnsite) {
      this.flgShowWorkPermit = true

    }

    if (this.attachW2 && this.flgOnsite) {
      this.flgShowW2 = true

    }

    if (this.attachContract && this.flgOnsite) {
      this.flgShowContractAgreement = true

    }

    if (this.attachPayStubs && this.flgOnsite) {
      this.flgShowPayStubs = true

    }


  }
  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }
  validateDates(strDate: Date, endDate: Date) {
    //     var oneDay = 24*60*60*1000;
    //    let currentJoiningDate: Date = new Date(this.selectedExperience.dtFromDate as string);
    //    let currentReleavingDate: Date = new Date(this.selectedExperience.dtToDate as string);
    //    var diff1=0;
    //    var diff2=0;
    //    if (this.workexperiences != null && this.workexperiences.length > 0 &&this.workexperiences[0].intcandexpdetid==this.selectedExperience.intcandexpdetid) {

    //      if(strDate.getTime() >= currentJoiningDate.getTime()){
    //      diff1 = Math.round((strDate.getTime() - currentJoiningDate.getTime())/oneDay);
    //    }
    //    else{
    //      diff1 = Math.round((currentJoiningDate.getTime() - strDate.getTime())/oneDay);
    //    }

    //    if(endDate.getTime() >= currentReleavingDate.getTime()){
    //      diff2 = Math.round((endDate.getTime()- currentReleavingDate.getTime())/oneDay);
    //    }
    //    else{
    //      diff2 = Math.round((currentReleavingDate.getTime() - endDate.getTime())/oneDay);
    //    }
    //    if (diff1 > 90 || diff2 > 90) {
    //            if(this.isSKAVA){
    //              this.dateError = false;
    //            }
    //            else{
    //               this.dateError = true;
    //            }
    //             this.dateError=false;
    //          } 
    //  }
    //  else if (currentJoiningDate.getMonth()  != strDate.getMonth() || currentReleavingDate.getMonth()  != endDate.getMonth()) {

    //    this.dateError = false;
    //  }
    //  else {
    //    this.dateError = false;
    //  }

    let tempStr: Date = strDate;
    let tempEnd: Date = endDate;

    if (tempStr >= tempEnd) {
      this.dateError = true;
      this.alert.error("Joining date cannot be later than relieving date.", this.mobile);
    }
    const diffTime = (this.doj.valueOf() - tempEnd.valueOf())
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 45 && diffDays > 0)
      this.flgResignationAcceptance = true;
    else
      this.flgResignationAcceptance = false;


    // else if (diff1 > 90 || diff2 > 90) {
    //         if(this.isSKAVA){
    //           this.dateError = false;
    //         }
    //         else
    //         {
    //         this.dateError = true;
    //         this.alert.error("Dates can only be changed within three months.", this.mobile);
    //         }
    //    } 

  }

  validateInsertDates(strDate: Date, endDate: Date) {
    //console.log(strDate ,endDate);
    let tempStr: Date = strDate;
    let tempEnd: Date = endDate;

    if (strDate >= endDate) {
      this.dateError = true;
      this.alert.error("Joining date cannot be later than relieving date.", this.mobile);
    } else {
      this.dateError = false;
    }
  }

  editMember(experience: WorkExperience) {
    if (this.lateral) {
      this.flgOnsite = false
      this.flgIndia = false
      // this.getBGVDetails();
      // console.log(this.flgCheckNoReason)
      // if(this.flgCheckNoReason){
      //   console.log("entered if")
      // this.flgRelieveingLetterYES=true
      // this.flgRelieveingLetterNO=false
      // to uncomment later   
      this.flgRelieveingLetterValue = 'N'
      this.flgRelieveingLetterNO = false
      this.flgRelieveingLetterYES = false

      this.flgWorkExpValueNo = false
      this.flgWorkExpValueYes = false
      this.flgRAMNo = false
      this.flgRAMYes = false
      this.flgWorkIndiaNo = false
      this.flgWorkIndia = ''
      this.flgRAM = ''
      this.flgWorkExpValue = ''

      // to uncomment later 
      this.flgIndiaOnsiteValue = 'N'
      // }
      // else{
      // console.log("entered else")
      // this.flgRelieveingLetterYES=true
      // this.flgRelieveingLetterNO=false
      // }
      // else{
      //   this.flgRelieveingLetterYES=false
      //   this.flgRelieveingLetterNO=true
      // }
      // this.flgRelieveingLetterValue='N'
      // this.flgRelieveingLetterYES=false
      // this.flgRelieveingLetterNO=false
      // this.flgOnsite=false
      // this.flgIndia=false
      if (this.attachForm16 && this.flgIndia) {
        this.flgShowForm16 = true
      }

      if (this.attachOffer && (this.flgIndia || this.ibpm)) {
        this.flgShowOfferLetter = true

      }

      if (this.attachPaySlips && this.flgIndia) {
        this.flgShowPaySlips = true

      }

      if (this.attachBankLOA && this.flgIndia) {
        this.flgShowBankLOA = true

      }

      if (this.attachBankStatement && this.flgIndia) {
        this.flgShowBankStatement = true

      }

      if (this.attachPFStatement && this.flgIndia) {
        this.flgShowPFStatement = true

      }

      if (this.attachWorkPermit && this.flgOnsite) {
        this.flgShowWorkPermit = true

      }

      if (this.attachW2 && this.flgOnsite) {
        this.flgShowW2 = true

      }

      if (this.attachContract && this.flgOnsite) {
        this.flgShowContractAgreement = true

      }

      if (this.attachPayStubs && this.flgOnsite) {
        this.flgShowPayStubs = true

      }
    }
    this.dateError = false;
    //console.log("Inside Edit")
    this.pfromDate = new Date(experience.dtFromDate as string);
    //this.fromDate = this.dateformatter.parseIndia(this.pfromDate.toLocaleDateString());
    this.fromDate = this.pfromDate;
    this.fromDateOld = new Date(experience.dtFromDate as string);
    this.ptoDate = new Date(experience.dtToDate as string);
    //this.toDate = this.dateformatter.parseIndia(this.ptoDate.toLocaleDateString());
    this.toDate = this.ptoDate;
    this.toDateOld = new Date(experience.dtToDate as string);
    let myDate1: Date = new Date(experience.dtFromDate as string);
    let myDate2: Date = new Date(experience.dtToDate as string);
    this.myFile0 = null;
    this.myFile1 = null;
    this.fname0 = null;
    this.fname1 = null;
    this.serialNumber = null;
    this.experienceForm = true;
    this.selectedExperience = experience;
    let workExInputParam: WorkExInputParam;
    workExInputParam = new WorkExInputParam();
    this.intCandQualDetIdForBGV = this.selectedExperience.intcandexpdetid as number;
    workExInputParam.CandExDetID = this.selectedExperience.intcandexpdetid as number;
     this.spinner.show();
    this.getBGVDetails();
    //let currentJoiningDate: Date = new Date(this.selectedExperience.dtFromDate as string);
    // let currentReleavingDate: Date = new Date(this.selectedExperience.dtToDate as string);
    this._workexperienceservice.FetchWorkExDocDetails(workExInputParam).subscribe(
      response => {
        this.lpFetchExpDocDetailsList = response;
        this.getAttachments();
        // console.log("Documents Found:" + JSON.stringify(this.lpFetchExpDocDetailsList));
         this.spinner.hide();
      }
    );
    this.porgName = this.selectedExperience.txtorgzworked;
    this.pcomapanyContactNo = this.selectedExperience.txtorgzphoneno;
    this.pcompanyAddress = this.selectedExperience.txtorgzaddress;
    this.pdesignation = this.selectedExperience.Designation;
    this.pjoiningdate = this.selectedExperience.dtFromDate.concat(" - ");
    this.pjoiningdate = this.pjoiningdate.concat(String(this.selectedExperience.dtToDate));
    this.preasonofseparation = this.selectedExperience.ReasonForSeperation;
    this.pempno = this.selectedExperience.txtorgzempid;
    this.pmgrName = this.selectedExperience.txtmanagername;
    this.pmgremailid = this.selectedExperience.txtmanageremailid;
    this.pmgrcontact = this.selectedExperience.txtmanagerphoneno;
    this.proleres = this.selectedExperience.MajorResponsibilities;
    this.pagency = this.selectedExperience.txtContractAgencyDetails;


    let currentJoiningDate: Date = new Date(this.selectedExperience.dtFromDate as string);
    let currentReleavingDate: Date = new Date(this.selectedExperience.dtToDate as string);

    if (this.workexperiences != null && this.workexperiences.length > 0 && this.workexperiences[0].intcandexpdetid == this.selectedExperience.intcandexpdetid && !this.ibpm) {
      this.minstartdate = new Date(currentJoiningDate.getFullYear(), currentJoiningDate.getMonth() - 2, 1);
      this.maxstartdate = new Date(currentJoiningDate.getFullYear(), currentJoiningDate.getMonth() + 3, 0);
      this.minenddate = new Date(currentReleavingDate.getFullYear(), currentReleavingDate.getMonth() - 2, 1);
      this.maxenddate = new Date(currentReleavingDate.getFullYear(), currentReleavingDate.getMonth() + 3, 0);
    }
    else {
      this.minstartdate = new Date(currentJoiningDate.getFullYear(), currentJoiningDate.getMonth() - 1, 1);
      this.maxstartdate = new Date(currentJoiningDate.getFullYear(), currentJoiningDate.getMonth() + 2, 0);
      this.minenddate = new Date(currentReleavingDate.getFullYear(), currentReleavingDate.getMonth() - 1, 1);
      this.maxenddate = new Date(currentReleavingDate.getFullYear(), currentReleavingDate.getMonth() + 2, 0);
    }


  }
  callModal() {
    if(!this.ibpm){
      this.updateBGV.intCandidateId = localStorage.getItem("intCandidateId");
      this.updateBGV.txtSection = this.selectedExperience.intcandexpdetid;
      this.dashboard.FetchBGVPopupStatus(this.updateBGV).subscribe(
        (res) => {
          if (res != null) {
            this.bgvShow = res;
            if (this.bgvShow.flgShow == 'Y') {
              const modalRef = this.modal.open(BgvcheckComponent, {
                panelClass: 'open-dialog',
                hasBackdrop: true,
              });
              modalRef.afterClosed().subscribe(result => {
                if (this.dashboard.modalClose == true) {
                  this.dashboard.modalClose = false;
                  modalRef.close();
                }
                else {
                  this.combMsgShow = true;
               //   UpdateButtonClick('updatereq');
               this.UpdateExpWorkdetailsBGVreq();
                }
              });
            }
            else {
              this.appService.updateForm = true;
            //  UpdateButtonClick('updatereq');
            this.UpdateExpWorkdetailsBGVreq();
            }
          }
  
           this.spinner.hide();
  
        }
      );
    }
    else {
      this.appService.updateForm = true;
    //  UpdateButtonClick('updatereq');
    this.UpdateExpWorkdetailsBGVreq();
    }
  }

  UpdateExpWorkdetailsBGVreq(){
    if (!this.dateError) {


       this.spinner.show();
      this._workexperienceservice.UpdateCandWorkExp(this.workExperienceUpdated).subscribe(
        responseData => {
          this.countrycode = null;
          this.flgWorkIndia = null;
          this.msg = responseData; this._workexperienceservice.FetchExpDetailsList().subscribe(
            responseData => {
              if (responseData != null && responseData.length > 0) {
                if (this.fromDateNew != null && this.toDateNew != null) {
                  if (this.fromDateOld.getDate() != this.fromDateNew.getDate() || this.fromDateOld.getMonth() != this.fromDateNew.getMonth() || this.fromDateOld.getFullYear() != this.fromDateNew.getFullYear() || this.toDateOld.getDate() != this.toDateNew.getDate() || this.toDateOld.getMonth() != this.toDateNew.getMonth() || this.toDateOld.getFullYear() != this.toDateNew.getFullYear()) {
                    this.onUpdateClickDeleteGapDetails();

                  }
                }
                else if (this.fromDateNew != null) {
                  if (this.fromDateOld.getDate() != this.fromDateNew.getDate() || this.fromDateOld.getMonth() != this.fromDateNew.getMonth() || this.fromDateOld.getFullYear() != this.fromDateNew.getFullYear()) {
                    this.onUpdateClickDeleteGapDetails();
                  }
                }
                else if (this.toDateNew != null) {
                  if (this.toDateOld.getDate() != this.toDateNew.getDate() || this.toDateOld.getMonth() != this.toDateNew.getMonth() || this.toDateOld.getFullYear() != this.toDateNew.getFullYear()) {
                    this.onUpdateClickDeleteGapDetails();
                  }
                }
                else {
                  //DO NOTHING(DO NOT DELETE GAPS)
                }
                this.workexperiences = responseData;  this.spinner.hide();
              }
              else {
                 this.spinner.hide();
              }
            });
        });
      if (this.combMsgShow == true) {
        if (this.mobile == true) {
          this.alert.success("Done! BGV Initiated & Work Experience Details are updated.", this.mobile);
        }
        if (this.mobile == false) { //this.popup();
          this.alert.success("Done! BGV Initiated & Work Experience Details are updated.", this.desktop);
        }
      } else {
        if (this.mobile == true) {
          this.alert.success("Done! Work Experience Details are updated.", this.mobile);
        }
        if (this.mobile == false) { //this.popup();
          this.alert.success("Done! Work Experience Details are updated.", this.desktop);
        }
      }
      if(!this.ibpm){
        if (this.bgvShow.flgShow == 'Y') {
          this.flgBGV = true;
        }
      }

      this.appService.updateForms();
    }
    else {
      this.alert.error("Invalid Dates, Please adhare to guidelines.", this.mobile);
    }
  }
  EditWorkDetails(form: NgForm, exp: WorkExperience) {
    if (form.value.flgbgvreq == 'Y') {
      window.scroll(0, 700);
      this.selectedExperience = exp;
      this.workExperienceUpdated = new WorkExperienceUpdate();
      this.workExperienceUpdated.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
      this.workExperienceUpdated.flgInsertUpdate = 'U';
      this.workExperienceUpdated.CandExDetIdID = this.selectedExperience.intcandexpdetid;
      this.workExperienceUpdated.strCompanyAdd = form.value.companyAddressRef;
      this.workExperienceUpdated.strCompanyNo = form.value.companyContactNoRef;
      this.workExperienceUpdated.strEmpno = form.value.empnoRef;
      this.workExperienceUpdated.strDepartment = this.selectedExperience.txtorgzdept;
      this.workExperienceUpdated.strManagerName = form.value.mgrnameRef;
      this.workExperienceUpdated.strManagerNo = form.value.mgrcontactRef;
      this.workExperienceUpdated.strManagerMailId = form.value.mgremailRef;
      this.workExperienceUpdated.strAgency = form.value.agentRef;
      this.workExperienceUpdated.strReason = form.value.reasonOfSeparationRef;
      this.workExperienceUpdated.strResponsibility = form.value.roleresRef;
      this.workExperienceUpdated.strPosition = this.selectedExperience.PositionType;
      this.workExperienceUpdated.flgEmploymentInIndia =  this.flgWorkIndia ?  this.flgWorkIndia :this.selectedExperience.flgEmploymentInIndia;
      this.workExperienceUpdated.txtCountryCode = this.countrycode? this.countrycode : this.selectedExperience.CountryCodeOfEmployment;
      
      let temp: Date = this.fromDate;
      this.workExperienceUpdated.strFromDate = this.dateformatter.formatMatDate(temp)
      //console.log( this.workExperienceUpdated.strFromDate)    
      temp = this.toDate;
      this.workExperienceUpdated.strToDate = this.dateformatter.formatMatDate(temp)
      //console.log(this.workExperienceUpdated.strToDate)
      this.workExperienceUpdated.strDesignation = form.value.designationRef;
      if (this.isSKAVA) {
        this.workExperienceUpdated.strOrgName = form.value.orgNameRef;
      }
      else {
        this.workExperienceUpdated.strOrgName = this.selectedExperience.txtorgzworked;
      }
      if (this.appService.updateForm == false) {
        this.callModal();
      }
      // if (this.appService.updateForm == true) {
       

      // }
    }
    else {
      window.scroll(0, 700);
      this.selectedExperience = exp;
      this.workExperienceUpdated = new WorkExperienceUpdate();
      this.workExperienceUpdated.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
      this.workExperienceUpdated.flgInsertUpdate = 'U';
      this.workExperienceUpdated.CandExDetIdID = this.selectedExperience.intcandexpdetid;
      this.workExperienceUpdated.strCompanyAdd = form.value.companyAddressRef;
      this.workExperienceUpdated.strCompanyNo = form.value.companyContactNoRef;
      this.workExperienceUpdated.strEmpno = form.value.empnoRef;
      this.workExperienceUpdated.strDepartment = this.selectedExperience.txtorgzdept;
      this.workExperienceUpdated.strManagerName = form.value.mgrnameRef;
      this.workExperienceUpdated.strManagerNo = form.value.mgrcontactRef;
      this.workExperienceUpdated.strManagerMailId = form.value.mgremailRef;
      this.workExperienceUpdated.strAgency = form.value.agentRef;
      this.workExperienceUpdated.strReason = form.value.reasonOfSeparationRef;
      this.workExperienceUpdated.strResponsibility = form.value.roleresRef;
      this.workExperienceUpdated.strPosition = this.selectedExperience.PositionType;
      this.workExperienceUpdated.flgEmploymentInIndia = this.flgWorkIndia ?  this.flgWorkIndia :this.selectedExperience.flgEmploymentInIndia;
      this.workExperienceUpdated.txtCountryCode= this.countrycode ? this.countrycode : this.selectedExperience.CountryCodeOfEmployment;
      let temp: Date = this.fromDate;
      this.workExperienceUpdated.strFromDate = this.dateformatter.formatMatDate(temp)
      //console.log( this.workExperienceUpdated.strFromDate)    
      temp = this.toDate;
      this.workExperienceUpdated.strToDate = this.dateformatter.formatMatDate(temp)
      //console.log(this.workExperienceUpdated.strToDate)
      this.workExperienceUpdated.strDesignation = form.value.designationRef;
      if (this.isSKAVA) {
        this.workExperienceUpdated.strOrgName = form.value.orgNameRef;
      }
      else {
        this.workExperienceUpdated.strOrgName = this.selectedExperience.txtorgzworked;
      }
      if (!this.dateError) {


         this.spinner.show();
        this._workexperienceservice.UpdateCandWorkExp(this.workExperienceUpdated).subscribe(
          responseData => {
            this.msg = responseData; this._workexperienceservice.FetchExpDetailsList().subscribe(
              responseData => {
                if (responseData != null && responseData.length > 0) {
                  this.countrycode = null;
                  this.flgWorkIndia = null;
                  if (this.fromDateNew != null && this.toDateNew != null) {
                    if (this.fromDateOld.getDate() != this.fromDateNew.getDate() || this.fromDateOld.getMonth() != this.fromDateNew.getMonth() || this.fromDateOld.getFullYear() != this.fromDateNew.getFullYear() || this.toDateOld.getDate() != this.toDateNew.getDate() || this.toDateOld.getMonth() != this.toDateNew.getMonth() || this.toDateOld.getFullYear() != this.toDateNew.getFullYear()) {
                      this.onUpdateClickDeleteGapDetails();

                    }
                  }
                  else if (this.fromDateNew != null) {
                    if (this.fromDateOld.getDate() != this.fromDateNew.getDate() || this.fromDateOld.getMonth() != this.fromDateNew.getMonth() || this.fromDateOld.getFullYear() != this.fromDateNew.getFullYear()) {
                      this.onUpdateClickDeleteGapDetails();
                    }
                  }
                  else if (this.toDateNew != null) {
                    if (this.toDateOld.getDate() != this.toDateNew.getDate() || this.toDateOld.getMonth() != this.toDateNew.getMonth() || this.toDateOld.getFullYear() != this.toDateNew.getFullYear()) {
                      this.onUpdateClickDeleteGapDetails();
                    }
                  }
                  else {
                    //DO NOTHING(DO NOT DELETE GAPS)
                  }
                  this.workexperiences = responseData;  this.spinner.hide();
                }
                else {
                   this.spinner.hide();
                }
              });
          });
        if (this.mobile == true) {
          this.alert.success("Done! Work Experience Details are updated.", this.mobile);
        }
        if (this.mobile == false) { //this.popup();
          this.alert.success("Done! Work Experience Details are updated.", this.desktop);
        }

      }
      else {
        this.alert.error("Invalid Dates, Please adhare to guidelines.", this.mobile);
      }
    }
  }

  insertNewWorkExp(form: NgForm) {
    // console.log("inside InserNewWorkExp");
    //  this.selectedExperience = exp;
    this.workExperienceUpdated = new WorkExperienceUpdate();
    this.workExperienceUpdated.CandidateID = parseInt(localStorage.getItem("intCandidateId"));
    this.workExperienceUpdated.flgInsertUpdate = 'I';
    this.workExperienceUpdated.CandExDetIdID = null;
    this.workExperienceUpdated.strCompanyAdd = form.value.companyAddressRef;
    this.workExperienceUpdated.strCompanyNo = form.value.companyContactNoRef;
    this.workExperienceUpdated.strEmpno = form.value.empnoRef;
    this.workExperienceUpdated.strDepartment = null;
    this.workExperienceUpdated.strManagerName = form.value.mgrnameRef;
    this.workExperienceUpdated.strManagerNo = form.value.mgrcontactRef;
    this.workExperienceUpdated.strManagerMailId = form.value.mgremailRef;
    this.workExperienceUpdated.strAgency = form.value.agentRef;
    this.workExperienceUpdated.strReason = form.value.reasonOfSeparationRef;
    this.workExperienceUpdated.strResponsibility = form.value.roleresRef;
    this.workExperienceUpdated.strPosition = null;
    let temp: Date = form.value.joinRef;
    this.workExperienceUpdated.strFromDate = this.dateformatter.formatMatDate(temp)
    //console.log( this.workExperienceUpdated.strFromDate)    
    temp = form.value.relRef;
    this.workExperienceUpdated.strToDate = this.dateformatter.formatMatDate(temp)
    //console.log(this.workExperienceUpdated.strToDate)
    this.workExperienceUpdated.strDesignation = form.value.designationRef;
    this.workExperienceUpdated.strOrgName = form.value.orgNameRef;
    // if(this.isSKAVA){
    //         this.workExperienceUpdated.strOrgName = form.value.orgNameRef;
    //       }
    //       else
    //       {
    //         this.workExperienceUpdated.strOrgName = this.selectedExperience.txtorgzworked;
    //       } 
    this.validateInsertDates(form.value.joinRef, form.value.relRef);
    if (!this.dateError) {
      //  console.log("inside not dateError");

       this.spinner.show();
      this._workexperienceservice.UpdateCandWorkExp(this.workExperienceUpdated).subscribe(
        responseData => {
          //console.log(responseData);
          this.countrycode = null;
          this.flgWorkIndia = null;
          this.msg = responseData; this._workexperienceservice.FetchExpDetailsList().subscribe(
            responseData => {
              if (responseData != null && responseData.length > 0) {
                if (this.fromDateNew != null && this.toDateNew != null) {
                  if (this.fromDateOld.getDate() != this.fromDateNew.getDate() || this.fromDateOld.getMonth() != this.fromDateNew.getMonth() || this.fromDateOld.getFullYear() != this.fromDateNew.getFullYear() || this.toDateOld.getDate() != this.toDateNew.getDate() || this.toDateOld.getMonth() != this.toDateNew.getMonth() || this.toDateOld.getFullYear() != this.toDateNew.getFullYear()) {
                    this.onUpdateClickDeleteGapDetails();

                  }
                }
                else if (this.fromDateNew != null) {
                  if (this.fromDateOld.getDate() != this.fromDateNew.getDate() || this.fromDateOld.getMonth() != this.fromDateNew.getMonth() || this.fromDateOld.getFullYear() != this.fromDateNew.getFullYear()) {
                    this.onUpdateClickDeleteGapDetails();
                  }
                }
                else if (this.toDateNew != null) {
                  if (this.toDateOld.getDate() != this.toDateNew.getDate() || this.toDateOld.getMonth() != this.toDateNew.getMonth() || this.toDateOld.getFullYear() != this.toDateNew.getFullYear()) {
                    this.onUpdateClickDeleteGapDetails();
                  }
                }
                else {
                  //DO NOTHING(DO NOT DELETE GAPS)
                }
                this.workexperiences = responseData;  this.spinner.hide();
              }
              else {
                 this.spinner.hide();
              }
            });
        });
      // this._workexperienceservice.InsertCandWorkExp(this.workExperienceUpdated)
      // .subscribe(
      //   updatedResponseEducationIN => {
      //     this.msg = updatedResponseEducationIN;
      //     //need to check this.spinner.hide();
      //     if (updatedResponseEducationIN != 0) {
      //       this.getEducationDetails();
      //       CloseDataSubmitModal();
      //       this.alertEducationIN.success("Done! WorkExperience details are added.", this.mobile);
      //       this.isTenthTwelth = false;
      //     }
      //   }
      // );


      if (this.mobile == true) {
        CloseDataSubmitModal();
        this.alert.success("Done! Work Experience Details are updated.", this.mobile);

      }
      if (this.mobile == false) { //this.popup();
        this.addNewWorkExp = false;
        this.alert.success("Done! Work Experience Details are updated.", this.desktop);
      }
      //added
      this.ngOnInit();
      this.datapresent = true;
      form.resetForm();
    }
    else {
      this.alert.error("Invalid Dates, Please adhare to guidelines.", this.mobile);
    }
  }


  CallJsForSubmitDataModal() {

    LoadDataSubmitModal();
    LoadJsForModalBackDrop();

  }


  closeEditMember() {
    this.experienceForm = false;
  }
  validateFileName(fname: String) {
    if (fname == null || fname == undefined) {
      return true;
    }
    let nameregex = RegExp('^[a-zA-Z0-9_. -]+$');
    if (nameregex.test(fname as string)) {
      return true
    }
    else {
      return false;
    }
  }
  validateFileSize(filesize: number) {
    if (filesize == null) {
      return true;
    }
    else if (filesize > 5242880) {
      return false;
    }
    else {
      return true;
    }
  }

  onChangeStartDate(event: any) {
    this.fromDateNew = event;
    // console.log("New value = " + this.fromDateNew.toString())
    // console.log("Previous value = " + this.fromDateOld.toString())
    if (this.fromDateOld.getDate() != this.fromDateNew.getDate() || this.fromDateOld.getMonth() != this.fromDateNew.getMonth() || this.fromDateOld.getFullYear() != this.fromDateNew.getFullYear()) {
      // console.log("correctStartDate")
      const dialogRef = this.dialog.open(DialogLogoutWorkExp, {
        width: '450px',
        height: '200px',

      });
    }
  }

  onChangeEndDate(event: any) {
    this.toDateNew = event;
    const diffTime = (this.doj.valueOf() - this.toDateNew.valueOf());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays <= 45 && diffDays > 0)
      this.flgResignationAcceptance = true;
    else
      this.flgResignationAcceptance = false;
    // console.log("New value = " + this.toDateNew.toString())
    // console.log("Previous value = " + this.toDateOld.toString())
    if (this.toDateOld.getDate() != this.toDateNew.getDate() || this.toDateOld.getMonth() != this.toDateNew.getMonth() || this.toDateOld.getFullYear() != this.toDateNew.getFullYear()) {
      // console.log("correctEndDate")
      const dialogRef = this.dialog.open(DialogLogoutWorkExp, {
        width: '450px',
        height: '200px',

      });
    }
  }

  onUpdateClickDeleteGapDetails() {
    let objindiagap = new IndiaInputParam()
    objindiagap.CandidateId = Number(localStorage.getItem('intCandidateId'))
    this._workexperienceservice.DeleteGapDetails(objindiagap).subscribe(res => {
      // console.log(res)
    }
    )
  }
  validateFileFormat(name: String) {
    if (name == null) {
      return true;
    }
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'pdf') {
      return true;
    }
    else {
      return false;
    }
  }
  validFileChosen(filename: String, filesize: number) {
    if (filename != null && this.validateFileFormat(filename) && this.validateFileName(filename) && this.validateFileSize(filesize)) {
      return true;
    }
    else {
      return false;
    }
  }
  generateFileName(index: Number) {
    let fileName: String;
    if (index == 0) { fileName = this.myFile0.name }
    else if (index == 1) { fileName = this.myFile1.name }
    else if (index == 2) { fileName = this.myFile2.name }
    else if (index == 3) { fileName = this.myFile3.name }
    else if (index == 4) { fileName = this.myFile4.name }
    else if (index == 5) { fileName = this.myFile5.name }
    else if (index == 6) { fileName = this.myFile6.name }
    else if (index == 7) { fileName = this.myFile7.name }
    else if (index == 8) { fileName = this.myFile8.name }
    else if (index == 9) { fileName = this.myFile9.name }
    else if (index == 10) { fileName = this.myFile10.name }
    else if (index == 11) { fileName = this.myFile11.name }
    else if (index == 13) { fileName = this.myFile13.name }


    this.launchpadFileName = parseInt(localStorage.getItem("intCandidateId")) + "_" + "WRKEXP" + "_" + this.selectedExperience.intcandexpdetid + "_" + this.serialNumber.toString() + '.' + fileName.substring(fileName.lastIndexOf('.') + 1);
    // console.log("File Name Generated:", this.launchpadFileName);
  }


  navigate() {
    if (this.order != "N" || !this.mobile) {
      if (this.order == "P" && this.mobile) {
        LoadOwlCarouselForGallery();
        LoadJsForDashBoardMobileCarousel();
        this.getPendingAction();
        this.appService.setHiddenDashBoard(false);
        this.router.navigateByUrl('lpau/step1-au/dummy', { skipLocationChange: true });
        setTimeout(() => this.router.navigate(['../lpindia']), 3000);
        setTimeout(() => ShowBottomBar(), 3000);
      } else if (this.order == "W") {
        this.router.navigate(['../lpindia/postjoining/workexperience'], { queryParams: { order: "W" } })
      }
      else if (this.order == "W" && !this.mobile) {
        this.router.navigate(['../lpindia/postjoining/workexperience'], { queryParams: { order: "W" } })
      }
      else {
        if (this.isgap) {
          ChggapIN();
          this.router.navigate(['../work-education-gap'], { relativeTo: this.r });
        }
        else {
          window.scroll(0, 670);
          ChgnsrIN();
          this.router.navigate(['../nsrcard'], { relativeTo: this.r });
        }
      }
    }
    else {
      this.router.navigate(['notification']);
    }


  }
  addNewWorkExperience() {
     this.spinner.show();
    this._workexperienceservice.FetchExpDetailsList().subscribe(
      responseData => {
        if (responseData != null && responseData.length > 0) {
          //console.log(responseData);
          this.workexperiences = responseData;
          this.countNewFlags = this.workexperiences.length;
           this.spinner.hide();
          if (this.flgIsRebadging) {
            if (this.countNewFlags >= 5) {
              this.alert.error("Only 5 Work Experience details can be added.", this.mobile)
            }
            else {
              this.minstartdate = new Date(1900, 1, 1)
              this.maxstartdate = new Date(this.maxdate.getFullYear(), this.maxdate.getMonth() + 3, 1);
              this.minenddate = new Date(1900, 1, 1)
              this.maxenddate = new Date(this.maxdate.getFullYear(), this.maxdate.getMonth() + 3, 1);
              this.addNewWorkExp = true;
            }
          }
        }
        else {
          if (this.flgIsRebadging) {
            if (this.countNewFlags < 5) {
              this.minstartdate = new Date(1900, 1, 1)
              this.maxstartdate = new Date(this.maxdate.getFullYear(), this.maxdate.getMonth() + 3, 1);
              this.minenddate = new Date(1900, 1, 1)
              this.maxenddate = new Date(this.maxdate.getFullYear(), this.maxdate.getMonth() + 3, 1);
              this.addNewWorkExp = true;
            }
          }
           this.spinner.hide();
        }
      });
  }

  cancelForm() {
    this.addNewWorkExp = false;
    if (this.mobile) {
      AddBodyscroll();
      CloseDataSubmitModal();
    }
  }

  getPendingAction() {
    let params = new IndiaInputParam();
    params.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
    params.EmailId = localStorage.getItem("txtEmailId");
    this._postService.getPendingActions(params)
      .subscribe(
        res => {
          if (res != null) {
            // console.log(res);
            LoadStyleForIconsIndia(res.ImageUpload, res.Emergency, res.BANK, res.BasicDetails, res.FamilyDetails,
              res.ContactDetails, res.EducationDetails, res.ExperienceDetails, res.PassportDetails, res.GapDetails,
              res.Disabilitydetails, res.NSRDetails, res.Pandetails, res.AadharDetails, res.Accommodation,
              res.Medical, res.Achievement, res.NatsDetails, res.Statutory);
          }
        });
  }

  getBGVDetails() {
     this.spinner.show();
    let bgvdetails = new BGVDetails();
    this.tentativeDate1 = null;
    bgvdetails.intCandQualDetId = this.intCandQualDetIdForBGV
    bgvdetails.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
    this._workexperienceservice.GetBGVDetails(bgvdetails)
      .subscribe(
        res => {
          if (res != null) {
            if (res.length > 0) {
              for (let ele of res) {
                if (ele.txtDataType.trim() == "WORKEXP") {
                  this.txtReason = ele.txtReasonCons;
                  this.docDeclaration = ele.flgDeclaration;
                  this.tentativeDate1 = ele.dtTentative;

                  if(ele.txtQuestionCode == "WRKEXRL"){
                    if (ele.flgResponse == "Y") {
                      this.flgWorkExpValue = "yes"
                      this.flgWorkExpValueYes = true
                      this.flgWorkExpValueNo = false
  
                      // this.flgRelieveingLetterValue = "yes0"
                      // this.flgRelieveingLetterYES = true
                      // this.flgRelieveingLetterNO = false
                      // this.flgIndia = false;
                      // this.flgOnsite = false;
  
                    }
                    else if (ele.flgResponse == "N")  {
                      this.flgWorkExpValue = "No"
                      this.flgWorkExpValueYes = false
                      this.flgWorkExpValueNo = true
                      // this.flgRelieveingLetterValue = "No"
                      // this.flgRelieveingLetterYES = false
                      // this.flgRelieveingLetterNO = true
                    }
                  }

                  if(ele.txtQuestionCode == "WRERAL"){
                    if (ele.flgResponse == "Y") {
                      this.flgWorkExpValue = "No"
                      this.flgWorkExpValueYes = false
                      this.flgWorkExpValueNo = true
                      this.flgRAM ="yesram"
                      this.flgRAMYes = true
                      this.flgRAMNo = false
  
                      // this.flgRelieveingLetterValue = "yes0"
                      // this.flgRelieveingLetterYES = true
                      // this.flgRelieveingLetterNO = false
                      // this.flgIndia = false;
                      // this.flgOnsite = false;
  
                    }
                    else if (ele.flgResponse == "N")  {
                      this.flgWorkExpValue = "No"
                      this.flgWorkExpValueYes = false
                      this.flgWorkExpValueNo = true

                      this.flgRAM ="Noram"
                      this.flgRAMYes = false
                      this.flgRAMNo = true
                      // this.flgRelieveingLetterValue = "No"
                      // this.flgRelieveingLetterYES = false
                      // this.flgRelieveingLetterNO = true
                    }
                  }
                 
                  if(this.ibpm){
                    if (ele.flgIndia == "Y") {
                      this.flgIndiaOnsiteValue = "yesIndia"
                      this.flgIndia = true
                      this.flgOnsite = false
                      if (this.attachForm16 && this.flgIndia) {
                        this.flgShowForm16 = true
                      }
  
                      if (this.attachOffer && (this.flgIndia || this.ibpm)) {
                        this.flgShowOfferLetter = true
  
                      }
  
                      if (this.attachPaySlips && this.flgIndia) {
                        this.flgShowPaySlips = true
  
                      }
  
                      if (this.attachBankLOA && this.flgIndia) {
                        this.flgShowBankLOA = true
  
                      }
  
                      if (this.attachBankStatement && this.flgIndia) {
                        this.flgShowBankStatement = true
  
                      }
  
                      if (this.attachPFStatement && this.flgIndia) {
                        this.flgShowPFStatement = true
  
                      }
  
  
                      // this.flgShowBankLOA=true
                      // this.flgShowBankStatement=true
                      // this.flgShowPaySlips=true
                      // this.flgShowForm16=true
                      this.flgShowWorkPermit = false
                      this.flgShowW2 = false
                      this.flgShowContractAgreement = false
                      this.flgShowPayStubs = false
  
  
                    }
                    else if (ele.flgIndia == "N") {
                      this.flgIndiaOnsiteValue = "yesOnsite"
                      this.flgIndia = false
                      this.flgOnsite = true
                      if (this.attachWorkPermit && this.flgOnsite) {
                        this.flgShowWorkPermit = true
  
                      }
  
  
                      if (this.attachW2 && this.flgOnsite) {
                        this.flgShowW2 = true
  
                      }
  
                      if (this.attachContract && this.flgOnsite) {
                        this.flgShowContractAgreement = true
  
                      }
  
                      if (this.attachPayStubs && this.flgOnsite) {
                        this.flgShowPayStubs = true
  
                      }
  
                      this.flgShowBankLOA = false
                      this.flgShowBankStatement = false
                      this.flgShowPaySlips = false
                      this.flgShowForm16 = false
                      this.flgShowOfferLetter = false
                      this.flgShowPFStatement = false
  
                    }
                  } 
                }

                 this.spinner.hide();
              }
            }
            else {
              this.txtReason = ""
              this.flgCheckNoReason = true
               this.spinner.hide();
            }
          }
          else {
            this.txtReason = ""
            this.flgCheckNoReason = true
             this.spinner.hide();
          }
        }
      )

  if(this.ibpm){
    if (this.attachForm16 && this.flgIndia) {
      this.flgShowForm16 = true
    }

    if (this.attachOffer && (this.flgIndia || this.ibpm)) {
      this.flgShowOfferLetter = true

    }

    if (this.attachPaySlips && this.flgIndia) {
      this.flgShowPaySlips = true

    }

        if (this.attachBankLOA && this.flgIndia) {
          this.flgShowBankLOA = true
    
        }
    
        if (this.attachBankStatement && this.flgIndia) {
          this.flgShowBankStatement = true
    
        }
    
        if (this.attachPFStatement && this.flgIndia) {
          this.flgShowPFStatement = true
    
        }
    
        if (this.attachWorkPermit && this.flgOnsite) {
          this.flgShowWorkPermit = true
    
        }
    
        if (this.attachW2 && this.flgOnsite) {
          this.flgShowW2 = true
    
        }
    
        if (this.attachContract && this.flgOnsite) {
          this.flgShowContractAgreement = true
    
        }
    
        if (this.attachPayStubs && this.flgOnsite) {
          this.flgShowPayStubs = true
    
        }
      }
  }



  AddAnswersforBGV(form : NgForm) {
    this.spinner.show();
    let bgvdetails = new BGVDetails();
    if(this.flgWorkExpValueNo && !this.flgWorkExpValueYes && !this.flgRAMNo && !this.flgRAMYes){
    bgvdetails.txtDataType = 'WORKEXP'
    // console.log(this.selectedValue,this.callBGV);


    // bgvdetails.txtQualificationCode=this.txtQualCodeForBGV
    bgvdetails.intCandQualDetId = this.intCandQualDetIdForBGV;
    bgvdetails.dtTentative = new Date((new Date(form.value.tntDate)).getFullYear(), (new Date(form.value.tntDate)).getMonth(), (new Date(form.value.tntDate)).getDate(), 9, 0, 0);
    bgvdetails.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
    bgvdetails.txtQualificationCode = this.selectedExperience.txtorgzworked;
    bgvdetails.txtQuestionCode = 'WRKEXRL'
    if (this.flgWorkExpValue == "yes") {
      bgvdetails.flgResponse = 'Y'
        bgvdetails.txtReasonCons = 'NA';
      }
      else if (this.flgWorkExpValue == "No"){
        bgvdetails.flgResponse = 'N'
        bgvdetails.txtReasonCons = form.value.noreasonprov;
        // console.log(remarks,bgvdetails.txtReasonCons);
  
      }
    }

    if( this.flgRAMNo && !this.flgRAMYes && this.flgWorkExpValueNo){
      bgvdetails.txtDataType = 'WORKEXP'
      // console.log(this.selectedValue,this.callBGV);
  
  
      // bgvdetails.txtQualificationCode=this.txtQualCodeForBGV
      bgvdetails.intCandQualDetId = this.intCandQualDetIdForBGV;
      bgvdetails.dtTentative = new Date((new Date(form.value.tntDate)).getFullYear(), (new Date(form.value.tntDate)).getMonth(), (new Date(form.value.tntDate)).getDate(), 9, 0, 0);
  
      bgvdetails.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
      bgvdetails.txtQualificationCode = this.selectedExperience.txtorgzworked;
      bgvdetails.txtQuestionCode = 'WRERAL'
      if (this.flgRAM == "yesram") {
        bgvdetails.flgResponse = 'Y'
        // bgvdetails.txtReasonCons = 'NA';
      }
      else if (this.flgRAM == "Noram"){
        bgvdetails.flgResponse = 'N'
        bgvdetails.txtReasonCons = form.value.noreasonprov;
        // console.log(remarks,bgvdetails.txtReasonCons);
  
      }
    }
   

    
    // console.log(this.flgRelieveingLetterValue);

    if (this.flgWorkExpValue == "yes" ) {
      bgvdetails.txtDataType = 'WORKEXP'
      bgvdetails.intCandQualDetId = this.intCandQualDetIdForBGV;
      bgvdetails.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
      bgvdetails.txtQualificationCode = this.selectedExperience.txtorgzworked;
      bgvdetails.txtQuestionCode = 'WRKEXRL'
      bgvdetails.flgResponse = 'Y'
      // bgvdetails.txtReasonCons = 'NA'
      bgvdetails.dtTentative=this.today;
    }else if(this.flgRAM == "yesram" ){
      bgvdetails.txtDataType = 'WORKEXP'
      bgvdetails.intCandQualDetId = this.intCandQualDetIdForBGV;
      bgvdetails.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
      bgvdetails.txtQualificationCode = this.selectedExperience.txtorgzworked;
      bgvdetails.txtQuestionCode = 'WRERAL'
      bgvdetails.flgResponse = 'Y'
      // bgvdetails.txtReasonCons = 'NA'
      bgvdetails.dtTentative=this.today;
    }
    else {
      bgvdetails.flgResponse = 'N'
      bgvdetails.txtReasonCons = form.value.noreasonprov
      // console.log(remarks,bgvdetails.txtReasonCons);

    }

    if(this.ibpm){
    if (this.flgIndiaOnsiteValue == "yesIndia") {
      bgvdetails.flgIndia = 'Y'
      if (this.attachForm16 && this.flgIndia) {
        this.flgShowForm16 = true
      }

      if (this.attachOffer && (this.flgIndia || this.ibpm)) {
        this.flgShowOfferLetter = true

      }

      if (this.attachPaySlips && this.flgIndia) {
        this.flgShowPaySlips = true

      }

      if (this.attachBankLOA && this.flgIndia) {
        this.flgShowBankLOA = true

      }

      if (this.attachBankStatement && this.flgIndia) {
        this.flgShowBankStatement = true

      }

      if (this.attachPFStatement && this.flgIndia) {
        this.flgShowPFStatement = true

      }


      // this.flgShowBankLOA=true
      // this.flgShowBankStatement=true
      // this.flgShowPaySlips=true
      // this.flgShowForm16=true
      this.flgShowWorkPermit = false
      this.flgShowW2 = false
      this.flgShowContractAgreement = false
      this.flgShowPayStubs = false

    }
    else if (this.flgIndiaOnsiteValue == "yesOnsite") {
      bgvdetails.flgIndia = 'N'
      if (this.attachWorkPermit && this.flgOnsite) {
        this.flgShowWorkPermit = true

      }

      if (this.attachW2 && this.flgOnsite) {
        this.flgShowW2 = true

      }

      if (this.attachContract && this.flgOnsite) {
        this.flgShowContractAgreement = true

      }

      if (this.attachPayStubs && this.flgOnsite) {
        this.flgShowPayStubs = true

      }

      this.flgShowBankLOA = false
      this.flgShowBankStatement = false
      this.flgShowPaySlips = false
      this.flgShowForm16 = false
      this.flgShowOfferLetter = false
      this.flgShowPFStatement = false

    }
    }

    
    // bgvdetails.flgResponse='N'
   // bgvdetails.dtTentative = this.today;
    bgvdetails.flgDeclaration = this.docDeclaration;
    // console.log(this.selectedValue,bgvdetails.flgResponse);
    
    // if (this.selectedValue == '1' && this.callBGV) {
    //   bgvdetails.txtQuestionCode = 'WRERAL';
    //   bgvdetails.flgResponse = 'N';
    //   bgvdetails.flgDeclaration = 'Y';
    // }
    // else if (this.selectedValue == '2' && this.callBGV) {
    //   bgvdetails.txtQuestionCode = 'WRKEXRL';
    //   bgvdetails.flgResponse = 'N';
    //   bgvdetails.flgDeclaration = 'Y';
    // }
    this._workexperienceservice.AddBGVDetails(bgvdetails)
      .subscribe(
        res => {
          this.alert.success("Done! Details are updated successfully.", this.mobile);
          // form.resetForm();
          this.getBGVDetails();
        }
      );
  }
  onlyRelieveingLetterSelectYes(value) {
    // console.log(value)
    this.selectedValue = '';
    this.flgIndiaOnsiteValue = "";
    this.docDeclaration = "";
    this.flgRelieveingLetterValue = value
    if (this.flgRelieveingLetterValue == "yes0") {
      this.flgRelieveingLetterYES = true
      this.flgRelieveingLetterNO = false
      this.flgOnsite = false
      this.flgIndia = false
      this.flgShowOfferLetter = false
      this.flgShowPaySlips = false
      this.flgShowBankLOA = false
      this.flgShowBankStatement = false
      this.flgShowForm16 = false
      this.flgShowPFStatement = false
      this.flgShowWorkPermit = false
      this.flgShowW2 = false
      this.flgShowContractAgreement = false
      this.flgShowPayStubs = false
    }



  }
  onlyRelieveingLetterSelectNo(value) {
    this.selectedValue = '';
    this.flgRelieveingLetterValue = value

    if (this.flgRelieveingLetterValue == "No0") {
      this.flgRelieveingLetterYES = false
      this.flgRelieveingLetterNO = true
      // this.flgShowOfferLetter=false
      //     this.flgShowPaySlips=false
      //     this.flgShowBankLOA=false
      //     this.flgShowBankStatement=false
      //     this.flgShowForm16=false
      //     this.flgShowPFStatement=false
      //     this.flgShowWorkPermit=false
      //     this.flgShowW2=false
      //     this.flgShowContractAgreement=false
      //     this.flgShowPayStubs=false
    }

  }

  SelectIndiaYes(value) {
    console.log(value)
    this.flgIndiaOnsiteValue = value
    if (this.flgIndiaOnsiteValue == "yesIndia") {
      this.flgIndia = true
      this.flgOnsite = false
      if (this.attachForm16 && this.flgIndia) {
        this.flgShowForm16 = true
      }

      if (this.attachOffer && (this.flgIndia || this.ibpm)) {
        this.flgShowOfferLetter = true

      }

      if (this.attachPaySlips && this.flgIndia) {
        this.flgShowPaySlips = true

      }

      if (this.attachBankLOA && this.flgIndia) {
        this.flgShowBankLOA = true

      }

      if (this.attachBankStatement && this.flgIndia) {
        this.flgShowBankStatement = true

      }

      if (this.attachPFStatement && this.flgIndia) {
        this.flgShowPFStatement = true

      }


      // this.flgShowBankLOA=true
      // this.flgShowBankStatement=true
      // this.flgShowPaySlips=true
      // this.flgShowForm16=true
      this.flgShowWorkPermit = false
      this.flgShowW2 = false
      this.flgShowContractAgreement = false
      this.flgShowPayStubs = false
    }



  }
  SelectOnsiteYes(value) {
    this.flgIndiaOnsiteValue = value
    if (this.flgIndiaOnsiteValue == "yesOnsite") {
      this.flgIndia = false
      this.flgOnsite = true
      if (this.attachWorkPermit && this.flgOnsite) {
        this.flgShowWorkPermit = true

      }

      if (this.attachW2 && this.flgOnsite) {
        this.flgShowW2 = true

      }

      if (this.attachContract && this.flgOnsite) {
        this.flgShowContractAgreement = true

      }

      if (this.attachPayStubs && this.flgOnsite) {
        this.flgShowPayStubs = true

      }
      this.flgShowBankLOA = false
      this.flgShowBankStatement = false
      this.flgShowPaySlips = false
      this.flgShowForm16 = false
      this.flgShowOfferLetter = false
      this.flgShowPFStatement = false


    }

  }

  
  // new interface related changes -- Service/workexp
  onlyServiceorWorkExpYes(value) {
    this.flgWorkExpValue = value
    if (this.flgWorkExpValue == "yes") { 
      this.flgWorkExpValueYes = true;
      this.flgWorkExpValueNo = false;  
      this.flgRAMYes = false; 
      this.flgRAMNo = false;
      this.flgRAM ='';
    }
  }
    // new interface related changes -- Service/workexp
    onlyServiceorWorkExpNo(value) {
      this.flgWorkExpValue = value
      if (this.flgWorkExpValue == "No") {
        this.flgWorkExpValueYes = false;
        this.flgWorkExpValueNo = true;
        this.flgRAMYes = false; 
        this.flgRAMNo = false;
        this.flgRAM ='';
      }
    }

    // new interface related changes -- RAM
  onlyRAMYes(value) {
    this.flgRAM = value
    if (this.flgRAM == "yesram") { 
      this.flgRAMYes = true;
      this.flgRAMNo = false;    
    }
  }
    // new interface related changes -- RAM
    onlyRAMNo(value) {
      this.flgRAM = value
      if (this.flgRAM == "Noram") {
        this.flgRAMYes = false;
        this.flgRAMNo = true;
      }
    }

    EmpIndia(value){
      this.flgWorkIndia = value
      if(this.flgWorkIndia== 'Y'){
        this.flgWorkIndiaNo = false;
        this.countrycode = 'IN';
      }
    }

    EmpNoIndia(value){
      this.flgWorkIndia = value
      if(this.flgWorkIndia== 'N'){
        this.flgWorkIndiaNo = true;
      }
    }


    // Country of emp

    
  searchcountry = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term.length < 1 ? []
      : this.countryName.filter(v => v.toLowerCase().indexOf(term.toLowerCase()) > -1).slice(0, 10))
  )



  focusOutFunctionForCountry1(form:NgForm){
    if(form.value.country!='' && form.value.country!=null){
      // console.log(form.value.tfrom);
    for(let i of this.countryName){
      if(form.value.country.trim().toLowerCase()==i.trim().toLowerCase()){
        this.errC=false;
        return;
      }else{
        this.errC=true;
      }
    } 
  }
  }

countrySelected($event) {
  this.internationalCountry = $event.item;
 const selectedCountry= this.countryList.find(country => country.txtCountryName === this.internationalCountry);
  if(selectedCountry){
    this.countrycode = selectedCountry.txtCountryCode;
    // this.countryCodeControl.setValue(this.countrycode);
  }
 
}

updateFlgWorkNo(exp: any) {
  if(exp.flgEmploymentInIndia === 'N'){
    this.flgWorkIndiaNo = true;
  }
}

getAllCountries(){
  this._resiIndiaService.GetCountry()
  .subscribe(
    response => {
      this.countryList = response;
      // this.iscountry = true;
      for (let c of this.countryList) {
        let name = c.txtCountryName;
        let code = c.txtCountryCode;
        this.countryName.push(name);
        // this.countrycode.push(code);
      }
      
    }
  );
} 

}

declare function logoutdialog(): any;
@Component({
  selector: 'dialog-Logout',
  templateUrl: 'dialog-Logout.html',
})
export class DialogLogoutWorkExp {
  mobile: boolean;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogLogoutWorkExp>, private authService: AuthService, private router: Router, private Appservice: AppService) {

    this.mobile = this.Appservice.mobile;
    if (!this.mobile) { logoutdialog() }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}


