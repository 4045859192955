import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../app.service';
import { GetAttendanceDetails, UpdateAttendanceDetails, DatatoGetDetails } from '../models/Attendace-us.model';
import { DashboardIndiaService } from '../../lpindia/dashboard-india/dashboard-india.service'
import { NgForm } from '@angular/forms';
import { AlertService } from 'src/app/utils/alert/alert.service';

declare function LoadOwlCarouselForGallery() :any;
declare function LoadJsForDashBoardMobileCarousel() : any;
declare function HideBottomBar() : any;
declare function ShowBottomBar() : any;
declare function AddBodyscroll():any;
declare function LoadScrollJS(): any;
declare function LoadAttendanceCloseModalWeb():any;
@Component({
  selector: 'app-attendance-us',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.css']
})
export class AttendanceComponent implements OnInit {
  mobileUS:boolean;
  usCurrentSession:GetAttendanceDetails = null;
  usCandidateAttendance:UpdateAttendanceDetails[] = [];
  uscodeDetails:GetAttendanceDetails[] = [];
  usfresher:Boolean=localStorage.getItem("SourceType").toUpperCase().trim()=="CAMPUS";
  uscodeError : Boolean = false;
  ussessionAttended: Boolean = false;
  // usshowAttendanceIcon :Boolean = Number(localStorage.getItem("DaysLeft"))<=0 && Number(localStorage.getItem("DaysLeft"))>=-2;
  usshowAttendanceIcon :Boolean = Number(localStorage.getItem("DaysLeft"))<=0;

  errmsgUS:string = "";
  codeAUS:string = "";
  uscurrentEvent:string = "";
  uscurrentAvail:Boolean = false;
  attProvided:string ="";
  constructor(
    private breakpointObserver : BreakpointObserver,
    private usappService : AppService,
    private USalert : AlertService,
    private usdashboardService : DashboardIndiaService
  ) { 
    this.breakpointObserver
      .observe(['(max-width: 599px)'])
      .subscribe((state: BreakpointState) => {
        if (state.matches) {
          this.usappService.setIsMobile(true);
          this.mobileUS = true;
        } else {
          this.usappService.setIsMobile(false);
          this.mobileUS = false;
         
        }
      });
  }

  ngOnInit() {
    this.GetUSAttendanceCodeDetails();
    LoadScrollJS();
    window.scroll(0, 0);
    HideBottomBar();
  }

  GetUSAttendanceCodeDetails(){
    let dataUs = new DatatoGetDetails();
    dataUs.Doj = localStorage.getItem("CandDOJ");
    dataUs.location = localStorage.getItem("Location");
    //dataUs.ConfirmedOBLOC = localStorage.getItem("ConfirmedOBLOC");
    dataUs.candidateid = Number(localStorage.getItem("CandidateID"));
    if(this.usfresher){
      dataUs.identification = "F";
    }
    else{
      dataUs.identification = "L";
    }
    let todayUS = new Date();
    
    let currentHourUS = todayUS.getHours();
    let currentMinUS = todayUS.getMinutes();
    this.usdashboardService.GetAttendanceDetails(dataUs).subscribe(
      response=>{
        if(response!=null && response.length >0 ){
        this.uscodeDetails = response;
        for(let i of this.uscodeDetails){
          let fromhUS= Number(i.FromTime.split(":")[0]);
          let frommUS = Number(i.FromTime.split(":")[1]);
          let tohUS= Number(i.ToTime.split(":")[0]);
          let tomUS = Number(i.ToTime.split(":")[1]);
          if(currentHourUS == fromhUS && currentHourUS == tohUS && currentMinUS >= frommUS && currentMinUS <= tomUS)
          {
            this.usCurrentSession = i;
            this.uscurrentEvent = this.usCurrentSession.txtEvent;
            this.usshowAttendanceIcon = true;
            this.uscurrentAvail = true;
            this.GetUSCandidateAttendanceDetails();
          }
          else if(currentHourUS == fromhUS && currentHourUS < tohUS && currentMinUS >= frommUS){
            this.usCurrentSession = i;
            this.uscurrentEvent = this.usCurrentSession.txtEvent;
            this.usshowAttendanceIcon = true;
            this.uscurrentAvail = true;
            this.GetUSCandidateAttendanceDetails();
          }
          else if(currentHourUS > fromhUS && currentHourUS == tohUS && currentMinUS <= tomUS){
            this.usCurrentSession = i;
            this.uscurrentEvent = this.usCurrentSession.txtEvent;
            this.usshowAttendanceIcon = true;
            this.uscurrentAvail = true;
            this.GetUSCandidateAttendanceDetails();
          }
          else if(currentHourUS > fromhUS && currentHourUS < tohUS)
          {
            this.usCurrentSession = i;
            this.uscurrentEvent = this.usCurrentSession.txtEvent;
            this.usshowAttendanceIcon = true;
            this.uscurrentAvail = true;
            this.GetUSCandidateAttendanceDetails();
          }
        }
      }
      })
  }
  GetUSCandidateAttendanceDetails(){
    let dataUs = new UpdateAttendanceDetails();
    dataUs.intCandidateId = Number(localStorage.getItem("CandidateID"));
    this.usdashboardService.GetEnteredAttendanceDetails(dataUs).subscribe(
      response=>{
        if(response!=null && response.length >0 ){
          this.usCandidateAttendance = response;
          let todayUS= new Date().toLocaleString()
          todayUS=todayUS.split(',')[0]
          // console.log(todayUS);
          
          for(let i of this.usCandidateAttendance){
            let date=new Date(i.sessionDate).toLocaleString();
            let sessionDateUs= date.split(',')[0]
            // console.log(sessionDateUs,this.usCurrentSession.txtEvent,i.txtEvent);
            
            if(this.usCurrentSession != null && this.usCurrentSession.txtEvent.trim() ==i.txtEvent 
            && sessionDateUs==todayUS){
              //  console.log( 'making true...',this.ussessionAttended,this.uscurrentAvail)
              this.ussessionAttended = true;
            }
            
          }
          // console.log(this.ussessionAttended,this.uscurrentAvail)
        }
      })
  }
  InsertUSAttendanceDetails(form:NgForm)
  {
    this.uscodeError = false;
    this.ussessionAttended = false;
    this.errmsgUS = "";
    let todayUS= new Date().toLocaleString()
    todayUS=todayUS.split(',')[0]
    for(let i of this.usCandidateAttendance){
      let sessionDateUs= i.sessionDate.split(' ')[0]
      if(this.usCurrentSession != null && this.usCurrentSession.txtEvent.trim() ==i.txtEvent && sessionDateUs==todayUS){
        this.ussessionAttended = true;
        this.errmsgUS = "You have already submiteed your attendance for this session.";
      }
    }
    if((form.value.Acode == this.usCurrentSession.code) && !this.ussessionAttended){
    let inputUs = new UpdateAttendanceDetails();
    inputUs.intCandidateId = Number(localStorage.getItem("CandidateID"));
    inputUs.txtEvent = this.usCurrentSession.txtEvent;
    inputUs.sessionDate = this.usCurrentSession.dtDateofSession;
    inputUs.attProvided=form.value.attendanceRef;
    this.usdashboardService.InsertAttendanceDetails(inputUs)
      .subscribe(
        response=>{
          
          this.USalert.success("Done! Your attendance is submitted.", this.mobileUS);
          this.codeAUS= "";
          this.GetUSCandidateAttendanceDetails();
          LoadAttendanceCloseModalWeb();
          
          if(this.mobileUS){
          document.getElementById("close_btn").click();
          }
          setTimeout(() => { ShowBottomBar(); }, 3500);
        })
      }
      else if(!this.ussessionAttended){
        this.errmsgUS = "Code did not match";
        this.uscodeError = true;
      }
  }
  AddBodyscrollUS(){
    ShowBottomBar();
    AddBodyscroll();
  
  }

}
