import { Component, OnInit } from '@angular/core';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AppService } from '../../app.service';
//need to check import { Ng4LoadingSpinnerService } from '../../../../node_modules/ng4-loading-spinner';
import { lpUKUserCredentials } from '../models/lpukUserCredentials.model';
import { Router } from '../../../../node_modules/@angular/router';
import { DatatoGetDetails } from '../../lpus/models/Attendace-us.model';
import { DashboardIndiaService } from 'src/app/lpindia/dashboard-india/dashboard-india.service';
import { NgxSpinner, NgxSpinnerService } from 'ngx-spinner';



declare function LoadJsForModalBackDrop(): any;
declare function LoadJsForDashBoardMobileCarousel() :any;
declare function RemoveDefaultBadgeImage():any;
declare function addCssForQrCode(): any;
declare function OpenDialler(email) :any;
declare function RemoveBodyscroll():any;

@Component({
  selector: 'app-more-common',
  templateUrl: './more-common.component.html',
  styleUrls: ['./more-common.component.css']
})
export class MoreCommonComponent implements OnInit {
  mobile:boolean
  userCredentials= new lpUKUserCredentials;
  imgURL:string;
  name:String;
  candidateId:Number=Number(localStorage.getItem("intCandidateId"));
  location:String = localStorage.getItem("Region");
  companyCode:String = localStorage.getItem("CompanyCode");
  inductionTime:Date;
  joiningAnchor:String;
  image:Boolean=false;
  joiningAnchorContact:String=null;
  elementType : 'url' | 'canvas' | 'img' = 'url';
  emailId:string=localStorage.getItem("txtEmailId");
  Vanguard: boolean=false;
  region : Boolean = localStorage.getItem("Region").trim().toUpperCase() == "CANADA";
  regionGer : Boolean = localStorage.getItem("Region").trim().toUpperCase() == "IL GERMANY";
  gerILIGAM: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "ILIGAM";
  regionMalaysia: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "IL MALAYSIA";
  BPMPoland: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "BPM POLAND";
  Malta: Boolean = localStorage.getItem("Region").trim().toUpperCase() ==  "MALTA";
  bpmNorway : Boolean = localStorage.getItem("Region").trim().toUpperCase() == "NORWAY";
  HongKong :Boolean = localStorage.getItem("Region").trim().toUpperCase()  == "IL HONG KONG"
  philippines :Boolean = localStorage.getItem("Region").trim().toUpperCase()  == "INFOSYS BPO PHILIPPINES"
  bpmGermany :Boolean = localStorage.getItem("Region").trim().toUpperCase()  == "BPM GERMANY"
  Taiwan :Boolean = localStorage.getItem("Region").trim().toUpperCase()  == "IL - REST OF APAC"
  bpmuk: Boolean = localStorage.getItem("Region").trim().toUpperCase() =="BPM UK";
  Armenia :Boolean = localStorage.getItem("Region").trim().toUpperCase()  == "ARMENIA"
  Icompaz:Boolean = localStorage.getItem("Region").trim().toUpperCase()  == "ILICOMPAZSINGAPORE"
  bpmromania:Boolean = localStorage.getItem("Region").trim().toUpperCase()  == "BPM ROMANIA"
  BpmSingapore: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "BPM SINGAPORE";
  ipsCanada :Boolean =  localStorage.getItem("Region").toUpperCase()=="IPS CANADA";
  ILUK: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "IL UK";
  BPMAustralia: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "BPM AUSTRALIA";


  candidacyId:string=localStorage.getItem("CandidacyId");;
  qrcodeValue:string=(this.candidacyId)+'/'+this.candidateId+'/'+this.emailId;
  postjoining:Boolean=Number(localStorage.getItem("DaysLeft"))<=0;;
  prejoining:Boolean=Number(localStorage.getItem("DaysLeft"))>0;;
  AuRegion:Boolean=false;
  usRegion:Boolean=false;
  bpoRegion:Boolean = false;
  common:Boolean = false;
  Ipsregion:Boolean=false;
  franceRegion: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "IL FRANCE";
  finlandRegion: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "FINLAND";
  bpmirelandRegion: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "BPM IRELAND";
  cezk: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "INFBCZ";
  puertoRico: Boolean = localStorage.getItem("Region").trim().toUpperCase() == "IBPOPR";
  IpsRegion:Boolean= localStorage.getItem("Region").trim().toUpperCase()=="IPS";
  DenmarkReg:Boolean= localStorage.getItem("Region").trim().toUpperCase()=="DENMARK";
  showMoreAttendanceIcon: Boolean = Number(localStorage.getItem("DaysLeft")) <= 0 && Number(localStorage.getItem("DaysLeft")) >= -2;
  fresherMore:boolean=localStorage.getItem("SourceType").toLowerCase()=="campus";
  
  constructor(
  private myRoute: Router,
  private breakpointObserver: BreakpointObserver,
  private appService:AppService,
  private attendanceServ:DashboardIndiaService,
  private spinner:NgxSpinnerService
  ) { 
    this.breakpointObserver
    .observe(['(max-width: 599px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.appService.setIsMobile(true);
        this.mobile = true;
        window.scroll(0, 0);
        addCssForQrCode()
      } else {
        this.appService.setIsMobile(false);
        this.mobile = false;
      }
    });}

  ngOnInit() {
     this.spinner.show();
    this.userCredentials.CandidateID=parseInt(localStorage.getItem("CandidateId"));
     this.spinner.show();
  
     if(this.location=="IL AUSTRALIA"){
      this.AuRegion=true;
      this.usRegion = false;
    }
    else if(this.location=="US" )
    {
      if(localStorage.getItem("CompanyNameIdentifier").toUpperCase() == "VANGUARD"){
        this.Vanguard = true;   
      }
      
      this.AuRegion=false;
      this.usRegion = true;
    }
    else if(this.location.toUpperCase() == "IBPO" || this.location.toUpperCase() == "MCCAM" || this.location.toUpperCase() == "IBAUSA" || this.location.toUpperCase() == "IPS"){

      if(localStorage.getItem("CompanyNameIdentifier").toUpperCase() == "VANGUARD" && this.location.toUpperCase() != "MCCAM"){
        this.Vanguard = true;   
      }
      
      this.bpoRegion = true;
    }
    else if (this.location.toUpperCase()  == "CANADA" || this.location.toUpperCase()  == "IL SWITZERLAND" || 
  this.location.toUpperCase() == "IL GERMANY" || this.location.toUpperCase() == "IL NEW ZEALAND" || 
  this.location.toUpperCase() == "IL  BELGIUM" || this.location.toUpperCase() == "IL NETHERLANDS" || 
  this.location.toUpperCase() == "IL SINGAPORE" || this.companyCode.toUpperCase() =="COMMON" || 
  this.location.toUpperCase() == "ILIGAM" || this.location.toUpperCase() == "IL MALAYSIA")
      {
        this.common = true;
      }
    else{
      this.AuRegion=false;
    }
    this.GetUSAttendanceCodeDetails();
    
  }
  RemoveBodyscroll(){
    RemoveBodyscroll();
  }
  GetUSAttendanceCodeDetails(){
    let dataUS = new DatatoGetDetails();
    dataUS.Doj = localStorage.getItem("CandDOJ");
    dataUS.location = localStorage.getItem("Location");
    if(this.fresherMore){
      dataUS.identification = "F";
    }
    else{
      dataUS.identification = "L";
    }
    console.log(dataUS);
    let todayUS = new Date();
    let currentHourUS = todayUS.getHours();
    let currentMinUS = todayUS.getMinutes();
    this.attendanceServ.GetAttendanceDetails(dataUS).subscribe(
      response=>{
        if(response!=null && response.length >0 ){
        for(let i of response){
          let fromhUS= Number(i.FromTime.split(":")[0]);
          let frommUS = Number(i.FromTime.split(":")[1]);
          let tohUS= Number(i.ToTime.split(":")[0]);
          let tomUS = Number(i.ToTime.split(":")[1]);
          if(currentHourUS == fromhUS && currentHourUS == tohUS && currentMinUS >= frommUS && currentMinUS <= tomUS)
          {
            this.showMoreAttendanceIcon = true;
          }
          else if(currentHourUS == fromhUS && currentHourUS < tohUS && currentMinUS >= frommUS){
            this.showMoreAttendanceIcon = true;
          }
          else if(currentHourUS > fromhUS && currentHourUS == tohUS && currentMinUS <= tomUS){
            this.showMoreAttendanceIcon = true;
          }
          else if(currentHourUS > fromhUS && currentHourUS < tohUS)
          {
            this.showMoreAttendanceIcon = true;
          }
          console.log(this.showMoreAttendanceIcon);
          
        }
      }
      })
  }
tncClicked(){
  console.log(this.location);
  if(this.location == "IL UK" || this.location == "BPM UK"){
    this.myRoute.navigate(["tc-uk"]);
  }
  else if(this.location == "IL AUSTRALIA" || this.location == "BPM AUSTRALIA"){
    this.myRoute.navigate(["tnc-au"]);
  }
  else if(this.location == "US"){
    this.myRoute.navigate(["tnc"]);
  }
  else if(this.location.toUpperCase() == "IBPO" || this.location.toUpperCase() == "MCCAM" || this.location.toUpperCase() == "IBAUSA" || this.location.toUpperCase() == "IPS"){
    this.myRoute.navigate(['tnc-bpo'])
  }
  else if (this.location.toUpperCase()  == "CANADA" || this.location.toUpperCase()  == "IL SWITZERLAND" || 
  this.location.toUpperCase() == "IL GERMANY" || this.location.toUpperCase() == "IL NEW ZEALAND" || 
  this.location.toUpperCase() == "IL  BELGIUM" || this.location.toUpperCase() == "IL NETHERLANDS" || 
  this.location.toUpperCase() == "IL SINGAPORE" || this.companyCode.toUpperCase() =="COMMON" || 
  this.location.toUpperCase() == "ILIGAM" || this.location.toUpperCase() == "IL MALAYSIA"){
    this.myRoute.navigate(["termsandconditons"]);
  }

}

contactClicked(){
  if(this.location == "IL UK" || this.location == "BPM UK" ){
    this.myRoute.navigate(["/lpukstatic/contactus-uk"]);
  }
  else if(this.location == "IL AUSTRALIA" || this.location == "BPM AUSTRALIA"){
    this.myRoute.navigate(["/lpaustatic/contactus-au"]);
  }
  else if(this.location == "US"){
    this.myRoute.navigate(["/lpusstatic/contactus-us"]);
  }
  else if(this.location.toUpperCase() == "IBPO" || this.location.toUpperCase() == "MCCAM" || this.location.toUpperCase() == "IBAUSA" || this.location.toUpperCase() == "IPS"){
    this.myRoute.navigate(['/lpusbpostatic/contactus-usbpo'])
  }
  else if (this.location.toUpperCase()  == "CANADA" || this.location.toUpperCase()  == "IL SWITZERLAND" || 
  this.location.toUpperCase() == "IL GERMANY" || this.location.toUpperCase() == "IL NEW ZEALAND" || 
  this.location.toUpperCase() == "IL  BELGIUM" || this.location.toUpperCase() == "IL NETHERLANDS" || 
  this.location.toUpperCase() == "IL SINGAPORE" || this.location.toUpperCase() == "IL NETHERLANDS" || this.companyCode.toUpperCase() =="COMMON" || 
  this.location.toUpperCase() == "ILIGAM" || this.location.toUpperCase() == "IL MALAYSIA" || this.location.toUpperCase() == "IPS CANADA" ){
    this.myRoute.navigate(["/lpukstatic/contactus-main"]);
  }
}
Day3Clicked(){
  
  if(this.location=="US" && localStorage.getItem("SourceType")=="Lateral"){
    this.myRoute.navigate(['feedback'], { queryParams: {FeedbackType : "LDay3" } });
  }
  else{
    this.myRoute.navigate(['feedback'], { queryParams: {FeedbackType : "Day3" } });
  }
  
}
Day30Clicked(){
  if(this.location=="US" && localStorage.getItem("SourceType")=="Lateral"){
    this.myRoute.navigate(['feedback'], { queryParams: {FeedbackType : "LDay30" } });
  }
  else{
    this.myRoute.navigate(['feedback'], { queryParams: {FeedbackType : "Day30" } });
  }
}
Day4Clicked(){
  this.myRoute.navigate(['feedback'], { queryParams: {FeedbackType : "Day4" } });
}
Week4Clicked(){
  this.myRoute.navigate(['feedback'], { queryParams: {FeedbackType : "Week4" } });
}

}
