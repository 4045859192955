import { environment } from 'src/environments/environment';

export class Configuration{
    
//Dev Api
 
//public static ResServerBaseURL:string = "http://10.152.131.186/LPLexAPI/";
// public static ResServerBaseURL:string = "http://10.152.131.186/LPLexAPI/";
// public static ResServerBaseURLIndia:string = "http://10.152.131.186/LPLexAPI/";
//public static ResServerBaseURLIndia:string = "http://10.152.136.96/LPLEX.API/"
//Test Api
// public static ResServerBaseURL:string = "http://172.25.105.10/LPLexAPI/";
// public static ResServerBaseURL:string = "https://iscls2tapps/LPLexAPI/";
// public static ResServerBaseURL:string = "https://itgatewaytst.infosys.com/lplexapi/";
// public static ResServerBaseURL:string = "https://itgatewaytst.infosys.com/lplexapi/"; 
//public static ResServerBaseURLIndia:string = "https://itgatewaytst.infosys.com/lplexapi/";
public static ResServerBaseURL:string = "https://itgatewaytst.infosys.com/infysso/lplexapi/"; 
public static ResServerBaseURLIndia:string = "https://itgatewaytst.infosys.com/infysso/lplexapi/";
//public static ResServerBaseURL:string = environment.ResServerBaseURL; 
//public static ResServerBaseURLIndia:string = environment.ResServerBaseURLIndia;

//public static GetW4StatusURL:string = "https://itgatewaytst.infosys.com/incometx/GetW4Status";
//public static ResServerBaseURLIndia:string = "http://172.25.105.10/LPLexAPI/";
//for IDP Test release
//public static ResServerBaseURL:string = "${baseurl}"; 
//public static ResServerBaseURLIndia:string = "${baseurlindia}";


//Production Api
    // public static ResServerBaseURL:string = "https://careers.infosys.com/LPLexAPI/";
    // public static ResServerBaseURLIndia:string = "https://careers.infosys.com/LPLexAPI/";
}
export class APIUrls{
    public static FetchHeadings: string="api/LPLex/FetchNotesForAllGeos";
    public static InsertLoginDetailsOfCandidate: string="api/LPLex/InsertLoginDetailsOfCandidate";
    public static GetFeedbackContentDetails:string="api/LPLex/GetFeedbackContentDetails";
    public static InsertFeedbackResponseDetails:string="api/LPLex/InsertFeedbackResponseDetails";
    public static GetFeedbackResponse:string="api/LPLex/GetFeedbackResponse";
    public static InsertRoutingNumberDetails:string="api/LPLex/InsertRoutingNumberDetails";
    public static GetRoutingNumberDetails: string = "api/LPLex/GetRoutingNumberDetails";
    public static GetDocNotesForAllGeos: string = "api/LPLex/FetchDocNotesForAllGeos";
    // New geo url
    public static GetFAQContentDetails:string="api/LPLex/GetFAQContentDetails";
    public static GetPoliciesContentDetails:string="api/LPLex/GetPoliciesContentDetails";
    public static GetPoliciesDetails:string="api/LPLex/GetPoliciesDetails";

    public static GetCommonDocDetails:string="api/LPLex/GetCommonDocsDetails";
    public static GetNationalIdDetails:string="api/LPLex/FetchNationalIdDetails";
    public static InsertUpdateNationalIdDetials:string="api/LPLex/InsertUpdateNationalIdDetails";
    
    public static GetFamilyMemberDetailsGlobal : string="api/LPLex/GetFamilyDetailsGlobal";
    public static UpdateFamilyDetailsGlobal : string="api/LPLex/UpdateFamilyDetailsGlobal";
    public static DeleteFamilyDetailsGlobal : string="api/LPLex/DeleteFamilyDetailsGlobal";

    //US URL'S
    public static getOnboardingPercentage :string = "api/LPLEX/GetOnboardingPercentage";
    public static fnGetCandPhoto :string="api/LPLEX/fnGetCandPhoto";
    public static fetchPolicyDetails: string = "api/LPLEX/GetPolicyCount";
    public static GetUserDetails : string="api/LPLEX/GetCandidateDetailsAfterAuthentication";
    public static SendMailerHelp : string="api/LPLEX/SendMailerHelp";
    public static GetOnboardingDetails : string="api/LPLEX/GetOnboardingDetailsNew";
    public static GetNotifications : string="api/LPLEX/FetchNotificationsNew";
    public static GetMaritalDetailsforW4 : string="api/LPLEX/GetMaritalDetailsforW4";
    public static GetDataForW4 : string="api/LPLEX/GetDataForW4";
    public static InsertW4DetailsOfCandidate : string="api/LPLex/InsertW4DetailsOfCandidate";
    public static GetPersonalDetails : string="api/LPLEX/GetPersonalDetails";
    public static getPendingActions : string ="api/LPLex/GetPendingActions";
    public static GetBranchDetails : string="api/LPLex/GetBranchDetails";
    public static InsertBankingDetails : string="api/LPLex/InsertBankingDetailsNew";
    public static GetUserBankingDetails : string="api/LPLex/GetUserBankingDetailsNew";
    public static GenerateLPUSOTP : string="api/LPLex/GenerateLPUSOTP";
    public static ValidateLPUSOTP : string="api/LPLex/ValidateLPUSOTP";
    public static GetCorpCardData : string="api/LPLEX/GetCorpCardUserData";
    public static AddCorpCardData : string="api/LPLEX/InsertUserCorporateCardDetails";
    public static GetTermsAndWageAgreeMentContent : string="api/LPLEX/GetTermsAndWageAgreeMentContent";
    public static GetUSStates : string="api/LPLEX/GetUSStates";
    public static GetUSCitiesOnState : string="api/LPLEX/GetUSCitiesOnState";
    public static InsertAddressDetailsForIdCard : string="api/LPLEX/InsertAddressDetailsForIdCard";
    public static InsertEmergencyContact : string="api/LPLEX/InsertEmergencyContact";
    public static UpdateCollectFromInAddressDetails : string="api/LPLEX/UpdateCollectFromIdCardDetailInAddress";
    public static GetUserAddressDetailsForIdCard : string="api/LPLEX/GetUserAddressDetailsForIDCard";
    public static InsertCandidateImage : string="api/LPLEX/InsertCandidateImageFromGlobal";
    public static GetMaritalStatuses : string="api/LPLEX/GetMaritalDetails";
    public static GetRaceAndEthnicityStatus : string="api/LPLEX/GetRaceandEthnicityDetails";
    public static UpdatePersonalDetails : string="api/LPLEX/UpdatePersonalDetails";
    public static GetCountriesForPersonalDetails : string="api/LPLEX/GetCountriesForPersonalDetails";
    public static GetNationalityForDependentDetails: string="api/LPLEX/GetNationalityForDependentDetails";
    public static GetUserAddressDetails : string="api/LPLEX/GetUserAddressDetails";
    public static GetCountry : string="api/LPLEX/GetCountries";
    public static GetStates : string="api/LPLex/GetStates";
    public static GetCitiesOnState : string="api/LPLex/GetCitiesOnState";
    public static InsertAddressDetailsNew : string="api/LPLEX/InsertAddressDetailsNew";
    public static InsertLaptopAddressDetailsUS :string = "api/LPLEX/UpdateLaptopAddressUS";
    public static GenerateLPUSSSNOTP : string="api/LPLex/GenerateLPUSOTP";
    public static ValidateLPUSSSNOTP : string="api/LPLex/ValidateLPUSOTP";
    public static InsertSSNDetails : string="api/LPLex/InsertSSNDetailsNew";
    public static InsertBSNDetails : string="api/LPLex/InsertBSNDetailsNew";
    public static GetSSNDetails : string="api/LPLex/GetSSNDetailsNew";
    public static GetBSNDetails : string="api/LPLex/GetBSNDetailsNew";
    public static GetCFNDetails : string="api/LPLex/GetCFNDetailsNew";
    public static InsertCFNDetails : string="api/LPLex/InsertCFNDetailsNew";
    public static GetUSCities : string="api/LPLEX/GetUSCities";
    public static getEligibityMasterData : string="api/LPLEX/GetEligibityMasterData";
    public static getClaimsEligibityData : string="api/LPLEX/GetClaimsEligibityData";
    public static getJoiningDayAgenda : string="api/LPLex/GetJoiningDayAgenda";
    public static UpdateInfyDirectoryFlag : string="api/LPLex/UpdateInfyDirectoryFlag";
    public static GetInfyDirectoryFlag : string="api/LPLex/GetInfyDirectoryFlag";
    public static GetCandidateRegion : string="api/LPLex/GetCandidateRegion";
    public static UpdateTermsDetails : string="api/LPLex/UpdateTermsDetails";
    public static UpdatePrivacyConsent : string="api/LPLex/UpdatePrivacyConsent";
    public static ChangePasswordForCandidate : string="api/LPLex/ChangePasswordForCandidate";
    public static GetSalutationDetails  : string="api/LPLex/GetSalutationDetails ";
    public static GetPersonalJobLevel : string = "api/LPLex/GetPersonalJobLevel";
    public static GetHotelDetails  : string = "api/LPLex/GetHotelDetails";
    public static InsertPolicesDetailsUS  : string = "api/LPLex/InsertPolicesDetailsUS";
    public static FetchPolicyPageDetailsUS  : string = "api/LPLex/FetchPolicyPageDetailsUS";
    public static FetchPolicyPageDetailsUSTimestamp  : string = "api/LPLex/FetchPolicyPageDetailsUSTimestamp";
    public static GetReAuthButtonEnableOrDisableFlag : string="api/CandidateIndia/GetReAuthButtonEnableOrDisableFlag";
    // public static InsertAttendanceDetailsUS : string = "api/LPLex/InsertAttendanceDetailsUS";
    // public static GetAttendanceDetailsUS : string = "api/LPLex/GetAttendanceDetailsUS";
    // public static GetEnteredAttendanceDetailsUS : string = "api/LPLex/GetEnteredAttendanceDetailsUS";
    
    //US-BPO URL's
    public static GetCorpCardDataBpo : string="api/LPLEX/GetCorpCardUserDataBpo";
    public static GetPendingActionsUSBPO : string="api/LPLEX/GetPendingActionsUSBPO";
    

    public static AddCorpCardDataBpo : string="api/LPLEX/InsertUserCorporateCardDetailsBpo";
    public static GetTermsAndWageAgreeMentContentBpo : string="api/LPLEX/GetTermsAndWageAgreeMentContentBpo";
    public static GetUSStatesBpo : string="api/LPLEX/GetUSStates";
    public static GetUSCitiesOnStateBpo : string="api/LPLEX/GetUSCitiesOnState";
    public static GetUserAddressDetailsBpo : string="api/LPLEX/GetUserAddressDetailsBpo";
    public static getPendingActionsBpo : string ="api/LPLex/GetPendingActionsBpo";
    public static GetBranchDetailsBpo : string="api/LPLex/GetBranchDetailsBpo";
    public static InsertBankingDetailsBpo : string="api/LPLex/InsertBankingDetailsNewBpo";
    public static GetUserBankingDetailsBpo : string="api/LPLex/GetUserBankingDetailsNewBpo";
    public static GenerateLPUSOTPBpo : string="api/LPLex/GenerateLPUSOTPBpo";
    public static ValidateLPUSOTPBpo : string="api/LPLex/ValidateLPUSOTPBpo";
    public static FetchNotificationsUSBPO:string = "api/LPLex/FetchNotificationsUSBPO";
    public static GetOnboardingPercentageUSBPO:string = "api/LPLex/GetOnboardingPercentageUSBPO"
    public static InsertEEOInfoUSBpo : string="api/LPLex/InsertEEOInfoUSBpo";
    public static GetEEOInfoUSBpo : string="api/LPLex/GetEEOInfoUSBpo";
    public static GetEEOInfoEMEA : string="api/LPLex/GetEEOInfoEMEA";
    public static GetIPDeclarationInfoEMEA : string="api/LPLex/GetIPDeclarationInfoEMEA";

    public static GetInventionDetails : string="api/LPLex/GetInventionDetails";
    public static GETSeniorExecDetails : string="api/LPLex/GETSeniorExecDetails";
    public static InsertInventionDetails : string="api/LPLex/InsertInventionDetails";
    public static DeleteInventionDetail : string="api/LPLex/DeleteInventionDetail";
    public static InsertConfindAgreementDetails : string="api/LPLex/InsertConfindAgreementDetails";
    public static GetConfindAgreementDetails : string="api/LPLex/GetConfindAgreementDetails";
    public static InsertW4stateDetails: string="api/LPLex/InsertUSW4FormDetailsOfCand";
    public static GetW4StateDetails:string="api/LPLex/GetUSW4FormDetailsOfCand"

    //UK URL'S
    public static getOnboardingPercentageUK : string="api/LPLEX/GetOnboardingPercentageUK";
    public static fnGetCandPhotoUK : string="api/LPLEX/fnGetCandPhotoEMEA";
    public static getCandidateDetailsAfterAuthenticationUK : string="api/LPLEX/GetCandidateDetailsAfterAuthentication";
    public static GetOnboardingDetailsUK : string="api/LPLex/GetOnboardingDetailsUK";
    public static GetNotificationsUK : string="api/LPLEX/FetchNotificationsForUK";
    public static GetUserDetailsUK : string="api/LPLEX/GetCandidateDetailsAfterAuthentication";
    public static SendMailerHelpUK : string="api/LPLEX/SendMailerHelp";
    public static getPendingActionsUK : string="api/LPLex/GetPendingActionsUK";
    public static GetPersonalDetailsUK : string="api/LPLEX/GetPersonalDetailsEMEA";
    public static GetBranchDetailsUK : string="api/LPLex/GetBranchDetailsUK";
    public static InsertBankingDetailsUK : string="api/LPLex/InsertBankingDetailsAUS";
    public static GetUserBankingDetailsUK : string="api/LPLex/GetUserBankingDetailsAUS";
    public static GenerateLPUSOTPUK : string="api/LPLex/GenerateLPUSOTP";
    public static ValidateLPUSOTPUK : string="api/LPLex/ValidateLPUSOTP";
    public static InsertAddressDetailsForIdCardUK : string="api/LPLEX/InsertAddressDetailsForIdCardEMEA";
    public static InsertEmergencyContactUK : string="api/LPLEX/InsertEmergencyContactEMEA";
    public static UpdateCollectFromInAddressDetailsUK : string="api/LPLEX/UpdateCollectFromIdCardDetailInAddressEMEA";
    public static GetUserAddressDetailsForIdCardUK : string="api/LPLEX/GetUserAddressDetailsForIDCardEMEA";
    public static InsertCandidateImageUK : string="api/LPLEX/InsertCandidateImageEMEAGlobal";
    public static UpdatePersonalDetailsUK : string="api/LPLEX/UpdatePersonalDetailsEMEA";
    public static GetMaritalDetailsUK : string="api/LPLEX/GetMaritalDetails";
    public static GetUserAddressDetailsUK : string="api/LPLEX/GetUserAddressDetailsEMEA";
    public static GetUserAddressDetailsNewGeos : string="api/LPLEX/GetUserAddressDetailsGEOS";
    public static GetGeosAddressVisibility : string="api/LPLEX/GetGeosAddressVisiblity";
    public static GetCountryUK : string="api/LPLEX/GetCountries";
    public static GetStatesUK : string="api/LPLex/GetStates";
    public static GetCitiesOnStateUK : string="api/LPLex/GetCitiesOnState";
    public static InsertAddressDetailsNewUK : string="api/LPLex/InsertAddressDetailsNewEMEA";
    public static InsertAddressDetailsNewGeos : string="api/LPLex/InsertAddressDetailsGEOS";
    public static getJoiningDayAgendaUK : string="api/LPLex/GetJoiningDayAgendaAUS";
    public static UpdateInfyDirectoryFlagUK : string="api/LPLex/UpdateInfyDirectoryFlagEMEA";
    public static GetInfyDirectoryFlagUK : string="api/LPLex/GetInfyDirectoryFlagEMEA";
    public static UpdateTermsDetailsUK: string="api/LPLex/UpdateTermsDetailsEMEA";
    public static UpdatePrivacyConsentUK: string="api/LPLex/UpdatePrivacyConsentEMEA";
    public static ChangePasswordForCandidateEMEA : string="api/LPLex/ChangePasswordForCandidateEMEA";
    public static InsertPolicesDetailsUK : string="api/LPLEX/InsertPolicesDetailsUK";
    public static FetchPolicyPageDetailsUK : string="api/LPLEX/FetchPolicyPageDetailsUK";
    public static FetchInsuranceDetailsForUK : string="api/LPLEX/FetchInsuranceDetailsForUK";
    public static InsertInsuranceDetailsForUK : string="api/LPLEX/InsertInsuranceDetailsForUK";

    public static DownloadDocumentFromDMSUK : string="api/lplex/GetDocFromDMS";
    public static UploadPlainDocumentNewUK : string="api/lplex/UploadPlainDocumentNew";
    public static InsertDMSDocDetailsUK : string="api/lplex/InsertDMSDocDetails";
    public static FetchDocNamefromTableUK : string="api/lplex/FetchDocNamefromTable";
    public static GetPlainDocFromDMSUK : string="api/lplex/GetDocFromDMS";
    public static DeleteDocFromTableUK: string="api/LPLex/DeleteDocFromTable";
    //India URL'S
    public static AcceptOffer:string="api/CandidateIndia/AcceptOffer";
    public static AcceptOfferNew:string="api/CandidateIndia/AcceptOfferNew";
    public static GetUANDetails:string="api/CandidateIndia/GetUANDetails";
    public static UpdateUANDetails:string="api/CandidateIndia/UpdateUANDetails";
    public static FetchLaptopAddress:string="api/CandidateIndia/FetchLaptopAddress";
    public static UpdateLaptopAddress:string="api/CandidateIndia/UpdateLaptopAddress";
    public static UpdateOfferStatus:string="api/CandidateIndia/UpdateOfferStatus";
    public static ChangePasswordForINDIA : string = "api/CandidateIndia/UpdatePasswordDetails";
    public static GetQualTypes :string = "api/CandidateIndia/GetQualTypes";
    public static UpdateFreshersEduDetails :string = "api/CandidateIndia/UpdateFreshersEduDetails";
    public static GetDocumentStatus :string = "api/CandidateIndia/GetDocumentStatus";
    public static GetProfileStatus :string = "api/CandidateIndia/GetProfileStatus";
    public static fnGetCandPhotoIN :string="api/CandidateIndia/GetCandidatePhoto";
    public static InsertCandidateImageLPIndia :string="api/CandidateIndia/InsertCandidateImageLPIndia";
    public static GetOnboardingAdvisorDetails : string="api/CandidateIndia/GetOnboardingAdvisorDetails";
    public static FetchJoiningAnchorDetails : string = "api/CandidateIndia/FetchJoiningAnchorDetails"
    public static GetNotificationsIN : string="api/Candidate/GetCandNotificationDetails";
    public static GetUserDetailsIN : string="api/CandidateIndia/GetCandidateDetailsAfterAuthentication";
    public static updatePhoneNumberFromProfileIN : string="api/CandidateIndia/updatePhoneNumberFromProfile";
    public static SendMailerHelpIN : string="api/CandidateIndia/SendMailerHelp";
    public static GetCandidateDetailsOnCandidateId : string="api/CandidateIndia/GetCandidateDetailsOnCandidateId";
    public static GetMaritalDetailsforW4IN : string="api/CandidateIndia/GetMaritalDetailsforW4";    
    public static GetPendingActionsIN : string ="api/CandidateIndia/GetPendingAction";
    public static GetPersonalDetailsIN : string="api/CandidateIndia/GetCandidatePersonalDetails";
    public static GetMandDocsDetailsIBPM : string="api/CandidateIndia/GetMandDocsDetailsIBPM";
    public static GetPDFFlagDetails : string="api/CandidateIndia/GetPDFFlagDetails";
    public static GetGDCDetails : string="api/CandidateIndia/GetGDCDetails";
    public static GetOfferReleasedate : string="api/CandidateIndia/GetOfferReleasedate";
    public static LPFetchDisabilityDetails : string="api/CandidateIndia/LPFetchDisabilityDetails";
    public static LPFetchflgPolicy : string="api/CandidateIndia/LPFetchflgPolicy";
    public static GetAuthenticationInfo : string="api/CandidateIndia/GetAuthenticationInfo";
    public static UpdatePDFFlagDetails : string="api/CandidateIndia/UpdatePDFFlagDetails";
    public static DownloadPlainDocument : string="api/CandidateIndia/DownloadPlainDocument";
    public static InsertSatutoryFormsDetails : string="api/CandidateIndia/InsertSatutoryFormsDetails";
    public static LPUpdateflgPolicy : string="api/CandidateIndia/LPUpdateflgPolicy";
    public static GetBranchDetailsIN : string="api/CandidateIndia/GetBranchDetails";
    public static GetUserBankingDetailsIN : string="api/CandidateIndia/GetUserBankingDetails";
    public static GetWorkFromHomeDetailsIN : string="api/CandidateIndia/GetWorkFromHomeDetails"; wfhus
    public static InsertBankingDetailsIN : string="api/CandidateIndia/InsertBankingDetails";
    public static BGVStatus : string = "api/CandidateIndia/GetBGVStatus";
    public static BGVPopupStatus : string = "api/CandidateIndia/FetchBGVPopupStatus";
    public static UpdateBGVStatus : string = "api/CandidateIndia/UpdateBGVStatus";
    public static FetchFacialRecoginitionRStatus : string = "api/CandidateIndia/FetchFRStatus";
    public static UpdateFRData : string = "api/CandidateIndia/UpdateFRDate";
    public static GenerateLPOTPIN : string="api/CandidateIndia/GenerateLPOTP";
    public static ValidateLPOTPIN : string="api/CandidateIndia/ValidateLPOTP";
    public static ValidateLPOTPBank : string="api/CandidateIndia/ValidateLPOTPBank";
    public static getOfferDetails : string="api/CandidateIndia/GetOfferDetails";
    public static updateOfferStatus : string="api/CandidateIndia/UpdateOfferStatus";
    public static updateDataForGDC : string="api/CandidateIndia/spEJoiningLaunchPadUpdateDataForGDC";
    public static FetchOtherAchievementDocNamesForCandidate : string="api/CandidateIndia/FetchOtherAchievementDocNamesForCandidate";
    public static GetMasterDisablitiesCategory : string="api/CandidateIndia/GetMasterDisablityCategories";
    public static UpdateCandDisablityData : string="api/CandidateIndia/UpdateCandDisabilityData";
    public static GetFetchDisabilityDataCountry : string="api/CandidateIndia/FetchCandidateDisabilityCategories";
    public static DownloadPlainDocumentIN : string="api/CandidateIndia/DownloadPlainDocument";
    public static DownloadFinanceFormsZip : string="api/CandidateIndia/DownloadFinanceFormsZipFile";
    public static UploadPlainDocument : string="api/CandidateIndia/UploadPlainDocument";
    public static UploadPlainDocumentNew:string="api/CandidateIndia/UploadPlainDocumentNew";
    public static UpdateOtherDocDetails : string="api/CandidateIndia/UpdateOtherDocDetails";
    public static DeleteOtherDocDetails : string="api/CandidateIndia/DeleteOtherDocDetails";
    public static GetCandidateEducationDetails : string="api/CandidateIndia/GetCandidateEducationDetails";
    public static FetchFreshersEduDetails : string="api/CandidateIndia/FetchFreshersEduDetails";
    public static UpdateEduDetails : string="api/CandidateIndia/UpdateEduDetails";
    public static UpdateFreshersEduDetail : string="api/CandidateIndia/UpdateFreshersEduDetails";
    public static InsertUpdateEduDetails : string="api/CandidateIndia/InsertUpdateEduDetails";
    public static GetStatesIN : string="api/CandidateIndia/GetStates";
    public static GetDegrees : string="api/CandidateIndia/FillDegreeDropDown";
    public static GetLevels : string="api/CandidateIndia/FillLevelDropDown";
    public static DeleteEduDocDetails : string="api/CandidateIndia/DeleteEduDocDetails";
    public static DeleteGapDetailsIndia : string="api/CandidateIndia/DeleteGapDetailsFromGlobal";
    public static FetchDegreeDescription : string="api/CandidateIndia/FetchDegreeDescription";
    public static FetchEduDocSerialNo : string="api/CandidateIndia/FetchEduDocSerialNo";
    public static DownloadDocumentFromDMS : string="api/CandidateIndia/DownloadPlainDocument";
    public static InsertEduDocDetails : string="api/CandidateIndia/InsertEduDocDetails";
    public static FetchEduDocDetails : string="api/CandidateIndia/FetchEduDocDetails";
    public static GetFamilyMemberDetails : string="api/CandidateIndia/GetFamilyDetails";
    public static GetRelations : string="api/CandidateIndia/GetRelations";
    public static UpdateFamilyDetails : string="api/CandidateIndia/UpdateFamilyDetails";
    public static DeleteFamilyDetails : string="api/CandidateIndia/DeleteFamilyDetails";
    public static GetAadharDetails : string="api/CandidateIndia/GetPanAadharDetails";
    public static AddAadharDetails : string="api/CandidateIndia/InsertPanAadharDetails";
    public static GetCandidateOtherDocList : string="api/CandidateIndia/GetCandidateOtherDocList";
    public static GetMedDocDetails : string="api/CandidateIndia/GetMedDocDetails";
    public static GetSelfDeclarationDetails : string="api/CandidateIndia/GetSelfDeclarationDetails";
    public static FetchOtherMedicalDocNamesForCandidate:string = "api/CandidateIndia/FetchOtherMedicalDocNamesForCandidate";
    public static AddMedDocDetails : string="api/CandidateIndia/InsertMedDocDetails";
    public static GetNSRCardDetails : string="api/CandidateIndia/GetNSRDetails";
    public static AddBGVDetails : string="api/CandidateIndia/InsertBGVDetails";
    public static GetBGVDetails : string="api/CandidateIndia/FetchBGVDetails";
    public static AddNSRDetails : string="api/CandidateIndia/InsertNSRDetails";
    public static GetPANCardDetails : string="api/CandidateIndia/GetPanAadharDetails";
    public static AddPANCardDetails : string="api/CandidateIndia/InsertPanAadharDetails";
    public static GetPassportDetails : string="api/CandidateIndia/GetPassportDetails";
    public static InsertPastAddresses : string="api/CandidateIndia/InsertPastAddresses";
    public static DeletePastAddress : string="api/CandidateIndia/DeletePastAddress";
    public static FetchAddressDetailsForPast : string="api/CandidateIndia/FetchAddressDetailsForPast";
    public static ValidatePastAddress : string="api/CandidateIndia/ValidatePastAddress";
    public static GetPassportFlagDetails : string="api/CandidateIndia/GetPassportFlagDetails";
    public static AddPassportDetails : string="api/CandidateIndia/InsertPassPortDetails?CandidateID=";
    public static UpdatePassportDetails : string="api/CandidateIndia/UpdatePassPortDetails?CandidateID=";
    public static UpdateFlagDetails : string="api/CandidateIndia/UpdateCandidateFlags";
    public static GetOtherPassportDocNamesForCandidate : string="api/CandidateIndia/FetchOtherPassportDocNamesForCandidate";
    public static GetCountryIN : string="api/CandidateIndia/GetAllCountries";
    public static GetCitiesOnStateIN : string="api/CandidateIndia/GetCities";
    public static GetHolidayDetailsIN: string="api/CandidateIndia/GetHolidayDetails"
    public static GetCandidateBasicDDLData : string="api/CandidateIndia/GetBasicDetailsDropdownData";
    public static UpdatePersonalDetailsIN : string="api/CandidateIndia/UpdatePersonalDetails";
    public static UpdateMandDocsDetailsIBPM : string="api/CandidateIndia/UpdateMandDocsDetailsIBPM";
    public static GetAddressDetails : string="api/CandidateIndia/FetchAddressDetails";
    public static AddAddressDetails : string="api/CandidateIndia/UpdatePermanentAddressDetails";
    public static GetGapsInterval : string="api/CandidateIndia/GetGapsInterval";
    public static GetGapDetails : string="api/CandidateIndia/GetGapDetails";
    public static UpdateGapDetails : string="api/CandidateIndia/UpdateGapDetails";
    public static DeleteGapDetails : string="api/CandidateIndia/DeleteGapDetails";
    public static FetchExpDetailsList : string="api/CandidateIndia/FetchExpDetailsList";
    public static UpdateCandWorkExp : string="api/CandidateIndia/UpdateCandWorkExp";
    public static FetchExpDocSerialNo : string="api/CandidateIndia/FetchExpDocSerialNo";
    public static FetchWorkExDocDetails : string="api/CandidateIndia/FetchWorkExDocDetails";
    public static InsertWorkExDocDetails : string="api/CandidateIndia/InsertWorkExDocDetails";
    public static DeleteExpDocDetails : string="api/CandidateIndia/DeleteExpDocDetails";
    public static getJoiningDayAgendaIN : string="api/CandidateIndia/GetJoiningDayAgenda";
    public static UpdateVideoSeenFlag : string = "api/CandidateIndia/UpdateVideoSeenFlag";
    public static UpdateBioConsentFlag:string = "api/CandidateIndia/UpdateBioConsentFlag";
    public static FetchClubsIN : string="api/CandidateIndia/FetchClubs";
    public static FetchAchievementDetailsIN : string="api/CandidateIndia/FetchAchievementDetails";
    public static FetchOtherAchievementDocNamesForCandidateIN : string="api/CandidateIndia/FetchOtherAchievementDocNamesForCandidate";
    public static InsertAchievementDetailsIN : string="api/CandidateIndia/InsertAchievementDetails";
    public static InsertExtActivitiesDetailsIN : string="api/CandidateIndia/InsertExtActivitiesDetails";
    public static GetInfyLocnsIndia:string="api/CandidateIndia/GetInfyLocns";
    public static FetchLocationPreferences:string="api/CandidateIndia/FetchLocationPreferences";
    public static InsertLocationPreferencesIN : string="api/CandidateIndia/InsertLocationPreferences";
    public static InsertInductionFeedbackDetailsIN: string="api/CandidateIndia/InsertInductionFeedbackDetails";
    public static DeleteAchievementDetailsIN : string="api/CandidateIndia/DeleteAchievementDetails";
    public static InsertOnboardingPulseDetailsIN: string="api/CandidateIndia/InsertOnboardingPulseDetails";
    public static FetchBoardingPassInfoIN: string="api/CandidateIndia/FetchBoardingPassInfo";
    public static FetchAchievementDetails: string = "api/CandidateIndia/FetchAchievementDetails";
    public static GetEmergencyContactIN: string = "api/CandidateIndia/GetEmergencyContact";
    public static InsertEmergencyContactIN: string = "api/CandidateIndia/InsertEmergencyContact";
    public static GetJoiningAgendaDetailsIN: string = "api/CandidateIndia/GetJoiningAgendaDetails";
    public static GetAccommodationDetailsIN: string="api/CandidateIndia/GetAccommodationDetails";
    public static InsertAccommodationDetailsIN: string="api/CandidateIndia/InsertAccommodationDetails";
    public static GetLocationForAccommodationIN : string="api/CandidateIndia/GetLocationForAccommodation";
    public static GetLocationForAttendanceIN : string="api/CandidateIndia/GetLocationForAccommodation";
    public static GetCandidateAccommodationStatusIN : string="api/CandidateIndia/GetCandidateAccommodationStatus";
    public static GetAccommodationAddressDetails : string="api/CandidateIndia/GetAccommodationAddressDetails";
    public static InsertRequestCallDetails : string = "api/CandidateIndia/InsertRequestCallDetails";
    public static GetRequestCallDetails : string = "api/CandidateIndia/GetRequestCallDetails";
    public static UpdatePrivacyPolicyIN: string="api/CandidateIndia/UpdatePrivacyPolicy";
    public static UpdateTermsCondIN: string="api/CandidateIndia/UpdateTermsCond";
    public static UpdateBioConsentFlagIN: string="api/CandidateIndia/UpdateBioConsentFlag";
    public static InsertOnboardingPulseDetails: string="api/CandidateIndia/InsertOnboardingPulseDetails";
    public static InsertInductionFeedbackDetails: string="api/CandidateIndia/InsertInductionFeedbackDetails";
    public static GetNominationDetailsIN: string="api/CandidateIndia/GetNominationDetails";
    public static InsertNominationDetailsIN: string="api/CandidateIndia/InsertNominationDetails";
    public static DeleteNominationDetailsIN: string="api/CandidateIndia/DeleteNominationDetails";
    public static FetchStatutoryDetails: string="api/CandidateIndia/FetchSatutoryDetails";
    public static InsertCovidDetails : string = "api/CandidateIndia/InsertCovidDetails";
    public static InsertVaccineDetails : string = "api/CandidateIndia/InsertVaccineDetails";
    public static GetVaccineDetails : string = "api/CandidateIndia/GetVaccineDetails";
    public static FetchVaccineDetails : string = "api/CandidateIndia/FetchVaccineDetails";
    public static GetGeneratedDocuments : string = "api/CandidateIndia/GetGeneratedDocuments";
    public static GetCompanyName : string = "api/CandidateIndia/GetCompanyName";
    public static InsertAttendanceDetails : string = "api/CandidateIndia/InsertAttendanceDetails";
    public static GetAttendanceDetails : string = "api/CandidateIndia/GetAttendanceDetails";
    public static GetEnteredAttendanceDetails : string = "api/CandidateIndia/GetEnteredAttendanceDetails";

    public static GetNATSDetails : string="api/CandidateIndia/GetNATSDetails";
    public static AddNATSDetails : string="api/CandidateIndia/InsertNATSDetails";
    public static InsertIPDeclarationDetails : string="api/CandidateIndia/InsertOnboardingIPDeclarationDetails";
    public static GetLaptopShipmentDetails :string = "api/CandidateIndia/GetCandLaptopShippingDetials";
    public static GetOfferLetter:string ="api/CandidateIndia/GetOfferLetter";
    public static GetAgestatus:string ="api/CandidateIndia/GetAgestatus";


//AUS URL'S
public static getOnboardingPercentageAUS : string="api/LPLEX/GetOnboardingPercentageAUS";
public static GetOnboardingDetailsAUS : string="api/LPLEX/GetOnboardingDetailsAUS";
public static GetNotificationsAUS : string="api/LPLEX/FetchNotificationsForAUS";
public static GetUserDetailsAUS : string="api/LPLEX/GetCandidateDetailsAfterAuthentication";
public static fnGetCandPhotoAUS : string="api/LPLEX/fnGetCandPhotoEMEA";
public static SendMailerHelpAUS : string="api/LPLEX/SendMailerHelp";
public static GetPendingActionsAUS : string="api/LPLex/GetPendingActionsAUS";
public static GetPersonalDetailsAUS : string="api/LPLEX/GetPersonalDetailsEMEA";
public static InsertPolicesDetailsEMEA : string="api/LPLEX/InsertPolicesDetailsEMEA";
public static GetNumberOfWeeksEMEA : string="api/LPLEX/GetNumberOfWeeksEMEA";
// public static GetJoiningReportDetails : string="api/LPLEX/GetJoiningReportDetailsNewGeos";
// public static UpdateJoiningReportDetails : string="api/LPLEX/UpdateJoiningReportDetailsNewGeos";
public static FetchPolicyPageDetails : string="api/LPLEX/FetchPolicyPageDetails";
public static GetCountryCodeEmea : string="api/LPLEX/GetCountryCodeEmea";
public static GetBranchDetailsAUS : string="api/LPLEX/GetBranchDetailsAUS";
public static GetUserBankingDetailsAUS : string="api/LPLEX/GetUserBankingDetailsAUS";
public static InsertBankingDetailsAUS : string="api/LPLEX/InsertBankingDetailsAUS";
public static ValidateLPUSOTPAUS : string="api/LPLex/ValidateLPUSOTP";
public static GenerateLPUSOTPAUS : string="api/LPLex/GenerateLPUSOTP";
public static InsertAddressDetailsForIDCardEMEA : string="api/LPLEX/InsertAddressDetailsForIDCardEMEA";
public static InsertEmergencyContactEMEA : string="api/LPLEX/InsertEmergencyContactEMEA";
public static InsertAddressDetailsNewEMEAAUS : string="api/LPLEX/InsertAddressDetailsNewEMEA";
public static UpdateCollectFromIdCardDetailInAddressEMEA : string="api/LPLEX/UpdateCollectFromIdCardDetailInAddressEMEA";
public static GetUserAddressDetailsForIDCardEMEA : string="api/LPLEX/GetUserAddressDetailsForIDCardEMEA";
public static InsertCandidateImageEMEAGlobal : string="api/LPLEX/InsertCandidateImageEMEAGlobal";
public static GetUserAddressDetailsEMEA : string="api/LPLEX/GetUserAddressDetailsEMEA";
public static GetCountryAUS : string="api/LPLEX/GetCountries";
public static GetStatesAUS : string="api/LPLex/GetStates";
public static GetCitiesOnStateAUS : string="api/LPLex/GetCitiesOnState";
public static FetchSuperannuationDetailsForEmea : string="api/LPLEX/FetchSuperannuationDetailsForEmea";
public static InsertSuperannuationdataforEMEA : string="api/LPLEX/InsertSuperannuationdataforEMEA";
public static InsertTaxDetailsForEMEA : string="api/LPLEX/InsertTaxDetailsForEMEA";
public static FetchTaxDetailsForEmea : string="api/LPLEX/FetchTaxDetailsForEmea";
public static DownloadDocumentFromDMSAUS : string="api/lplex/GetDocFromDMS";
public static UploadPlainDocumentAUS : string="api/lplex/UploadPlainDocument";
public static UploadPlainDocumentNewAUS : string="api/lplex/UploadPlainDocumentNew";
public static InsertDMSDocDetails : string="api/lplex/InsertDMSDocDetails";
public static FetchDocNamefromTable : string="api/lplex/FetchDocNamefromTable";
public static GetPlainDocFromDMS : string="api/lplex/GetDocFromDMS";
public static GetJoiningDayAgendaAUS : string="api/LPLex/GetJoiningDayAgendaAUS";
public static DeleteDocFromTable: string="api/LPLex/DeleteDocFromTable";
public static UpdateInfyDirectoryFlagEMEA: string="api/LPLex/UpdateInfyDirectoryFlagEMEA";
public static GetInfyDirectoryFlagEMEA: string="api/LPLex/GetInfyDirectoryFlagEMEA";
public static UpdateInfyDirectoryFlagAU : string="api/LPLex/UpdateInfyDirectoryFlagEMEA";
public static GetInfyDirectoryFlagAU : string="api/LPLex/GetInfyDirectoryFlagEMEA";
public static UpdateTermsDetailsAU: string="api/LPLex/UpdateTermsDetailsEMEA";
public static UpdatePrivacyConsentAU: string="api/LPLex/UpdatePrivacyConsentEMEA";

public static GetCurrencies: string="api/LPLex/GetCurrencies";
public static GetPassportDetailsForNewGeos: string="api/LPLex/GetPassportDetailsForNewGeos";
public static InsertPassPortDetailsForNewGeos: string="api/LPLex/InsertPassPortDetailsForNewGeos";

//RADB Logger
public static HandleExceptionFromMobile : string="api/LPLex/HandleExceptionFromMobile";
//Forgot password
// public static ForgotPassword: string="api/LPLex/ForgotPasswordForCandidate";
public static ForgotPassword: string="api/LPLex/ForgotPasswordForGlobalApp";
// public static GetDPContent: string = "infyitdpservice/dataprivacy/launchpad/getdpcontent";
// public static SaveUserConsent: string = "infyitdpservice/dataprivacy/launchpad/saveuserconsent";

public static GetDPContent: string = "api/LPLex/getDPContent";
public static SaveUserConsent: string = "api/LPLex/saveDPConsent";
public static getPrivacyFlagForDP: string = "api/LPLex/getPrivacyFlagForDP";
//public static UpdateEmpDetails: string = "infyitdpservice/dataprivacy/launchpad/updateempdetails";



//New GEO URLs
public static GetStepOneFlow : string="api/LPLEX/GetStepOneFlow";
public static fnGetCandPhotoGlobal :string="api/LPLEX/fnGetCandPhotoForGlobal";
public static InsertCandidateImageForGlobal :string="api/LPLEX/InsertCandidateImageForGlobal";
public static GetPersonalDetailsNewGeos : string="api/LPLEX/GetPersonalDetailsNewGeos";
public static UpdatePersonalDetailsNewGeos : string="api/LPLEX/UpdatePersonalDetailsNewGeos";
public static GetReligionDetails:string="api/LPLEX/GetReligionDetails";
public static GetProvinceDetails:string="api/LPLEX/GetProvinceDetails";
public static GetInfyDirectoryFlagNewGeos : string="api/LPLex/GetInfyDirectoryFlagGEOS";
public static UpdateInfyDirectoryFlagNewGeos : string="api/LPLex/UpdateInfyDirectoryFlagGEOS";
public static GetOnboardingDetailsNewGeos : string="api/LPLex/GetOnboardingDetailsGlobal";
public static FetchDocNamefromTableGeos : string="api/lplex/FetchDocNamefromTableGeos";
public static DeleteDocFromTableGlobal: string="api/LPLex/DeleteDocFromTableGlobal";
public static GetMaritalStatusesNewGeos : string="api/LPLEX/GetMaritalDetailsNewGeos";
public static FetchNotificationsGlobal : string="api/LPLEX/FetchNotificationsGlobal";
public static InsertEmergencyContactLPGLOBAL : string="api/LPLex/InsertEmergencyContactLPGLOBAL";
public static GetUserAddressDetailsForIDCardLPGLOBAL : string="api/lplex/GetUserAddressDetailsForIDCardLPGLOBAL";
public static InsertAddressDetailsForIDCardLPGLOBAL : string="api/LPLEX/InsertAddressDetailsForIDCardLPGLOBAL";
public static InsertAddressDetailsNewLPGLOBAL : string="api/LPLEX/InsertAddressDetailsNewLPGLOBAL";
public static getPendingActionsGlobal : string ="api/LPLex/GetPendingActionsGlobal";
public static getOnboardingPercentageGlobal : string="api/LPLEX/GetOnboardingPercentageGlobal";
public static GetTermsAndWageAgreeMentContentLPGlobal : string="api/LPLEX/GetTermsAndWageAgreeMentContentLPGlobal";
public static GetGeosCities : string="api/LPLEX/GetGeosCities";
public static getEligibityMasterDataGlobal : string="api/LPLEX/GetEligibityMasterDataGlobal";
public static UpdateNonEUFlagDetailsForGlobal : string="api/LPLEX/UpdateNonEUFlagForGlobal";
public static GetNonEUFlagDetailsForGlobal : string="api/LPLEX/GetNonEUFlagDetailsForGlobal";
public static UpdateRulingApplicableFlagForGlobal : string="api/LPLEX/UpdateRulingApplicableFlagForGlobal";
public static GetRulingApplicableFlagDetailsForGlobal : string="api/LPLEX/GetRulingApplicableFlagDetailsForGlobal";

public static LPInsertWorkExpDetailsLPGlobal : string="api/LPLEX/InsertWorkExpForLPGlobalMain";
public static LPFetchWorkExpDetailsLPGlobal : string="api/LPLEX/GetWorkExpForLPGlobalMain";
public static GetUniversityDetails : string = "api/LPLEX/GetUniversityDetails";
public static GetCertificationDetails : string = "api/LPLEX/GetCertificationDetails";
public static LPFetchEduDetailsLPGlobal: string = "api/LPLEX/GetEduForLPGlobalMain";
public static LPInsertEduDetailsLPGlobal: string = "api/LPLEX/InsertEduForLPGlobalMain";
public static LPInsertNRICDetailsLPGlobal : string="api/LPLEX/InsertNRICForLPGlobalMain";
public static LPFetchNRICDetailsLPGlobal : string="api/LPLEX/GetNRICForLPGlobalMain";

//privacy content
public static GetRegionDetails : String="api/LPLEX/GetRegionDetailsforPrivacy"
public static ChangePasswordOnKeycloak : string = "api/LPLEX/NewChangePasswordOnKeyCloak";
}
