export class Candidatedata{
      CandidateID :Number;
      CandidateEmail :String;
      CandAdvImg :String;
      CandAddressType:String;
      CandNotificationSeqID :Number;
      CandLocation :String;
      ResizeImageHeight :Number;
      ResizeImageWidth:Number;
      charItemChecked:Boolean;
      CandReqType :String;
      RoleCode :String;
      Source :String;

}
export class StepCompletion{
      OnboardingPercent : number;
      TotalCount : number;
      StepCount : number;
}
