export class BGVDetails
{
    public CandidateId : Number;
    public txtReasonCons : String;
    public txtReasonSem : String;
    public flgResponse : String;
    public dtTentative : Date;
    public txtDataType : String;
    public txtQuestionCode : String;
    public intCandQualDetId:Number;
    public txtQualificationCode:String;
    public flgIndia:String;
    public flgDeclaration:String;
}