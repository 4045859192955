
import {HttpClient,HttpHeaders, HttpErrorResponse} from '@angular/common/http'
import { Injectable } from '@angular/core';

import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs'; 
import { ForgotPwd } from '../models/ForgotPwd.model';
import { ForgetPasswordResult } from '../models/ForgetPasswordResult.model';
import { Configuration, APIUrls } from '../configuration';



@Injectable()
export class ForgotPasswordService {
    constructor(private http: HttpClient) { }

    ForgotPasswordForCandidate (forgotpwd:ForgotPwd): Observable<ForgetPasswordResult> {
                var headers=new HttpHeaders();
                headers=headers.append('Content-Type','application/json')
                return this.http.post<ForgetPasswordResult>(Configuration.ResServerBaseURL + APIUrls.ForgotPassword ,{"CandidateId":forgotpwd.CandidateID,"CandEmailID":forgotpwd.CandEmailID},{
                headers:headers
                }).pipe(map(
                (res) => {
                    // console.log(res);
                return res;
            } 
        ))  
    }
         }