import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { LoginComponent } from './login/login.component';
import { ChangePasswordComponent } from './login/change-password/change-password.component';
import { CollaborationContentComponent } from './collaboration-content/collaboration-content.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { PrivacyUkComponent } from './lpuk/header-uk/privacy-uk/privacy-uk.component';
import { TCUkComponent } from './lpuk/header-uk/tc-uk/tc-uk.component';
import { PrivacyAuComponent } from './lpau/privacy-au/privacy-au.component';
import { TncAuComponent } from './lpau/tnc-au/tnc-au.component';
import { TncComponent } from './lpus/tnc/tnc.component';
import { PrivacyComponent } from './lpus/privacy/privacy.component';
import { TncIndiaComponent } from './lpindia/header-india/tnc-india/tnc-india.component';
import { PrivacynoticeComponent } from './lpindia/header-india/privacynotice/privacynotice.component';
import { AcceptOfferComponent } from './lpindia/acceptoffer/acceptoffer.component';
import { BiometricComponent } from './lpindia/biometric/biometric.component';
import { ProfileComponent } from './lpus/profile/profile.component';
import { AuthGuard } from './login/auth.guard';
import { NotificationsComponent } from './lpus/header/notifications/notifications.component';
import { DeclarenauthorizeComponent } from './lpindia/step1onboarding-india/declarenauthorize/declare.component';
import { OnBoardingVideoComponent } from './lpindia/header-india/on-boarding-video/on-boarding-video.component';
import { NominationComponent } from './lpindia/step1onboarding-india/nomination/nomination.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuardIndia } from './login/authIN.guard';
import { AuthGuardUS } from './login/authUS.guard';
import { AuthGuardAUS } from './login/authAUS.guard';
import { AuthGuardUK } from './login/authUK.guard';
import { LpusBPOComponent } from './lpus-bpo/lpus-bpo.component';
import { CoronaComponent } from './lpindia/header-india/corona/corona.component';
import { TncUsbpoComponent } from './lpus-bpo/tnc-usbpo/tnc-usbpo.component';
import { PrivacyUsbpoComponent } from './lpus-bpo/privacy-usbpo/privacy-usbpo.component';
import { AuthGuardUSBPO } from './login/authUSBPO.guard';
import { TermsandconditonsComponent } from './lpmain/termsandconditons/termsandconditons.component';
import { AuthGuardMain } from './login/authMain.guard';
// import { PrivacyComponentMain } from './lpmain/privacy/privacy.component';
// import { NotificationsComponentMain } from './lpmain/header/notifications/notifications.component';
// import { ResiAddress } from './lpindia/models-india/resiaddress.model';
// import { ResiaddressComponent } from './lpus/step1onboarding/resiaddress/resiaddress.component';
import { AddRoutingNoComponent } from './add-routing-no/add-routing-no.component';
// import { VaccineComponent } from './lpindia/header-india/vaccine/vaccine.component';
// import { PersonalComponent } from './lpus/step1onboarding/personal/personal.component';
// import { SsnComponent } from './lpus/step1onboarding/ssn/ssn.component';
// import { AttendanceComponent } from './lpus/attendance/attendance.component';

import { SIdisabilityComponent } from './lpus/step1onboarding/s-idisability/s-idisability.component';
import { VeteranSectionComponent } from './lpus/step1onboarding/veteran-section/veteran-section.component';
//import { SsnUsbpoComponent } from './lpus-bpo/step1onboarding-usbpo/ssn-usbpo/ssn-usbpo.component';
//import { SsnMainComponent } from './lpmain/step1onboarding/ssn-main/ssn-main.component';


const routes: Routes = [
  {path:'lpus',loadChildren:() => import('./lpus/lpus.module').then(m => m.LpusModule)},
  {path:'lpindia',loadChildren:() => import('./lpindia/lpindia.module').then(m => m.LpindiaModule)},
  {path:'lpau',loadChildren:() => import('./lpau/lpau.module').then(m => m.LpauModule)},
  {path:'lpuk',loadChildren:() => import('./lpuk/lpuk.module').then(m => m.LpukModule)},
  {path:'lpusbpo',loadChildren:() => import('./lpus-bpo/lpus-bpo.module').then(m => m.LpusBPOModule)},
  {path:'lpukstatic', loadChildren:() => import('./shared/lpukstatic/lpukstatic.module').then(m => m.LpukStaticModule)},
  {path:'lpaustatic', loadChildren:() => import('./shared/lpaustatic/lpaustatic.module').then(m => m.LpauStaticModule)},
  {path:'lpindiastatic', loadChildren:() => import('./shared/lpindiastatic/lpindiastatic.module').then(m => m.LpindiaStaticModule)},
  {path:'lpusstatic', loadChildren:() => import('./shared/lpusstatic/lpusstatic.module').then(m => m.LpusStaticModule)},
  {path:'lpusbpostatic', loadChildren:() => import('./shared/lpusbpostatic/lpusbpostatic.module').then(m => m.LpusbpoStaticModule)},
  {path:'lpmain',loadChildren:() => import('./lpmain/lpmain.module').then(m => m.LpmainModule)},
  {path:'privacy', component:AdminComponent},
  //need to check {path:'notification',component:NotificationsComponent,canActivate:[AuthGuard]},
  {path:'notification',redirectTo:'lpus/notification'},//need to check canActivate:[AuthGuard]
   //need to check {path:'notificationMain',component:NotificationsComponentMain,canActivate:[AuthGuardMain]},
   {path:'notificationMain',redirectTo:'lpmain/notificationMain'},//need to check canActivate:[AuthGuardMain]
  // India
    //need to check {path:'tnc-ind',component:TncIndiaComponent,canActivate:[AuthGuardIndia]},
    {path:'tnc-ind',redirectTo:'lpindia/tnc-ind'},// need to check canActivate:[AuthGuardIndia]
  //   {path:'faq-ind',component:FaqsComponent},
      //need to check {path:'privacynotice',component:PrivacynoticeComponent , canActivate:[AuthGuardIndia]},
      {path:'privacynotice',redirectTo:'lpindia/privacynotice'}, //need to check canActivate:[AuthGuardIndia]
  //   {path:'benefit-ind',component:BenefitsIndiaComponent},
  //   {path:'contactus-ind',component:ContactusIndiaComponent},
  //   {path:'profile-ind',component:ProfileIndiaComponent},
      //need to check {path:'accept',component:AcceptOfferComponent,canActivate:[AuthGuardIndia] }, 
      {path:'accept',redirectTo:'lpindia/accept' }, //canActivate:[AuthGuardIndia] 
   //need to check {path:'video-ind',component:OnBoardingVideoComponent,canActivate:[AuthGuardIndia] },
      {path:'video-ind',redirectTo:'lpindia/video-ind' }, //canActivate:[AuthGuardIndia]
   //need to check { path: 'nomination', component: NominationComponent },
      {path: 'nomination', redirectTo:'lpindia/nomination' },
      //need to check {path:'biometric-ind',component:BiometricComponent , canActivate:[AuthGuardIndia]},
      {path:'biometric-ind',redirectTo:'lpindia/biometric-ind'}, //canActivate:[AuthGuardIndia]
      //need to check {path:'corona',component:CoronaComponent , canActivate:[AuthGuardIndia]},
      {path:'corona',redirectTo:'lpindia/corona' }, //canActivate:[AuthGuardIndia]
      //need to check {path:'vaccine',component:VaccineComponent , canActivate:[AuthGuardIndia]},
      {path:'vaccine',redirectTo:'lpindia/vaccine'}, //canActivate:[AuthGuardIndia]
  //   {path:'Mavens',component:MavensComponent},
  //   {path:'Infyhealth',component:InfyhealthComponent},
  //   {path:'needleIN',component:needleComponent},
  //   {path:'culturally-fitIN',component:CulturallyFitComponentIN},
  //   {path:'Fireball',component:FireballComponent},
  //   {path:'Toastmasters',component:ToastmastersComponent},
  //   {path:'winsIN',component:WinsComponenIN},
  //   {path:'Pega',component:PegaIndiaComponent},
  //   {path:'next-genIN',component:NextGenComponentIN},
  //   {path:'Prize',component:PrizeComponent},
  //   {path:'Library',component:LibararyComponent},
  //   {path:'sdet',component:SDETComponent},
  //   {path:'moreIN',component:MoreComponentIN},
  //   {path:'speak',component:speakComponent},
  //   {path:'digitize',component:digitizeComponent},
  //   {path:'conference',component:conferenceComponent},
  //   {path:'design',component:designComponent},
  //   {path:'experience',component:experienceComponent},
  //   {path:'Tennis',component:TennisComponent},
  //   {path:'leadership',component:leadershipComponent},
  //   {path:'opensource',component:OpensourceComponent},
  //   {path:'Curiosity',component:CuriosityComponent},
  //   {path:'federerIN',component:FedererComponentIN},
  //   {path:'endlessin',component:EndlessComponent},
  //   {path:'Envision',component:EnvisioneeringIndiaComponent},
  //   {path:'automation',component:automationComponent},
  //   {path:'leavenoone',component:leavenooneComponent},
  //   {path:'pulse-ind',component:PulseIndiaComponent},
  //   {path:'paper',component:paperComponent},
  //   {path:'notificationIN',component:notificationINComponent},
  //   {path:'boarding-pass',component:BoardingPassComponent},
    //need to check {path:'declare',component:DeclarenauthorizeComponent },
    {path:'declare',redirectTo:'lpindia/declare'},

  //  {path:'ssn1',component:Ssn1FortestComponent },
  //   {path:'infydiaryIN',component:InfydiariesComponentIN},
  //   {path:'leaderscontent',component:LeadersspeakComponentIN},
  
  // { path: 'faq', component: FaqComponent },
  // { path: 'profile', component: ProfileComponent },
    { path: 'forgot', component: ForgotPasswordComponent },
  // { path: 'benefits', component: BenefitsComponent },
  // { path: 'contactus', component: ContactusComponent },
  {
    path: 'login', component: LoginComponent, children: [
      { path: 'change', component: ChangePasswordComponent }

    ]
  },
  { path: 'change', component: ChangePasswordComponent,canActivate:[AuthGuard] },
  // { path: 'lpus', component: LpusComponent },
  // { path: 'lpuk', component: LpukComponent },
  // { path: 'lpau', component: LpauComponent },
  // { path: 'lpin', component: LpindiaComponent }
  {path:'collaborationcontent',component:CollaborationContentComponent,canActivate:[AuthGuard]},
  {path:'feedback',component:FeedbackComponent,canActivate:[AuthGuard]},
  {path:'routingNo',component:AddRoutingNoComponent,canActivate:[AuthGuard]},
  //US
//   {path:'endless',component:EndlessPossibilitiesComponent},
//   {path:'culture',component:CulturallyFitComponent},
//   {path:'celebrating',component:CelebratingComponent},
//   {path:'benefits',component:BenefitsComponent},
//   {path:'faq',component:FaqComponent},
   //need to check {path:'profile',component:ProfileComponent,canActivate:[AuthGuard]},
   {path:'profile',redirectTo:'lpus/profile'}, //canActivate:[AuthGuard]
  {path:'',component:LoginComponent},
//   {path:'boost',component:BoostingComponent},
//   {path:'fed',component:FedererComponent},
//   {path:'leave',component:LeaveComponent},
//   {path:'gallery',component:GalleryComponent},
   //need to check {path:'pri',component:PrivacyComponent, canActivate:[AuthGuardUS]},
   {path:'pri',redirectTo:'lpus/pri'}, //canActivate:[AuthGuardUS]
   //need to check {path:'ssn',component:SsnComponent, canActivate:[AuthGuardUS]},
   {path:'ssn',redirectTo:'lpus/ssn'}, //canActivate:[AuthGuardUS]
   //need to check {path:'force',component:ResiaddressComponent, canActivate:[AuthGuardUS]},
   {path:'force',redirectTo:'lpus/force'}, //canActivate:[AuthGuardUS]

 // {path:'disforce', component:SIdisabilityComponent, canActivate:[AuthGuardUS]},
 {path:'disforce', redirectTo:'lpus/disforce'}, //canActivate:[AuthGuardUS]
 // {path:'veteranforce', component:VeteranSectionComponent,canActivate:[AuthGuardUS]},

   //need to check {path:'tnc',component:TncComponent, canActivate:[AuthGuardUS]},
   {path:'tnc',redirectTo:'lpus/tnc'}, //canActivate:[AuthGuardUS]
   //need to check {path:'personal',component:PersonalComponent, canActivate:[AuthGuardUS]},
   {path:'personal',redirectTo:'lpus/personal'}, //canActivate:[AuthGuardUS]
   //need to check {path:'attendance',component:AttendanceComponent, canActivate:[AuthGuardUS]},
   {path:'attendance',redirectTo:'lpus/attendance'}, //canActivate:[AuthGuardUS]
//   {path:'wins',component:WinsComponent},
//   {path:'gems',component:GemComponent},
//   {path:'contactus',component:ContactusComponent},
//   {path:'zdPower',component:MoreComponent},
//   {path:'next',component:NextGenComponent},
//   {path:'arizona',component:BoostingComponent},
//   {path:'connecticut',component:KnowyourworkComponent},
//   {path:'raleigh',component:RaleighComponent},
//   {path:'indianapolis',component:IndianapolisComponent},
//   {path:'hartford',component:HartfordComponent},
//   {path:'palo',component:PaloComponent},
//   {path:'rich',component:RichardsonComponent},
//   {path:'pro',component:ProvidenceComponent},
//   {path:'new',component:NewyorkComponent},
  // {path:'notification',component:NotificationsComponent},
//   {path:'confluence',component:ConfluenceComponent},
//   {path:'digitallyintelligent',component:DigitallyIntelligentComponent},
//   {path:'endpoints',component:EndPointsComponent},
//   {path:'indianapolisLeaders',component:IndianapolisLeadersComponent},
//   {path:'spreadingHope',component:SpreadingHopeComponent},
//   {path:'gldy',component:GldyComponent},
//   {path:'infosysPrize',component:InfosysPrizeComponent},
//   {path:'pega',component:PegaComponent},
//   {path:'pride',component:PrideComponent},
//   {path:'venture',component:VentureComponent},
//   {path:'thoughtLeadership',component:ThoughtLeadershipComponent},
//   {path:'leadersSpeakSeeAll',component:LeadersSeeAllUSComponent},
//   {path:'infyDiariesSpeakSeeAll',component:InfyDiariesSeeAllUSComponent},
//Australia
  // { path: 'faq-au', component: FaqAUComponent },
  // { path: 'contactus-au', component: ContactusAuComponent },
  // { path: 'benefits-au', component: BenefitsAuComponent },
  // { path: 'profile-au', component: ProfileAuComponent },
    //need to check { path: 'privacy-au', component: PrivacyAuComponent,canActivate:[AuthGuardAUS] },
    { path: 'privacy-au', redirectTo: 'lpau/privacy-au' }, //canActivate:[AuthGuardAUS]
    //need to check { path: 'tnc-au', component: TncAuComponent,canActivate:[AuthGuardAUS] },
    { path: 'tnc-au', redirectTo: 'lpau/tnc-au' }, //canActivate:[AuthGuardAUS]
  // { path: 'retail', component: RetailComponent },
  // { path: 'telstra', component: TelstraComponent },
  // { path: 'brilliantly', component: BrilliantlyComponent },
  // { path: 'winners', component: WinnersComponent },
  // { path: 'catalyst-award', component: CatalystAwardComponent },
  // { path: 'pega', component: PegaAUComponent },
  // { path: 'isg', component: IsgComponent },
  // { path: 'yicte', component: YicteComponent },
  // { path: 'digitizing', component: DigitizingComponent },
  // { path: 'design-thinking', component: DesignThinkingComponent },
  // { path: 'zero-distance', component: ZeroDistanceComponent },
  // { path: 'selenium', component: SeleniumComponent },
  // { path: 'australian-open', component: AustralianOpenComponent },
  // { path: 'thought-leadership', component: ThoughtLeadershipAUComponent },
  // { path: 'infykubecon', component: InfykubeconComponent },
  // { path: 'curiosity-au', component: CuriosityAuComponent },
  // { path: 'disciple', component: DiscipleComponent },
  // { path: 'tennis-insights', component: TennisInsightsComponent },
  // { path: 'envisioneering', component: EnvisioneeringComponent },
  // { path: 'endless-possibilities', component: EndlessPossibilitiesAUComponent },
  // { path: 'automation-success', component: AutomationSuccessComponent },
  // { path: 'none-behind', component: NoneBehindComponent },
  // { path: 'birds-eye', component: BirdsEyeComponent },
  // { path: 'ace-australian-open', component: AceAustralianOpenComponent },
  // { path: 'fine-tuning', component: FineTuningComponent },
  // { path: 'freshers-au', component: FreshersAuComponent },
  // { path: 'davos', component: DavosComponent },
  // { path: 'govhack', component: GovhackComponent },
  // { path: 'salvos', component: SalvosComponent },
  // { path: 'farewell2018', component: Farewell2018Component },
  // { path: 'kitchen-au', component: KitchenAuComponent },
  // { path: 'notification-au', component: NotificationsAuComponent },
  // {path:'diariesContent-au',component:DiariesContentAuComponent},
  // {path:'leadersContent-au',component:LeaderContentAuComponent},
  // {path:'workplaceContent-au',component:WorkplaceContentAuComponent},

    // {path:'benefits-uk',component:BenefitsUkComponent},
    // {path:'contactus-uk',component:ContactusUkComponent},
    // {path:'faq-uk',component:FaqUkComponent},
      //need to check {path:'privacy-uk',component:PrivacyUkComponent,canActivate:[AuthGuardUK]},
      {path:'privacy-uk',redirectTo:'lpuk/privacy-uk'}, //canActivate:[AuthGuardUK]
      //need to check {path:'tc-uk',component:TCUkComponent,canActivate:[AuthGuardUK]},
      {path:'tc-uk',redirectTo:'lpuk/tc-uk'}, //canActivate:[AuthGuardUK]
    // {path:'changepassword-uk',component:ChangepasswordUkComponent},
    // {path:'notification-uk',component:NotificationsUkComponent},
    // {path:'know-uk',component:KnowyourworkUkComponent},
    // {path:'infyd-uk',component:InfydiariesUkComponent},
    // {path:'leaders-uk',component:LeadersspeakUkComponent},
    // {path:'leaderscontent-uk',component:LeaderscontentUkComponent},
    // {path:'kywcontent-uk',component:KywcontentUkComponent},
    // {path:'infydairiescontent-uk',component:InfydairiescontentUkComponent},
    // {path:'collaboration-uk',component:CollaborationUkComponent}
     //need to check {path:'tnc-bpo', component:TncUsbpoComponent,canActivate:[AuthGuardUSBPO]},
     {path:'tnc-bpo', redirectTo:'lpusbpo/tnc-bpo'}, //canActivate:[AuthGuardUSBPO]
     //need to check {path:'privacy-bpo',component:PrivacyUsbpoComponent,canActivate:[AuthGuardUSBPO]},
     {path:'privacy-bpo',redirectTo:'lpusbpo/privacy-bpo'}, //canActivate:[AuthGuardUSBPO]
     //need to check {path:'termsandconditons', component:TermsandconditonsComponent,canActivate:[AuthGuardMain]},
     {path:'termsandconditons', redirectTo:'lpmain/termsandconditons'}, //canActivate:[AuthGuardMain]
     //need to check {path:'privacymain', component:PrivacyComponentMain,canActivate:[AuthGuardMain]},
     {path:'privacymain', redirectTo:'lpmain/privacymain'}, //canActivate:[AuthGuardMain]
     {path:'ssn-main',  redirectTo:'lpmain/ssn-main'}, //canActivate:[AuthGuardMain]
     {path:'ssn-usbpo',redirectTo:'lpusbpo/ssn-usbpo'}, //canActivate:[AuthGuardUSBPO]

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
