export class IndiaInputParam
{
    CandidateId :number;
    EmailId:string;
    CandExDetID :number;
    GapID :number;
    CandExDetIdID :number;
    sqlGapDetails : string;
    sqlParameterMarks : string;
    sqlParameterLanguage : string;
    flgMode : string;
    levelCode : string;
    Location : string;
}