import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { AlertService } from '../utils/alert/alert.service';
import { AppService } from '../app.service';
import { Router, ActivatedRoute } from '@angular/router';
declare function ShowBottomBar():any;
declare function HideBottomBar():any;

declare function AddBodyscroll():any;

@Component({
  selector: 'app-collaboration-content',
  templateUrl: './collaboration-content.component.html',
  styleUrls: ['./collaboration-content.component.css']
})
export class CollaborationContentComponent implements OnInit {

  mobile:boolean;
  constructor(
    private spinner:NgxSpinnerService,
    private breakpointObserver: BreakpointObserver, 
    private alert:AlertService,
    private appService:AppService,
    private router:Router,
    private r:ActivatedRoute
  ) { }

  ngOnInit() {
     this.spinner.show();
    HideBottomBar();
    this.breakpointObserver.observe(['(max-width: 599px)']).subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.appService.setIsMobile(true);
        this.mobile = true;
        window.scroll(0, 0);
         this.spinner.hide();
      } else {
        this.appService.setIsMobile(false);
        this.mobile = false;
         this.spinner.hide();
      }
    });
  }
  OnStepBackClicked(){
    this.appService.setHiddenDashBoard(false);
    if(localStorage.getItem("Region")=="IL UK" || localStorage.getItem("Region")=="BPM UK"){
      this.router.navigate(['../lpuk'], { relativeTo: this.r })
    }
    else if(localStorage.getItem("Region")=="IL AUSTRALIA" || localStorage.getItem("Region")=="BPM AUSTRALIA"){
      this.router.navigate(['../lpau'], { relativeTo: this.r })
    }
     else if(localStorage.getItem("Region")=="US"){
      this.router.navigate(['../lpus'], { relativeTo: this.r })
    }
    else if(localStorage.getItem("Region").toUpperCase()=="CANADA" || localStorage.getItem("Region").toUpperCase() == "IPS CANADA" || localStorage.getItem("Region").toUpperCase() == "IL NEW ZEALAND" || localStorage.getItem("Region").toUpperCase()=="IL GERMANY" || localStorage.getItem("Region").trim().toUpperCase() == "ILIGAM" || localStorage.getItem("Region").trim().toUpperCase() == "IL MALAYSIA"){
      this.router.navigate(['../lpmain'], { relativeTo: this.r })
    }
    else{
      this.router.navigate(['../lpindia'], { relativeTo: this.r })
    }       
    //this.router.navigate(['../lpuk']);
    ShowBottomBar();
  }
  AddBodyscroll(){
    AddBodyscroll();

  }

}
