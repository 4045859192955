import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import {map} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { LPIndiaCustomHttpClient } from '../../lpindia.interceptor';
import { Configuration, APIUrls } from '../../../configuration';
import { inductionfeedbackQuestions } from '../../models-india/inductionfeedbackQuestions.model';

@Injectable()
export class InductionfeedbackIndiaService {

  constructor(private _http: LPIndiaCustomHttpClient) { }

  InsertInductionFeedbackDetails(feedbackquelist:inductionfeedbackQuestions[]): Observable<string> {
    var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
    headers=headers.append('Content-Type','application/json')
    return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.InsertInductionFeedbackDetails,feedbackquelist,{
    headers:headers
    }).pipe(map(
    (res) => {
        // console.log(res)
    return res;
} 
))  
}


_errorHandler(error: HttpErrorResponse) {
  // console.log(error);
  return throwError(error.message || "Server Error");
}


}