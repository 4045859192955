import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http'
import { Injectable } from '@angular/core';
import {map} from 'rxjs/operators'
import {catchError} from 'rxjs/operators'
import { Observable, throwError } from 'rxjs';
import { IndiaUserCredentials } from '../models-india/indiaUserCredentials.model';
import { IndiaPreJoiningLogin } from '../models-india/IndiaPreJoiningLogin.model';
import { IndiaCandidateAdvisorImage } from '../models-india/indiaCandidateAdvisorImage.model';
import { IndiaMailContent } from '../models-india/IndiaMailContent.model';
import { IndiaResult } from '../models-india/indiaResult.model';
import { LPFetchLoginAndIdentification } from '../models-india/LPFetchLoginAndIdentification';
import { LPIndiaCustomHttpClient } from '../lpindia.interceptor';
import { Configuration, APIUrls } from '../../configuration';
import { RequestACall } from '../models-india/requestACall';
import { RequestACallDetails } from '../models-india/requestACallDetails';
import { WorkFromHomeDetails } from '../models-india/IndiaBankDetails.model'; //wfhus
import { CommonDocContent } from 'src/app/lpmain/Model-Classes/policy-main.model';
import { Candidate } from '../models-india/Candidate';
import { IpDeclaration, StatutoryForms } from '../models-india/StatutoryForms.model';
import { LPFetchGDCPFDDetails } from '../models-india/LPFetchGDCPFDDetails';
import { UpdatePDFFlgDetails } from '../models-india/UpdatePDFFlgDetails';
import { Policy } from '../models-india/policy.model';


@Injectable()
export class HeaderIndiaService {    
    
    constructor(private _http: LPIndiaCustomHttpClient) { }

    GetUserDetails(user:IndiaUserCredentials):Observable<IndiaPreJoiningLogin[]>{
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4NzZiYWU4Yi02NjYyLTQ1ZGMtYmQ5Zi0xNWE0NzQ4MGZjYmYiLCJleHAiOjE1NTY2ODYwOTEsIm5iZiI6MCwiaWF0IjoxNTU1MzkwMDkxLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgvcmVhbG1zL0lTMSIsImF1ZCI6InBvcnRhbCIsInN1YiI6IjkzYjlmMGQ5LTg0ZjctNDVmNS05MzAxLTZjNGEwMzA4OTNmOCIsInR5cCI6IklEIiwiYXpwIjoicG9ydGFsIiwibm9uY2UiOiJiOThkOTk3My1jNjEyLTRmNjYtYjRmYi1lZGY5MzFlMjRkZDQiLCJhdXRoX3RpbWUiOjE1NTUzMzExNTcsInNlc3Npb25fc3RhdGUiOiIxYmJlMzIyZC0wNjUxLTRlNTgtOTQyNi01MDAzODVmOWI4OGMiLCJhY3IiOiIwIiwibmFtZSI6IkFiaGlzaGVrIE5lZ2kiLCJwcmVmZXJyZWRfdXNlcm5hbWUiOiJ0ZXN0MUBkdW1teS5jb20iLCJnaXZlbl9uYW1lIjoiQWJoaXNoZWsiLCJmYW1pbHlfbmFtZSI6Ik5lZ2kiLCJlbWFpbCI6InRlc3QxQGR1bW15LmNvbSJ9.dR6KzCyPLN-x1gt1SJAz3W9q2JqDESn5gTIh19sy5wgka6Uy7a3H_-km8UoQiUX5qTgg8onjbroWp0FUh45olXmAmVDOcL7cLvQvFuHOxL7OBeo5Dh18a3kUUl5tqPlypuxPQ-eZ7NGSR0T0jd6qn_is1hM1q_xQ3h7Jal8b7IH3012P8C7s51txRt0xH38bQCSSaz7tjgLRu0vc_95Cqwz2xMUq19NIJdEoWqxgw0P-6xGOgq44Np2Y2VypweacEpsEZDGBa9EdBEKcFPLe4rRabR8sE7LWJktX7JWqU1JHPWiWD4szVhlYnHjB2eS3k7hrvGEJ9Dz_gurL_oA8PQ');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<IndiaPreJoiningLogin[]>(Configuration.ResServerBaseURLIndia+APIUrls.GetUserDetailsIN,user,{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
        }))
    }
    GetCommonDocDetails(input: CommonDocContent): Observable<CommonDocContent[]> {
        return this._http.post<CommonDocContent[]>(Configuration.ResServerBaseURL + APIUrls.GetCommonDocDetails, input, {
        }).pipe(map(
            (res) => {
                //console.log(res);/
                return res;
            }
        ))
    }
    fnGetCandPhoto(userCredentials:IndiaUserCredentials): Observable<IndiaCandidateAdvisorImage> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<IndiaCandidateAdvisorImage>(Configuration.ResServerBaseURLIndia+APIUrls.fnGetCandPhotoIN,{"CandidateId":parseInt(localStorage.getItem("intCandidateId"))},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
    } 
))  
    }
    _errorHandler(error: HttpErrorResponse) {
        // console.log(error);
        return throwError(error.message || "Server Error");
    }
    SendMailerHelp (mailContent:IndiaMailContent): Observable<IndiaResult> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI4MzgwOGFhYi01MDFiLTRlMGMtOTVjYi1lODg2YTA1NzRiOTMiLCJleHAiOjE1NTU1Njc2NTgsIm5iZiI6MCwiaWF0IjoxNTU0MjcxNjU4LCJpc3MiOiJodHRwczovL3dpbmdzcGFuLWRldi5pbmZvc3lzYXBwcy5jb20vYXV0aC9yZWFsbXMvSVMxIiwiYXVkIjoicG9ydGFsIiwic3ViIjoiOTNiOWYwZDktODRmNy00NWY1LTkzMDEtNmM0YTAzMDg5M2Y4IiwidHlwIjoiSUQiLCJhenAiOiJwb3J0YWwiLCJub25jZSI6ImY5ZWYzNDUxLWEzYTctNDc1Mi04YmNhLTQxYjg0NTg1YWU0YyIsImF1dGhfdGltZSI6MTU1NDIwMzM5Miwic2Vzc2lvbl9zdGF0ZSI6IjA5NjFhMDNiLTU2NGItNGYwNy1iMjRjLTgzN2FlYmJhNjY0MCIsImFjciI6IjAiLCJuYW1lIjoiQWJoaXNoZWsgTmVnaSIsInByZWZlcnJlZF91c2VybmFtZSI6InRlc3QxQGR1bW15LmNvbSIsImdpdmVuX25hbWUiOiJBYmhpc2hlayIsImZhbWlseV9uYW1lIjoiTmVnaSIsImVtYWlsIjoidGVzdDFAZHVtbXkuY29tIn0.ncBiZlVLJa5QHpe8KwWlOjcnpoF4rnbuQd70_2vSQbrpy0qdP7-AzLDadEp5U03mjzlWWEJR0KegfwVrJuQIdxRHurlJbZ2M7MCHdWroi4RCDCI4HzDSbvyFsDyLrZgg0WEuo5YO7b4Up1JN4sfVro1MfqqCBoimWV63HfDGC0XKJ6hMVt1UC-sX3NfqO4xtC3w5lTfv84wSmdWGhQNKvaTOlHhjqyXTDg8c2UrCaOvbu-jk3L_hOdNOogizMqIufyBhmOMsJusuO-hH5sQuX5L0BA7DFF1plwqyXm9Y5DdjSVApDYiHr5GCgdVCitLAXmCWN1yA3WlnrZGHATr-yw');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<IndiaResult>(Configuration.ResServerBaseURLIndia+APIUrls.SendMailerHelpIN,{"MailBody":mailContent.MailBody},{
        headers:headers
        }).pipe(map(
        (res) => {
        return res;
    } 
))  
    }
    GetCandidateDetailsOnCandidateId(id:Number): Observable<LPFetchLoginAndIdentification> {
        var headers=new HttpHeaders().set('Authorization','Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers=headers.append('Content-Type','application/json')
        return this._http.post<LPFetchLoginAndIdentification>(Configuration.ResServerBaseURLIndia+APIUrls.GetCandidateDetailsOnCandidateId,id,{
        headers:headers
        }).pipe(map(
        (res) => {
            // console.log(res)
        return res;
    } 
))  
    }

    GetWorkFromHomeDetailsIN(wfhDetails:WorkFromHomeDetails):Observable<WorkFromHomeDetails>{
        return this._http.post<WorkFromHomeDetails>(Configuration.ResServerBaseURLIndia+APIUrls.GetWorkFromHomeDetailsIN,wfhDetails,).pipe(map(
            (res) => {
           // console.log(res);
            return res;
            }
            )) 
    // wfhus
} 

    InsertRequestCallDetails(request: RequestACall):Observable<string>
    {
        // console.log(request);
        return this._http.post<string>(Configuration.ResServerBaseURLIndia+APIUrls.InsertRequestCallDetails,request).pipe(map(
            (res) => {
                // console.log(res)
            return res;
        } 
    ))
    }

    GetRequestCallDetails():Observable<RequestACallDetails>
    {
        return this._http.post<RequestACallDetails>(Configuration.ResServerBaseURLIndia+APIUrls.GetRequestCallDetails,null).pipe(map((res) => {
            // console.log(res)
        return res;
    } ));
    }
    LPFetchflgPolicy(cand: Candidate): Observable<string> {
        var headers = new HttpHeaders().set('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers = headers.append('Content-Type', 'application/json')
        let body = JSON.stringify(cand);
        return this._http.post<string>(Configuration.ResServerBaseURLIndia + APIUrls.LPFetchflgPolicy, body, {
            headers: headers
        }).pipe(map(
            (res) => {
                return res;
            }
        ))
    }

    InsertIPDeclarationDetails(ipdeclaration: IpDeclaration[]): Observable<Number> {
        var headers = new HttpHeaders().set('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers = headers.append('Content-Type', 'application/json');
        let body = JSON.stringify(ipdeclaration);
        // console.log(body);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia + APIUrls.InsertIPDeclarationDetails, body, { headers: headers }).pipe(map((res) => { return res; }));
    }
    InsertSatutoryFormsDetails(statutoryForm: StatutoryForms): Observable<Number> {
        var headers = new HttpHeaders().set('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers = headers.append('Content-Type', 'application/json');
        let body = JSON.stringify(statutoryForm);
        // console.log(body);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia + APIUrls.InsertSatutoryFormsDetails, body, { headers: headers }).pipe(map((res) => { return res; }));
    }
    GetGDCDetails(cand: Candidate): Observable<LPFetchGDCPFDDetails[]> {
        var headers = new HttpHeaders().set('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers = headers.append('Content-Type', 'application/json')
        let body = JSON.stringify(cand);
        return this._http.post<LPFetchGDCPFDDetails[]>(Configuration.ResServerBaseURLIndia + APIUrls.GetGDCDetails, body, {
            headers: headers
        }).pipe(map(
            (res) => {
                return res;
            }
        ))
    }
    UpdatePDFFlagDetails(details: UpdatePDFFlgDetails): Observable<string> {
        var headers = new HttpHeaders().set('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers = headers.append('Content-Type', 'application/json')
        let body = JSON.stringify(details);
        return this._http.post<string>(Configuration.ResServerBaseURLIndia + APIUrls.UpdatePDFFlagDetails, body, {
            headers: headers
        }).pipe(map(
            (res) => {
                return res;
            }
        ))
    }
    LPUpdateflgPolicy(policy: Policy) {
        var headers = new HttpHeaders().set('Authorization', 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJVc2VyTmFtZSI6Imxva2VzaHYubWVjaEBnbWFpbC5jb20iLCJDYW5kaWRhdGVJRCI6IjEyIiwiTGFzdFB3ZENoYW5nZWRTdGFtcCI6IjEvNy8yMDE5IDc6MDc6MDAgUE0iLCJpc3MiOiJJbmZ5SVMuTFBXZWJBUEkuQXV0aFNlcnYuQVBJX0RFViIsImF1ZCI6IkxwSW5kaWEiLCJleHAiOjE1NzI3ODI3NjEsIm5iZiI6MTU1NTUwMjc2MX0.0495MK4I8bMiZ7uUJeTX08fO8E2_Cqz1Qiq6_U9_6jI');
        headers = headers.append('Content-Type', 'application/json');
        let body = JSON.stringify(policy);
        return this._http.post<Number>(Configuration.ResServerBaseURLIndia + APIUrls.LPUpdateflgPolicy, body, { headers: headers }).pipe(map((res) => { return res; }));
    }
}