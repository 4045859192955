export class LPLoginDetailsBadges{
    CandidateId :Number;
    TimeStamp :Date;
    Platform :String;
    MobOSCode :String;
    MobOSName :String;
    Manufacturer :String;
    MobAppVer :String;
    DeviceId :String;

}