import { Component, OnInit } from '@angular/core';
import { NgbDateFRParserFormatter } from '../../../NgbDateFRParserFormatter';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { EducationService } from './education.service';
import { Education } from '../../models-india/Education.model';
import { LPFetchEducationDetails } from '../../models-india/LPFetchEducationDetails';
// import { isDefaultChangeDetectionStrategy } from '@angular/core/src/change_detection/constants';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { StateForLpIndia } from '../../models-india/StatesForIndia.models';
import { Degrees } from '../../models-india/LPFillDegreeDropDown.model';
import { Levels } from '../../models-india/LPFillLevelDropDown.model';
import { MarksDetails } from '../../models-india/MarksDetailsFreshers.model';
import { LPFetchFresherEducationDetails, BasicDetailsFreshers } from '../../models-india/LPFetchFresherEducationDetail.model';
import { EducationDocs } from '../../models-india/EducationDocs';
import { LPFetchEduDegreeDesc } from '../../models-india/LPFetchEduDegreeDesc';
import { LPDms } from '../../models-india/LPDms';
import { DMSInternetPlainDocumentResponse } from '../../models-india/DMSInternetPlainDocumentResponse';
import { LPFetchEduDocDetails } from '../../models-india/LPFetchEduDocDetails';
import { FetchEducationDocs } from '../../models-india/FetchEducationDocs';
import { AlertService } from '../../../utils/alert/alert.service';
import { FetchEducation } from '../../models-india/FetchEducation';
import { LPGetQualTypes } from '../../models-india/LPGetQualTypes';
import { max } from 'rxjs/operators';
// import { isDate } from '@angular/common/src/i18n/format_date';
import { MarksDetailsFreshersSemList } from '../../models-india/MarksDeailsFresherSemesterList.model';
import { NgxSpinnerService } from 'ngx-spinner';

import { AppService } from 'src/app/app.service';
import { NgxImageCompressService } from 'ngx-image-compress';
//need to check import { Ng2ImgMaxService } from 'ng2-img-max';
import { DMSNew } from '../../models-india/dmsNew';
import { DashboardIndiaService } from '../../dashboard-india/dashboard-india.service';
import { PostjoiningIndiaService } from 'src/app/lpindia/postjoining/postjoining.serivce';
import { IndiaInputParam } from 'src/app/lpindia/models-india/IndiaInputParam.model';
import { DatePipe } from '@angular/common';
import { AuthService } from 'src/app/login/auth.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { NoteConstants } from 'src/app/NotesConstants';
import { BGVDetails } from '../../models-india/BGVDetails.model';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BgvcheckComponent } from '../../bgvcheck/bgvcheck.component';
import { BGV, BGVPopup,SectionShow } from '../../models-india/GDC.model';
import { Candidate } from '../../models-india/Candidate';

declare function ChgwrkexIN(): any;
declare function ChgeduIN(): any;
declare function LoadAccordianJs(): any;
declare function GetBase64Data(stream, name): any;
declare function LoadJsForModalBackDrop(): any;
declare function LoadStartStep1Modal1(): any;
declare function StartModalClose1(): any;
declare function addCssForMatElement(): any;
declare function getElement(id): any;
declare function UpdateButtonClick1(id): any;
declare function ChgachieveIN(): any;
declare function AddBodyscroll(): any;
declare function RemoveBodyscroll(): any;
declare function LoadDataSubmitModal(): any;
declare function CloseDataSubmitModal(): any;
declare function LoadOwlCarouselForGallery(): any;
declare function LoadJsForDashBoardMobileCarousel(): any;
declare function ShowBottomBar(): any;
declare function LoadStyleForIconsIndia(i: string, e: string, b: string, ba: string, f: string, c: string, edu: string,
  exp: string, p: string, g: string, dis: string, nsr: string, pan: string, aadhar: string, acc: string, med: string, ach: string, nats: string, statutory: string): any;
declare function matFormBorderTopZero(): any;

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})

export class EducationComponent implements OnInit {
  marksList: MarksDetails[] = []; 
  markObtained: number = 0;
  maxMarks: number = 0;
  avgMarks: number = 0.0;
  addMarkObtained: number = 0;
  addMaxMarks: number = 0;
  xDocument: XMLDocument;
  semNumber: number = 0;
  markType: string;
  cgpaChecked: boolean = false;
  percentageChecked: boolean = false;
  markEditFlag: boolean = false;
  isValidMark: boolean = false;
  addSemesterFlag: boolean = false;
  minstart: Date;
  maxstart: Date;
  minend: Date;
  maxend: Date;
  minstartarray: NgbDate[];
  minendarray: NgbDate[];
  maxstartarray: NgbDate[];
  maxendarray: NgbDate[];
  sdate: String[] = [];
  edate: String[] = [];
  fresher:Boolean= localStorage.getItem("Identification").toUpperCase().trim()=="FRESHER";
  lateral:Boolean= localStorage.getItem("Identification").toUpperCase().trim()!="FRESHER";
  mainPage: Boolean = true;
  showForm: Boolean;
  dateError: Boolean;
  addNewEdu: Boolean;
  XForm: Boolean = true;
  Degree: Boolean = false;
  edit: Boolean = false;
  attchDoc: Boolean = false;
  education: Education;
  msg: Number;
  stateList: StateForLpIndia[] = [];
  //manuallyAddedState={txtState:'Others',txtStateCode:'OT'}
  levelList: Levels[] = [];
  newlevelList: Levels[] = [];
  degreeList: Degrees[] = [];
  errormsg: String;
  edudetail: LPFetchEducationDetails;
  eduDetails: LPFetchEducationDetails[] = [];
  txtInstituteName: String;
  txtBranchCode: String;
  txtStartDate: Date;
  txtOldStartDate:Date;
  txtStartDateNew:Date;
  txtEndDate: Date;
  txtOldEndDate:Date;
  txtEndDateNew:Date;
  edudegree: String;
  dtMonthYear: String;
  MarksOrGPA: String;
  txtDegree: String;
  txtDegreeCode: String;
  roll: String;
  semCount: Number;
  yearOrSemester: String;
  state: String;
  candDetId: Number = 0;
  QualificationGained: String;
  intQualMarkId: Number;
  mobile: Boolean;
  desktop: Boolean;
  myFile: File[] = new Array(22);
  semMarks: Number[] = [];
  isedit: Boolean = true;
  nonedit: Boolean = true;
  university: String;
  txtstate: String;
  //txtscoretype: String;

  txtothersstate: String;
  fresherDetails: LPFetchFresherEducationDetails;
  fresherDetail: LPFetchFresherEducationDetails;
  lateralDetails: LPFetchFresherEducationDetails;
  basicDetails: BasicDetailsFreshers[];
  markDetails: MarksDetails[];
  isTenthTwelth: Boolean;
  isdropDown: Boolean = true;
  today = new Date();
  maxyear = this.today.getFullYear();
  maxmonth = this.today.getMonth() + 1;
  maxday = this.today.getDate();
  maxdate = new Date(this.maxyear, this.maxmonth, this.maxday);
  mindate = new Date(1900, 1, 1)
  stDate: NgbDate;
  fname: String[] = new Array(22);
  fsize: Number[] = new Array(22);
  selectedEduQualId: number;
  selectedEduQyalifiactionCode: string;
  selectedEduPassingYear: string;
  selectedEdu: LPFetchEducationDetails;
  selectedEdu1: BasicDetailsFreshers;
  docType: String;
  DegreeDescription: String;
  EduLevel: String;
  doclevel: String;
  YearOrSem: String;
  nameGenerated: String;
  uploadDocResponse: DMSInternetPlainDocumentResponse;
  doc_serial_number: Number;
  marks: number;
  selStart: Date;
  dataPresent: Boolean;
  selEnd: Date;
  semesterNumberList: number[] = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  addMarksFlag: boolean = false;
  lpFetchEduDocDetailsList: LPFetchEduDocDetails[] = new Array(14);
  static fileAsBasestring: string;
  formatError: boolean;
  sizeError: Boolean;
  nameError: Boolean;
  fileUploadFlag: boolean;
  tenthTwelthDegree: boolean = false;
  tenthTwelthMarkSheet: boolean = false;
  degree: boolean = false;
  provisional: boolean = false;
  consolidated: boolean = false;
  sem1: boolean = false;
  sem2: boolean = false;
  sem3: boolean = false;
  sem4: boolean = false;
  sem5: boolean = false;
  sem6: boolean = false;
  sem7: boolean = false;
  sem8: boolean = false;
  sem9: boolean = false;
  sem10: boolean = false;
  sem11: boolean = false;
  sem12: boolean = false;
  semWise: boolean = false;
  consMark: boolean = false;
  year1: boolean = false;
  year2: boolean = false;
  year3: boolean = false;
  year4: boolean = false;
  year5: boolean =false;
  year6: boolean =false;
  year7: boolean =false;
  year8: boolean =false;
  dipshow: boolean =true;
  dipgrad:boolean =true;
  pgshow: boolean =true;
  diploma: boolean =false;
  semhide : boolean =true;
  BSchide : boolean =true;
  semesterDropdown: String[] ;
  xmlstring: string;
  semOrYear: string;
  listOfMarks: MarksDetails[];
  semesterListOfMarks: MarksDetails[] = [];
  semesterList: string[] = ["S1", "S2", "S3", "S4", "S5", "S6", "S7", "S8", "S9", "S10", "S11", "S12"];
  yearList: string[] = ["Y1", "Y2", "Y3", "Y4", "Y5", "Y6", "Y7", "Y8", "Y9", "Y10", "Y11", "Y12"];
  // marksType:string[]=["S1","S2","S3","S4","S5","S6","S7","S8","S9","S10","S11","S12"];
  // marksObtained:number[]=[];
  // marksMax:number[]=[];
  marksType: string;
  marksObtained: number;
  marksMax: number = 0;
  markObtainedTenthTwelth: number;
  maxMarksTenthTwelth: number;
  avgMarksTenthTwelth: number;
  avgMarksTenthTwelthOne: number;
  degreeCode: String;
  maxmarkList: number[] = [];
  //editSemMarks:boolean[]=[false,false,false];
  editSemMarks: boolean;
  selectedSemester: string;
  totalMarksObtained: number;
  totalMaxMarks: number;
  totalAverage: number;
  semesterTOTintQualMarkId: any;
  educationDetail: boolean;
  countNewFlags: number = 0;
  editableFields: boolean;
  userIndex: number;
  uploadType: string ;
  fresherSemesterMarksList: MarksDetailsFreshersSemList[] = [];
  hideme = [];
  static Base64String: any;
  static arr: any[];
  camera: boolean;
  order: string;
  pendingWeb: Boolean = false;
  CompanyName: Boolean = (localStorage.getItem("COMPANY").toUpperCase() == "KALSKV");
  txtTopNote:string;
  txtBottomNote:string;
  txtTitleName:string;
  flgEnableTopNote:boolean;
  flgEnableBottomNote:boolean;
  fileValidate:File;
  fileBool: boolean=false;
  params: IndiaInputParam = new IndiaInputParam();
  educationalDetails: Boolean;
  experienceDetails: Boolean;
  passportDetails: Boolean;
  achievement: Boolean;
  placeholder: Boolean;
  natsDetails: Boolean;
  statutory: boolean;
  agenda: Boolean;
  accomodation: Boolean;
  medical: Boolean;
  basicDetails1: Boolean;
  familyDetails: Boolean;
  contactDetails: Boolean;
  gapDetails: Boolean;
  disabilitydetails: Boolean;
  nsrDetails: Boolean;
  pandetails: Boolean;
  aadharDetails: Boolean;
  emergency: Boolean;
  bank: Boolean;
  message: Boolean;
  flgBGV: Boolean = false;
  candidate: Candidate;
  bgv: BGV;
  bgvShow: BGVPopup;
  popShow: boolean = false;
  combMsgShow: boolean = false;
  updateBGV = new SectionShow;
  txtBGVMessage: String;
  flgBGVMessage: Boolean = false;
  
  EmailId: string;
  ibpm:boolean = localStorage.getItem("COMPANY").toString().toUpperCase()=="IBPM INDIA";
  company:Boolean=localStorage.getItem("COMPANY").toUpperCase().trim()=="IL";
  flgEdu:Boolean = localStorage.getItem("flgEdu").toUpperCase() == "Y";
  flgIsRebadging:Boolean = localStorage.getItem("flgRebadging") == "True";
  flgNoConsolidated:Boolean;
  flgNoProvisional:Boolean;
  flgNoDegreeCertificate:Boolean;
  flgValidProvisional: boolean;
  flgValidConsolidated: boolean;
  flgValidDegreeCertificate: boolean;
  flgtrynodegree: any='N';
  flgConsolidated: any='N';
  flgProv: any='N';

  flgNoSemMarksheets:Boolean;
  flgNoyearMarksheets:Boolean;
  flgNoProvisionalSemWise:Boolean;
  flgNoProvisionalyearwise:Boolean;
  flgNoDegreeCertificateSemWise:Boolean;
  flgNoDegreeCertificateyearwise:Boolean;
  flgValidProvisionalSemWise: boolean;
  flgValidProvisionalyearwise: boolean;
  flgValidSemMarksheets: boolean;
  flgValidyearMarksheets: boolean;
  flgValidDegreeCertificateSemWise: boolean;
  flgValidDegreeCertificateyearwise: boolean;
  flgDegreeSemWise:any;
  flgDegreeyearwise:any;
  flgProvSemWise:any;
  flgProvyearwise:any;
  flgMarksheetSemWise:any;
  flgMarksheetyearwise:any;
  flgEduPost:Boolean = Number(localStorage.getItem("DaysLeft"))<1;
  flgEduVerified: boolean=false;
  EduRejected: boolean;
  intCandQualDetIdForBGV: Number;
  txtQualCodeForBGV: String;
  public currentYearBGV: number = this.today.getFullYear();
  public currentMonthBGV: number = this.today.getMonth();
  public currentDayBGV: number = this.today.getDate();
  public maxDateBGV: Object =  new Date(this.currentYearBGV, this.currentMonthBGV+7, this.currentDayBGV);
  txtReasonSemester: String;
  txtReasonConsolidated: String;
  dateTentative: String;
  QuestionCode: String;
  txtReasonConsolidated1: String="";
  txtReasonConsolidated2: String="";
  txtReasonConsolidated3: String="";
  txtReasonSem1: String="";
  txtReasonSem2: String="";
  txtReasonSem3: String="";
  txtReasonyear1: String="";
  txtReasonyear2: String="";
  txtReasonyear3: String="";
  dtTent1: Date=this.today;
  dtTent2: Date=this.today;
  dtTent3: Date=this.today;
  dtTent4: Date=this.today;
  dtTent5: Date=this.today;
  dtTent6: Date=this.today;
  dtTent7: Date=this.today;
  dtTent8: Date=this.today;
  dtTent9: Date=this.today;
  docDeclaration1:String;
  docDeclaration3:String;
  docDeclaration4:String;
  docDeclaration5:String;
  docDeclaration6:String;
  docDeclaration7:String;

  txtEducationLevel: String;
  txtIdentifier: String;
  yearwise: boolean =false;
  flgYear: boolean ;
  flgSemester: boolean;
  flgStateEnter:boolean;
  flgDocVerified: boolean = false;
  enableUploadingDocs: boolean = true;
  freeze: boolean;
  //statecodevalue:String;
  constructor(
    public dialog: MatDialog,
    private appServiceEducationIN: AppService,
    private _educationServiceEducationIN: EducationService,
    private dateformatterEducationIN: NgbDateFRParserFormatter,
    private routerEducationIN: Router,
    private rEducationIN: ActivatedRoute,
    private breakpointObserverEducationIN: BreakpointObserver,
    private alertEducationIN: AlertService,
    private dashboardEducationIN: DashboardIndiaService,
    private _postServiceEducationIN: PostjoiningIndiaService,
    private appService: AppService,
    private dashboard:DashboardIndiaService,
    public activeModal: NgbActiveModal,
    public modal: MatDialog,
    private spinnerEducationIN:NgxSpinnerService,
    private ng2ImgMaxEducationIN: NgxImageCompressService) {
    this.rEducationIN.queryParams.subscribe(params => {
      this.order = params["order"]
      if (this.order == "W") {
        this.pendingWeb = true;
      }
      else {
        this.pendingWeb = false;
        ChgeduIN();
      }
    });

    if (this.dashboardEducationIN.Actions === true) {
      getElement('edu');
    }
  }
  options = ['Year', 'Semester'];
  //scoretype = ['CGPA', 'Percentage'];

  ngOnInit() {
    this.mobile = this.appServiceEducationIN.mobile;
    if (this.mobile == true) {
      this.desktop = false;
      window.scroll(0, 0);
      this.rEducationIN.queryParams.subscribe(params => {
        this.order = params["order"]
      });
      if (this.order === "N") {
        getElement('edu');
      }
    }
    else {
      this.desktop = true;
    }

     if (localStorage.getItem("Identification").toUpperCase() == "FRESHER") {
      this.fresher = true;
      this.lateral = false;
    }
    else {
     
      this.lateral = true;
      this.fresher = false;
    }
    if(this.fresher){
      this.EmailId = "Infosys_LPCampus@infosys.com";
    }
    else{
      if(!this.ibpm){
        this.EmailId="Infosys_Launchpad@infosys.com";
      }
      else if(this.ibpm){
        this.EmailId="IBPM_Launchpad@infosys.com";
      }
    }

    if(!this.ibpm){
    this.candidate = { CandidateID: parseInt(localStorage.getItem("intCandidateId")) }
     this.spinnerEducationIN.show();
    this.dashboard.GetBGVStatus(this.candidate).subscribe(
      (res) => {
        if(res != null){
          this.bgv = res;
          if(this.bgv.flgBGV == 'Y'){this.flgBGV = true;}
        }
        else {this.flgBGV = false;}
         this.spinnerEducationIN.hide();

      }
    );
    }
    if(this.flgSemester == true){
      this.semesterDropdown =  ["Semester1", "Semester2", "Semester3", "Semester4",
      "Semester5", "Semester6", "Semester7", "Semester8", "Semester9",
      "Semester10", "Semester11", "Semester12"]
    }else if(this.flgYear == true){
      this.semesterDropdown =  ["Year1", "Year2", "Year3", "Year4",
      "Year5", "Year6", "Year7", "Year8", "Year9",
      "Year10", "Year11", "Year12"]
    }
    if(this.fresher){	
      this.flgValidDegreeCertificateSemWise=true	
      this.flgNoDegreeCertificateSemWise=false	
      this.flgValidDegreeCertificateyearwise=true	
      this.flgNoDegreeCertificateyearwise=false	
      this.flgValidProvisionalSemWise=true
      this.flgNoProvisionalyearwise=false
      this.flgValidProvisionalyearwise=true		
      this.flgNoProvisionalSemWise=false	
      this.flgValidSemMarksheets=true	
      this.flgNoSemMarksheets=false	
      this.flgValidyearMarksheets=true	
      this.flgNoyearMarksheets=false	
      this.flgValidDegreeCertificate=true	
      this.flgValidProvisional=true	
      this.flgValidConsolidated=true	
      this.flgNoConsolidated=false	
      this.flgNoProvisional=false	
      this.flgNoDegreeCertificate=false	
    }

    this.SetupData();

               //Fetch top and bottom notes
               if(AuthService.HeadingList.length>0){
                for (let ele of AuthService.HeadingList){
                 if (ele.txtSectionCode!=null && ele.txtSectionCode==NoteConstants.txtEducationIndiaSectionCode){
                     this.txtTopNote=ele.txtTopNote
                     this.txtTitleName=ele.txtTitle
                     
                     if(ele.flgEnableBottomNote=="Y"){
                       this.flgEnableBottomNote=true
                     }
                     else{
                       this.flgEnableBottomNote=false
                     }
                     if(ele.flgEnableTopNote=="Y"){
                       this.flgEnableTopNote=true
                     }
                     else{
                       this.flgEnableTopNote=false
                     }
                 }
                 if(ele.txtSectionCode!=null && ele.txtSectionCode==NoteConstants.txtBGVMssage){
                  this.txtBGVMessage=ele.txtTopNote

                  if(ele.flgEnableTopNote=="Y"){
                    this.flgBGVMessage=true;
                  }
               }
               }
             }
             else{
               this.flgEnableBottomNote=true
               this.flgEnableTopNote=true
               this.txtTitleName=NoteConstants.txtEducationDetailsTitle
               this.txtTopNote=NoteConstants.txtEducationDetailsIndiaTopNote
               
             }
   

  }
  callModal() {
    if(!this.ibpm){
      this.updateBGV.intCandidateId = localStorage.getItem("intCandidateId");
      this.updateBGV.txtSection = this.candDetId;
      this.dashboard.FetchBGVPopupStatus(this.updateBGV).subscribe(
        (res) => {
          if(res != null){
            this.bgvShow = res;
            if(this.bgvShow.flgShow == 'Y'){
              const modalRef = this.modal.open(BgvcheckComponent, {
                panelClass: 'open-dialog',
                hasBackdrop: true,
              });

                modalRef.afterClosed().subscribe(result => {
                  if(this.dashboard.modalClose==true){
                    this.dashboard.modalClose=false;
                    modalRef.close();
                  }
                  else{
                    this.combMsgShow = true;
                  if (this.fresher) {
                    UpdateButtonClick1('updateFormfreshreq');
                  }else{
                    UpdateButtonClick1('updateFormreq'); 
                  }
                }
                });
            }
            else {
              this.appService.updateForm = true;
                if (this.fresher) {
                  UpdateButtonClick1('updateFormfreshreq');
                }else{
                  UpdateButtonClick1('updateFormreq'); 
                }
              }
              

            }
             this.spinnerEducationIN.hide();
          }
          );
        }
        else {
          this.appService.updateForm = true;
          if (this.fresher) {
            UpdateButtonClick1('updateFormfreshreq');
          }else{
            UpdateButtonClick1('updateFormreq');
        }

  
      }
  }

  onSelectionChange() {
    this.enableUploadingDocs = false;
    if (this.yearOrSemester === 'Year') {
      this.flgYear = true;
      this.flgSemester = false;
      this.semWise = false;
    } else if (this.yearOrSemester === 'Semester') {
      this.flgYear = false;
      this.flgSemester= true;
      this.yearwise = false;
    }
    if(this.flgSemester == true){
      this.semesterDropdown =  ["Semester1", "Semester2", "Semester3", "Semester4",
      "Semester5", "Semester6", "Semester7", "Semester8", "Semester9",
      "Semester10", "Semester11", "Semester12"]
    }else if(this.flgYear == true){
      this.semesterDropdown =  ["Year1", "Year2", "Year3", "Year4",
      "Year5", "Year6", "Year7", "Year8", "Year9",
      "Year10", "Year11", "Year12"]
    }
  }


   

  SetupData() {
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.GetStates()
      .subscribe(
        responseGapData => {
          if (responseGapData != null && responseGapData.length > 0) {
            this.stateList = responseGapData;
            //this.stateList.push(this.manuallyAddedState);
          }
          this._educationServiceEducationIN.GetDegrees()
            .subscribe(
              response => {
                if (response.length > 0 && response != null) {
                  this.degreeList = response;
                }
                this._educationServiceEducationIN.GetLevels()
                  .subscribe(
                    response => {
                      if (response != null && response.length > 0) {
                        this.levelList = response;
                      }

                      if (this.lateral) {
                        this._educationServiceEducationIN.GetCandidateEducationDetails()
                          .subscribe(
                            responseGapDataEducationIN => {
                              if (responseGapData != null && responseGapData.length > 0) {
                                this.dataPresent = true;
                                this.educationDetail = true;
                                this.eduDetails = responseGapDataEducationIN;
                                this.countNewFlags=this.eduDetails.length;
                                for (var edu of this.eduDetails) {
                                  if (edu.EduStartDate == null) {
                                    edu.EduStartDate = new Date(1900, 1, 1, 0, 0, 0);
                                  }

                                  if (edu.EduEndDate == null) {
                                    edu.EduEndDate = new Date(1900, 1, 1, 0, 0, 0);
                                  }
                                  let startyear = (new Date(edu.EduStartDate)).getFullYear();
                                  let startmonth = (new Date(edu.EduStartDate)).getMonth();
                                  let startday = (new Date(edu.EduStartDate)).getDay();
                                  let endyear = (new Date(edu.EduEndDate)).getFullYear();
                                  let endmonth = (new Date(edu.EduEndDate)).getMonth();
                                  let endday = (new Date(edu.EduEndDate)).getDay();
                                  this.minstart = new Date(Number(startyear), Number(startmonth) - 4, 1);
                                  this.minend = new Date(Number(endyear), Number(endmonth) - 4, 1);
                                  if (Number(startmonth) == 1 || Number(endmonth) == 3 || Number(endmonth) == 5 || Number(endmonth) == 7 || Number(endmonth) == 8 || Number(endmonth) == 10 || Number(endmonth) == 12) {
                                    this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 31);
                                  }
                                  else { this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 30) };
                                  if (Number(startmonth) == 1 || Number(startmonth) == 3 || Number(startmonth) == 5 || Number(startmonth) == 7 || Number(startmonth) == 8 || Number(startmonth) == 10 || Number(startmonth) == 12) {
                                    this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 31);
                                  }
                                  else { this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 30); }
                                  edu.minstartdate = this.minstart;
                                  edu.minenddate = this.minend;
                                  edu.maxstartdate = this.maxstart;
                                  edu.maxenddate = this.maxend;
                                  if ((new Date(edu.EduStartDate)).getFullYear() == 1900 || edu.EduStartDate == null) {
                                    edu.minstartdate = edu.EduStartDate;
                                    edu.maxstartdate = new Date();
                                  }
                                  if ((new Date(edu.EduEndDate)).getFullYear() == 1900 || edu.EduEndDate == null) {
                                    edu.minenddate = edu.EduEndDate;
                                    edu.maxenddate = new Date((new Date().getFullYear()) + 10, new Date().getMonth(), new Date().getDate(), 9, 0, 0);
                                  }
                                  // if (edu.NewEducation.trim().toUpperCase() == "N") {
                                  //   this.countNewFlags = this.countNewFlags + 1;
                                  // }
                                
                                  if(edu.txtDegreeCode=='02'){

                                    this.diploma=true;
                                  }

                                  // this.txtstate=edu.txtState;

                                  // if(this.txtstate='Others'){
                                  //   this.flgStateEnter=true;
                                  // }
                                  // else{
                                  //   this.flgStateEnter=false;
                                  // }

                                  // this.txtothersstate=edu.txtOthersState;

                                }
                                this.yearOrSemester = edu.txtYearOrSemester;
                              }
                              else {
                                this.dataPresent = false;
                              }
                               this.spinnerEducationIN.hide();
                            }
                          );

                      }

                      if (this.fresher) {
                        let fetchEducation: FetchEducation = new FetchEducation();
                        fetchEducation.CandidateId = Number(localStorage.getItem("intCandidateId"));
                        this._educationServiceEducationIN.FetchFreshersEduDetails(fetchEducation)
                          .subscribe(
                            responseDataEducationIN => {
                              if (responseDataEducationIN != null) {
                                this.fresherDetails = responseDataEducationIN;
                                this.basicDetails = this.fresherDetails.lstBasicFreshers;
                                this.markDetails = this.fresherDetails.lstMarksFreshers;
                                if (this.basicDetails != null && this.basicDetails.length > 0) {
                                  this.dataPresent = true;
                                  this.educationDetail = true;
                                  for (var fresherDtl of this.basicDetails) {
                                    if (fresherDtl.EduStartDate == null) {
                                      fresherDtl.EduStartDate = new Date(1900, 1, 1, 0, 0, 0);
                                    }

                                    if (fresherDtl.EduEndDate == null) {
                                      fresherDtl.EduEndDate = new Date(1900, 1, 1, 0, 0, 0);
                                    }
                                    
                                    // this.txtstate=fresherDtl.txtState;
                                    // if(this.txtstate=='Others'){
                                    //   this.flgStateEnter=true;
                                    // }
                                    // else
                                    // {
                                    //   this.flgStateEnter=false;
                                    // }
                                    // this.txtothersstate=fresherDtl.txtOthersState;


                                    let startyear = (new Date(fresherDtl.EduStartDate)).getFullYear();
                                    let startmonth = (new Date(fresherDtl.EduStartDate)).getMonth();
                                    let startday = (new Date(fresherDtl.EduStartDate)).getDay();
                                    let endyear = (new Date(fresherDtl.EduEndDate)).getFullYear();
                                    let endmonth = (new Date(fresherDtl.EduEndDate)).getMonth();
                                    let endday = (new Date(fresherDtl.EduEndDate)).getDay();
                                    this.minstart = new Date(Number(startyear), Number(startmonth) - 4, 1);
                                    this.minend = new Date(Number(endyear), Number(endmonth) - 4, 1);
                                    if (Number(endmonth) == 1 || Number(endmonth) == 3 || Number(endmonth) == 5 || Number(endmonth) == 7 || Number(endmonth) == 8 || Number(endmonth) == 10 || Number(endmonth) == 12) {
                                      this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 31);
                                    }
                                    else { this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 30) };
                                    if (Number(startmonth) == 1 || Number(startmonth) == 3 || Number(startmonth) == 5 || Number(startmonth) == 7 || Number(startmonth) == 8 || Number(startmonth) == 10 || Number(startmonth) == 12) {
                                      this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 31);
                                    }
                                    else { this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 30); }
                                    fresherDtl.minstartdate = this.minstart;
                                    fresherDtl.minenddate = this.minend;
                                    fresherDtl.maxstartdate = this.maxstart;
                                    fresherDtl.maxenddate = this.maxend;
                                    if ((new Date(fresherDtl.EduStartDate)).getFullYear() == 1900 || fresherDtl.EduStartDate == null) {
                                      fresherDtl.minstartdate = fresherDtl.EduStartDate;
                                      fresherDtl.maxstartdate = new Date();
                                    }
                                    if ((new Date(fresherDtl.EduEndDate)).getFullYear() == 1900 || fresherDtl.EduEndDate == null) {
                                      fresherDtl.minenddate = fresherDtl.EduEndDate;
                                      fresherDtl.maxenddate = new Date((new Date().getFullYear()) + 10, new Date().getMonth(), new Date().getDate(), 9, 0, 0);
                                    }
                                  }
                                }
                                else {
                                  this.dataPresent = false;
                                }
                              }
                              else {
                                ////need to check this.spinner.hide();
                              }
                               this.spinnerEducationIN.hide();
                            }
                          );

                      }
                    }
                  );

              }
            );

        }
      );

  }

  validateMarks(obtainedMarks: number, maxMarks: number): boolean {
    if (obtainedMarks > maxMarks) {
      this.isValidMark = false;
      return false;
    }
    else {
      this.isValidMark = true;
      return true;
    }
  }

  addMarks() {
    this.addMarkObtained = 0;
    this.addMaxMarks = 0;
    this.addMarksFlag = !this.addMarksFlag;
  }

  isAvailable(index: Number): boolean {
    if (this.lpFetchEduDocDetailsList[index as number] != null) {
      return true;
    }
    else {
      return false;
    }
  }

  getAttachments(intCandQualDetId: Number) {
    this.tenthTwelthDegree = false;
    this.tenthTwelthMarkSheet = false;
    this.degree = false;
    this.provisional = false;
    this.consolidated = false;
    this.sem1 = false;
    this.sem2 = false;
    this.sem3 = false;
    this.sem4 = false;
    this.sem5 = false;
    this.sem6 = false;
    this.sem7 = false;
    this.sem8 = false;
    this.sem9 = false;
    this.sem10 = false;
    this.sem11 = false;
    this.sem12 = false;
    this.year1 = false;
    this.year2 = false;
    this.year3 = false;
    this.year4 = false;
    this.year5 = false;
    this.year6 = false;
    this.year7 = false;
    this.year8 = false;
    this.flgDocVerified = false;

    // this.uploadType = "S";
    // this.consMark = false;
    // this.semWise = true;
    let eduDoc = new FetchEducationDocs();
    for (let i = 0; i < 22; i++) {
      this.lpFetchEduDocDetailsList[i] = null;
    }
    eduDoc.CandExDetID = intCandQualDetId;
    this._educationServiceEducationIN.FetchEduDocDetails(eduDoc).subscribe(
      response => {
        if (response != null && response.length > 0) {
          for (let ele of response) {
            if (ele.txtMarksType == 'DC') {
              this.lpFetchEduDocDetailsList[0] = ele;
              if (this.isTenthTwelth) {
                this.tenthTwelthDegree = true;
              }
              else if (!this.isTenthTwelth) {
                this.tenthTwelthDegree = false;
                this.degree = true
                this.ForValidDegreeCertificateSemWise("yes");
                this.ForValidDegreeCertificateyearwise("yes");
                this.ForValidDegreeCertificate("yes");
              }
            }
            else if (ele.txtMarksType == 'PC') {
              this.lpFetchEduDocDetailsList[1] = ele;
                if (!this.isTenthTwelth) {
                this.tenthTwelthMarkSheet = false;
                this.provisional = true;
                this.ForValidProvisionalCertificate("yes2");
                this.ForValidProvisionalCertificateSemWise("yes2");
                this.ForValidProvisionalCertificateyearwise("yes2");
              }
            }
            else if (ele.txtMarksType == 'MS') {
              if (this.isTenthTwelth) {
                this.lpFetchEduDocDetailsList[1] = ele;
                this.tenthTwelthMarkSheet = true;
              }
            }
            else if (ele.txtMarksType == 'CMS') {
              this.lpFetchEduDocDetailsList[14] = ele;
              this.consolidated = true
              this.uploadType = "C";
              this.consMark = true;
              this.semWise = false;
              this.yearwise=false;
              this.ForValidConsolidatedMarksheet("yes3");
            }
            else {
              let index = Number.parseInt(ele.txtMarksType.split('S')[1]);
              let index1 = Number.parseInt(ele.txtMarksType.split('Y')[1]);
              this.lpFetchEduDocDetailsList[index1 + 14] = ele;
              this.lpFetchEduDocDetailsList[index + 1] = ele;
            }
          }
          for (var ar of this.lpFetchEduDocDetailsList) {
            if (ar != null) {

            if(this.yearOrSemester == "Semester"){
                this.flgSemester = true;
                this.flgYear = false;
              if (ar.txtMarksType == "S1") {
                this.sem1 = true;
                this.semWise = true;
                this.uploadType = "S";
                this.yearwise = false;
                this.consMark=false;
                this.ForValidSemMarksheetSemWise("yes3");
              }
              if (ar.txtMarksType == "S2") {
                this.sem2 = true;
                this.semWise = true;
                this.uploadType = "S";
                this.yearwise = false;
                this.consMark=false;
                this.ForValidSemMarksheetSemWise("yes3");
              }
              if (ar.txtMarksType == "S3") {
                this.sem3 = true;
                this.semWise = true;
                this.uploadType = "S";
                this.yearwise = false;
                this.consMark=false;
                this.ForValidSemMarksheetSemWise("yes3");
              }
              if (ar.txtMarksType == "S4") {
                this.sem4 = true;
                this.semWise = true;
                this.uploadType = "S";
                this.yearwise = false;
                this.consMark=false;
                this.ForValidSemMarksheetSemWise("yes3");
              }
              if (ar.txtMarksType == "S5") {
                this.sem5 = true;
                this.semWise = true;
                this.uploadType = "S";
                this.yearwise = false;
                this.consMark=false;
              }
              if (ar.txtMarksType == "S6") {
                this.sem6 = true;
                this.semWise = true;
                this.uploadType = "S";
                this.yearwise = false;
                this.consMark=false;
              }
              if (ar.txtMarksType == "S7") {
                this.sem7 = true;
                this.semWise = true;
                this.uploadType = "S";
                this.yearwise = false;
                this.consMark=false;
              }
              if (ar.txtMarksType == "S8") {
                this.sem8 = true;
              }
              if (ar.txtMarksType == "S9") {
                this.sem9 = true;
              }
              if (ar.txtMarksType == "S10") {
                this.sem10 = true;
              }
              if (ar.txtMarksType == "S11") {
                this.sem11 = true;
              }
              if (ar.txtMarksType == "S12") {
                this.sem12 = true;
              }
            }
            if(this.yearOrSemester == "Year"){
              this.flgSemester = false;
              this.flgYear = true;
              if (ar.txtMarksType == "Y1") {
                this.year1 = true;
                this.yearwise = true;
                this.uploadType = "Y";
                this.semWise = false;
                this.consMark=false;
                this.ForValidSemMarksheetyearwise("yes4");
              }
              if (ar.txtMarksType == "Y2") {
                this.year2 = true;
                this.yearwise = true;
                this.uploadType = "Y";
                this.semWise = false;
                this.consMark=false;
                this.ForValidSemMarksheetyearwise("yes4");
              }
              if (ar.txtMarksType == "Y3") {
                this.year3 = true;
                this.yearwise = true;
                this.uploadType = "Y";
                this.semWise = false;
                this.consMark=false;
                this.ForValidSemMarksheetyearwise("yes4");
              }
              if (ar.txtMarksType == "Y4") {
                this.year4 = true;
                this.yearwise = true;
                this.uploadType = "Y";
                this.semWise = false;
                this.consMark=false;
                this.ForValidSemMarksheetyearwise("yes4");
              }
              if (ar.txtMarksType == "Y5") {
                this.year5 = true;
           
              }
              if (ar.txtMarksType == "Y6") {
                this.year6 =true;

              }
           
               
              if (ar.txtMarksType == "Y7") {

                this.year7 = true;
              }
              if (ar.txtMarksType == "Y8") {
                this.year8 = true;

              }
            }

              if(ar.intVerifiedStatus==4 || ar.intVerifiedStatus==5){
                this.flgEduVerified=true;
                this.flgDocVerified = true;
              }
              else{
                this.flgEduVerified=false;
              }
              if(ar.intVerifiedStatus==3){
                this.EduRejected=true;
              }
              else{
                this.EduRejected=false;
              }
            }
          }
        }
      }
    );
  }

  attchDocuments() {
    this.XForm = false;
    this.attchDoc = true;
    this.fname = new Array(14);
    this.fsize = new Array(14);
    this.mainPage = false;
  }

  closeAttachDocuments() {
    this.mainPage = false;
    this.attchDoc = false;
    this.XForm = true;
  }

  fileChange(files: any, index: number) {
    this.fileValidate=files[0];
    if (files[0] != null) {
      this.myFile[index] = files[0];
      this.fsize[index] = this.myFile[index].size;
      this.fname[index] = this.myFile[index].name;
      var name = this.myFile[index].name;
      let current_time = new Date().getTime();
      let modified_time = files[0].lastModified;
      let diff = current_time - modified_time;
      var ext = name.substring(name.lastIndexOf('.') + 1);
      var ext1 = name.substring(name.lastIndexOf('.') + 1);
      if (diff <= 30000 && ext.trim().toLowerCase() != 'pdf') {
        let name = files[0].name;
        ext = name.substring(name.lastIndexOf('.') + 1);
        if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' || ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'fif') {
          this.camera = true;
          if (name.substring(0, name.lastIndexOf('.').length > 20)) {
            this.fname[index] = name.substring(0, 20) + "." + ext;
          }
          this.uploadDocument(index);
        }
        else {
          this.alertEducationIN.error("Unable to upload the document. Please try again.", this.mobile);
        }
      }
      else {
        this.camera = false;
        this.validateFileSize(this.fsize[index], index);
        this.fileUploadFlag = true;
        let reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = function () {
          EducationComponent.fileAsBasestring = String(reader.result).substr(22,);

        };
        reader.onerror = function (error) {
        };
        if (this.nameError && this.formatError && this.sizeError) {
          this.uploadDocument(index);
        }
      }
    }
    else {
      this.fname[index] = null;
      this.fsize[index] = null;
    }
    

  }

  getEducationDetails() {
     this.spinnerEducationIN.show()

    if (this.lateral) {
      this._educationServiceEducationIN.GetCandidateEducationDetails()
        .subscribe(
          responseGapData => {
            if (responseGapData != null && responseGapData.length > 0) {
              this.dataPresent = true;
              this.educationDetail = true;
              this.eduDetails = responseGapData;
              this.countNewFlags=this.eduDetails.length;
              for (var edu of this.eduDetails) {
                if (edu.EduStartDate == null) {
                  edu.EduStartDate = new Date(1900, 1, 1, 0, 0, 0);
                }

                if (edu.EduEndDate == null) {
                  edu.EduEndDate = new Date(1900, 1, 1, 0, 0, 0);
                }
                let startyear = (new Date(edu.EduStartDate)).getFullYear();
                let startmonth = (new Date(edu.EduStartDate)).getMonth();
                let startday = (new Date(edu.EduStartDate)).getDay();
                let endyear = (new Date(edu.EduEndDate)).getFullYear();
                let endmonth = (new Date(edu.EduEndDate)).getMonth();
                let endday = (new Date(edu.EduEndDate)).getDay();
                this.minstart = new Date(Number(startyear), Number(startmonth) - 4, 1);
                this.minend = new Date(Number(endyear), Number(endmonth) - 4, 1);
                if (Number(startmonth) == 1 || Number(endmonth) == 3 || Number(endmonth) == 5 || Number(endmonth) == 7 || Number(endmonth) == 8 || Number(endmonth) == 10 || Number(endmonth) == 12) {
                  this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 31);

                }
                else { this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 30) };
                if (Number(startmonth) == 1 || Number(startmonth) == 3 || Number(startmonth) == 5 || Number(startmonth) == 7 || Number(startmonth) == 8 || Number(startmonth) == 10 || Number(startmonth) == 12) {

                  this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 31);
                }
                else { this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 30); }
                edu.minstartdate = this.minstart;
                edu.minenddate = this.minend;
                edu.maxstartdate = this.maxstart;
                edu.maxenddate = this.maxend
                if ((new Date(edu.EduStartDate)).getFullYear() == 1900 || edu.EduStartDate == null) {
                  edu.minstartdate = edu.EduStartDate;
                  edu.maxstartdate = new Date();

                }

                if ((new Date(edu.EduEndDate)).getFullYear() == 1900 || edu.EduEndDate == null) {
                  edu.minenddate = edu.EduEndDate;
                  edu.maxenddate = new Date((new Date().getFullYear()) + 10, new Date().getMonth(), new Date().getDate(), 9, 0, 0);
                }

                // if (edu.NewEducation.trim().toUpperCase() == "N") {
                //   this.countNewFlags = this.countNewFlags + 1;
                // }
                edu.txtYearOrSemester=this.yearOrSemester;

                // this.txtstate=edu.txtState;

                // if(this.txtstate='Others'){
                //   this.flgStateEnter=true;
                // }
                // else{
                //   this.flgStateEnter=false;
                // }

                // this.txtothersstate=edu.txtOthersState;
              }
            }
            else {
              this.dataPresent = false;
              ////need to check this.spinner.hide();
            }
             this.spinnerEducationIN.hide();
          }
        );

    }
    
    if (this.fresher) {
      let fetchEducation: FetchEducation = new FetchEducation();
      fetchEducation.CandidateId = Number(localStorage.getItem("intCandidateId"));
      this._educationServiceEducationIN.FetchFreshersEduDetails(fetchEducation)
        .subscribe(
          responseDataEducationIN => {
            if (responseDataEducationIN != null) {
              this.fresherDetails = responseDataEducationIN;
              this.basicDetails = this.fresherDetails.lstBasicFreshers;
              this.markDetails = this.fresherDetails.lstMarksFreshers;
              if (this.basicDetails != null && this.basicDetails.length > 0) {
                this.dataPresent = true;
                this.educationDetail = true;
                for (var fresherDtl of this.basicDetails) {
                  if (fresherDtl.EduStartDate == null) {
                    fresherDtl.EduStartDate = new Date(1900, 1, 1, 0, 0, 0);
                  }
                  if (fresherDtl.EduEndDate == null) {
                    fresherDtl.EduEndDate = new Date(1900, 1, 1, 0, 0, 0);
                  }
                  
                

                  // this.txtstate=fresherDtl.txtState;
                  // if(this.txtstate=='Others')
                  // {
                  //     this.flgStateEnter=true;
                  // }
                  // else
                  // {
                  //     this.flgStateEnter=false;
                  // }
                  // this.txtothersstate=fresherDtl.txtOthersState;

                  let startyear = (new Date(fresherDtl.EduStartDate)).getFullYear();
                  let startmonth = (new Date(fresherDtl.EduStartDate)).getMonth();
                  let startday = (new Date(fresherDtl.EduStartDate)).getDay();
                  let endyear = (new Date(fresherDtl.EduEndDate)).getFullYear();
                  let endmonth = (new Date(fresherDtl.EduEndDate)).getMonth();
                  let endday = (new Date(fresherDtl.EduEndDate)).getDay();
                  this.minstart = new Date(Number(startyear), Number(startmonth) - 4, 1);
                  this.minend = new Date(Number(endyear), Number(endmonth) - 4, 1);
                  if (Number(endmonth) == 1 || Number(endmonth) == 3 || Number(endmonth) == 5 || Number(endmonth) == 7 || Number(endmonth) == 8 || Number(endmonth) == 10 || Number(endmonth) == 12) {
                    this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 31);

                  }
                  else { this.maxend = new Date(Number(endyear), Number(endmonth) + 2, 30) };
                  if (Number(startmonth) == 1 || Number(startmonth) == 3 || Number(startmonth) == 5 || Number(startmonth) == 7 || Number(startmonth) == 8 || Number(startmonth) == 10 || Number(startmonth) == 12) {

                    this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 31);
                  }
                  else { this.maxstart = new Date(Number(startyear), Number(startmonth) + 2, 30); }
                  fresherDtl.minstartdate = this.minstart;
                  fresherDtl.minenddate = this.minend;
                  fresherDtl.maxstartdate = this.maxstart;
                  fresherDtl.maxenddate = this.maxend
                  if ((new Date(fresherDtl.EduStartDate)).getFullYear() == 1900 || fresherDtl.EduStartDate == null) {
                    fresherDtl.minstartdate = fresherDtl.EduStartDate;
                    fresherDtl.maxstartdate = new Date();
                  }

                  if ((new Date(fresherDtl.EduEndDate)).getFullYear() == 1900 || fresherDtl.EduEndDate == null) {
                    fresherDtl.minenddate = fresherDtl.EduEndDate;
                    fresherDtl.maxenddate = new Date((new Date().getFullYear()) + 10, new Date().getMonth(), new Date().getDate(), 9, 0, 0);
                  }
                }
              }
              else {
                this.dataPresent = false;
              }
            }
            else {
              ////need to check this.spinner.hide();
            }
             this.spinnerEducationIN.hide();
          }
        );

    }
   
  }

  getstates() {
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.GetStates()
      .subscribe(
        responseGapData => {
          if (responseGapData != null && responseGapData.length > 0) {
            this.stateList = responseGapData;
            //this.stateList.push(this.manuallyAddedState);

          }
           this.spinnerEducationIN.hide();
        }
      );
  }

  getDegrees() {
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.GetDegrees()
      .subscribe(
        response => {
          if (response.length > 0 && response != null) {
            this.degreeList = response;
          }
           this.spinnerEducationIN.hide();
        }
      );
  }

  getLevels() {
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.GetLevels()
      .subscribe(
        response => {
          if (response != null && response.length > 0) {
            this.levelList = response;
          }
           this.spinnerEducationIN.hide();
        }
      );
  }

  showDetails(selectedEdu: LPFetchEducationDetails) {
    //this.XForm = true;
    this.markEditFlag = false;
    this.maxMarks = 0;
    this.markObtained = 0;
    this.avgMarks = 0;
    this.totalMarksObtained = 0;
    this.totalMaxMarks = 0;
    this.cgpaChecked = false;
    this.percentageChecked = false;
    this.markType = null;
    this.semesterListOfMarks = [];
    this.listOfMarks = [];
    this.editableFields = false;
    this.markObtainedTenthTwelth = 0;
    this.maxMarksTenthTwelth = 0;
    this.avgMarksTenthTwelth = 0;
    if (selectedEdu != null) {
      this.selectedEdu = selectedEdu;
      this.selStart = new Date(selectedEdu.EduStartDate.toString());
      this.selEnd = new Date(selectedEdu.EduEndDate.toString());
      this.selectedEduQualId = selectedEdu.intCandQualDetId as number;
      this.selectedEduQyalifiactionCode = selectedEdu.txtQualificationCode as string;
      this.selectedEduPassingYear = selectedEdu.PassingYear as string;
      let eduDoc: FetchEducation = new FetchEducation();
      eduDoc.CandidateId = Number(localStorage.getItem("intCandidateId"));
      eduDoc.intCandQualDetId = selectedEdu.intCandQualDetId as number;
      let lPGetQualTypes: LPGetQualTypes[];
      this._educationServiceEducationIN.GetQualTypes(eduDoc).subscribe(
        response => {
          lPGetQualTypes = response;
          if (response != null && response.length > 0) {
            for (let qual of lPGetQualTypes) {
              if (qual.intCandQualDetId == selectedEdu.intCandQualDetId) {
                if (qual.flgAvailable == 'Y') {
                  this.markEditFlag = true;
                  if (qual.txtSemesterType == 'NON SEMESTER') {
                    this.addSemesterFlag = false;
                  }
                  if (qual.txtSemesterType == 'SEMESTER') {
                    this.addSemesterFlag = true;
                  }
                }
                else if (qual.flgAvailable == 'N') {
                  this.markEditFlag = false;
                  if (qual.txtSemesterType == 'SEMESTER') {
                    this.addSemesterFlag = true;
                  }
                  else if (qual.txtSemesterType == 'NON SEMESTER') {
                    this.addSemesterFlag = false;
                  }
                }
                if (qual.txtScoreType == 'CGPA') {
                  this.cgpaChecked = true;
                  this.markType = 'CGPA';
                }
                else if (qual.txtScoreType == 'PERCENTAGE') {
                  this.percentageChecked = true;
                  this.markType = 'PERCENTAGE';
                }
                else if (qual.txtScoreType == 'N.A') {
                  this.cgpaChecked = false;
                  this.percentageChecked = false;
                  this.markType = null;
                  //this.markType = 'PERCENTAGE';

                }
              }
            }
          }
          this.getAttachments(this.selectedEduQualId);
          if (selectedEdu.txtQualificationCode == "0424" || selectedEdu.txtQualificationCode == "0425" || selectedEdu.txtQualificationCode == "424" || selectedEdu.txtQualificationCode == "425") {
            this.isTenthTwelth = true;
          }
          else {
            this.isTenthTwelth = false;
          }


          if(this.diploma){
            if( selectedEdu.txtQualificationCode == "0434" || selectedEdu.txtQualificationCode == "0436" ){
              this.semhide=false;
            }
          }

          if(this.diploma){
            if(selectedEdu.txtQualificationCode == "0435" ){
              this.BSchide=false;
            }
          }



          this.txtInstituteName = selectedEdu.txtInstituteName;
          this.university = selectedEdu.txtUniversity;
          this.txtBranchCode = selectedEdu.txtBranchCode;
          this.txtStartDate = selectedEdu.EduStartDate == null || selectedEdu.EduStartDate == undefined ? null : new Date(selectedEdu.EduStartDate);
          this.txtOldStartDate=selectedEdu.EduStartDate == null || selectedEdu.EduStartDate == undefined ? null : new Date(selectedEdu.EduStartDate);
          this.txtEndDate = selectedEdu.EduEndDate == null || selectedEdu.EduEndDate == undefined ? null : new Date(selectedEdu.EduEndDate);
          this.txtOldEndDate=selectedEdu.EduEndDate == null || selectedEdu.EduEndDate == undefined ? null : new Date(selectedEdu.EduEndDate);

          for (var lev of this.levelList) {
            if (selectedEdu.EduLevel.trim() == lev.txtDegreeDescription.trim()) {
              this.EduLevel = lev.txtDegreeDescription;
            }
          }

          for (var deg of this.degreeList) {
            if (selectedEdu.txtQualificationCode.trim() == deg.txtQualificationCode.trim()) {
              this.txtDegree = deg.txtQualificationDesc;
            }
          }
          this.dtMonthYear = selectedEdu.dtMonthYear;
          this.MarksOrGPA = selectedEdu.MarksOrGPA;
          this.QualificationGained = selectedEdu.QualificationGained;
          this.roll = selectedEdu.txtRollNo;
          if (selectedEdu.intSemCount == 0 || selectedEdu.intSemCount.valueOf() < 0 ) {
            this.semCount = null;
          }
          else {
            this.semCount = selectedEdu.intSemCount;
          }
          this.yearOrSemester = selectedEdu.txtYearOrSemester;
          if (this.yearOrSemester === 'Year') {
            this.flgYear = true;
            this.flgSemester = false;
          } else if (this.yearOrSemester === 'Semester') {
            this.flgYear = false;
            this.flgSemester= true;
          }
          if(!this.isTenthTwelth){
            if(selectedEdu.txtYearOrSemester !== "" && selectedEdu.txtYearOrSemester !==null && selectedEdu.intSemCount.valueOf() > 0){
              this.enableUploadingDocs = true;
              this.freeze = true;
            }else{
              this.enableUploadingDocs = false;
              this.freeze = false;
            }
          }
          // this.txtstate = selectedEdu.txtState;
          // if (this.txtstate =='Others') {
          //   this.flgStateEnter = true;
          // } 
          // else {
          //   this.flgStateEnter = false;
          // }
          
          // this.txtothersstate = selectedEdu.txtOthersState;

          this.candDetId = selectedEdu.intCandQualDetId;

          let fetchEducation: FetchEducation = new FetchEducation();
          fetchEducation.CandidateId = Number(localStorage.getItem("intCandidateId"));
          fetchEducation.intCandQualDetId = this.selectedEduQualId;
          this._educationServiceEducationIN.FetchFreshersEduDetails(fetchEducation).subscribe(
            resEducationIN => {
              if (resEducationIN != null && resEducationIN.lstBasicFreshers.length > 0 && resEducationIN.lstMarksFreshers.length > 0) {
                this.lateralDetails = resEducationIN;
                this.listOfMarks = this.lateralDetails.lstMarksFreshers;
                this.basicDetails = this.lateralDetails.lstBasicFreshers;
                this.intQualMarkId = this.listOfMarks[0].intQualMarkId;

                // for (var fresherDtl of this.basicDetails) {
                //   this.txtstate=fresherDtl.txtState;
                //   if(this.txtstate=='Others'){
                //     this.flgStateEnter=true;
                //   }
                //   else
                //   {
                //    this.flgStateEnter=false;
                //   }
                //    this.txtothersstate=fresherDtl.txtOthersState;
                // }

                // for (var arr1 of this.listOfMarks) {
                //   if (arr1.txtMarksType.trim().toUpperCase() !== "TOT") {
                //     this.maxmarkList.push(arr1.intTotalMarks as number);
                //     this.semesterListOfMarks.push(arr1)
                //   }
                // }

                if (this.isTenthTwelth) {
                  let list = new MarksDetails()
                  for (var ar of this.listOfMarks) {
                    if (ar.txtMarksType.trim().toUpperCase() == "TOT") {
                      list = ar;
                      //this.intQualMarkId = ar.intQualMarkId;
                    }
                  }
                  this.markType = list.txtScoreType as string;
                  this.markObtainedTenthTwelth = list.intMarksObtained as number;
                  this.maxMarksTenthTwelth = list.intTotalMarks as number;
                  this.calculateAverage(this.markObtainedTenthTwelth, this.maxMarksTenthTwelth);
                  this.avgMarksTenthTwelth = Number(this.avgMarks.toFixed(2)) as number;
                }
                else {
                  for (var m of this.listOfMarks) {
                    if (m.txtMarksType.trim()[0].toLowerCase() == "s") {
                      let r = m.txtMarksType[1]
                      m.txtMarksType = "Semester" + r + "/ Year" + r
                      }
                      // this.marksType = m.txtMarksType as string;
                      // this.marksObtained = m.intMarksObtained as number;
                      // this.marksMax = m.intTotalMarks as number;
                      // this.calculateAverage(this.marksObtained, this.marksMax);

                  }
                  // for (var m of this.semesterListOfMarks) {
                  //     if (m.txtMarksType.trim().substr(m.txtMarksType.trim().length - 2).toLowerCase()[0]=='s') {
                  //       var r = m.txtMarksType.trim().substr(m.txtMarksType.trim().length - 1)
                  //     }
                  //     else{
                  //       var r = m.txtMarksType.trim().substr(m.txtMarksType.trim().length - 2)
                  //     }
                  //       m.txtMarksType = "Semester" + r + "/Year" + r
                  //       this.marksType = m.txtMarksType as string;
                  //       this.marksObtained = m.intMarksObtained as number;
                  //       this.marksMax = m.intTotalMarks as number;
                  //       //this.calculateAverage(this.marksObtained, this.marksMax);

                      
                  //   }
                }
              }

              

              for (let markList of resEducationIN.lstMarksFreshers) {
                if (markList.txtMarksType == "TOT" && !this.isTenthTwelth && markList.intCandQualDetId == selectedEdu.intCandQualDetId) {
                  this.markObtained = markList.intMarksObtained as number;
                  this.maxMarks = markList.intTotalMarks as number;
                  //this.totalMarksObtained = markList.intMarksObtained as number;
                  //this.totalMaxMarks = markList.intTotalMarks as number;
                }
                else if (markList.txtMarksType == "TOT" && this.isTenthTwelth) {
                  this.maxMarks = 0;
                  this.maxMarks = markList.intTotalMarks as number;
                }
              }
              this.calculateAverage(this.totalMarksObtained, this.totalMaxMarks);
              this.totalAverage = this.avgMarks;
              //this.calculateAverage(this.markObtained, this.maxMarks);
              //this.MarksOrGPA = selectedEdu.MarksOrGPA;

              // if (!this.isTenthTwelth && (resEducationIN.lstBasicFreshers != null || resEducationIN.lstMarksFreshers != null)) {
              //   if (resEducationIN.lstMarksFreshers != null) {
              //     for (let markList of resEducationIN.lstMarksFreshers) {
              //       if (markList.txtMarksType.trim().toUpperCase() != "TOT" && !this.isTenthTwelth && markList.intCandQualDetId == selectedEdu.intCandQualDetId) {
              //         this.markObtained += markList.intMarksObtained as number;
              //         this.maxMarks += markList.intTotalMarks as number;
              //         this.totalMarksObtained += markList.intMarksObtained as number;
              //         this.totalMaxMarks += markList.intTotalMarks as number;
              //       }
              //       else if (markList.txtMarksType.trim().toUpperCase() == "TOT" && this.isTenthTwelth) {
              //         this.maxMarks = 0;
              //         this.maxMarks = markList.intTotalMarks as number;
              //       }
              //     }
              //     this.calculateAverage(this.totalMarksObtained, this.totalMaxMarks);
              //     this.totalAverage = this.avgMarks;
              //     this.calculateAverage(this.markObtained, this.maxMarks);
              //   }
              //   else {
              //     this.markObtained = 0;
              //     this.maxMarks = 0;
              //     this.calculateAverage(this.markObtained, this.maxMarks);
              //   }
              // }

              //this.MarksOrGPA = selectedEdu.MarksOrGPA;




              for (var i of this.eduDetails) {
                if (i.NewEducation.trim().toUpperCase() == "N" && i.intCandQualDetId == selectedEdu.intCandQualDetId) {
                  this.editableFields = true;
                }
              }
            }
          );
        }
      )
    }
  }

  setMarkType(arg: string, markob: number, maxmark: number) {
    this.cgpaChecked = false;
    this.percentageChecked = false;
    this.markType = arg;
    if (arg == 'CGPA') {
      this.cgpaChecked = true;
    }
    else if (arg == 'PERCENTAGE') {
      this.percentageChecked = true;
    }
    this.calculateAverage(markob, maxmark);
  }

  showFresherDetails(selectedEdu: BasicDetailsFreshers) {
    this.markEditFlag = false;
    this.maxMarks = 0;
    this.markObtained = 0;
    this.avgMarks = 0;
    this.totalMarksObtained = 0;
    this.totalMaxMarks = 0;
    this.cgpaChecked = false;
    this.percentageChecked = false;
    this.markType = null;
    this.semesterListOfMarks = [];
    this.listOfMarks = [];
    this.markObtainedTenthTwelth = 0;
    this.maxMarksTenthTwelth = 0;
    this.avgMarksTenthTwelth = 0;
    if (selectedEdu != null) {
      if (selectedEdu.txtQualificationCode == "0424" || selectedEdu.txtQualificationCode == "0425" || selectedEdu.txtQualificationCode == "424" || selectedEdu.txtQualificationCode == "425") {
        this.isTenthTwelth = true;
      }
      else {
        this.isTenthTwelth = false;
      }
      let eduDoc: FetchEducation = new FetchEducation();
      eduDoc.CandidateId = Number(localStorage.getItem("intCandidateId"));
      eduDoc.intCandQualDetId = selectedEdu.intCandQualDetId as number;
      let lPGetQualTypes: LPGetQualTypes[];
       this.spinnerEducationIN.show();
      this._educationServiceEducationIN.GetQualTypes(eduDoc).subscribe(
        response => {
          lPGetQualTypes = response;
          if (response != null && response.length > 0) {
            for (let qual of lPGetQualTypes) {
              if (qual.intCandQualDetId == selectedEdu.intCandQualDetId) {
                if (qual.flgAvailable == 'Y') {
                  this.markEditFlag = true;
                  if (qual.txtSemesterType == 'NON SEMESTER') {
                    this.addSemesterFlag = false;
                  }
                  if (qual.txtSemesterType == 'SEMESTER') {
                    this.addSemesterFlag = true;
                  }
                }
                else if (qual.flgAvailable == 'N') {
                  this.markEditFlag = false;
                  if (qual.txtSemesterType == 'SEMESTER') {
                    this.addSemesterFlag = true;
                  }
                  else if (qual.txtSemesterType == 'NON SEMESTER') {
                    this.addSemesterFlag = false;
                  }
                }
                if (qual.txtScoreType == 'CGPA') {
                  this.cgpaChecked = true;
                  this.markType = 'CGPA';
                }
                else if (qual.txtScoreType == 'PERCENTAGE') {
                  this.percentageChecked = true;
                  this.markType = 'PERCENTAGE';
                }
                else if (qual.txtScoreType == 'N.A') {
                  this.cgpaChecked = false;
                  this.percentageChecked = true;
                  this.markType = 'PERCENTAGE';
                }
              }
            }
          }
           this.spinnerEducationIN.hide();
          this.selStart = new Date(selectedEdu.EduStartDate.toString());
          this.selEnd = new Date(selectedEdu.EduEndDate.toString());
          this.selectedEduQualId = selectedEdu.intCandQualDetId as number;
          this.selectedEduQyalifiactionCode = selectedEdu.txtQualificationCode as string;
          this.selectedEduPassingYear = selectedEdu.PassingYear as string;
          let fetchEducation: FetchEducation = new FetchEducation();
          fetchEducation.CandidateId = Number(localStorage.getItem("intCandidateId"));
          fetchEducation.intCandQualDetId = this.selectedEduQualId;
           this.spinnerEducationIN.show();
          this._educationServiceEducationIN.FetchFreshersEduDetails(fetchEducation).subscribe(
            responseEducationIN => {
              this.fresherDetail = responseEducationIN;
              if (this.fresherDetails.lstBasicFreshers != null || this.fresherDetails.lstMarksFreshers != null) {
                if (this.fresherDetails.lstMarksFreshers != null) {
                  this.listOfMarks = this.fresherDetail.lstMarksFreshers;
                  this.basicDetails = this.fresherDetails.lstBasicFreshers;
                  if(!this.isTenthTwelth){
                    if(selectedEdu.txtYearOrSemester !== "" && selectedEdu.txtYearOrSemester !==null && selectedEdu.intSemCount.valueOf() > 0){
                      this.enableUploadingDocs = true;
                      this.freeze = true;
                    }else{
                      this.enableUploadingDocs = false;
                      this.freeze = false;
                    }
                  }
                  
                  // for (var fresherDtl of this.basicDetails) {
                  //   this.txtstate=fresherDtl.txtState;
                  //   if(this.txtstate=='Others'){
                  //     this.flgStateEnter=true;
                  //   }
                  //   else
                  //   {
                  //    this.flgStateEnter=false;
                  //   }

                  // this.txtothersstate=fresherDtl.txtOthersState;
                  // }

                  for (var arr1 of this.listOfMarks) {
                    if (arr1.txtMarksType.trim().toUpperCase() !== "TOT") {
                      this.maxmarkList.push(arr1.intTotalMarks as number);
                      this.semesterListOfMarks.push(arr1)
                    }
                  }
                  if (this.isTenthTwelth) {
                    let detail = new MarksDetails()
                    for (var ar of this.listOfMarks) {
                      if (ar.txtMarksType.trim().toUpperCase() == "TOT") {
                        detail = ar;
                        this.intQualMarkId = ar.intQualMarkId;
                      }
                    }
                    this.markType = detail.txtScoreType as string;
                    this.markObtainedTenthTwelth = detail.intMarksObtained as number;
                    this.maxMarksTenthTwelth = detail.intTotalMarks as number;
                    this.calculateAverage(this.markObtainedTenthTwelth, this.maxMarksTenthTwelth);
                  }
                  else {
                    this.marksList = [];
                    for (var m of this.semesterListOfMarks) {
                    
                      if (selectedEdu.txtYearOrSemester == "Semester") {
                        if (m.txtMarksType.trim()[0].toLowerCase() == "s") {
                          let r = m.txtMarksType[1];
                          m.txtMarksType = "Semester" + r;
                          let marksData = {
                            txtMarksType: m.txtMarksType,
                            intMarksObtained: m.intMarksObtained,
                            intTotalMarks: m.intTotalMarks,
                            intQualMarkId: m.intQualMarkId,
                            intCandQualDetId: m.intCandQualDetId,
                            intCgpaPercent: m.intCgpaPercent,
                            txtScoreType:m.txtScoreType,
                            txtDegreeCode: m.txtDegreeCode
                          };
                    
                          this.marksList.push(marksData);
                        }
                      } else if (selectedEdu.txtYearOrSemester == "Year") {
                        if (m.txtMarksType.trim()[0].toLowerCase() == "y") {
                          let r = m.txtMarksType[1];
                          m.txtMarksType = "Year" + r;
                    
                          let marksData = {
                            txtMarksType: m.txtMarksType,
                            intMarksObtained: m.intMarksObtained,
                            intTotalMarks: m.intTotalMarks,
                            intQualMarkId: m.intQualMarkId,
                            intCandQualDetId: m.intCandQualDetId,
                            intCgpaPercent: m.intCgpaPercent,
                            txtScoreType:m.txtScoreType,
                            txtDegreeCode: m.txtDegreeCode
                          };
                    
                          this.marksList.push(marksData);
                        }
                      }
                    }
                  }
                }
              }


              if (!this.isTenthTwelth && (this.fresherDetails.lstBasicFreshers != null || this.fresherDetails.lstMarksFreshers != null)) {
                if (this.fresherDetails.lstMarksFreshers != null) {
                  for (let markList of this.fresherDetails.lstMarksFreshers) {
                    if (markList.txtMarksType.trim().toUpperCase() != "TOT" && !this.isTenthTwelth && markList.intCandQualDetId == selectedEdu.intCandQualDetId) {
                      this.markObtained += markList.intMarksObtained as number;
                      this.maxMarks += markList.intTotalMarks as number;
                      this.totalMarksObtained += markList.intMarksObtained as number;
                      this.totalMaxMarks += markList.intTotalMarks as number;
                    }
                    else if (markList.txtMarksType.trim().toUpperCase() == "TOT" && this.isTenthTwelth) {
                      this.maxMarks = 0;
                      this.maxMarks = markList.intTotalMarks as number;
                    }
                  }
                  this.calculateAverage(this.totalMarksObtained, this.totalMaxMarks);
                  this.totalAverage = this.avgMarks;
                  this.calculateAverage(this.markObtained, this.maxMarks);
                }
                else {
                  this.markObtained = 0;
                  this.maxMarks = 0;
                  this.calculateAverage(this.markObtained, this.maxMarks);
                }
              }
              this.getAttachments(selectedEdu.intCandQualDetId as number);
              this.mainPage = true;
              this.txtInstituteName = selectedEdu.txtInstituteName;
              this.university = selectedEdu.txtUniversity;
              this.txtBranchCode = selectedEdu.txtBranchcode;
              this.txtStartDate = selectedEdu.EduStartDate == null || selectedEdu.EduStartDate == undefined ? null : new Date(selectedEdu.EduStartDate);
              this.txtOldStartDate=selectedEdu.EduStartDate == null || selectedEdu.EduStartDate == undefined ? null : new Date(selectedEdu.EduStartDate);
              this.txtEndDate = selectedEdu.EduEndDate == null || selectedEdu.EduEndDate == undefined ? null : new Date(selectedEdu.EduEndDate);
              this.txtOldEndDate=selectedEdu.EduEndDate == null || selectedEdu.EduEndDate == undefined ? null : new Date(selectedEdu.EduEndDate);
              for (var lev of this.levelList) {
                if (selectedEdu.EduLevel.trim() == lev.txtDegreeDescription.trim()) {
                  this.EduLevel = lev.txtDegreeDescription;
                }
              }

              for (var deg of this.degreeList) {
                if (selectedEdu.txtQualificationCode.trim().toLowerCase() == deg.txtQualificationCode.trim().toLowerCase()) {
                  this.txtDegree = deg.txtQualificationDesc.trim();
                }
              }
              this.dtMonthYear = selectedEdu.dtMonthYear;
              this.MarksOrGPA = selectedEdu.MarksOrGPA;
              this.QualificationGained = selectedEdu.QualificationGained;
              this.roll = selectedEdu.txtRollNo;
              if (selectedEdu.intSemCount == 0 || selectedEdu.intSemCount.valueOf() < 0) {
                this.semCount = null;
              }
              else {
                this.semCount = selectedEdu.intSemCount;
              }
              this.yearOrSemester = selectedEdu.txtYearOrSemester;
              if (this.yearOrSemester === 'Year') {
                this.flgYear = true;
                this.flgSemester = false;
                this.semesterDropdown =  ["Year1", "Year2", "Year3", "Year4",
                "Year5", "Year6", "Year7", "Year8", "Year9",
                "Year10", "Year11", "Year12"]  
              } else if (this.yearOrSemester === 'Semester') {
                this.flgYear = false;
                this.flgSemester= true;
                this.semesterDropdown =  ["Semester1", "Semester2", "Semester3", "Semester4",
                "Semester5", "Semester6", "Semester7", "Semester8", "Semester9",
                "Semester10", "Semester11", "Semester12"]
              }

              // this.txtstate = selectedEdu.txtState;
              // if (this.txtstate == 'Others') {
              //   this.flgStateEnter = true;
              // } 
              // else 
              // {
              //   this.flgStateEnter = false;
              // }
              
              // this.txtothersstate = selectedEdu.txtOthersState;

              this.candDetId = selectedEdu.intCandQualDetId;
               this.spinnerEducationIN.hide();
            }
          );
           this.spinnerEducationIN.hide();
        }
      );
    }

  }

  XaddMarks() {
    if (this.validateMarks(this.addMarkObtained, this.addMaxMarks)) {

    }
  }
  onChangeStartDate(event: any) {
    this.txtStartDateNew = event;
    if(this.txtStartDateNew.getMonth()!=this.txtOldStartDate.getMonth() || this.txtStartDateNew.getDate()!=this.txtOldStartDate.getDate() || this.txtStartDateNew.getFullYear()!=this.txtOldStartDate.getFullYear()){
      const dialogRef = this.dialog.open(DialogLogoutEducation, {
        width: '450px',
        height: '200px',
  
      });
    }
}

onChangeEndDate(event:any,index){
  if(event!=null){
    this.txtEndDateNew = event;
  }
    // if(this.txtOldEndDate && event!=null){
    //   if(this.txtEndDateNew.getMonth()!=this.txtOldEndDate.getMonth() || this.txtEndDateNew.getDate()!=this.txtOldEndDate.getDate() || this.txtEndDateNew.getFullYear()!=this.txtOldEndDate.getFullYear()){
    //      const dialogRef = this.dialog.open(DialogLogoutEducation, {
    //        width: '450px',
    //        height: '200px',
    
    //      });
    //    }
     //  }
    this.dtMonthYear=this.txtEndDateNew.getDate().toString()+' '+this.txtEndDateNew.toLocaleString('default', { month: 'long' })+' '+this.txtEndDateNew.getFullYear().toString()
      }
 onUpdateClickDeleteGapDetails(){
   this.spinnerEducationIN.show()
   let objindiagap=new IndiaInputParam()
   objindiagap.CandidateId=Number(localStorage.getItem('intCandidateId'))
   this._educationServiceEducationIN.DeleteGapDetails(objindiagap).subscribe(res=>{
      this.spinnerEducationIN.hide()
   }
   )
 }


  createXMLDocument(flag: boolean, arrResult: string, arrTotal: string, QualMarkId: string): string {

    let doc = document.implementation.createDocument("", "", null);
    let Qualification = doc.createElement("Qualification");
    let QualificationMarks = doc.createElement("QualificationMarks");
    let txtMarksType = doc.createElement("txtMarksType");
    let intMarksObtained = doc.createElement("intMarksObtained");
    let intMaximumMarks = doc.createElement("intMaximumMarks");
    let intCgpaPercent = doc.createElement("intCgpaPercent");
   
    QualificationMarks.appendChild(txtMarksType);
    QualificationMarks.appendChild(intMarksObtained);
    QualificationMarks.appendChild(intMaximumMarks);
    QualificationMarks.appendChild(intCgpaPercent);

    if (!this.tenthTwelthDegree) {
      if (arrResult != null) {
        if (this.semesterTOTintQualMarkId == null || this.semesterTOTintQualMarkId == "" || this.semesterTOTintQualMarkId == undefined) {
          this.semesterTOTintQualMarkId = 0;
        }
        this.calculateAverage(Number(arrResult), Number(arrTotal));
        QualificationMarks.setAttribute("intQualMarkId", String(QualMarkId));
        txtMarksType.innerHTML = String(this.selectedSemester);
        intMarksObtained.innerHTML = String(arrResult);
        intMaximumMarks.innerHTML = String(arrTotal);
        intCgpaPercent.innerHTML = String(this.avgMarks);
        Qualification.appendChild(QualificationMarks);

        let QualificationMarks1 = doc.createElement("QualificationMarks");
        QualificationMarks1.setAttribute("intQualMarkId", this.semesterTOTintQualMarkId);
        let txtMarksType1 = doc.createElement("txtMarksType");
        txtMarksType1.innerHTML = "TOT";
        let intMarksObtained1 = doc.createElement("intMarksObtained");
        intMarksObtained1.innerHTML = String(this.totalMarksObtained);
        let intMaximumMarks1 = doc.createElement("intMaximumMarks");
        intMaximumMarks1.innerHTML = String(this.totalMaxMarks);
        let intCgpaPercent1 = doc.createElement("intCgpaPercent");
        intCgpaPercent1.innerHTML = String(this.totalAverage);
        QualificationMarks1.appendChild(txtMarksType1);
        QualificationMarks1.appendChild(intMarksObtained1);
        QualificationMarks1.appendChild(intMaximumMarks1);
        QualificationMarks1.appendChild(intCgpaPercent1);
        Qualification.appendChild(QualificationMarks1);

        doc.appendChild(Qualification);
        this.xmlstring = (new XMLSerializer()).serializeToString(doc.documentElement)
      }
      else {
        QualificationMarks.setAttribute("intQualMarkId", String(QualMarkId));
        txtMarksType.innerHTML = String("TOT");
        intMarksObtained.innerHTML = String(this.totalMarksObtained);
        intMaximumMarks.innerHTML = String(this.totalMaxMarks);
        intCgpaPercent.innerHTML = String(this.totalAverage);
        Qualification.appendChild(QualificationMarks);
        doc.appendChild(Qualification);
        this.xmlstring = (new XMLSerializer()).serializeToString(doc.documentElement)
      }
    }
    else {
      if (flag) {
        QualificationMarks.setAttribute("intQualMarkId", String(this.intQualMarkId));
        txtMarksType.innerHTML = String("TOT");
        intMarksObtained.innerHTML = String(this.totalMarksObtained);
        intMaximumMarks.innerHTML = String(this.totalMaxMarks);
        intCgpaPercent.innerHTML = String(this.avgMarksTenthTwelth);
        Qualification.appendChild(QualificationMarks);
        doc.appendChild(Qualification);
        this.xmlstring = (new XMLSerializer()).serializeToString(doc.documentElement);
      }
      else {

      }
    }
    return this.xmlstring;
  }

  deleteXMLDocument(marksId: Number): string {
    let doc = document.implementation.createDocument("", "", null);
    let Qualification = doc.createElement("Qualification");
    let QualificationMarks = doc.createElement("QualificationMarks");
    QualificationMarks.setAttribute("intQualMarkId", String(marksId));
    Qualification.appendChild(QualificationMarks);
    doc.appendChild(Qualification);
    this.xmlstring = (new XMLSerializer()).serializeToString(doc.documentElement);
    return this.xmlstring;
  }

  calculateAverage(markObtained: number, maximumMarks: number) {
    let avgmarks: number;
    let mark: number;
    if (this.validateMarks(markObtained, maximumMarks)) {
      if (this.percentageChecked) {
        this.percentageChecked = true;
        if (maximumMarks > 0) {
          this.avgMarks = Number((markObtained / maximumMarks * 100).toFixed(2));
          avgmarks = Number((markObtained / maximumMarks * 100).toFixed(2));

        }
        else {
          this.avgMarks = 0;
          avgmarks = 0;
        }
      }
      else if (this.cgpaChecked) {
        this.cgpaChecked = true;
        if (this.maxmarkList.length > 0) {
          this.avgMarks = Number(((markObtained / maximumMarks) * 10).toFixed(2));
          avgmarks = Number((markObtained / maximumMarks * 10).toFixed(2));
        }
      }
      else {
        //console.log('why??')
      }

    }
    else {
      this.avgMarks = 0;
      avgmarks = 0;
    }
    if (this.isTenthTwelth) {
      if (avgmarks > 0) {
        this.avgMarksTenthTwelth = Number((avgmarks).toFixed(2));
      }
      else { this.avgMarksTenthTwelth = 0; }
    }
  }

  calculateTotalMarks(markObtained: Number, markMax: Number) {
    this.totalMarksObtained = this.totalMarksObtained + (markObtained as number);
    this.totalMaxMarks = this.totalMaxMarks + (markMax as number);
    this.calculateAverage(this.totalMarksObtained, this.totalMaxMarks);
    this.totalAverage = this.avgMarks;
  }

  cancelForm() {
    this.XForm = false;
    this.addNewEdu = false;
    this.mainPage = true;
    this.isedit = true;
    this.educationDetail = true;
    if (this.mobile) {
      AddBodyscroll();
      CloseDataSubmitModal();
    }

  }

  addNewEducation() {
    this.dtMonthYear=" "
   if (!this.flgIsRebadging){
    if (this.countNewFlags >= 2) {
      this.alertEducationIN.error("Only 2 education details can be added.", this.mobile)
    }
    else {
      this.mainPage = true;
      if (this.mobile) {
        this.educationDetail = true;
        RemoveBodyscroll();
      }
      else {
        this.educationDetail = false;
        this.addNewEdu = true;
      }
    }
   }
   else{
    if (this.countNewFlags >=5) {
      this.alertEducationIN.error("Only 5 education details can be added.", this.mobile)
    }
    else {
      this.mainPage = true;
      if (this.mobile) {
        this.educationDetail = true;
        RemoveBodyscroll();
      }
      else {
        this.educationDetail = false;
        this.addNewEdu = true;
      }
    }
   }
  }

  //Added by Divyanshu
  checkTenthTwelth(value: string) {
    for (var i of this.levelList) {
      if (i.txtDegreeDescription == value && (i.txtDegreeCode == "09" || i.txtDegreeCode == "08")) {
        this.isTenthTwelth = true;
        break;
      } else {
        this.isTenthTwelth = false;
      }
    }
  }

  CancelEditfield() {
    this.isedit = true;
  }

  Editfield() {
    this.isedit = false;
  }

  editfalse() {
    this.isedit = true;
  }

  editForm() {
    this.edit = true;
    this.isdropDown = false;
  }

  validateDates(endDate: Date) {
    this.dateError = false;
    if (this.addNewEdu) {
      // if (strDate > endDate) {
      //   this.dateError = true;
      //   this.alertEducationIN.error("Start date cannot be greater than End date.", this.mobile);
      //   this.dateError = true;
      // }
      // else if (strDate == endDate) {
      //   this.dateError = true;
      //   this.alertEducationIN.error("Start date & end date cannot be the same.", this.mobile);
      //   this.dateError = true;
      // }
       if (endDate > new Date()) {
        this.dateError = true;
        this.alertEducationIN.error("End date cannot be later than today.", this.mobile);
        this.dateError = true;
      }
      else { this.dateError = false; }
    }
    else {
      
       if (endDate > new Date()) {
        this.dateError = true;
        this.alertEducationIN.error("End date cannot be greater than current month", this.mobile);
        this.dateError = true;
      }
      // else if (strDate > this.selStart && this.selStart.getFullYear() != 1900 && this.selStart != null) {

      //   if ((strDate.getMonth() - this.selStart.getMonth()) > 3 || (strDate.getFullYear() - this.selStart.getFullYear() != 0)) {
      //     this.alertEducationIN.error("Start date can be changed within a 3-month range only.", this.mobile);
      //     this.dateError = true;
      //   }
      // }
      // else if (strDate < this.selStart && this.selStart.getFullYear() != 1900 && this.selStart != null) {
      //   if ((this.selStart.getMonth() - strDate.getMonth()) > 3 || (strDate.getFullYear() - this.selStart.getFullYear() != 0)) {
      //     this.alertEducationIN.error("Start date can be changed within a 3-month range only.", this.mobile);
      //     this.dateError = true;
      //   }
      // }
      else if (endDate > this.selEnd && this.selEnd.getFullYear() != 1900 && this.selEnd != null) {
        if ((endDate.getMonth() - this.selEnd.getMonth()) > 3 || (endDate.getFullYear() - this.selEnd.getFullYear() != 0)) {
          this.alertEducationIN.error("End date can be changed within a 3-month range only.", this.mobile);
          this.dateError = true;
        }
      }
      else if (endDate < this.selEnd && this.selEnd.getFullYear() != 1900 && this.selEnd != null) {
        if ((this.selEnd.getMonth() - endDate.getMonth()) > 3 || (endDate.getFullYear() - this.selEnd.getFullYear() != 0)) {
          this.alertEducationIN.error("End date can be changed within a 3-month range only.", this.mobile);
          this.dateError = true;
        }
      }
    }
    // for (var ar of this.eduDetails) {
    //   var FromDate = new Date(ar.EduStartDate);
    //   var ToDate = new Date(ar.EduEndDate)
    //   var FromDateNew = strDate
    //   var ToDateNew = endDate

    //   if (this.candDetId != ar.intCandQualDetId) {
    //     if (FromDate <= ToDateNew && FromDateNew <= ToDate && FromDate.getFullYear() != 1900 && ToDate.getFullYear() != 1900) {
    //       this.alertEducationIN.error("Please ensure there is no overlapping of education dates. In case of any queries, please reach out to your On-boarding Advisor.", this.mobile)
    //       this.dateError = true;
    //       return;
    //     }
    //   }
   // }
  }

  // onClickState() {
  //   //console.log(this.txtstate);
  //   if(this.txtstate=='Others'){
  //     this.flgStateEnter=true;
  //   }
  //   else{
  //     this.flgStateEnter=false;
  //   }
    
  // }

  // onCheckedPercentOrCGPA() {
  //   //console.log(this.txtstate);
  //   if(this.txtscoretype=='Percentage'){
  //     this.percentageChecked=true;
  //     this.cgpaChecked=false;
  //   }
  //   else{
  //     this.percentageChecked=false;
  //     this.cgpaChecked=true;
  //   }
    
  // }

  insertNewEdu(form: NgForm) {

    this.education = new Education();
    this.education.txtSchoolCollege = form.value.Xschoolname.trim();
    this.education.txtBoardUniversity = form.value.Xuniversity.trim();
    //this.education.dtStartDate = new Date((new Date(form.value.startRef1)).getFullYear(), (new Date(form.value.startRef1)).getMonth(), (new Date(form.value.startRef1)).getDate(), 9, 0, 0);
    this.education.dtEndDate = new Date((new Date(form.value.endRef1)).getFullYear(), (new Date(form.value.endRef1)).getMonth(), (new Date(form.value.endRef1)).getDate(), 9, 0, 0);
    this.validateDates( this.education.dtEndDate);
    for (var lev of this.levelList) {
      if (form.value.Xlevel.trim() == lev.txtDegreeDescription.trim()) {
        this.education.txtLevelCode = lev.txtDegreeCode;
      }
    }
    for (var deg of this.degreeList) {
      if (form.value.XDegree.trim().toLowerCase() == deg.txtQualificationDesc.trim().toLowerCase()) {
        this.education.txtDegreeCode = deg.txtQualificationCode.trim();
        this.education.txtDegreeDesc = deg.txtQualificationDesc.trim();
      }
    }
    this.education.txtMarks = form.value.Xmarks;
    this.education.flgFullTime = form.value.Xpartorfull;
    this.education.txtRollNo = form.value.Xroll;
    this.education.intSemCount = form.value.Xnosem;
    this.education.txtYearOrSemester = form.value.yearSemester;
    // for (var state of this.stateList) {
    //   if (form.value.Xstate.trim().toLowerCase() == state.txtStateCode.trim().toLowerCase()) {
    //     this.education.txtState = state.txtStateCode.trim();
    //   }
    // }
    // this.education.txtState = form.value.Xstate;
    // this.education.txtOthersState=form.value.Xothersstate;
    this.education.intCandQualDetId = 0;
    this.education.CandidateId = Number(localStorage.getItem("intCandidateId"));
    if (!this.dateError) {
       this.spinnerEducationIN.show();
      this._educationServiceEducationIN.InsertUpdateEduDetails(this.education)
        .subscribe(
          updatedResponseEducationIN => {
            this.msg = updatedResponseEducationIN;
             this.spinnerEducationIN.hide();
            if (updatedResponseEducationIN != 0) {
              this.getEducationDetails();
              CloseDataSubmitModal();
              this.alertEducationIN.success("Done! Education details are added.", this.mobile);
              this.isTenthTwelth = false;
              form.resetForm();
            }
          }
        );
      this.XForm = false;
      this.mainPage = true;
      this.isedit = true;
      this.addNewEdu = false;
    }
  }

  skiplink() {
    this.navigate();

  }

  updateXDetails(form: NgForm) {
    if(form.value.flgbgvreq == 'Y'){
      if(this.appService.updateForm == false){   
        this.callModal();
        }   
        if(this.appService.updateForm == true){
      window.scroll(0, 700);
       this.spinnerEducationIN.show()
      this.education = new Education();
      this.education.txtSchoolCollege = this.txtInstituteName.trim();
      this.education.txtBoardUniversity = this.university.trim();
      //this.education.dtStartDate = new Date((new Date(form.value.startRef1)).getFullYear(), (new Date(form.value.startRef1)).getMonth(), (new Date(form.value.startRef1)).getDate(), 9, 0, 0);
      this.education.dtEndDate = new Date((new Date(form.value.endRef1)).getFullYear(), (new Date(form.value.endRef1)).getMonth(), (new Date(form.value.endRef1)).getDate(), 9, 0, 0);
      this.education.intCandQualDetId = this.candDetId;
      this.education.CandidateId = Number(localStorage.getItem("intCandidateId"));
      this.validateDates( this.education.dtEndDate);
      // if(form.value.flgbgvreq == 'Y'){
      //   console.log('Popup show');
      // }
      for (var lev of this.levelList) {
        if (this.EduLevel.trim().toLowerCase() == lev.txtDegreeDescription.trim().toLowerCase()) {
          this.education.txtLevelCode = lev.txtDegreeCode.trim();
        }
      }
      for (var deg of this.degreeList) {
        if (this.txtDegree.trim().toLowerCase() == deg.txtQualificationDesc.trim().toLowerCase()) {
          this.education.txtDegreeCode = deg.txtQualificationCode.trim();
          this.education.txtDegreeDesc = deg.txtQualificationDesc.trim();
        }
      }
      if (this.lateral) {
        this.education.txtMarks = String(this.MarksOrGPA);
      }
      else {
        if (this.isTenthTwelth) {
          this.education.txtMarks = String(this.avgMarksTenthTwelth);
        }
        else {
          this.education.txtMarks = String(this.avgMarks);
        }
      }

      // if (this.fresher || this.lateral){
      //   if (this.isTenthTwelth) {
      //     this.education.txtMarks = String(this.avgMarksTenthTwelth);
      //   }
      //   else {
      //     this.education.txtMarks = String(this.avgMarks);
      //   }
      // }


      this.education.flgFullTime = this.QualificationGained;
      this.education.txtRollNo = form.value.Xroll;
      this.education.intSemCount = form.value.Xnosem;
      this.education.txtYearOrSemester = form.value.yearSemester;
      // for (var state of this.stateList) {
      //   if (form.value.Xstate.trim().toLowerCase() == state.txtStateCode.trim().toLowerCase()) {
      //     this.education.txtState = state.txtStateCode.trim();
      //   }
      // }
      // this.education.txtState=form.value.Xstate;
      // this.education.txtOthersState=form.value.Xothersstate;


       this.spinnerEducationIN.show();

      if (this.fresher) {
        this.updateFresherDetails(form);
      }
      if (this.lateral) {
        if (!this.dateError) {
          this._educationServiceEducationIN.InsertUpdateEduDetails(this.education)
            .subscribe(
              updatedResponseEducationIN => {
                 this.spinnerEducationIN.hide()
                this.msg = updatedResponseEducationIN;
                 this.spinnerEducationIN.hide();
                if (updatedResponseEducationIN != 0) {
                  if(this.txtStartDateNew!=null && this.txtEndDateNew!=null){
                    if(this.txtStartDateNew.getMonth()!=this.txtOldStartDate.getMonth() || this.txtStartDateNew.getDate()!=this.txtOldStartDate.getDate() || this.txtStartDateNew.getFullYear()!=this.txtOldStartDate.getFullYear() || this.txtEndDateNew.getMonth()!=this.txtOldEndDate.getMonth() || this.txtEndDateNew.getDate()!=this.txtOldEndDate.getDate() || this.txtEndDateNew.getFullYear()!=this.txtOldEndDate.getFullYear()){
                      this.onUpdateClickDeleteGapDetails();

                    }
                  }
                  else if(this.txtStartDateNew!=null){
                    if(this.txtStartDateNew.getMonth()!=this.txtOldStartDate.getMonth() || this.txtStartDateNew.getDate()!=this.txtOldStartDate.getDate() || this.txtStartDateNew.getFullYear()!=this.txtOldStartDate.getFullYear()){
                      this.onUpdateClickDeleteGapDetails();
                      }
                    }
                  else if(this.txtEndDateNew!=null){
                      if(this.txtEndDateNew.getMonth()!=this.txtOldEndDate.getMonth() || this.txtEndDateNew.getDate()!=this.txtOldEndDate.getDate() || this.txtEndDateNew.getFullYear()!=this.txtOldEndDate.getFullYear()){
                        this.onUpdateClickDeleteGapDetails();

                      }
                    }
                  else{
                    //DO NOTHING (DO NOT DELETE GAPS)
                  }

                  this.getEducationDetails();
                  if(this.combMsgShow == true){
                  this.alertEducationIN.success("Done! BGV Initiated & Education details are updated.", this.mobile);
                  }
                  else{
                    this.alertEducationIN.success("Done! Education details are updated.", this.mobile);
                  }
                  if(!this.ibpm){
                    if(this.bgvShow.flgShow == 'Y'){
                      this.flgBGV = true;
                    }
                  }
                  this.appService.updateForms();
                }
              }
            );
        }
         this.spinnerEducationIN.hide();
      }
      this.XForm = false;
      this.mainPage = true;
      this.isedit = true;

    }
  }
  else
  {
    window.scroll(0, 700);
     this.spinnerEducationIN.show()
    this.education = new Education();
    this.education.txtSchoolCollege = this.txtInstituteName.trim();
    this.education.txtBoardUniversity = this.university.trim();
    //this.education.dtStartDate = new Date((new Date(form.value.startRef1)).getFullYear(), (new Date(form.value.startRef1)).getMonth(), (new Date(form.value.startRef1)).getDate(), 9, 0, 0);
    this.education.dtEndDate = new Date((new Date(form.value.endRef1)).getFullYear(), (new Date(form.value.endRef1)).getMonth(), (new Date(form.value.endRef1)).getDate(), 9, 0, 0);
    this.education.intCandQualDetId = this.candDetId;
    this.education.CandidateId = Number(localStorage.getItem("intCandidateId"));
    this.validateDates( this.education.dtEndDate);
        // if(form.value.flgbgvreq == 'Y'){
    //   console.log('Popup show');
    // }
    for (var lev of this.levelList) {
      if (this.EduLevel.trim().toLowerCase() == lev.txtDegreeDescription.trim().toLowerCase()) {
        this.education.txtLevelCode = lev.txtDegreeCode.trim();
      }
    }
    for (var deg of this.degreeList) {
      if (this.txtDegree.trim().toLowerCase() == deg.txtQualificationDesc.trim().toLowerCase()) {
        this.education.txtDegreeCode = deg.txtQualificationCode.trim();
        this.education.txtDegreeDesc = deg.txtQualificationDesc.trim();
      }
    }

    if (this.lateral) {
      this.education.txtMarks = String(this.MarksOrGPA);
    }
    else {
      if (this.isTenthTwelth) {
        this.education.txtMarks = String(this.avgMarksTenthTwelth);
      }
      else {
        this.education.txtMarks = String(this.avgMarks);
      }
    }

    // if(this.fresher || this.lateral){
    //   if (this.isTenthTwelth) {
    //     this.education.txtMarks = String(this.avgMarksTenthTwelth);
    //   }
    //   else {
    //     this.education.txtMarks = String(this.avgMarks);
    //   }
    // }


    this.education.flgFullTime = this.QualificationGained;
    this.education.txtRollNo = form.value.Xroll;
    this.education.intSemCount = form.value.Xnosem;
    this.education.txtYearOrSemester = form.value.yearSemester;
    // for (var state of this.stateList) {
    //   if (form.value.Xstate.trim().toLowerCase() == state.txtStateCode.trim().toLowerCase()) {
    //     this.education.txtState = state.txtStateCode.trim();
    //   }
    // }
    // this.education.txtState=form.value.Xstate;

    // this.education.txtOthersState=form.value.Xothersstate;


     this.spinnerEducationIN.show();

    if (this.fresher) {
      this.updateFresherDetails(form);
    }
    if (this.lateral) {
      if (!this.dateError) {
        this._educationServiceEducationIN.InsertUpdateEduDetails(this.education)
          .subscribe(
            updatedResponseEducationIN => {
               this.spinnerEducationIN.hide()
              this.msg = updatedResponseEducationIN;
               this.spinnerEducationIN.hide();
              if (updatedResponseEducationIN != 0) {
                if(this.txtStartDateNew!=null && this.txtEndDateNew!=null){
                  if(this.txtStartDateNew.getMonth()!=this.txtOldStartDate.getMonth() || this.txtStartDateNew.getDate()!=this.txtOldStartDate.getDate() || this.txtStartDateNew.getFullYear()!=this.txtOldStartDate.getFullYear() || this.txtEndDateNew.getMonth()!=this.txtOldEndDate.getMonth() || this.txtEndDateNew.getDate()!=this.txtOldEndDate.getDate() || this.txtEndDateNew.getFullYear()!=this.txtOldEndDate.getFullYear()){
                    this.onUpdateClickDeleteGapDetails();

                  }
                }
                else if(this.txtStartDateNew!=null){
                  if(this.txtStartDateNew.getMonth()!=this.txtOldStartDate.getMonth() || this.txtStartDateNew.getDate()!=this.txtOldStartDate.getDate() || this.txtStartDateNew.getFullYear()!=this.txtOldStartDate.getFullYear()){
                    this.onUpdateClickDeleteGapDetails();
                    }
                  }
                else if(this.txtEndDateNew!=null){
                    if(this.txtEndDateNew.getMonth()!=this.txtOldEndDate.getMonth() || this.txtEndDateNew.getDate()!=this.txtOldEndDate.getDate() || this.txtEndDateNew.getFullYear()!=this.txtOldEndDate.getFullYear()){
                      this.onUpdateClickDeleteGapDetails();

                    }
                  }
                else{
                  //DO NOTHING (DO NOT DELETE GAPS)
                }
                
                this.getEducationDetails();
                this.alertEducationIN.success("Done! Education details are updated.", this.mobile);
              }
            }
          );
      }
       this.spinnerEducationIN.hide();
    }
    this.XForm = false;
    this.mainPage = true;
    this.isedit = true;

  }
}
  updateFresherDetails(form: NgForm) {
    if (this.isTenthTwelth && !this.isValidMark) {
      // if((this.ibpm && this.fresher)||(!this.ibpm)){
        this.alertEducationIN.error("Obtained marks must be less than or equal to maximum marks.", this.mobile);
         this.spinnerEducationIN.hide();
    //}
  }
    else{
    let educationFre = new Education();
    if (this.intQualMarkId == undefined) {
      this.intQualMarkId = 0;
    }
    this.calculateTotalMarks(form.value.XmarksObtained, form.value.XmaxMarks)
    educationFre.xmlUpdate = this.createXMLDocument(true, null, null, (this.intQualMarkId.toString()));
    educationFre.xmlDelete = "<Qualification />"; 
    for (var lev of this.levelList) {
      if (this.EduLevel.trim().toLowerCase() == lev.txtDegreeDescription.trim().toLowerCase()) {
        educationFre.txtDegreeCode = lev.txtDegreeCode.trim();
      }
    }
    educationFre.intCandQualDetId = this.candDetId;
    educationFre.intSemCount = form.value.Xnosem;
    educationFre.txtYearOrSemester = form.value.yearSemester;
    educationFre.CandidateId = Number(localStorage.getItem("intCandidateId"));
    educationFre.modifiedBy = (localStorage.getItem("intCandidateId"));
     this.spinnerEducationIN.show();
    if (!this.dateError) {
      this.education.dtStartDate = new Date(1900,1,1,0,0,0);
      this._educationServiceEducationIN.UpdateEduDetails(this.education).subscribe(
        resEducationIN => {
          if (resEducationIN != 0) {
            this._educationServiceEducationIN.UpdateFreshersEduDetail(educationFre)
              .subscribe(
                updatedResponseEducationIN => {
                  this.msg = updatedResponseEducationIN;
                   this.spinnerEducationIN.hide();
                  if (updatedResponseEducationIN != 0) {
                    if(this.txtStartDateNew!=null && this.txtEndDateNew!=null){
                      if(this.txtStartDateNew.getMonth()!=this.txtOldStartDate.getMonth() || this.txtStartDateNew.getDate()!=this.txtOldStartDate.getDate() || this.txtStartDateNew.getFullYear()!=this.txtOldStartDate.getFullYear() || this.txtEndDateNew.getMonth()!=this.txtOldEndDate.getMonth() || this.txtEndDateNew.getDate()!=this.txtOldEndDate.getDate() || this.txtEndDateNew.getFullYear()!=this.txtOldEndDate.getFullYear()){
                        this.onUpdateClickDeleteGapDetails();
    
                      }
                    }
                    else if(this.txtStartDateNew!=null){
                      if(this.txtStartDateNew.getMonth()!=this.txtOldStartDate.getMonth() || this.txtStartDateNew.getDate()!=this.txtOldStartDate.getDate() || this.txtStartDateNew.getFullYear()!=this.txtOldStartDate.getFullYear()){
                        this.onUpdateClickDeleteGapDetails();
                        }
                      }
                    else if(this.txtEndDateNew!=null){
                        if(this.txtEndDateNew.getMonth()!=this.txtOldEndDate.getMonth() || this.txtEndDateNew.getDate()!=this.txtOldEndDate.getDate() || this.txtEndDateNew.getFullYear()!=this.txtOldEndDate.getFullYear()){
                          this.onUpdateClickDeleteGapDetails();
    
                        }
                      }
                    else{
                      //DO NOTHING (DO NOT DELETE GAPS)
                    }
                    if(form.value.flgbgvreq == 'Y'){
                      if(this.combMsgShow == true){
                      this.alertEducationIN.success("Done! BGV Initiated & Education details are updated.", this.mobile);
                      }
                      else{
                        this.alertEducationIN.success("Done! Education details are updated.", this.mobile);
                      }
                      if(!this.ibpm){
                        if(this.bgvShow.flgShow == 'Y'){
                          this.flgBGV = true;
                        }
                      }
                      this.appService.updateForms();
                    }else{
                    this.alertEducationIN.success("Done! Education details are updated.", this.mobile);
                    }
                    this.getEducationDetails();
                    this.XForm = false; this.mainPage = true; this.isedit = true;
                  }
                }
              );
          }
        }
      );
    }
     this.spinnerEducationIN.hide();
  }
  }

  validateFileName(filename: String) {
    if (filename == null || filename == undefined) {
      return true;
    }
    let nameregex = RegExp('^[a-zA-Z0-9_. -]+$');
    if (nameregex.test(filename as string)) {
      this.nameError = true;
    }
    else {
      this.nameError = false;
      this.alertEducationIN.error("File name must be alphanumeric only.", this.mobile);
    }
  }

  validateFileSize(filesize: Number, index: number) {
     this.spinnerEducationIN.show();
    if (filesize > 5242880) {
      this.sizeError = false;
       this.spinnerEducationIN.hide();
      this.alertEducationIN.error("Let's keep that file size within 5MB.", this.mobile);
    }
    else {
       this.spinnerEducationIN.hide();
      this.sizeError = true;
      this.validateFileFormat(this.fname[index], index);
    }
  }

  validateFileFormat(name: String, index: number) {
     this.spinnerEducationIN.show();
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'tif' || ext.toLowerCase() == 'tiff' || ext.toLowerCase() == 'jpg' ||
      ext.toLowerCase() == 'jpeg' || ext.toLowerCase() == 'pdf') {
      this.formatError = true;
       this.spinnerEducationIN.hide();
      this.validateFileName(this.fname[index]);
    }
    else {
       this.spinnerEducationIN.hide();
      this.formatError = false;
      this.alertEducationIN.error("Supported File Formats are TIF,TIFF,JPG,JPEG,and PDF.", this.mobile);
    }
  }

  setDocCharacteristics(index: number) {
    if (index == 0) {
      this.docType = "DC";
      this.EduLevel = "DC";
      this.doclevel = "DC";
      this.YearOrSem = "";
      if (this.consMark){
        this.txtIdentifier="CONSDEGREE";
      }else if(this.semWise) {
        this.txtIdentifier="SEMDEGREE";
      }
      else{
        this.txtIdentifier="YEARDEGREE";

      }
    }
    else if (index == 1) {
      if (this.isTenthTwelth) {
        this.docType = "MS";
        this.EduLevel = "MS";
        this.doclevel = "MS";
        this.YearOrSem = "";
      }
      else {
        this.docType = "PC";
        this.EduLevel = "PCERT";
        this.doclevel = "PCERT";
        this.YearOrSem = "";
        if (this.consMark){
        this.txtIdentifier="CONSPROV";

        }else if(this.semWise) {
          this.txtIdentifier="SEMPROV";
            
          }
          else{
  
            this.txtIdentifier="YEARPROV";
  
          }
      }
    }
    else if (index == 14) {
      this.docType = "CMS";
      this.EduLevel = "CMS";
      this.doclevel = "CMS";
      this.YearOrSem = "";
      this.txtIdentifier="CONSCERT";
    }
    else if(index >=15){
      this.txtIdentifier="YEARMARK";
      this.docType = "Y" + (index - 14).toString();
      this.EduLevel = "MS";
      this.doclevel = "MS";
      this.YearOrSem = (index - 14).toString();
    }
    else {
      if(this.lateral){
        this.txtIdentifier="SEMMARK";
      this.docType = "S" + (index - 1).toString();
      this.EduLevel = "MS";
      this.doclevel = "MS";
      this.YearOrSem = (index - 1).toString();
      }
      else if (this.fresher && this.yearOrSemester == "Year"){
        this.txtIdentifier="YEARMARK";
        this.docType = "Y" + (index - 1).toString();
        this.EduLevel = "MS";
        this.doclevel = "MS";
        this.YearOrSem = (index - 1).toString();
      }
      else if (this.fresher && this.yearOrSemester == "Semester"){
        this.txtIdentifier="SEMMARK";
        this.docType = "S" + (index - 1).toString();
        this.EduLevel = "MS";
        this.doclevel = "MS";
        this.YearOrSem = (index - 1).toString();
      }
    }
    this.generateFileName(index, this.fname[index]);
  }

  uploadFile(index: number) {
    if (!this.camera) {
      let files = this.myFile[index as number];
      this.spinnerEducationIN.show();
      let file = this.myFile[index as number];
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e) => {
        EducationComponent.Base64String = reader.result;
        EducationComponent.arr = String(EducationComponent.Base64String).split(",");
        EducationComponent.fileAsBasestring = EducationComponent.arr[1];
        let lpdms: DMSNew = new DMSNew();
        lpdms.filename = this.nameGenerated.toString();;
        lpdms.UserName = localStorage.getItem("txtEmailId");
        lpdms.documentTitle = this.nameGenerated.toString();
        lpdms.DMSDocPath = localStorage.getItem("DMSSitePath");
        lpdms.docBytesAsBase64String = EducationComponent.fileAsBasestring;
        this.spinnerEducationIN.show();
        this._educationServiceEducationIN.UploadPlainDocumentNew(lpdms).subscribe(responseEducationIN => {
          this.uploadDocResponse = responseEducationIN

          if(this.uploadDocResponse !=null && this.uploadDocResponse.documentGUIDField.length >0)
          {
          let educationDocs: EducationDocs = new EducationDocs();
          educationDocs.CandidateID = Number(localStorage.getItem("intCandidateId"));
          educationDocs.CandEduDetId = this.selectedEduQualId;
          educationDocs.FileUploaded = this.nameGenerated;
          educationDocs.markType = this.docType;
          educationDocs.OriginalFileName = this.fname[index as number];
          educationDocs.PassingYr = this.selectedEduPassingYear;
          educationDocs.QualificationCode = this.selectedEduQyalifiactionCode;
          educationDocs.CertOrMark = this.doclevel;
          educationDocs.YearorSem = this.YearOrSem;
          educationDocs.SerialNo = this.doc_serial_number;
          educationDocs.DMSSitePath = localStorage.getItem("DMSSitePath");
          educationDocs.txtGUID_DMS = this.uploadDocResponse.documentGUIDField;
          this._educationServiceEducationIN.InsertEduDocDetails(educationDocs).subscribe(response => {
            if (response == 1) {
              this.alertEducationIN.success("Success! Document is uploaded.", this.mobile);
              this.AddAnswersforBGV(null,this.txtIdentifier);
            }
            else if (response == 2) {
              this.alertEducationIN.error("Oops! An error occurred in document upload.", this.mobile);
            }
            else if (response == 3) {
              this.alertEducationIN.error("Oops! An error occurred in document upload. Kindly contact HR to fix this issue", this.mobile);
            }
            this.getAttachments(this.selectedEduQualId);
            this.spinnerEducationIN.hide();
          });
        }
        });
      }
    }
    if (this.camera) {
      let files = this.myFile[index as number];
      var reader = new FileReader();
      reader.onload = (event: any) => {
        var orientation = -1;
        this.ng2ImgMaxEducationIN.compressFile(event.target.result, orientation, 60, 60).then(
            result => {   
                var lpdmsCam = new DMSNew(); 
                EducationComponent.Base64String = result;
                EducationComponent.arr = String(EducationComponent.Base64String).split(",");
                EducationComponent.fileAsBasestring = EducationComponent.arr[1];
                lpdmsCam.filename = this.nameGenerated.toString();
                lpdmsCam.UserName = localStorage.getItem("txtEmailId");
                lpdmsCam.documentTitle = this.fname[index] as string;
                lpdmsCam.DMSDocPath = localStorage.getItem("DMSSitePath");
                lpdmsCam.docBytesAsBase64String = EducationComponent.fileAsBasestring;
                this.spinnerEducationIN.show();
                this._educationServiceEducationIN.UploadPlainDocumentNew(lpdmsCam).subscribe(responseEducationIN => {
                  this.uploadDocResponse = responseEducationIN;
    
                  if(this.uploadDocResponse !=null && this.uploadDocResponse.documentGUIDField.length >0)
                  {
                  let educationDocs: EducationDocs = new EducationDocs();
                  educationDocs.CandidateID = Number(localStorage.getItem("intCandidateId"));
                  educationDocs.CandEduDetId = this.selectedEduQualId;
                  educationDocs.FileUploaded = this.nameGenerated;
                  educationDocs.markType = this.docType;
                  educationDocs.OriginalFileName = this.fname[index as number];
                  educationDocs.PassingYr = this.selectedEduPassingYear;
                  educationDocs.QualificationCode = this.selectedEduQyalifiactionCode;
                  educationDocs.CertOrMark = this.doclevel;
                  educationDocs.YearorSem = this.YearOrSem;
                  educationDocs.SerialNo = this.doc_serial_number;
                  educationDocs.DMSSitePath = localStorage.getItem("DMSSitePath");
                  educationDocs.txtGUID_DMS = this.uploadDocResponse.documentGUIDField;
                  this._educationServiceEducationIN.InsertEduDocDetails(educationDocs).subscribe(responseEducationIN => {
                    if (responseEducationIN == 1) {
                      this.alertEducationIN.success("Success! Document is uploaded.", this.mobile);
                      this.AddAnswersforBGV(null,this.txtIdentifier);
                    }
                    else if (responseEducationIN == 2) {
                      this.alertEducationIN.error("Oops! An error occurred in document upload.", this.mobile);
                    }
                    else if (responseEducationIN == 3) {
                      this.alertEducationIN.error("Oops! An error occurred in document upload. Kindly contact HR to fix this issue", this.mobile);
                    }
                    this.getAttachments(this.selectedEduQualId);
                    this.spinnerEducationIN.hide();
                  });
                }
                });
            },
            error => {
              console.log("Error", error)
            }
          );
      };
      reader.readAsDataURL(files);
    }

  }


  uploadDocument(index: number) {
    this.setDocCharacteristics(index);
  }

  generateFileName(index: number, fileName: String) {
    this.nameGenerated = "";
    let Description: LPFetchEduDegreeDesc[];
    let CandidateId = Number(localStorage.getItem("intCandidateId"));
    let edu = new Education();
    let eduDoc = new EducationDocs();
    eduDoc.CandidateID = CandidateId;
    eduDoc.CertOrMark = this.EduLevel;
    eduDoc.CandEduDetId = this.selectedEduQualId;
    eduDoc.QualificationCode = this.selectedEduQyalifiactionCode;
    eduDoc.YearorSem = this.YearOrSem;
    eduDoc.markType = this.docType;
    eduDoc.PassingYr = this.selectedEduPassingYear;
    edu.CandidateId = CandidateId;
    edu.intCandQualDetId = this.selectedEduQualId;
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.FetchDegreeDescription(edu).subscribe(
      responseEducationIN => {
        Description = responseEducationIN;
        this._educationServiceEducationIN.FetchEduDocSerialNo(eduDoc).subscribe(
          responseEducationIN => {
            this.doc_serial_number = responseEducationIN[0].intserialno;
            this.nameGenerated = CandidateId + "_"+this.selectedEduQualId+"_"+ Description[0].DegreeDesc + "_" + this.EduLevel + "_" + this.selectedEduQyalifiactionCode + "_" + this.doc_serial_number + 1 + fileName.substring(fileName.lastIndexOf('.'));
            this.uploadFile(index);
          }
        );
         this.spinnerEducationIN.hide();
      }
    );
  }

  downloadDoc(index: number) {
    let lpdms = new LPDms();
    lpdms.documentTitle = this.lpFetchEduDocDetailsList[index].txtFileUploaded;
    lpdms.DMSDocPath = this.lpFetchEduDocDetailsList[index].txtDMSDocPath;
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.DownloadDocumentFromDMS(lpdms).subscribe(
      (resEducationIN) => {
        if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
           this.spinnerEducationIN.hide();
          GetBase64Data(resEducationIN.FileData, resEducationIN.FileName);
          var byteArray = this.base64ToArrayBuffer(resEducationIN.FileData);
          var blob = new Blob([byteArray], { type: 'application/pdf' });
          (window.navigator as any).msSaveOrOpenBlob(blob, resEducationIN.FileName);
        } else {
          GetBase64Data(resEducationIN.FileData, resEducationIN.FileName);
          var byteArray = this.base64ToArrayBuffer(resEducationIN.FileData);
          var blob = new Blob([byteArray], { type: 'application/jpg' });
          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          document.body.appendChild(a);
          a.setAttribute('style', 'display: none');
          a.href = url;
          a.download = resEducationIN.FileName as string;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
           this.spinnerEducationIN.hide();
        }
      }
    );
    if (window.navigator && (window.navigator as any).msSaveOrOpenBlob) {
       this.spinnerEducationIN.hide();
    }
  }

  base64ToArrayBuffer(base64) {
    var binary_string = window.atob(base64);
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }
    return bytes.buffer;
  }

  deleteFile(index: number) {
    let lpFetchEducationDetails: any[];
    let eduDoc: FetchEducationDocs = new FetchEducationDocs();
    eduDoc.CandExDetID = this.selectedEduQualId;
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.FetchEduDocDetails(eduDoc).subscribe(
      responseEducationIN => {
        lpFetchEducationDetails = responseEducationIN;
        if (index == 0) {
          for (let ele of lpFetchEducationDetails) {
            if (ele.txtMarksType === 'DC') {
              let eduDocs: EducationDocs = new EducationDocs();
              eduDocs.CandEduDetId = ele.intEduId;
              this._educationServiceEducationIN.DeleteEduDocDetails(eduDocs).subscribe(
                response => {
                  this.getAttachments(this.selectedEduQualId);
                   this.spinnerEducationIN.hide();
                  if(response == 0) {this.alertEducationIN.success("This document has been approved, you'll not be able to delete this document.", this.mobile);}
                  if(response == 1) {this.alertEducationIN.success("Done! File(s) deleted.", this.mobile);}
                }
              );
            }
          }
        }
        else if (index == 1) {
          for (let ele of lpFetchEducationDetails) {
            if(this.isTenthTwelth){
              if (ele.txtMarksType === 'MS') {
                let eduDocs: EducationDocs = new EducationDocs();
                eduDocs.CandEduDetId = ele.intEduId;
                this._educationServiceEducationIN.DeleteEduDocDetails(eduDocs).subscribe(
                  response => {
                    this.getAttachments(this.selectedEduQualId);
                     this.spinnerEducationIN.hide();
                    if(response == 0) {this.alertEducationIN.success("This document has been approved, you'll not be able to delete this document.", this.mobile);}
                    if(response == 1) {this.alertEducationIN.success("Done! File(s) deleted.", this.mobile);}
                  }
                );;
              }
            }
            else if(!this.isTenthTwelth){
              if (ele.txtMarksType === 'PC') {
                let eduDocs: EducationDocs = new EducationDocs();
                eduDocs.CandEduDetId = ele.intEduId;
                this._educationServiceEducationIN.DeleteEduDocDetails(eduDocs).subscribe(
                  response => {
                    this.getAttachments(this.selectedEduQualId);
                     this.spinnerEducationIN.hide();
                    if(response == 0) {this.alertEducationIN.success("This document has been approved, you'll not be able to delete this document.", this.mobile);}
                    if(response == 1) {this.alertEducationIN.success("Done! File(s) deleted.", this.mobile);}
                  }
                );;
              }
            }           
          }
        }
        else if (index == 14) {
          for (let ele of lpFetchEducationDetails) {
            if (ele.txtMarksType === 'CMS') {
              let eduDocs: EducationDocs = new EducationDocs();
              eduDocs.CandEduDetId = ele.intEduId;
              this._educationServiceEducationIN.DeleteEduDocDetails(eduDocs).subscribe(
                response => {
                  this.getAttachments(this.selectedEduQualId);
                   this.spinnerEducationIN.hide();
                  if(response == 0) {this.alertEducationIN.success("This document has been approved, you'll not be able to delete this document.", this.mobile);}
                  if(response == 1) {this.alertEducationIN.success("Done! File(s) deleted.", this.mobile);}
                }
              );;
            }
          }
        }
        else if (index >= 15) {
          for (let ele of lpFetchEducationDetails) {
            if (ele.txtMarksType === ('Y' + (index - 14).toString())) {
              let eduDocs: EducationDocs = new EducationDocs();
              eduDocs.CandEduDetId = ele.intEduId;
              this._educationServiceEducationIN.DeleteEduDocDetails(eduDocs).subscribe(
                response => {
                  this.getAttachments(this.selectedEduQualId);
                   this.spinnerEducationIN.hide();
                  if(response == 0) {this.alertEducationIN.success("This document has been approved, you'll not be able to delete this document.", this.mobile);}
                  if(response == 1) {this.alertEducationIN.success("Done! File(s) deleted.", this.mobile);}
                }
              );;
            }
          }
        }
        else {
          for (let ele of lpFetchEducationDetails) {
            if (ele.txtMarksType === ('S' + (index - 1).toString())) {
              let eduDocs: EducationDocs = new EducationDocs();
              eduDocs.CandEduDetId = ele.intEduId;
              this._educationServiceEducationIN.DeleteEduDocDetails(eduDocs).subscribe(
                response => {
                  this.getAttachments(this.selectedEduQualId);
                   this.spinnerEducationIN.hide();
                  if(response == 0) {this.alertEducationIN.success("This document has been approved, you'll not be able to delete this document.", this.mobile);}
                  if(response == 1) {this.alertEducationIN.success("Done! File(s) deleted.", this.mobile);}
                }
              );;
            }
          }
        }
        // //need to check this.spinnerEducationIN.hide();
        // this.alertEducationIN.success("Done! File(s) deleted.", this.mobile);
      });
  }

  navigate() {
    if (this.order != "N" || !this.mobile) {
      if (this.order == "P" && this.mobile) {
        LoadOwlCarouselForGallery();
        LoadJsForDashBoardMobileCarousel();
        this.getPendingAction();
        this.appServiceEducationIN.setHiddenDashBoard(false);
        this.routerEducationIN.navigateByUrl('lpau/step1-au/dummy', { skipLocationChange: true });
        setTimeout(() => this.routerEducationIN.navigate(['../lpindia']), 3000);
        setTimeout(() => ShowBottomBar(), 3000);
      }
      else if (this.order == "W" && !this.mobile) {
        this.routerEducationIN.navigate(['../lpindia/postjoining/education'],{ queryParams: { order: "W" } })
      } else if (this.order == "W") {
        this.routerEducationIN.navigate(['../lpindia/postjoining/education'],{ queryParams: { order: "W" } })
      }
      else {
        if (this.lateral) {
          ChgwrkexIN();
          this.routerEducationIN.navigate(['../workexperience'], { relativeTo: this.rEducationIN });
        }
        else {
          ChgachieveIN();
          this.routerEducationIN.navigate(['../achievement'], { relativeTo: this.rEducationIN });
        }
      }
    }
    else {
      this.routerEducationIN.navigate(['notification']);
    }
  }

  hideMainPage(selectedEdu: BasicDetailsFreshers) {
    if (selectedEdu.txtQualificationCode == "0424" || selectedEdu.txtQualificationCode == "0425" || selectedEdu.txtQualificationCode == "424" || selectedEdu.txtQualificationCode == "425") {
      this.isTenthTwelth = true;
    }
    else {
      this.isTenthTwelth = false;
    }
    if (this.enableUploadingDocs || this.isTenthTwelth){
    window.scroll(0, 700);
    this.mainPage = false;
    this.intCandQualDetIdForBGV=selectedEdu.intCandQualDetId	
    this.txtQualCodeForBGV=selectedEdu.txtQualificationCode    
    this.txtEducationLevel=selectedEdu.EduLevel
    if(this.lateral){
    this.flgtrynodegree ='N';
    this.flgConsolidated='N';
    this.flgProv='N';
    this.flgMarksheetSemWise='N'
    this.flgMarksheetyearwise='N'
    this.flgDegreeSemWise='N'
    this.flgDegreeyearwise='N'
    this.flgProvSemWise='N'
    this.flgProvyearwise='N'
    this.flgValidDegreeCertificateSemWise=false	
    this.flgNoDegreeCertificateSemWise=false	
    this.flgValidDegreeCertificateyearwise=false	
    this.flgNoDegreeCertificateyearwise=false	
    this.flgValidProvisionalSemWise=false	
    this.flgNoProvisionalyearwise=false
    this.flgValidProvisionalyearwise=false
    this.flgNoProvisionalSemWise=false	
    this.flgValidSemMarksheets=false	
    this.flgNoSemMarksheets=false	
    this.flgValidyearMarksheets=false	
    this.flgNoyearMarksheets=false	
    this.flgValidDegreeCertificate=false	
    this.flgValidProvisional=false	
    this.flgValidConsolidated=false	
    this.flgNoConsolidated=false	
    this.flgNoProvisional=false	
    this.flgNoDegreeCertificate=false	
    }
    this.getAttachments(selectedEdu.intCandQualDetId);
    if(this.lateral){
      this.getBGVDetails();
    }
    if (selectedEdu.txtQualificationCode == "0424" || selectedEdu.txtQualificationCode == "0425" || selectedEdu.txtQualificationCode == "424" || selectedEdu.txtQualificationCode == "425") {
      this.isTenthTwelth = true;
    }
    else {
      this.isTenthTwelth = false;
    }

     //Diploma showcase
    
    if(selectedEdu.txtDegreeCode == "02"){
        this.dipshow=false;
        this.semhide=true;
    }
     else {
      this.dipshow=true;
  
    }

    // PG showcase
    
     if(selectedEdu.txtDegreeCode == "06"){
      this.pgshow=false;
  }
   else {
      this.pgshow=true;

  }
    } else{
      this.alertEducationIN.error("Click on the update button and upload the documents.",this.mobile);
    } 
  }

  showMainPage() {
     this.spinnerEducationIN.show();
    this.mainPage = true;
    this.educationDetail = true;
    this.ngOnInit();
     this.spinnerEducationIN.hide();
  }
  editSemesterMarks(markId: string) {
    this.editSemMarks = true;
  }

  saveEditedSemesterMarks(selectedEdu: BasicDetailsFreshers, marks: MarksDetails, i: number) {
    if(marks.intMarksObtained > marks.intTotalMarks){
      this.alertEducationIN.error("Marks Obtained should be less than Maximum Marks",this.mobile);
      this.hideme[i] = !this.hideme[i];
     }else{
    if(marks.intMarksObtained==null || marks.intTotalMarks==null){
      // this.alertEducationIN.error("Please enter obtained and maximum marks.", this.mobile);
      return;
    }
    let lst1 = String(marks.intMarksObtained);
    let lst2 = String(marks.intTotalMarks);
    let lst3 = String(marks.intQualMarkId)
    
    
   
    // this.selectedSemester = marks.txtMarksType.trim()[0] + marks.txtMarksType.trim().substr((marks.txtMarksType.trim().length) - 1)
    if(marks.txtMarksType.trim().substr((marks.txtMarksType.trim().length) - 2)[0]=='r'){
      this.selectedSemester = marks.txtMarksType.trim()[0] + marks.txtMarksType.trim().substr((marks.txtMarksType.trim().length) - 1)
    }
    else{
      this.selectedSemester = marks.txtMarksType.trim()[0] + marks.txtMarksType.trim().substr((marks.txtMarksType.trim().length) - 2)
    }
    let education = new Education();
    education.CandidateId = Number(localStorage.getItem("intCandidateId"));
    education.intCandQualDetId = selectedEdu.intCandQualDetId;
    education.modifiedBy = String(localStorage.getItem("intCandidateId"));
    education.txtDegreeCode = selectedEdu.txtDegreeCode;
    education.intSemCount = selectedEdu.intSemCount;
    education.txtYearOrSemester = selectedEdu.txtYearOrSemester;
    if (this.percentageChecked) {
      education.scoreType = "PERCENTAGE";
    }
    else {
      education.scoreType = "CGPA";
    }
    for (var ar of this.markDetails) {
      if (ar.txtMarksType.trim().toUpperCase() == 'TOT' && selectedEdu.txtDegreeCode == ar.txtDegreeCode && !this.isTenthTwelth) {
        this.semesterTOTintQualMarkId = ar.intQualMarkId;
      }
    }

    for (var arr of this.markDetails) {
      if (arr.txtMarksType.trim().toUpperCase() != 'TOT' && arr.intQualMarkId == marks.intQualMarkId && !this.isTenthTwelth) {
        this.totalMarksObtained = this.totalMarksObtained - (arr.intMarksObtained as number);
        this.totalMaxMarks = this.totalMaxMarks - (arr.intTotalMarks as number);
      }
    }
    this.calculateTotalMarks(Number(lst1), Number(lst2));
    education.xmlDelete = this.deleteXMLDocument(marks.intQualMarkId);
    education.xmlUpdate = this.createXMLDocument(true, lst1, lst2, lst3);
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.UpdateFreshersEduDetails(education).subscribe(
      resEducationIN => {
        if (resEducationIN != null || resEducationIN != 0) {
           this.spinnerEducationIN.hide();
          this.alertEducationIN.success("Done! Semester details are updated.", this.mobile);
          this.hideme[i] = !this.hideme[i];
        }
      }
    )
    }
  }

  deleteSemseterMarks(selectedEdu: BasicDetailsFreshers, marks: MarksDetails) {
    let lst1 = String(marks.intMarksObtained);
    let lst2 = String(marks.intTotalMarks);
    let education = new Education();
    education.CandidateId = Number(localStorage.getItem("intCandidateId"));
    education.intCandQualDetId = selectedEdu.intCandQualDetId;
    education.modifiedBy = String(localStorage.getItem("intCandidateId"));
    education.txtDegreeCode = selectedEdu.txtDegreeCode;
    education.intSemCount = selectedEdu.intSemCount;
    education.txtYearOrSemester = selectedEdu.txtYearOrSemester;
    if (this.percentageChecked) {
      education.scoreType = "PERCENTAGE";
    }
    else {
      education.scoreType = "CGPA";
    }
    for (var ar of this.markDetails) {
      if (ar.txtMarksType.trim().toUpperCase() == 'TOT' && selectedEdu.txtDegreeCode == ar.txtDegreeCode && !this.isTenthTwelth) {
        this.semesterTOTintQualMarkId = ar.intQualMarkId;
      }
    }
    this.calculateTotalMarks(-Number(lst1), -Number(lst2));
    education.xmlDelete = this.deleteXMLDocument(marks.intQualMarkId);
    education.xmlUpdate = this.createXMLDocument(true, null, null, this.semesterTOTintQualMarkId);
     this.spinnerEducationIN.show();
    this._educationServiceEducationIN.UpdateFreshersEduDetails(education).subscribe(
      res => {
        if (res != null || res != 0) {
           this.spinnerEducationIN.hide();
          this.alertEducationIN.success("Done! Semester details are updated.", this.mobile);
          this.editSemMarks = false;
          this.getEducationDetails();
        }
      }
    )
  }

  addSemesterData(selectedEdu: BasicDetailsFreshers, form: NgForm) {

    if(form.value.XmarksObtained > form.value.XmaxMarks){
      this.alertEducationIN.error("Marks Obtained should be less than Maximum Marks",this.mobile);
    }else{
    
    var isAlreadyPresent = false;
    var paramstosent = new Education();
    if (form.value.Xsem.trim().toLowerCase() == "select" || form.value.Xsem == null || form.value.Xsem == "") {
      this.alertEducationIN.error("Please select Semester/Year to add.", this.mobile)
    }
    else if(form.invalid){
      form.controls['XmarksObtained'].markAsDirty();
      form.controls['XmaxMarks'].markAsDirty()
    }
    else {
      for (var arr of this.semesterListOfMarks) {
        if (arr.txtMarksType.trim() == form.value.Xsem.trim()) {
          isAlreadyPresent = true;
          break;
        }
      }
      if (!isAlreadyPresent) {
        if (this.validateMarks(form.value.XmarksObtained, form.value.XmaxMarks)) {
          this.calculateTotalMarks(Number(form.value.XmarksObtained), Number(form.value.XmaxMarks))
          for (var ar of this.markDetails) {
            if (ar.txtMarksType.trim().toUpperCase() == 'TOT' && selectedEdu.txtDegreeCode == ar.txtDegreeCode && !this.isTenthTwelth) {
              this.semesterTOTintQualMarkId = ar.intQualMarkId;
            }
          }
          if(form.value.Xsem.trim().substr((form.value.Xsem.trim().length) - 2)[0]=='r'){
            this.selectedSemester = form.value.Xsem.trim()[0] + form.value.Xsem.trim().substr((form.value.Xsem.trim().length) - 1)
          }
          else{
            this.selectedSemester = form.value.Xsem.trim()[0] + form.value.Xsem.trim().substr((form.value.Xsem.trim().length) - 2)
          }
          
          paramstosent.CandidateId = Number(localStorage.getItem("intCandidateId"));
          paramstosent.intCandQualDetId = selectedEdu.intCandQualDetId;
          paramstosent.modifiedBy = String(localStorage.getItem("intCandidateId"));
          paramstosent.txtDegreeCode = null ? " " : selectedEdu.txtDegreeCode;
          paramstosent.intSemCount = this.semCount;
          paramstosent.txtYearOrSemester = this.yearOrSemester;
          if (this.percentageChecked) {
            paramstosent.scoreType = "PERCENTAGE";
          }
          else {
            paramstosent.scoreType = "CGPA";
          }
          paramstosent.xmlDelete = "<Qualification />";
          paramstosent.xmlUpdate = this.createXMLDocument(true, form.value.XmarksObtained, form.value.XmaxMarks, "0");
           this.spinnerEducationIN.show();
          this._educationServiceEducationIN.UpdateFreshersEduDetails(paramstosent).subscribe(
            resEducationIN => {
              if (resEducationIN != null || resEducationIN != 0) {
                 this.spinnerEducationIN.hide();
                this.alertEducationIN.success("Done! Semester details are added.", this.mobile);
                this.editSemMarks = false;
                this.getEducationDetails();
              }
            }
          )
        }
      }
      else {
        this.alertEducationIN.error("This semester is already present.", this.mobile);
      }
     }  
    }
  }
  cancelSemesterDetails(id: string) {
    this.editSemMarks = false;
  }
  CallJsForModalFade() {
    if (this.order != "N" && this.order != "P") {
      LoadStartStep1Modal1();
      LoadJsForModalBackDrop();
    }
    else {
      this.navigate();
    }
  }

  CallJsForSubmitDataModal() {

    LoadDataSubmitModal();
    LoadJsForModalBackDrop();

  }

  selectedType(val: string) {
    // this.flgtrynodegree ='N';
    // this.flgConsolidated='N';
    // this.flgProv='N';
    // this.flgMarksheetSemWise='N'
    // this.flgDegreeSemWise='N'
    // this.flgProvSemWise='N'
    if(this.lateral){
    // this.flgValidDegreeCertificateSemWise=false	
    // this.flgNoDegreeCertificateSemWise=false	
    // this.flgValidProvisionalSemWise=false	
    // this.flgNoProvisionalSemWise=false	
    // this.flgValidSemMarksheets=false	
    // this.flgNoSemMarksheets=false	
    // this.flgValidDegreeCertificate=false	
    // this.flgValidProvisional=false	
    // this.flgValidConsolidated=false	
    // this.flgNoConsolidated=false	
    // this.flgNoProvisional=false	
    // this.flgNoDegreeCertificate=false	
    }
    if(this.lateral){
    this.getBGVDetails();
    }
    if (val == "S") {
      this.semWise = true;
      this.consMark = false;
      this.yearwise=false;
      if(this.lateral){
        this.getBGVDetails();
        }
    }
    else if(val == "C")  {
      this.semWise = false;
      this.consMark = true;
      this.yearwise=false;
      if(this.lateral){
        this.getBGVDetails();
        }

    }
    else if(val == "Y"){
      this.semWise = false;
      this.consMark = false;
      this.yearwise=true;
      if(this.lateral){
        this.getBGVDetails();
        }

    }
  }
  ForNoConsolidatedMarksheet(value){
    this.flgConsolidated=value
    if (this.flgConsolidated=="no3"){
      this.flgNoConsolidated=true
      this.flgValidConsolidated=false

    }


  }
  
  ForNoProvisionalCertificate(value){
    this.flgProv=value
    if (this.flgProv=="no2"){
      this.flgNoProvisional=true
      this.flgValidProvisional=false

    }
 

  }
  
  ForNoDegreeCertificate(value){
    this.flgtrynodegree=value
    if (this.flgtrynodegree=="no"){
      this.flgNoDegreeCertificate=true
      this.flgValidDegreeCertificate=false

    }
  }
  ForValidProvisionalCertificate(value){
    this.flgProv=value
    if (this.flgProv=="yes2"){
      this.flgValidProvisional=true
      this.flgNoProvisional=false
    }


  }
  ForValidConsolidatedMarksheet(value){
    this.flgConsolidated=value
    if (this.flgConsolidated=="yes3"){
      this.flgValidConsolidated=true
      this.flgNoConsolidated=false
    }

  }
  ForValidDegreeCertificate(value){
    this.flgtrynodegree=value
    if (this.flgtrynodegree=="yes"){
      this.flgValidDegreeCertificate=true
      this.flgNoDegreeCertificate=false
    }
  }


//Sem Wise 

ForNoSemMarksheetSemWise(value){
  this.flgMarksheetSemWise=value
  if (this.flgMarksheetSemWise=="no3"){
    this.flgValidSemMarksheets=false
    this.flgNoSemMarksheets=true
  }

}
ForNoSemMarksheetyearwise(value){
  this.flgMarksheetyearwise=value
  if (this.flgMarksheetyearwise=="no4"){
    this.flgValidyearMarksheets=false
    this.flgNoyearMarksheets=true
  }

}

ForNoProvisionalCertificateSemWise(value){
  this.flgProvSemWise=value
  if (this.flgProvSemWise=="no2"){
    this.flgValidProvisionalSemWise=false
    this.flgNoProvisionalSemWise=true
  }


}
ForNoProvisionalCertificateyearwise(value){
  this.flgProvyearwise=value
  if (this.flgProvyearwise=="no2"){
    this.flgValidProvisionalyearwise=false
    this.flgNoProvisionalyearwise=true
  }


}

ForNoDegreeCertificateSemWise(value){
  this.flgDegreeSemWise=value
  if (this.flgDegreeSemWise=="no"){
    this.flgValidDegreeCertificateSemWise=false
    this.flgNoDegreeCertificateSemWise=true
  }
}

ForNoDegreeCertificateyearwise(value){
  this.flgDegreeyearwise=value
  if (this.flgDegreeyearwise=="no"){
    this.flgValidDegreeCertificateyearwise=false
    this.flgNoDegreeCertificateyearwise=true
  }
}

ForValidProvisionalCertificateSemWise(value){
  this.flgProvSemWise=value
  if (this.flgProvSemWise=="yes2"){
    this.flgValidProvisionalSemWise=true
    this.flgNoProvisionalSemWise=false
  }


}

ForValidProvisionalCertificateyearwise(value){
  this.flgProvyearwise=value
  if (this.flgProvyearwise=="yes2"){
    this.flgValidProvisionalyearwise=true
    this.flgNoProvisionalyearwise=false
  }


}

ForValidSemMarksheetSemWise(value){
  this.flgMarksheetSemWise=value
  if (this.flgMarksheetSemWise=="yes3"){
    this.flgValidSemMarksheets=true
    this.flgNoSemMarksheets=false
  }

}

ForValidSemMarksheetyearwise(value){
  this.flgMarksheetyearwise=value
  if (this.flgMarksheetyearwise=="yes4"){
    this.flgValidyearMarksheets=true
    this.flgNoyearMarksheets=false
  }

}
ForValidDegreeCertificateSemWise(value){
  this.flgDegreeSemWise=value
  if (this.flgDegreeSemWise=="yes"){
    this.flgValidDegreeCertificateSemWise=true
    this.flgNoDegreeCertificateSemWise=false
  }
}

ForValidDegreeCertificateyearwise(value){
  this.flgDegreeyearwise=value
  if (this.flgDegreeyearwise=="yes"){
    this.flgValidDegreeCertificateyearwise=true
    this.flgNoDegreeCertificateyearwise=false
  }
}
  getBGVDetails() {
   this.spinnerEducationIN.show();
  let bgvdetails = new BGVDetails();
  bgvdetails.intCandQualDetId=this.intCandQualDetIdForBGV
  bgvdetails.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
  this._educationServiceEducationIN.GetBGVDetails(bgvdetails)
    .subscribe(
      res => {
        if (res != null) {
          if(res.length>0){
          for (let ele of res){
          if(ele.txtQuestionCode.trim()=="CONSDEGREE"){
            this.txtReasonConsolidated1 = ele.txtReasonCons;
            this.dtTent1=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            this.docDeclaration1=ele.flgDeclaration;
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoDegreeCertificate("no");
            }
          }
          if(ele.txtQuestionCode.trim()=="CONSPROV"){
          this.txtReasonConsolidated2 = ele.txtReasonCons;
          this.dtTent2=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
          if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
            this.ForNoProvisionalCertificate("no2");
          }
          }
          if(ele.txtQuestionCode.trim()=="CONSCERT"){
            this.txtReasonConsolidated3 = ele.txtReasonCons;
            this.dtTent3=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            this.docDeclaration3=ele.flgDeclaration
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoConsolidatedMarksheet("no3");
            }
          }
          if(ele.txtQuestionCode.trim()=="SEMDEGREE"){
            this.txtReasonSem1 = ele.txtReasonCons;
            this.dtTent4=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            this.docDeclaration4=ele.flgDeclaration
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoDegreeCertificateSemWise("no");
            }
          }
          if(ele.txtQuestionCode.trim()=="SEMPROV"){
            this.txtReasonSem2 = ele.txtReasonCons;
            this.dtTent5=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoProvisionalCertificateSemWise("no2");
            }
          }
          if(ele.txtQuestionCode.trim()=="SEMMARK"){
            this.txtReasonSem3 = ele.txtReasonCons;
            this.dtTent6=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            this.docDeclaration6=ele.flgDeclaration;
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoSemMarksheetSemWise("no3");
            }
          }
          if(ele.txtQuestionCode.trim()=="YEARDEGREE"){
            this.txtReasonyear1 = ele.txtReasonCons;
            this.dtTent7=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            this.docDeclaration5=ele.flgDeclaration
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoDegreeCertificateyearwise("no");
            }
          }
          if(ele.txtQuestionCode.trim()=="YEARPROV"){
            this.txtReasonyear2 = ele.txtReasonCons;
            this.dtTent8=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoProvisionalCertificateyearwise("no2");
            }
          }
          if(ele.txtQuestionCode.trim()=="YEARMARK"){
            this.txtReasonyear3 = ele.txtReasonCons;
            this.dtTent9=ele.dtTentative == null || ele.dtTentative == undefined ? null : new Date(ele.dtTentative);
            this.docDeclaration7=ele.flgDeclaration;
            if(ele.txtReasonCons!="" && ele.txtReasonCons!=null){
              this.ForNoSemMarksheetyearwise("no4");
            }
          }
         
             this.spinnerEducationIN.hide();
        }
      }
      else{
         this.spinnerEducationIN.hide();
        this.txtReasonConsolidated1=""
        this.txtReasonConsolidated2=""
        this.txtReasonConsolidated3=""
        this.txtReasonSem1=""
        this.txtReasonSem2=""
        this.txtReasonSem3=""
        this.txtReasonyear1=""
        this.txtReasonyear2=""
        this.txtReasonyear3=""
        this.dtTent1=this.today
        this.dtTent2=this.today
        this.dtTent3=this.today
        this.dtTent4=this.today
        this.dtTent5=this.today
        this.dtTent6=this.today
        this.dtTent7=this.today
        this.dtTent8=this.today
        this.dtTent9=this.today


      }
      }
        else {
           this.spinnerEducationIN.hide();
        }
      }
    )
}



AddAnswersforBGV(form:NgForm,txtIdentifier){
   this.spinnerEducationIN.show();
  let bgvdetails = new BGVDetails();
  bgvdetails.txtDataType='EDUCATION'
  // bgvdetails.txtQualificationCode=this.txtQualCodeForBGV
  bgvdetails.intCandQualDetId=this.intCandQualDetIdForBGV
  bgvdetails.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
  bgvdetails.txtQualificationCode=this.txtEducationLevel
  if(form){
    bgvdetails.flgResponse='N';
    if(this.consMark){
      if (txtIdentifier=="CONSDEGREE"){
        bgvdetails.txtQuestionCode='CONSDEGREE'
        bgvdetails.txtReasonCons = form.value.nopassportreasondeg
        if(this.docDeclaration1=='Y'){
          bgvdetails.dtTentative= new Date((new Date(form.value.endDeg1)).getFullYear(), (new Date(form.value.endDeg1)).getMonth(), (new Date(form.value.endDeg1)).getDate(), 9, 0, 0);
        }
        else{
          bgvdetails.dtTentative=new Date("1970-01-31T03:30:00");
        }
        bgvdetails.flgDeclaration=this.docDeclaration1
      
    }
      else if (txtIdentifier=="CONSPROV"){
        bgvdetails.txtQuestionCode='CONSPROV'
        bgvdetails.txtReasonCons=form.value.noreasonprov
        bgvdetails.dtTentative= new Date((new Date(form.value.endprov)).getFullYear(), (new Date(form.value.endprov)).getMonth(), (new Date(form.value.endprov)).getDate(), 9, 0, 0);
  
  
    }
    else if (txtIdentifier=="CONSCERT"){
        bgvdetails.txtQuestionCode='CONSCERT'
        bgvdetails.txtReasonCons=form.value.noreasoncons
        if(this.docDeclaration3=='Y'){
        bgvdetails.dtTentative= new Date((new Date(form.value.endCons1)).getFullYear(), (new Date(form.value.endCons1)).getMonth(), (new Date(form.value.endCons1)).getDate(), 9, 0, 0);
  
        }
        else{
          bgvdetails.dtTentative=new Date("1970-01-31T03:30:00");
        }
        bgvdetails.flgDeclaration=this.docDeclaration3
  
    }
  
    }
    else if(this.semWise){
      
       if (txtIdentifier=="SEMDEGREE"){
        bgvdetails.txtQuestionCode='SEMDEGREE'
        bgvdetails.txtReasonCons=form.value.noreasondegreesem
        if(this.docDeclaration4=='Y'){
        bgvdetails.dtTentative= new Date((new Date(form.value.endDegSem)).getFullYear(), (new Date(form.value.endDegSem)).getMonth(), (new Date(form.value.endDegSem)).getDate(), 9, 0, 0);
  
        }
        else{
          bgvdetails.dtTentative=new Date("1970-01-31T03:30:00");
        }
        bgvdetails.flgDeclaration=this.docDeclaration4
      }
      else if(txtIdentifier=="SEMPROV"){
  
        bgvdetails.txtQuestionCode='SEMPROV'
        bgvdetails.txtReasonCons=form.value.noprovsem
        bgvdetails.dtTentative= new Date((new Date(form.value.endProvSem)).getFullYear(), (new Date(form.value.endProvSem)).getMonth(), (new Date(form.value.endProvSem)).getDate(), 9, 0, 0);
      }
     else if (txtIdentifier=="SEMMARK"){
        bgvdetails.txtQuestionCode='SEMMARK'
        bgvdetails.txtReasonCons=form.value.noreasonSemMarksheet
        if(this.docDeclaration6=='Y'){
        bgvdetails.dtTentative= new Date((new Date(form.value.endSemMark)).getFullYear(), (new Date(form.value.endSemMark)).getMonth(), (new Date(form.value.endSemMark)).getDate(), 9, 0, 0);
  
        }
        else{
          bgvdetails.dtTentative=new Date("1970-01-31T03:30:00");
        }
        bgvdetails.flgDeclaration=this.docDeclaration6
      }
    }
    else if(this.yearwise){
      
      if (txtIdentifier=="YEARDEGREE"){
       bgvdetails.txtQuestionCode='YEARDEGREE'
       bgvdetails.txtReasonCons=form.value.noreasondegreeyear
       if(this.docDeclaration5=='Y'){
       bgvdetails.dtTentative= new Date((new Date(form.value.endDegyear)).getFullYear(), (new Date(form.value.endDegyear)).getMonth(), (new Date(form.value.endDegyear)).getDate(), 9, 0, 0);
 
       }
       else{
         bgvdetails.dtTentative=new Date("1970-01-31T03:30:00");
       }
       bgvdetails.flgDeclaration=this.docDeclaration5
     }
     else if(txtIdentifier=="YEARPROV"){
 
       bgvdetails.txtQuestionCode='YEARPROV'
       bgvdetails.txtReasonCons=form.value.noprovyear
       bgvdetails.dtTentative= new Date((new Date(form.value.endProvyear)).getFullYear(), (new Date(form.value.endProvyear)).getMonth(), (new Date(form.value.endProvyear)).getDate(), 9, 0, 0);
     }
    else if (txtIdentifier=="YEARMARK"){
       bgvdetails.txtQuestionCode='YEARMARK'
       bgvdetails.txtReasonCons=form.value.noreasonyearMarksheet
       if(this.docDeclaration7=='Y'){
       bgvdetails.dtTentative= new Date((new Date(form.value.endyearMark)).getFullYear(), (new Date(form.value.endyearMark)).getMonth(), (new Date(form.value.endyearMark)).getDate(), 9, 0, 0);
 
       }
       else{
         bgvdetails.dtTentative=new Date("1970-01-31T03:30:00");
       }
       bgvdetails.flgDeclaration=this.docDeclaration7
     }
   }
  }
  else{
    bgvdetails.flgResponse='Y';
    bgvdetails.dtTentative=new Date("1970-01-31T03:30:00");
    bgvdetails.txtQuestionCode=txtIdentifier;
  }
  
  

 
  this._educationServiceEducationIN.AddBGVDetails(bgvdetails)
  .subscribe(
    res=>{
      this.alertEducationIN.success("Done! Details are updated successfully.",this.mobile);
      if(form){form.resetForm();}
      this.getBGVDetails();
    }
  );
}
  navigatetonext() {
    if (this.order != "N") {
      if (this.order == "P" && this.mobile) {
        LoadOwlCarouselForGallery();
        LoadJsForDashBoardMobileCarousel();
        this.getPendingAction();
        this.appServiceEducationIN.setHiddenDashBoard(false);
        this.routerEducationIN.navigateByUrl('lpau/step1-au/dummy', { skipLocationChange: true });
        setTimeout(() => this.routerEducationIN.navigate(['../lpindia']),3000);
        setTimeout(() => ShowBottomBar(),3000);
      }else if (this.order == "W" ) {
        StartModalClose1();
        this.routerEducationIN.navigate(['../lpindia/postjoining/education'],{ queryParams: { order: "W" } })
      }
      else if (this.order == "W" && !this.mobile) {
        StartModalClose1();
        this.routerEducationIN.navigate(['../lpindia/postjoining/education'],{ queryParams: { order: "W" } })
      }
      else {
        if (this.lateral) {
          StartModalClose1();
          ChgwrkexIN();
          this.routerEducationIN.navigate(['../workexperience'], { relativeTo: this.rEducationIN });
        }
        else {
          StartModalClose1();
          ChgachieveIN();
          this.routerEducationIN.navigate(['../achievement'], { relativeTo: this.rEducationIN });
        }
      }
    }
    else {
      StartModalClose1();
      this.routerEducationIN.navigate(['notification']);
    }
  }
  getPendingAction() {
    let params = new IndiaInputParam();
    params.CandidateId = parseInt(localStorage.getItem("intCandidateId"));
    params.EmailId = localStorage.getItem("txtEmailId");
    this._postServiceEducationIN.getPendingActions(params)
      .subscribe(
        resEducationIN => {
          if (resEducationIN != null) {
            LoadStyleForIconsIndia(resEducationIN .ImageUpload, resEducationIN .Emergency, resEducationIN .BANK, resEducationIN .BasicDetails, resEducationIN .FamilyDetails,
              resEducationIN .ContactDetails, resEducationIN .EducationDetails, resEducationIN .ExperienceDetails, resEducationIN .PassportDetails, resEducationIN .GapDetails,
              resEducationIN .Disabilitydetails, resEducationIN .NSRDetails, resEducationIN .Pandetails, resEducationIN .AadharDetails, resEducationIN .Accommodation,
              resEducationIN .Medical, resEducationIN .Achievement, resEducationIN .NatsDetails, resEducationIN .Statutory);
          }
        });
  }

  carouselOptions = {
    margin: 25,
    nav: true,
    navText: ["<div class='nav-btn prev-slide'></div>", "<div class='nav-btn next-slide'></div>"],
    responsiveClass: true,
    responsive: {
      0: {
        items: 1,
        nav: true
      },
      600: {
        items: 1,
        nav: true
      },
      1000: {
        items: 3,
        nav: true,
        loop: false,
        dots: false,
        autoWidth: false
      },
      1500: {
        items: 6,
        nav: true,
        loop: false,
        dots: false
      }
    }
  }
}
declare function logoutdialog(): any;
@Component({
  selector: 'dialog-Logout',
  templateUrl: 'dialog-Logout.html',
})
export class DialogLogoutEducation {
  mobile: boolean;
  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DialogLogoutEducation>, private authService: AuthService, private router: Router, private Appservice: AppService) {

    this.mobile = this.Appservice.mobile;
    if (!this.mobile) { logoutdialog() }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }

}
