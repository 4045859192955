export class BankDetails{
    SerialNo : number;
    Priority: number;
    AccountHolderName : string;
    BankAccountNumber : string;
    //RaccountNumber: string;
    BankCode:string;
    BranchCode:string;
    AccountType: string;
    CurrencyCode:string;
    RoutingNumber: string;
    EmailId:string;
    CandidateId:number;
    Amount: number;
    
    
       
}