export class EmpAddress {
    CandidateID:number; 
    CareOfName:String; 
    ResidingFrom:String;
    StreetDetails:String;
    SuiteDetails:String;        
    AreaDetails:String;      
    City:String;        
    ZipCode:String;      
    PhoneNo:String;
    CellPhone:String;
    PagerNo:String;      
    Country:String;      
    State:String;
    AddressType:String;
    EmailID:String;  
    CountryCode:String;  
    StateCode:String;  
    CityCode:String;   
  
}

export class Country{

    public CountryCode: String ;
    public CountryName :String;
}

export class State{
    StateName: String ; 
    StateCode: String ;
    CountryCode: String ;
   
}

export class GetUserAddress{
    EmailID:string;
    AddressType:string;
    CandidateId:number;
}

export class UserCredentials
{
     UserName :string;
     Password :string;
     CandidateID :Number;
     RequestType :string;
     AddressType :string;
}

export class UserCredentials1
{
     UserName :string;
     Password :string;
     CandidateID :Number;
     RequestType :string;
     AddressType :string;
     infyDirectoryConsent:string;
}

export class CityOnSate{

    CityCode: String ;
    CityName: String ;
    StateName: String ;
    StateCode: String ;
}

export class RegionCountry{

    public regionCountry: String ;
    
}
