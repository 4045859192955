import { HttpErrorResponse,HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpHeaders, HttpClient, HttpBackend } from '@angular/common/http';
//import { Observable } from 'rxjs/Observable';
//import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { retry, catchError } from 'rxjs/operators';

@Injectable()
export class LPUKInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //console.log('Intercepted!', req);
    const authToken = localStorage.getItem("Token") || '';
    const authReq = req.clone({
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Bearer '+ authToken
      })
    });
    // const authReq = req.clone({
    //   headers: new HttpHeaders({
    //     'Content-Type':  'application/json',
    //     'Authorization': 'Bearer eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJ2ZVJsMTJjU0hjQ1NDNTFGVkJSNnlrUWhmNDc2WDUtNnp4ZWRjeVMteUNZIn0.eyJqdGkiOiI0YjdjZjg5OC01NzU0LTRlMTgtOWFkNy1lMzdmZThhYWIzYjgiLCJleHAiOjE1NjA0OTMxMjMsIm5iZiI6MCwiaWF0IjoxNTU5MTk3MTIzLCJpc3MiOiJodHRwczovL2luZnl0cS1kZXYuaW5mb3N5c2FwcHMuY29tL2F1dGgxL3JlYWxtcy9sZXhjYW1wdXMiLCJhdWQiOiJwb3J0YWwiLCJzdWIiOiJkMTZjMzcxMC0zMjBjLTQ3NjEtOWU2Yy1jMjM4Y2E0M2MwYTEiLCJ0eXAiOiJJRCIsImF6cCI6InBvcnRhbCIsIm5vbmNlIjoiNDBlZjczNzMtYWEwNy00YWEwLThiYTAtYjA3ZmE3OTA3YzM4IiwiYXV0aF90aW1lIjoxNTU5MTk3MTIyLCJzZXNzaW9uX3N0YXRlIjoiYTM0MzU3OTAtYWE2Yi00MjlmLTg2NDAtYmNhNDkyNjZhODVjIiwiYWNyIjoiMSIsIm5hbWUiOiJBYWRpdHlhIEJhbnNhbCIsInByZWZlcnJlZF91c2VybmFtZSI6ImFkaXR5YUBpbmZvc3lzLmNvbSIsImdpdmVuX25hbWUiOiJBYWRpdHlhIiwiZmFtaWx5X25hbWUiOiJCYW5zYWwiLCJlbWFpbCI6ImFkaXR5YUBpbmZvc3lzLmNvbSJ9.LffMdnXdzhoR27o4X7_y6XH2oKiiyvqFgCxD4T51pjP76NCTRjXLhjvE7S4f9lpSBh3IL6nM5Q5vnReMIJpKLsJ22EUQLVttv-YM-rzcPUBTBvKMZdpqpsa5txx4dRnw7B7q7D6KO5UAxNG35Gx-Okld6Fco7zhziGLyJRhQnhzM-XD4TiEVD_85G26xWtNRDm6DK6KO34i6CpjvAMeCFNTVHCt7YTC3Izyy5dEtNgrZoPAhhox3wC6dDoP_8SsRKqTg3AwqVTp56NJ22GOZJvo0Wh1nlaIv8s0ZMq1A9uXucJvbEp30WzbYjHgfenI0y9Uyi5S6cDA82r8Wa03f_Q'
    //   })
    // });
    return next.handle(authReq).pipe(
      retry(1),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          // refresh token
          //console.log("Inside custom error "+error.message);
          return throwError("Not Authorized");
        } else {
          //console.log("Inside custom error "+error.message);
          // if (error.status === 404){
          //   return throwError("Not found");
          // }
          return throwError(error);
        }
      })
    ); 
  }
}

export class HttpInterceptorHandler implements HttpHandler {
  constructor(private next: HttpHandler, private interceptor: HttpInterceptor) {}

  handle(req: HttpRequest<any>): Observable<HttpEvent<any>> {
      // execute an interceptor and pass the reference to the next handler
      return this.interceptor.intercept(req, this.next);
  }
}

@Injectable()
export class CustomUKHttpClient extends HttpClient {
  constructor(handler: HttpBackend, interceptors: HttpInterceptor[]) {
    const chain = interceptors.reduceRight(
      (next, interceptor) => new HttpInterceptorHandler(next, interceptor), handler);

    super(chain);
  }
}


@Injectable()
export class LPUKCustomHttpClient extends CustomUKHttpClient {
  constructor(handler: HttpBackend) {
    const interceptors: HttpInterceptor[] =[new LPUKInterceptor()];
    super(handler, interceptors);
  }
}