import { Component, HostListener, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { AppService } from '../app.service';
import { LPMainComponentService } from './lpmain.service';
import { GetStepOneDetails, StepOneFlow } from './Model-Classes/GetStepOneDeatils';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AuthService } from '../login/auth.service';
import { DashboardMainService } from './dashboard/dashboard.service';

declare function LoadStyleForIconsGlobal(i:string, c:string, a:string, b:string, p:string, s:string,
  t:string, po:string, f:string, pp:string, cc:string, ki:string, resip: string, contdoc: string, taxF: string, nric: string, hol: string, ben: string,cfn:string,medical:string,disability:string)   
  declare function LoadOwlCarouselForMenu_Glo_Mobile() : any;
  declare function LoadOwlCarousel():any;
  declare function LoadJsForDashBoardMobileCarousel():any;
  
@Component({
  selector: 'app-lpmain',
  templateUrl: './lpmain.component.html',
  styleUrls: ['./lpmain.component.css']
})
export class LpmainComponent implements OnInit {
  year=localStorage.getItem("footeryear");
  mobile:boolean;
  postjoining:Boolean=Number(localStorage.getItem("DaysLeft"))<=0;
  prejoining:Boolean=Number(localStorage.getItem("DaysLeft"))>0;
  idbadge:Boolean=false;
  emergency:Boolean=false;
  address:Boolean=false;
  bank:Boolean=false;
  personal:Boolean=false;
  ssn:Boolean=false;
  tax: boolean=false;
  pendingpolicy: boolean=false;
  passport: boolean=false;
  family: boolean=false;
  corpcard: boolean=false;
  kiwisaver: boolean=false;
  resiPermit: boolean = false;
  contractDoc: boolean = false;
  taxField: boolean = false;
  NRIC: boolean = false;
  holiday: boolean = false;
  insurance: boolean = false;
  forAction:Boolean = false;
  medical: boolean =false;
 disability:boolean= false;
  constructor(private appService:AppService,private _Service: DashboardMainService,
    private mainService:LPMainComponentService,
    private breakpointObserver: BreakpointObserver,private router:Router,private authService:AuthService,
    private spinner:NgxSpinnerService ) { }

  ngOnInit() {
    this.mobile=this.appService.mobile;

    this.breakpointObserver
    .observe(['(max-width: 599px)'])
    .subscribe((state: BreakpointState) => {
      if (state.matches) {
        this.appService.setIsMobile(true);
        this.mobile = true;
      } else {
        this.appService.setIsMobile(false);
        this.mobile = false;
          // this.toNavigateLPMain();
      }
      this.getStepOneFlow();
    });

    // this.getStepOneFlow();
    this.authService.GetHeadingsForGeo(localStorage.getItem("CandidateID")).subscribe((res) =>{
      // localStorage.setItem("HeadingList",res.toString())
      AuthService.HeadingList=res;
    });
    this.authService.GetLPDocNotesForAllGeos(localStorage.getItem("CandidateID")).subscribe((res) =>{
      // localStorage.setItem("DocsNotesList",res.toString())
      // AuthService.DocsNotesList=res;
    });
    // this._Service.getPendingActionsGlobal().subscribe(
    //   (res)=>{
    
    //     if(res.ImageUpload=="N")
    //     {
    //       this.idbadge=true;
    //     }
    //     if(res.Emergency=="N")
    //     {
    //       this.emergency=true;
    //     }
    //     if(res.Address=="N" || res.EmergencyAddress == "N")         
    //     {            
    //       this.address=true;
    //       res.Address = "N";           
    //     }
    //     if(res.BANK=="N")
    //     {
    //       this.bank=true;
    //     }
    //     if(res.Personal=="N")
    //     {
    //       this.personal=true;
    //     }
    //     if(res.SSN=="N")
    //     {
    //       this.ssn=true;
    //     }
    //     if(res.Tax=="N")
    //     {
    //       this.tax=true;
    //     }
    //     if(res.Policies == "N")
    //     {
    //       this.pendingpolicy = true;
    //     }
        
    //     if(res.Passport == "N")
    //     {
    //       this.passport = true;
    //     }

    //     if(res.Family == "N")
    //     {
    //       this.family = true;
    //     }
    //     if(localStorage.getItem("Region").trim().toUpperCase() == "CANADA"){
    //       if(res.Insurance == "N")
    //       {
    //         this.insurance = true;
    //       }
    //     }  
    //     if(res.CorpCard == "N")
    //     {
    //       this.corpcard = true;
    //     }
    //     if(res.KiwiSaver == "N")
    //     {
    //       this.kiwisaver = true;
    //     }      
    //     if(res.ResiPermit == 'N'){
    //       this.resiPermit = true;
    //     }

    //     if(res.ContractDoc == 'N'){
    //       this.contractDoc = true;
    //     }
    //     if((localStorage.getItem("Region").trim().toUpperCase() != "JERSEY") && (localStorage.getItem("Region").trim().toUpperCase() != "GUERNSEY")){
    //     if(res.TaxField == 'N'){
    //       this.taxField = true;
    //     }
    //   }
    //     if(res.NRIC =='N'){
    //       this.NRIC = true;
    //     }

    //     if(res.HolidayAttest =='N'){
    //       this.holiday = true;
    //     }
    //     if (this.idbadge == false &&  this.emergency == false &&  this.address == false &&  this.bank == false &&
    //       this.personal == false &&  this.ssn == false &&  this.tax == false &&  this.pendingpolicy == false &&  this.passport == false &&  this.family == false &&  this.corpcard == false&& 
    //       this.kiwisaver == false && this.insurance == false &&  this.resiPermit == false && this.contractDoc == false && this.taxField == false &&  this.NRIC == false &&  this.holiday == false) {
    //       this.forAction = true;
    //       return;
    //     }

    //     LoadStyleForIconsGlobal(res.ImageUpload,res.Emergency,res.Address,res.BANK,res.Personal,res.SSN,
    //       res.Tax,res.Policies,res.Family,res.Passport,res.CorpCard,res.KiwiSaver,res.ResiPermit, res.ContractDoc,
    //       res.TaxField, res.NRIC, res.HolidayAttest, res.Insurance);
        
    //     //need to check this.spinner.hide();
    //   }
     
    // )
    
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeunloadHandler(event) {
    if(!this.appService.mobile){      
      this.authService.logout();
            
    }    
  }
  getStepOneFlow()
  {
     this.spinner.show();
    let stepOne = new GetStepOneDetails();
    stepOne.candidateID = Number(localStorage.getItem("CandidateID"));
    stepOne.txtCompanyCode = localStorage.getItem("Region");
    stepOne.txtCountry = '';
    stepOne.txtJobLevel = localStorage.getItem("JobLevel");
    stepOne.flgPrePost = "PRE";
    
    if(this.mobile)
    {
    stepOne.flgMobile = "M";
    }
    else{
      stepOne.flgMobile = "D";
    }
    this.mainService.GetStepOneFlow(stepOne).subscribe(
      res=>{
        if(res!=null)
        {
          //console.log(res);
          if(!this.mobile){
          this.toNavigateLPMain();
        }
          this.mainService.stepOneIcons = res;
          if(stepOne.flgMobile = "M"){
          for(var step of this.mainService.stepOneIcons)
          {
            step.iconClass = "menu_icon "+ step.iconClass;
          }
        }
        }
         this.spinner.hide();
      }
    );

  }

  toNavigateLPMain(){

    
    var FlagTermsAndCondition=localStorage.getItem("FlagTermsAndCondition");
    var FlagPrivacy=localStorage.getItem("FlagPrivacy");
    var FlagFirstLogin=localStorage.getItem("FlagFirstLogin");
    var FlagPasswordChangedType=localStorage.getItem("FlagPasswordChangedType");
    var FlagForceSSN=localStorage.getItem("flgForceSSN");
    // if(FlagFirstLogin.toUpperCase() != "Y")
    //     {//need to check this.spinner.hide();
    //     this.router.navigate(["privacymain"],{queryParams:{"accept":"Y"}});
    //     }
        //  if(FlagTermsAndCondition.toUpperCase() != "Y")
        // {//need to check this.spinner.hide();
        // this.router.navigate(["privacymain"],{queryParams:{"accept":"Y"}});
        // }
         if(FlagPrivacy.toUpperCase() != "Y")
        { this.spinner.hide();
        this.router.navigate(["privacymain"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagPasswordChangedType.toUpperCase() != "U")
        { this.spinner.hide();
        this.router.navigate(["change"],{queryParams:{"accept":"Y"}});
        }
        else if(FlagForceSSN.toUpperCase() == "N" && (localStorage.getItem("Region").trim().toUpperCase() == "IBPOPR"))
          { this.spinner.hide();
            this.router.navigate(["ssn-main"],{queryParams:{"accept":"Y"}});
          }
        else
        { this.spinner.hide();
          if(this.mobile == false){
            //console.log("Inside not mobile");
            if(this.postjoining){
             //if(localStorage.getItem("IsComingFromLogin")=="Y"){
              //  this.router.navigate(['../lpmain/postjoining-common'])
                   // this.callPedningAction();
             if(localStorage.getItem("IsComingFromLogin")=="Y" && localStorage.getItem("Region").trim().toUpperCase() == "IBPOPR"){
              this.router.navigate(['lpmain/postjoining-common/badge-common'], { queryParams: { order: 'W' } });
            }
            else{
              this.callPedningAction();
             // }
            }
            }
            else if(this.prejoining){
              if(localStorage.getItem("IsComingFromLogin")=="Y"){
                localStorage.setItem("IsComingFromLogin","N")
                if(localStorage.getItem("Region").trim().toUpperCase() == "IL ROMANIA"){
                  this.router.navigate(['lpmain/step1-common/address-common']) 
                }
                else if(localStorage.getItem("Region").trim().toUpperCase() == "DENMARK" || localStorage.getItem("Region").trim().toUpperCase() == "BPM ROMANIA" ){
                  this.router.navigate(['lpmain/step1-common/address-common']) 
                }else if(localStorage.getItem("Region").trim().toUpperCase() == "SWEDEN"  || localStorage.getItem("Region").trim().toUpperCase() == "ILICOMPAZSINGAPORE"){
                  this.router.navigate(['lpmain/step1-common/personal-common'])
                }
                else if(localStorage.getItem("Region").trim().toUpperCase() == "CANADA" || localStorage.getItem("Region").trim().toUpperCase() == "IPS CANADA"){
                  this.router.navigate(['lpmain/step1-common/ssn-common'])
                }
                else if(localStorage.getItem("Region").trim().toUpperCase() == "BPM POLAND"){
                  this.router.navigate(['lpmain/step1-common/emergency-common'])
                }
                else{
                  this.router.navigate(['lpmain/step1-common/badge-common'])
                }
              }
              
            }
            
          }
        }
  }
  callPedningAction()
  {
    this._Service.getPendingActionsGlobal().subscribe(
      (res)=>{
        LoadOwlCarousel();
        LoadOwlCarouselForMenu_Glo_Mobile();
        LoadJsForDashBoardMobileCarousel();
        if(res.ImageUpload=="N")
        {
          this.idbadge=true;
        }
        if(res.Emergency=="N")
        {
          this.emergency=true;
        }
        if(localStorage.getItem("Region").trim().toUpperCase() != "ILICOMPAZSINGAPORE"){
        if(res.Address=="N" || res.EmergencyAddress == "N")         
        {            
          this.address=true;
          res.Address = "N";           
        }
      }
      if((localStorage.getItem("Region").trim().toUpperCase() == "ILICOMPAZSINGAPORE") && this.postjoining){
        if(res.Address=="N" || res.EmergencyAddress == "N")         
        {            
          this.address=true;
          res.Address = "N";           
        }
      }
      if(localStorage.getItem("Region").trim().toUpperCase() != "ILICOMPAZSINGAPORE"){
        if(res.BANK=="N")
        {
          this.bank=true;
        }
      }

      if((localStorage.getItem("Region").trim().toUpperCase() == "ILICOMPAZSINGAPORE") && this.postjoining){
        if(res.BANK=="N")
        {
          this.bank=true;
        }
      }
      
        if(res.Personal=="N")
        {
          this.personal=true;
        }
        if(res.SSN=="N")
        {
          this.ssn=true;
        }
        if(localStorage.getItem("Region").trim().toUpperCase() == "CANADA"){
          if(res.Insurance == "N")
          {
            this.insurance = true;
          }
        } 
        if(res.Tax=="N")
        {
          this.tax=true;
        }
        if(res.Policies == "N")
        {
          this.pendingpolicy = true;
        }
        
        if(res.Passport == "N")
        {
          this.passport = true;
        }

        if(res.Family == "N")
        {
          this.family = true;
        }

        if(res.CorpCard == "N")
        {
          this.corpcard = true;
        }
        if(res.KiwiSaver == "N")
        {
          this.kiwisaver = true;
        }    
        if(res.Disability == "N")
        {
          this.disability = true;
        } 
         // if ((localStorage.getItem("Region").trim().toUpperCase() == "BULGARIA") || (localStorage.getItem("Region").trim().toUpperCase() == "IL POLAND")) {
          if(res.MedicalInsurance== "N")
          {
            this.medical = true;
          }  
        // }  
        if(res.ResiPermit == 'N'){
          this.resiPermit = true;
        }

        if(res.ContractDoc == 'N'){
          this.contractDoc = true;
        }
        if((localStorage.getItem("Region").trim().toUpperCase() != "JERSEY") && (localStorage.getItem("Region").trim().toUpperCase() != "GUERNSEY")){
        if(res.TaxField == 'N'){
          this.taxField = true;
        } 
      }
        if(res.NRIC =='N'){
          this.NRIC = true;
        }

        if(res.HolidayAttest =='N'){
          this.holiday = true;
        }
        if (this.idbadge == false &&  this.emergency == false &&  this.address == false &&  this.bank == false &&
          this.personal == false &&  this.ssn == false &&  this.tax == false &&  this.pendingpolicy == false &&  this.passport == false &&  this.family == false &&  this.corpcard == false&& 
          this.kiwisaver == false &&  this.resiPermit == false && this.contractDoc == false && this.taxField == false &&  this.NRIC == false &&  this.holiday == false && this.medical == false &&this.disability==false ) {
          this.forAction = true;
          if(localStorage.getItem("Region").trim().toUpperCase() == "IL ROMANIA"){
            this.router.navigate(['lpmain/step1-common/address-common']) ;
            return;
          }
          else if(localStorage.getItem("Region").trim().toUpperCase() == "DENMARK" || localStorage.getItem("Region").trim().toUpperCase() == "BPM ROMANIA" ){
            this.router.navigate(['lpmain/step1-common/address-common']) 
          }
          else if(localStorage.getItem("Region").trim().toUpperCase() == "SWEDEN" || localStorage.getItem("Region").trim().toUpperCase() == "ILICOMPAZSINGAPORE"){
            this.router.navigate(['lpmain/step1-common/personal-common']);
            return;
          } else if(localStorage.getItem("Region").trim().toUpperCase() == "CANADA"){
            this.router.navigate(['lpmain/step1-common/ssn-common']);
            return;
          }
          else if(localStorage.getItem("Region").trim().toUpperCase() == "BPM POLAND"){
            this.router.navigate(['lpmain/step1-common/emergency-common']);
            return;
          }
          else{
            this.router.navigate(['lpmain/step1-common/badge-common']);
            return;
          }
        }
        
          LoadStyleForIconsGlobal(res.ImageUpload,res.Emergency,res.Address,res.BANK,res.Personal,res.SSN,res.CFN,
            res.Tax,res.Policies,res.Family,res.Passport,res.CorpCard,res.KiwiSaver,res.ResiPermit, res.ContractDoc,
            res.TaxField, res.NRIC, res.HolidayAttest, res.Insurance,res.MedicalInsurance,res.Disability);
        

  
  
          if(this.idbadge==true)
        {
          this.router.navigate(['lpmain/postjoining-common/badge-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.emergency==true)
        {
          this.router.navigate(['lpmain/postjoining-common/emergency-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.personal==true)
        {
          this.router.navigate(['lpmain/postjoining-common/personal-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.address==true)         
        {       
          this.router.navigate(['lpmain/postjoining-common/address-common'], { queryParams: { order: 'W' } });     
          return;         
        }
        if(this.NRIC == true)
        {
          this.router.navigate(['lpmain/postjoining-common/nric-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.family == true)
        {
          this.router.navigate(['lpmain/postjoining-common/family-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.passport == true)
        {
          this.router.navigate(['lpmain/postjoining-common/passport-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.resiPermit == true)
        {
          this.router.navigate(['lpmain/postjoining-common/resipermit-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.holiday == true)
        {
          this.router.navigate(['lpmain/postjoining-common/holidayattestation-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.ssn==true)
        {
          this.router.navigate(['lpmain/postjoining-common/ssn-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.tax==true)
        {
          if(localStorage.getItem("Region").trim().toUpperCase() == "IL GERMANY"){
            this.router.navigate(['lpmain/postjoining-common/insurance-common'], { queryParams: { order: 'W' } });
            return;
          }else{
            this.router.navigate(['lpmain/postjoining-common/tfn-common'], { queryParams: { order: 'W' } });
            return;
          }
          
        }
        if(this.bank==true)
        {
          this.router.navigate(['lpmain/postjoining-common/bank-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.corpcard == true)
        {
          this.router.navigate(['lpmain/postjoining-common/card-common'], { queryParams: { order: 'W' } });
          return;
        }
        if((localStorage.getItem("Region").trim().toUpperCase() != "JERSEY") && (localStorage.getItem("Region").trim().toUpperCase() != "GUERNSEY")){
        if(this.taxField == true)
        {
          this.router.navigate(['lpmain/postjoining-common/tfn-common'], { queryParams: { order: 'W' } });
          return;
        }}
        if(this.contractDoc == true)
        {
          this.router.navigate(['lpmain/postjoining-common/contractdocuments-common'], { queryParams: { order: 'W' } });
          return;
        }
        if(this.kiwisaver == true)
        {
          this.router.navigate(['lpmain/postjoining-common/kiwisaver-common'], { queryParams: { order: 'W' } });
          return;
        }  
        if(this.disability == true)
        {
          this.router.navigate(['lpmain/postjoining-common/disability-common'], { queryParams: { order: 'W' } });
          return;
        } 
        if ((localStorage.getItem("Region").trim().toUpperCase() == "BULGARIA") || (localStorage.getItem("Region").trim().toUpperCase() == "IL POLAND") || (localStorage.getItem("Region").trim().toUpperCase() == "BPM ROMANIA")) {
          if(this.medical == true)
          {
            this.router.navigate(['lpmain/postjoining-common/medical-common'], { queryParams: { order: 'W' } });
            return;
          }  
        }
        if(this.pendingpolicy == true)
        {
          this.router.navigate(['lpmain/postjoining-common/policies-common'], { queryParams: { order: 'W' } });
          return;
        }          
        }
      )
  
      }
}
