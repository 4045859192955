import { Injectable} from "@angular/core";
import { NgbDateParserFormatter, NgbDateStruct } from "@ng-bootstrap/ng-bootstrap";




@Injectable()
export class NgbDateFRParserFormatter extends NgbDateParserFormatter {
    parse(value: String): NgbDateStruct {
        if (value) {
            const dateParts = value.trim().split('/');
            if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
                return {year: this.toInteger(dateParts[0]), month: null, day: null};
            } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
                return {year: this.toInteger(dateParts[1]), month: this.toInteger(dateParts[0]), day: null};
            } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
                return {year: this.toInteger(dateParts[2]), month: this.toInteger(dateParts[0]), day: this.toInteger(dateParts[1])};
            }
        }   
        return null;
    }
    parsenew(val: String): NgbDateStruct {
        if (val) {
            const datetimepart=val.trim().split('T')
            const value=datetimepart[0];
            const dateParts = value.trim().split('-');
            if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
                return {year: this.toInteger(dateParts[0]), month: null, day: null};
            } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
                return {year: this.toInteger(dateParts[1]), month: this.toInteger(dateParts[0]), day: null};
            } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
                return {year: this.toInteger(dateParts[0]), month: this.toInteger(dateParts[1]), day: this.toInteger(dateParts[2])};
            }
        }   
        return null;
    }
    parseIndia(value: String): NgbDateStruct {
        if (value) {
            const dateParts = new Date(value.trim().slice(0,11)).toLocaleDateString().split('/');
            if (dateParts.length === 1 && this.isNumber(dateParts[0])) {
                return {year: this.toInteger(dateParts[0]), month: null, day: null};
            } else if (dateParts.length === 2 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1])) {
                return {year: this.toInteger(dateParts[1]), month: this.toInteger(dateParts[0]), day: null};
            } else if (dateParts.length === 3 && this.isNumber(dateParts[0]) && this.isNumber(dateParts[1]) && this.isNumber(dateParts[2])) {
                return {year: this.toInteger(dateParts[2]), month: this.toInteger(dateParts[0]), day: this.toInteger(dateParts[1])};
            } 
        }   
        return null;
    }

    format(date: NgbDateStruct): string {
        let stringDate: string = ""; 
        if(date) {
            stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + "/" : "";
            stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + "/" : "";
            stringDate += date.year;
        }
        return stringDate;
    }

    formatString(date: NgbDateStruct): string {
        let stringDate: string = ""; 
        if(date) {
            stringDate += this.isNumber(date.month) ? this.padNumber(date.month) + "/" : "";
            stringDate += this.isNumber(date.day) ? this.padNumber(date.day) + "/" : "";
            stringDate += date.year;
        }
        else{console.log('empty')}
        return stringDate;
    }

    padNumber(value: number) {
        if (this.isNumber(value)) {
            return `0${value}`.slice(-2);
        } else {
            return "";
        }
    }
    
    isNumber(value: any): boolean {
        return !isNaN(this.toInteger(value));
    }
    
    toInteger(value: any): number {
        return parseInt(`${value}`, 10);
    }

    formatMatDate(date: Date): string {
        let stringDate: string = ""; 
        if(date) {
            stringDate += this.isNumber(date.getMonth()) ? this.padNumber(date.getMonth()+1) + "/" : "";
            stringDate += this.isNumber(date.getDate()) ? this.padNumber(date.getDate()) + "/" : "";
            stringDate += date.getFullYear();
        }
        return stringDate;
    }
    
}
